<div id="demo" class="carousel slide" data-ride="carousel">

    <!-- The slideshow -->
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="bannerSlide1 text-white slider_section">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <h3 class="font-weight-bold">Offload your Administration and Support with our Global Managed Services</h3>
                        </div>
                    </div>
                    <div class="col-md-6 text-md-right text-center">
                        <div>
                            <img src="../../assets/images/home/header-img.webp" alt="" class="img-fluid px-5">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="carousel-item">
            <div class="bannerSlide2 text-white slider_section">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="">
                            <h3 class="font-weight-bold">Release Hidden value in your HR Technology</h3>
                            <p>Try our calculator to see how much value you can create with Pixentia</p>
                        </div>
                        <div class="mt-4">
                            <p>
                                <a href="" class="btn_style text-white">Take me to the calculator</a>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 text-md-right text-center">
                        <div>
                            <img src="../../assets/images/home/Calculator.webp" alt="" class="img-fluid px-5">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="carousel-item">
            <div class="bannerSlide3 text-white slider_section">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="">
                            <h3 class="font-weight-bold">Empower your people</h3>
                            <p>Unleash the creative energy in your workforce</p>
                        </div>
                        <div class="mt-4">
                            <p>
                                <a href="services/hcm/hcm-coe" class="btn_style">Explore HR & Learning Management</a>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 text-md-right text-center">
                        <div>
                            <img src="../../assets/images/home/Empower Your Workforce.webp" alt="" class="img-fluid px-5">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <div class="bannerSlide4 text-white slider_section">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="">
                            <h3 class="font-weight-bold">SumTotal Learning Services</h3>
                            <p>Realize the full potential of your SumTotal Technology</p>
                        </div>
                        <div class="mt-4">
                            <p>
                                <a href="services/cloud-transformation/sumtotal" class="btn_style text-white">Sumtotal Services</a>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 text-md-right text-center">
                        <div>
                            <img src="../../assets/images/home/Innovative Implementation.webp" alt="" class="img-fluid px-5">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Left and right controls -->
    <a class="carousel-control-prev" href="#demo" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#demo" data-slide="next">
        <span class="carousel-control-next-icon"></span>
    </a>

</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" width="200" height="" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" width="200" height="" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" width="200" height="" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" width="200" height="" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" width="200" height="" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row homepage_services mb-5">
        <div class="col-md-12 mb-4">
            <div class="text-center">
                <h1>Agile Solutions for Your Workforce</h1>
                <p class="px-5">
                    Pixentia creates innovative technology solutions that enable our clients to develop agile workforces, <span class="d-inline-block">delight their customers, grow revenue and improve the
                        bottom line.</span>
                </p>
            </div>
        </div>
        <div class="col-md-6 border-right border-bottom">
            <div>
                <img src="../../assets/images/home/HCM.webp" width="350" height="350" class="img-fluid" alt="">
            </div>
            <div>
                <h3>HUMAN CAPITAL MANAGEMENT</h3>
                <p class="">
                    Implementation, optimization, content, and support for human capital management software platforms
                </p>
                <p>
                    <a routerLink="/services/hcm/hcm-coe" class="btn_style">
                        More About HCM <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                </p>
            </div>
        </div>
        <div class="col-md-6 pl-md-5 border-bottom">
            <div>
                <img src="../../assets/images/home/Learning.webp" width="350" height="350" class="img-fluid" alt="">
            </div>
            <div>
                <h3>LEARNING</h3>
                <p class="">
                    When canned courses won’t do: personalized learning design, from simple gamified e-learning to advanced AI-driven modalities
                </p>
                <p>
                    <a routerLink="/services/learning/learning-coe" class="btn_style">
                        More about Learning <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                </p>
            </div>
        </div>
        <div class="col-md-6 border-right mb-md-5">
            <div class="mt-md-4">
                <img src="../../assets/images/home/Mobile application.webp" width="350" height="350" class="img-fluid" alt="">
            </div>
            <div>
                <h3>MOBILE APPLICATIONS</h3>
                <p class="">
                    Empower your mobile workforce with apps optimized for iOS, Android<span class="d-inline-block"> and
                        Windows</span>
                </p>
                <p>
                    <a routerLink="/services/mobile" class="btn_style">
                        More about Mobile Applications <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                </p>
            </div>
        </div>
        <div class="col-md-6 pl-md-5 mb-md-5">
            <div class="mt-md-4">
                <img src="../../assets/images/home/Advanced analytics.webp" width="350" height="350" class="img-fluid" alt="">
            </div>
            <div>
                <h3>ADVANCED ANALYTICS</h3>
                <p class="">
                    Make decisions at the speed of business with the analytical power you need to build an edge
                </p>
                <p>
                    <a routerLink="/services/analytics" class="btn_style">
                        More about Advanced Analytics <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                </p>
            </div>
        </div>
    </div>
    <app-get-started></app-get-started>
</div>