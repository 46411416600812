import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-digital-transformation',
  templateUrl: './digital-transformation.component.html',
  styleUrls: ['./digital-transformation.component.scss']
})
export class DigitalTransformationComponent implements OnInit {

  title = 'Your Path to Digital HR Transformation';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Pixentia supports your HR digital transformation with a strategy that enables you to drive change instead of being driven by it.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Your Path to Digital HR Transformation' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Pixentia supports your HR digital transformation with a strategy that enables you to drive change instead of being driven by it.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/digital-transformation' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Your Path to Digital HR Transformation' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Pixentia supports your HR digital transformation with a strategy that enables you to drive change instead of being driven by it.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/digital-transformation' }      
    );
  }

}