<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps">Chasma Apps</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps/chasma-lexy">Chasma Lexy</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Lexy</h3>
                <p>AI-Enabled Virtual Assistant for Human Capital Management Applications</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/lexy/Lexy-image.webp" alt="Chasma lexy image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">YOUR HR VIRTUAL ASSISTANT</h1>
                <div>
                    <p class="mb-3">
                        Your HR staff and your employees spend too much time looking for information and getting answers to simple, repetitive questions. Cognitive computing enables us to assign those tasks to computers so you and your people can focus on more productive work.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/lexy/Improve the user.png" alt="Improve the employee experience">
                </div>
                <div class="px-5">
                    <p>
                        Improve the employee experience in your HR processes.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/lexy/Enhance your.png" alt="Enhance your employer brand">
                </div>
                <div class="px-5">
                    <p>
                        Enhance your employer brand by improving communication with prospective hires <span class="d-inline-block">and ex-employees.</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/lexy/Drive Higher.png" alt="Drive higher productivity">
                </div>
                <div class="px-5">
                    <p>
                        Drive higher productivity <span class="d-inline-block">in your HR team.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center mb-4">
                <h4 class="font-weight-bold py-4 mb-0">Chasma Lexy Advantage</h4>
                <p>Lexy by Chasma helps you streamline the user experience while extending the capabilities of your HR technology.
                </p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/lexy/Fast Response.png" alt="Fast Response" class="mb-3">
                    <p class="font-weight-bold mb-0">Fast Response</p>
                    <p class="px-4">
                        Employees don’t have to wait for a callback or email from overworked staff.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/lexy/Collaboration Compatible.png" alt="Collaboration Compatible" class="mb-3">
                    <p class="font-weight-bold mb-0">Collaboration Compatible</p>
                    <p class="px-4">
                        Deploy Lexy in Slack, <span class="d-inline-block">Microsoft Teams, and Skype.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/lexy/AI-Assisted Search.png" alt="AI-Assisted Search" class="mb-3">
                    <p class="font-weight-bold mb-0">AI-Assisted Search</p>
                    <p class="px-4">
                        Find policies, procedures, documents, and forms on your intranet <span class="d-inline-block">or any storage site.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/lexy/Single Dashboard.png" alt="Single Dashboard for HR Notifications" class="mb-3">
                    <p class="font-weight-bold mb-0">Single Dashboard for HR Notifications</p>
                    <p class="px-4">
                        Integrated lightweight ticketing portal just for HR needs.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/lexy/Cognitive Analytics.png" alt="Cognitive Analytics" class="mb-3">
                    <p class="font-weight-bold mb-0">Cognitive Analytics</p>
                    <p class="px-4">
                        Capture user questions so you can analyze your organization’s needs.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/lexy/Integrated Assistance.png" alt="Integrated Assistance" class="mb-3">
                    <p class="font-weight-bold mb-0">Integrated Assistance</p>
                    <p class="px-4">
                        Lexy works with Cortana, Siri and <span class="d-inline-block">Alexa for Business.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Lexy?</h4>
                    <p class="">
                        Meet Lexy - Your HR Virtual assistant.<br> How much time does your HR staff spend answering simple, repetitive questions from employees, prospective employees, and ex-employees? Watch the video to see how Lexy can help you improve
                        the employee experience in your HR processes.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/256643684?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div>
                            <img src="../../assets/images/products/lexy/Ease Communication.png" class="img-fluid" alt="Ease Communication">
                        </div>
                    </div>
                    <div class="col-md-7 text-white mt-5 mt-md-0">
                        <h4 class="font-weight-bold">Ease Communication across your enterprise technology landscape
                        </h4>
                        <p class="mb-4">
                            Lexy is adaptable to most of your core HR applications, collaboration tools, cloud repositories and Artificial Intelligence platforms, so the communication and work flow across your enterprise is effortless.
                        </p>
                        <div class="more_info_btn">
                            <a href="demo">Take me to the demo <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>