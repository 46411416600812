<div class="bannerSlide1 text-white banner_section notfound_bg">
    <div class="row align-items-center">
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/404/notfound-image.png" alt="Embarrassed cartoon character looking sad and troubled" class="img-fluid px-5">
            </div>
        </div>
        <div class="col-md-6">
            <div class="">
                <h1 class="font-weight-bold">Oh, No!</h1>
                <p>We couldn’t find that! </p>
                <p class="yellow_color font-italic">This is so embarrassing…</p>
                <p>If you mistyped your query, please try again.</p>
                <p class="yellow_color font-italic">Or...</p>
                <p>Please visit our home page to find what you want.</p>
                <p class="notfound_btn"><a href=""><i class="fa fa-home mr-2" aria-hidden="true"></i>Take me to the home page</a></p>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>

</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>
<!-- <div>
    <img src="../../assets/images/404_page.png" class="img-fluid" alt="Embarrassed cartoon character looking sad and troubled">
</div> -->