import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  title = 'Pixentia | Privacy Policy for Website | Privacy policy';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Pixentia respects your privacy. This privacy policy sets our privacy practices & describes the ways we use the information provided or collected on our website.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia | Privacy Policy for Website | Privacy policy' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Pixentia respects your privacy. This privacy policy sets our privacy practices & describes the ways we use the information provided or collected on our website.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://www.pixentia.com/privacy-policy' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia | Privacy Policy for Website | Privacy policy' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Pixentia respects your privacy. This privacy policy sets our privacy practices & describes the ways we use the information provided or collected on our website.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://www.pixentia.com/privacy-policy' }      
    );
  }

}
