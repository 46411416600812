<div class="bannerSlide1 text-white banner_section">
  <div class="breadcrumbs">
    <ul>
      <li><a href="/">Home</a></li>
      <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
      <li><a>Services</a></li>
      <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
      <li><a>Human Capital</a></li>
      <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
      <li><a href="services/hcm/hcm-value-calculator">Value Calculator</a></li>
    </ul>
  </div>
  <div class="row align-items-center">
    <div class="col-md-6">
      <div>
        <h3 class="font-weight-bold">HCM Technology Value Calculator</h3>
        <p>Estimate the potential value you could realize with Pixentia technology</p>
      </div>
    </div>
    <div class="col-md-6 text-md-right text-center">
      <div>
        <img src="../../assets/images/hcm/payroll/Payroll Image.png" alt="Payroll Image" class="img-fluid px-5">
      </div>
    </div>
  </div>
</div>

<div id="main-section" class="container-fluid wrapper backgroundcolor">

  <!-- <div class="loader col-md-12 hidden">
      <i class="fa fa-spinner fa-spin text-center"></i>
    </div> -->


  <!-- stepperData -->
  <div class="stepwizard col-md-8 offset-md-3">
    <div class="stepwizard-row col-md-12 p-0 setup-panel">
      <div class="stepwizard-step col-md-5 p-0">
        <a href="#step-1" type="button" style="width: fit-content;"
          class="d-flex p-0 m-0 align-items-center justify-content-center btn_custom_class btn-success btn-circle org_data">
          <div class="col-md-2 p-0">
            <img src="../../assets/images/ROI_Images/org-normal.png" />
          </div>
          <div class="col-md-10 p-0 ml-1" style="white-space: nowrap;">
            Organization Details
          </div>
        </a>
      </div>
      <div class="stepwizard-step col-md-5 p-0">
        <a href="#step-2" type="button" style="width: fit-content;"
          class="d-flex p-0 m-0 align-items-center justify-content-center btn_custom_class btn-default btn-circle p_p_data"
          disabled="disabled">
          <div class="col-md-2 p-0">
            <img src="../../assets/images/ROI_Images/people-process-normal.png" />
          </div>
          <div class="col-md-10 p-0 ml-1" style="white-space: nowrap;">
            People Processes
          </div>
        </a>
      </div>
      <div class="stepwizard-step col-md-2 p-0">
        <a href=" #step-3" type="button" style="width: fit-content;"
          class="d-flex p-0 m-0 align-items-center justify-content-center btn_custom_class btn-default btn-circle res_data"
          disabled="disabled">
          <div class="col-md-2 p-0">
            <img src="../../assets/images/ROI_Images/results-normal.png" />
          </div>
          <div class="col-md-10 p-0 ml-1" style="white-space: nowrap;">
            Results
          </div>
        </a>
      </div>
    </div>
  </div>
  <form role="form" name="registration" action="" method="post" class="col-md-12">
    <!-- step1 -->
    <div class="row setup-content organization_details_step" id="step-1">
      <div class="col-xs-12 pad_null mar_btm" id="organization_details">
        <div class="row col-md-12 pad_null inner_data">
          <div class="col-md-3 col-sm-6 col-xs-12 left_side_section stepOne_left pad_left_one_null">
            <h4 class="left_header">
              Organization Details
            </h4>
            <p class="desc">
              <!-- Enter the basic details about the organization to proceed further -->
              Information about the current value of your human capital
            </p>
            <div class="image_class_org_big">
              <img src="../../assets/images/ROI_Images/building.png" />
            </div>
          </div>
          <div class="col-md-8 col-sm-6 col-xs-12 right_side_section">
            <p class="right_header_firstStep">
              To get started, we need a little information about your company.
              Please enter your information in this form.
            </p>
            <p class="right_header">
              Note: When we ask you for estimates, please don't fret about accuracy. Your estimates are more useful than
              you might think.
            </p>
            <div class="first_tab_form_fields">
              <div class="form-group col-md-12 fg_custom_class ">
                <label for="topLineRevenue" class="custom_for_label"
                  title="What was your top line revenue last fiscal year?">
                  <span class="singleLine">What was your top line revenue last fiscal year?</span>

                  <i class="fa fa-info-circle info_icon_data" title="Total sales or revenue"></i>
                </label>
                <input autocomplete="off" step="any" type="text" name="topLineRevenue"
                  onkeyup="if(!(event.keyCode>36 && event.keyCode<41)){topLineRevenuedata(event)}"
                  class="form-control input_custom_style padding-left-class" id="topLineRevenue"
                  placeholder="Enter the number in millions of dollars" required="required">
                <p class="dollar_tag_line">$</p>
              </div>

              <div class="form-group col-md-12 fg_custom_class ">
                <label for="businessSector" class="custom_for_label" title="What is your business sector?">
                  <span class="singleLine">
                    What is your business sector?
                  </span>
                  <i class="fa fa-info-circle info_icon_data" title="Sectors defined by Bureau of Labor Statistics"></i>
                </label>

                <div id="dynamicInput"></div>
                <p id="errorMessage"></p>
              </div>

              <div class="form-group col-md-12 fg_custom_class ">
                <label for="noOfEmployees" class="custom_for_label "
                  title="How many full time equivant (FTE) employees do you have?">
                  <span class="singleLine">
                    How many full time
                    equivant (FTE) employees do you have?
                  </span>
                  <i class="fa fa-info-circle info_icon_data"
                    title="The number of employees you report in financial statements"></i>
                </label>

                <input autocomplete="off" step="any"
                  onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){compareUpdateFunction()}" type="text"
                  class="form-control input_custom_style" id="noOfEmployees" placeholder="" required="required">
              </div>

              <div class="yourResultsHeadSection col-md-12 col-sm-12 col-xs-12 pad-null">
                <div class="yourResultsHeading col-md-12 col-sm-12 col-xs-12 pad-null">
                  <p class="col-md-12 col-sm-12 col-xs-12 pad-null resultsP">Your Results</p>
                </div>
              </div>

              <div class="form-group col-md-12 fg_custom_class avgRevenueperemployee_data">
                <label for="avgRevenueperemployee" class="custom_for_label "
                  title="Average revenue per employee in your business sector">
                  <span class="singleLine">
                    Average revenue per
                    employee in your business sector
                  </span>
                  <i class="fa fa-info-circle info_icon_data" title="According to US Bureau of Labor Statistics"></i>
                </label>

                <input autocomplete="off" step="any"
                  onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){employeesnumber(event)}" type="text"
                  class="form-control input_custom_style padding-left-class" id="avgRevenueperemployee" placeholder="">
                <p class="dollar_tag_line">$</p>
              </div>

              <div class="form-group col-md-12 fg_custom_class ">
                <label for="yourRevPerEmployee" class="custom_for_label " title="Your revenue per employee">
                  <span class="singleLine">
                    Your revenue per
                    employee
                  </span>
                  <i class="fa fa-info-circle info_icon_data" title="Top line revenue / Number of employees"></i>
                </label>

                <input autocomplete="off" step="any" readonly type="text"
                  class="form-control input_custom_style padding-left-class" id="yourRevPerEmployee" placeholder="">
                <p class="dollar_tag_line">$</p>
              </div>

              <div class="form-group col-md-12 fg_custom_class value_creation_data">
                <label for="totalGain" class="custom_for_label " title="Your value creation score">
                  <span class="singleLine">
                    Your value creation score
                  </span>
                  <i class="fa fa-info-circle info_icon_data"
                    title="Comparison of your results to the BLS benchmark"></i>
                </label>

                <input autocomplete="off" step="any" readonly type="text"
                  class="form-control input_custom_style padding_class" id="totalGain" placeholder="%">
                <p class="percentage_tag_line"></p>
                <div class="dynamic_display_text text-center"></div>
                <p class="congrats_message text-center col-md-12 pad_null hidden"></p>
                <p class="congrats_message_2 col-md-12 pad_null hidden"></p>
              </div>


              <div class="form-group col-md-12 fg_custom_class hidden potential_data">
                <label for="preferred_revenue_potential" title="Revenue potential" class="custom_for_label">
                  <span class="singleLine">
                    Revenue potential
                  </span>
                  <i class="fa fa-info-circle info_icon_data"
                    title="This is the potential revenue your could gain if you increase revenue per employee to the industry average"></i>
                </label>
                <input autocomplete="off" step="any" readonly type="text"
                  class="form-control input_custom_style padding-left-class" id="preferred_revenue_potential"
                  placeholder="">
                <p class="dollar_tag_line">$</p>
                <p class="dynamic_potential_display_text text-center">
                  Click Continue below to explore HCM areas for additional value.
                </p>
              </div>

              <!-- //previously it is in Quality analysis tab in talentAquisition -->


              <div class="updatedSection hidden">
                <div class="seperatorHeadingSection">
                  <p class="separatorHeading">Before you begin exploring, we need a little more information. </p>
                </div>

                <div class="form-group col-md-12 fg_custom_class turnOverRate">
                  <label for="turnOverRate" class="custom_for_label " title="What is your annual turnover rate?">
                    <span class="singleLine">
                      What is your annual turnover rate?
                    </span>
                    <i class="fa fa-info-circle info_icon_data" title="Employee churn rate"></i>
                  </label>

                  <input autocomplete="off" step="any"
                    onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){turnOverPercentage(event)}" type="text"
                    class="form-control input_custom_style padding_class" id="turnOverRate" placeholder="%"
                    required="required">
                  <p class="percentage_tag_line"></p>
                </div>

                <!-- //previously it is in Timeto Fill tab in talentAquisition -->
                <div class="form-group col-md-12 fg_custom_class  time_to_fill_value_data">
                  <label for="time_to_fill_value" class="custom_for_label "
                    title="What is your average time to fill (in days)?">
                    <span class="singleLine">
                      What is your average time to fill (in days)?
                    </span>
                    <i class="fa fa-info-circle info_icon_data" title="Time from job posting to first day of work"></i>
                  </label>

                  <input autocomplete="off" step="any" onkeyup=dailyandStaffing_staffing_cost_sum() type="text"
                    class="form-control input_custom_style" id="time_to_fill_value" placeholder="" required="required">
                </div>

                <div class="intimationSection">
                  <p class="intimation_text text-center">Now, let's get started. Click Continue to choose which areas to
                    explore.</p>
                </div>

              </div>
              <!-- End -->
            </div>
          </div>
        </div>
        <div class="col-md-12 step_button text-center">
          <a class="nextBtn button_continue" type="button">
            Continue <i class='fa fa arrow_icon'>&#xf061;</i>
          </a>
        </div>
      </div>
    </div>

    <!-- step2 -->
    <div class="row setup-content" id="step-2">
      <div class="col-xs-12 pad_null mar_btm" id="people_processes">
        <div class="row col-md-12 pad_null inner_data">
          <div class="col-md-3 col-sm-6 left_side_section pad_left_one_null">
            <div class="col-md-12 data_left_part">
              <h4 class="left_header">
                Which functional area would you like to review on?
              </h4>
              <div class="functional_check">

              </div>
            </div>
          </div>
          <div class="col-md-8 col-sm-6 col-xs-12 right_side_section">
            <div class="row toast_message text-center">

              <p class="col-md-12 hidden afterFirstStep">
                Select one or more functional areas from the list on the left
              </p>
              <p class="col-md-12 firstSelect toast_allert_message">
                Please fill in the organization details.
              </p>
              <div class="col-md-12 secondStep_image">
                <img src="../../assets/images/ROI_Images/Form.png">
              </div>
            </div>

            <div id="sectionForTabsData" class="col-md-12 pad-null">
              <!-- talent Aquisition tabs_part -->
              <div class="tabs_Data talentAquisition_info col-md-12 px-0 pad-null hidden" id="talentAquisition_info_id">

                <p class="title_headings">Talent Aquisition</p>

                <ul class="row d-flex align-items-center justify-content-center nav nav-tabs m-0">
                  <li class="col-md-6 nav-item p-0 text-center">
                    <a data-toggle="tab" href="#quality_of_hire" class="nav-link active qualityOfHire_tabs">Quality of
                      Hire</a><span class="horizontal-border"></span>
                  </li>
                  <li class="col-md-6 nav-item p-0 text-center">
                    <a data-toggle="tab" href="#time_to_fill" class="nav-link time_to_fill_tabs">
                      Time to Fill
                    </a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div id="quality_of_hire" class="tab-pane active col-md-12 pad_null tab_conetent_data">
                    <!-- For Talent Aquisition -->
                    <div class="pad_null col-md-12 talentAquisition_info hidden">

                      <div class="form-group col-md-12 fg_custom_class nintyDayAttrition">
                        <label for="nintyDayAttrition" class="custom_for_label"
                          title="Estimate the percentage of your new hires who fail to make it to 90 days">
                          <span class="singleLine">
                            Estimate
                            the percentage of your new hires who fail to make it to 90
                            days
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Your 90 day attrition rate"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){nintyDayAttritionPercentage(event)}"
                          type="text" class="form-control input_custom_style padding_class" id="nintyDayAttrition"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  numberOfAnnualHires">
                        <label for="numberOfAnnualHires" class="custom_for_label "
                          title="How many employees did you hire in your most recent fiscal year?">
                          <span class="singleLine">
                            How
                            many employees did you hire in your most recent fiscal
                            year?
                          </span>


                          <i class="fa fa-info-circle info_icon_data"
                            title="Number of annual hires (attrition X headcout)"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){nintyDayAttritionPercentage(event)}"
                          type="text" class="form-control input_custom_style" id="numberOfAnnualHires" placeholder="">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class turnOverRate">
                        <label for="turnOverRate_QualityOf_hire" class="custom_for_label "
                          title="Your annual total attrition rate">
                          <span class="singleLine">
                            Your annual total attrition rate
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Your turnover rate"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){turnOverPercentage(event)}" type="text"
                          class="form-control input_custom_style padding_class" id="turnOverRate_QualityOf_hire"
                          placeholder="" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class revenueLoss">
                        <label for="revenueLoss" class="custom_for_label "
                          title="This is our estimate of the average lost revenue from a bad hire, based on revenue per employee.">
                          <span class="singleLine">
                            This
                            is our estimate of the average lost revenue from a bad hire,
                            based on revenue per employee.
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="12.5% of revenue per employee"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){nintyDayAttritionPercentage(event)}"
                          readonly type="text" class="form-control input_custom_style padding-left-class"
                          id="revenueLoss" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  total_staffing_cost_data">
                        <label for="total_staffing_cost" class="custom_for_label "
                          title="Based on our estimates of hiring costs, this is your Your total staffing cost per hire ">
                          <span class="singleLine">
                            Based on our estimates of hiring costs, this is your total staffing cost per hire
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Total staffing cost"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="total_staffing_cost"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  90_day_attrition_number">
                        <label for="90_day_attrition_number" class="custom_for_label "
                          title="Your estimated Number of 90-day failed candidates per year">
                          <span class="singleLine">
                            Your estimated number of 90-day failed candidates per year
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Employees hired X 90 day attrition rate"></i>
                        </label>


                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){nintyDayAttritionPercentage(event)}"
                          readonly type="text" class="form-control input_custom_style" id="90_day_attrition_number"
                          placeholder="">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  cost_of_bad_hires">
                        <label for="cost_of_bad_hires" class="custom_for_label "
                          title="Estimated total cost of bad hires per year">
                          <span class="singleLine">
                            Estimated total cost of bad hires per year
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="total cost of bad hires per year"></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="cost_of_bad_hires"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>


                    </div>

                    <div class="tabFooterNote col-md-12 pad-null hidden">
                      <div class="pad-null col-md-6 text-left informationRegardingKPI">
                        <p class="btn_infoData" id="clickNext_text">Switch to Time to fill tab</p>
                        <p class="nextButtonClick_info_data hidden " id="clickNext_text">Please click on Continue button
                          to get Results</p>
                      </div>
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitch(event)" id="Yes"
                          value="Yes">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitch(event)" id="No" value="No">

                      </div>
                    </div>

                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="time_to_fill_tabs" data-infoKPI="talentAquisition_KPI"
                          value="Proceed to time to fill tab">
                      </div>
                    </div>

                  </div>

                  <div id="time_to_fill" class="tab-pane fade col-md-12 pad_null tab_conetent_data">

                    <div class="pad_null col-md-12 talentAquisition_info hidden">
                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="industry_recruiting" class="custom_for_label " tooltip="Select your industry">
                          <span class="singleLine">
                            Select your industry
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Choose your recruiting industry"></i>
                        </label>

                        <div id="dynamic_industry_recruiting_Input"></div>
                      </div>
                      <!-- pre -->

                      <div class="form-group col-md-12 fg_custom_class  industry_average_ttf_data">
                        <label for="industry_average_ttf" class="custom_for_label "
                          title="Industry average time to fill">
                          <span class="singleLine">
                            Industry average time to fill
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Industry average time to fill"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style" id="industry_average_ttf" placeholder="">
                      </div><!-- pre -->
                      <!-- updated -->
                      <div class="form-group col-md-12 fg_custom_class  time_to_fill_value_in_timetoFillTabData">
                        <label for="time_to_fill_value_in_timetoFillTab" class="custom_for_label"
                          title="What is your average time to fill (in days)?">
                          <span class="singleLine">
                            Your average time to fill (in days)?
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Time to fill"></i>
                        </label>

                        <input readonly autocomplete="off" step="any" onkeyup=dailyandStaffing_staffing_cost_sum()
                          type="text" class="form-control input_custom_style" id="time_to_fill_value_in_timetoFillTab"
                          placeholder="" required="required">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  velocity_gap_days_data"
                        id="velocity_gap_days_id">
                        <label for="velocity_gap_days" class="custom_for_label" title="Recruiting velocity gap">
                          <span class="singleLine">
                            Recruiting velocity gap
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Velocity gap (days)"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style" id="velocity_gap_days" placeholder=""
                          required="required">
                        <p class="negativeSign hidden orangeColor"></p>
                      </div><!-- pre -->
                      <!-- outputs -->

                      <!-- updatedAnd IncludedFormLabels -->
                      <div class="form-group col-md-12 fg_custom_class  velocity_gain_days_data"
                        id="velocity_gain_days_id">
                        <label for="velocity_gain_days" class="custom_for_label "
                          title="If you increased your velocity  by 10%, you would reduce time to fill by this many days">
                          <span class="singleLine">
                            If
                            you increased your velocity by 10%, you would reduce time to
                            fill by this many days
                          </span>

                          <i class="fa fa-info-circle info_icon_data" title="Velocity gain in days"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style" id="velocity_gain_days" placeholder=""
                          required="required">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class velocity_opportunity_data hidden">
                        <label for="velocity_opportunity" class="custom_for_label "
                          title="If you reduced time to fill by 10% you would reduce cost per hire by:">
                          <span class="singleLine">
                            If you reduced time to fill by 10% you would reduce cost per hire by:
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Velocity cost reduction"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="velocity_opportunity"
                          required="required" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  velocity_gap_cost_data">
                        <label for="velocity_gap_cost" class="custom_for_label"
                          title="And you would reduce cost per hire by ">
                          <span class="singleLine">
                            And you would reduce cost per hire by
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Savings per hire from a 10% reduction in time to fill"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="velocity_gap_cost"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div> <!-- pre -->

                      <div class="form-group col-md-12 fg_custom_class  total_cost_savings_data">
                        <label for="total_cost_savings" class="custom_for_label " title="Total cost savings ">
                          <span class="singleLine">
                            Total cost
                            savings
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Total cost savings"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="total_cost_savings"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div> <!-- pre -->

                      <div class="form-group col-md-12 fg_custom_class  cost_savings_of_improved_quality_of_hire">
                        <label for="cost_savings_of_improved_quality_of_hire" class="custom_for_label "
                          title="Savings from a 10% reduction in the number of employees who fail. ">
                          <span class="singleLine">
                            Savings
                            from a 10% reduction in the number of employees who
                            fail.
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Savings from a 10% reduction in the number of employees who fail"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){nintyDayAttritionPercentage(event)}"
                          readonly type="text" class="form-control input_custom_style padding-left-class"
                          id="cost_savings_of_improved_quality_of_hire" placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <!-- updated End Fields -->
                      <!-- predictive values starts -->
                      <div class="estimatedPredectiveValuesData hidden usedForPredectiveValues">

                        <div class="predective_values col-md-12 text-center">
                          <p class="predective_values_desc">
                            Our estimates of direct hiring costs
                          </p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  daily_cost_of_covering_vacancy_data hidden">
                          <label for="daily_cost_of_covering_vacancy" class="custom_for_label "
                            title="Daily cost of coverying a vacancy, based on a $50,000 salary">
                            <span class="singleLine">
                              Daily cost of coverying a vacancy, based on a $50,000 salary
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Based on a $50,000 salary"></i>
                          </label>

                          <input autocomplete="off" step="any"
                            onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){dailyandStaffing_staffing_cost_sum()}"
                            type="text" value="86" class="form-control input_custom_style padding-left-class"
                            id="daily_cost_of_covering_vacancy" placeholder="" required="required">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  manager_hourly_cost_data hidden">
                          <label for="manager_hourly_cost" class="custom_for_label "
                            title="Hourly cost of manager time, based on a $90,000 salary">
                            <span class="singleLine">
                              Hourly cost of manager time, based on a $90,000 salary
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Based on an $90,000 salary"></i>
                          </label>

                          <input autocomplete="off" step="any"
                            onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){dailyandStaffing_staffing_cost_sum()}"
                            type="text" value="43" class="form-control input_custom_style padding-left-class"
                            id="manager_hourly_cost" placeholder="" required="required">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  recruiter_hourly_cost_data hidden">
                          <label for="recruiter_hourly_cost" class="custom_for_label "
                            title="Recrruiter cost, based on 20 hours per vacancy">
                            <span class="singleLine">
                              Recrruiter cost, based on 20 hours per vacancy
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Based on 20 hours per vacancy"></i>
                          </label>

                          <input autocomplete="off" step="any"
                            onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){dailyandStaffing_staffing_cost_sum()}"
                            type="text" value="24" class="form-control input_custom_style padding-left-class"
                            id="recruiter_hourly_cost" placeholder="">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  background_check_data hidden">
                          <label for="background_check" class="custom_for_label " title="Outsourced background check">
                            <span class="singleLine">
                              Outsourced background check
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Outsourced background check"></i>
                          </label>

                          <input autocomplete="off" step="any"
                            onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){totalCost_per_hire()}" type="text"
                            value="500" class="form-control input_custom_style padding-left-class" id="background_check"
                            placeholder="" required="required">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  interviews_data hidden">
                          <label for="interviews" class="custom_for_label " title="Estimated interview cost">
                            <span class="singleLine">
                              Estimated interview cost
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Estimated interview cost"></i>
                          </label>

                          <input autocomplete="off" step="any"
                            onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){totalCost_per_hire()}" type="text"
                            value="35" class="form-control input_custom_style padding-left-class" id="interviews"
                            placeholder="" required="required">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  advertising_data hidden">
                          <label for="advertising" class="custom_for_label" title="Advertising">
                            <span class="singleLine">
                              Advertising
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Estimated advertising cost"></i>
                          </label>

                          <input autocomplete="off" step="any"
                            onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){totalCost_per_hire()}" type="text"
                            value="1200" class="form-control input_custom_style padding-left-class" id="advertising"
                            placeholder="" required="required">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  resume_screening_data hidden">
                          <label for="resume_screening" class="custom_for_label " title="Resume screening">
                            <span class="singleLine">
                              Resume screening
                            </span>

                            <i class="fa fa-info-circle info_icon_data" title="Resume screening value"></i>
                          </label>

                          <input autocomplete="off" step="any"
                            onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){totalCost_per_hire()}" type="text"
                            class="form-control input_custom_style padding-left-class" id="resume_screening"
                            placeholder="">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  onboarding_cost_data hidden">
                          <label for="onboarding_cost" class="custom_for_label " title="Onboarding">
                            <span class="singleLine">
                              Onboarding
                            </span>

                            <i class="fa fa-info-circle info_icon_data" title="Estimated onboarding cost"></i>
                          </label>

                          <input autocomplete="off" step="any"
                            onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){totalCost_per_hire()}" type="text"
                            value="2400" class="form-control input_custom_style padding-left-class" id="onboarding_cost"
                            placeholder="" required="required">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <!-- updatedOne total_staffing_cost_2 changes to Cost of Covering Vacancy-->

                        <div class="form-group col-md-12 fg_custom_class  cost_of_covering_vacancy_data">
                          <label for="cost_of_covering_vacancy" class="custom_for_label "
                            title="Cost of covering a vacancy (daily cost X time to fill).">
                            <span class="singleLine">
                              Cost of covering a vacancy (daily cost X time to fill).
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Cost of covering vacancy"></i>
                          </label>

                          <input autocomplete="off" step="any" readonly type="text"
                            class="form-control input_custom_style padding-left-class" id="cost_of_covering_vacancy"
                            placeholder="">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  t_a_manager_cost_data">
                          <label for="t_a_manager_cost" class="custom_for_label "
                            title="Manager cost of 4 in-person interviews per vacancy">
                            <span class="singleLine">
                              Manager cost of 4 in-person interviews per vacancy
                            </span>

                            <i class="fa fa-info-circle info_icon_data"
                              title="Estimated 4 in-person interviews per vacancy"></i>
                          </label>

                          <input autocomplete="off" step="any" readonly type="text"
                            class="form-control input_custom_style padding-left-class" id="t_a_manager_cost"
                            placeholder="">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  recruiter_cost_data">
                          <label for="recruiter_cost" class="custom_for_label "
                            title="Recrruiter cost, based on 20 hours per vacancy">
                            <span class="singleLine">
                              Recrruiter cost, based on 20 hours per vacancy
                            </span>

                            <i class="fa fa-info-circle info_icon_data" title="Estimated 20 hours per vacancy"></i>
                          </label>

                          <input autocomplete="off" step="any" readonly type="text"
                            class="form-control input_custom_style padding-left-class" id="recruiter_cost"
                            placeholder="">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  daily_staffing_cost_data hidden">
                          <label for="daily_staffing_cost" class="custom_for_label " title="Daily staffing cost">
                            <span class="singleLine">
                              Daily staffing cost
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Daily staffing cost Value"></i>
                          </label>

                          <input autocomplete="off" step="any" readonly type="text"
                            class="form-control input_custom_style padding-left-class" id="daily_staffing_cost"
                            placeholder="" required="required">
                          <p class="dollar_tag_line">$</p>
                        </div>
                        <!--pre deleted in updated xl-->


                        <div class="form-group col-md-12 fg_custom_class  total_cost_per_hire_data hidden">
                          <label for="total_cost_per_hire" class="custom_for_label" title="Total cost Per hire">
                            <span class="singleLine">
                              Total cost Per hire
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Total cost Per hire"></i>
                          </label>

                          <input autocomplete="off" step="any" readonly type="text"
                            class="form-control input_custom_style padding-left-class" id="total_cost_per_hire"
                            placeholder="">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  estimated_average_hiring_cost_data">
                          <label for="estimated_average_hiring_cost" title="Total direct cost"
                            class="custom_for_label ">
                            <span class="singleLine">
                              Total direct cost
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Total direct cost"></i>
                          </label>

                          <input autocomplete="off" step="any" readonly type="text"
                            class="form-control input_custom_style padding-left-class"
                            id="estimated_average_hiring_cost" placeholder="">
                          <p class="dollar_tag_line">$</p>
                        </div>

                        <div class="form-group col-md-12 fg_custom_class  estimated_daily_hiring_cost_data">
                          <label for="estimated_daily_hiring_cost" title="Estimated daily hiring cost"
                            class="custom_for_label ">
                            <span class="singleLine">
                              Estimated daily hiring cost
                            </span>
                            <i class="fa fa-info-circle info_icon_data" title="Estimated daily hiring cost"></i>
                          </label>

                          <input autocomplete="off" step="any" readonly type="text"
                            class="form-control input_custom_style padding-left-class" id="estimated_daily_hiring_cost"
                            placeholder="">
                          <p class="dollar_tag_line">$</p>
                        </div>

                      </div>

                      <div class="switchData">
                        <div class="pad-null">
                          <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                            data-name="qualityOfHire_tabs" data-infoKPI="talentAquisition_KPI"
                            value="Proceed to quality of hire tab">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- TalentAquisition End -->
                </div>
              </div>

              <!-- talent Aquisition tabs_part end-->
              <!-- old talent Management -->
              <div class="tabs_Data talent_management_info_old col-md-12 pad_null hidden">
                <p class="title_headings">Talent Management</p>
                <ul class="row d-flex align-items-center justify-content-center nav nav-tabs m-0">
                  <li class="col-md-3 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link active" href="#recruiting_automation">Recruiting Automation</a>
                  </li>
                  <li class="col-md-3 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link" href="#onboarding_automation">Onboarding Automation</a>
                  </li>
                  <li class="col-md-3 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link" href="#hr_services_delivery_automation">
                      HR Services Delivery
                      Automation
                    </a>
                  </li>
                  <li class="col-md-3 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link" href="#performance_compensation_management">
                      Performance/Compensation
                      Management
                    </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div id="recruiting_automation" class="tab-pane active col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 recruiting_automation_info_fields">

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="recruting_cost_savings" class="custom_for_label " title="Recruting Cost Savings">
                          <span class="singleLine">
                            Recruting Cost Savings
                          </span>

                          <i class="fa fa-info-circle info_icon_data"
                            title="Online recruiting can cut cycle times by 25% and reduce recruitment costs by more than $8000 per hire. Can you please fill 'Your Turnover Rate(in TalentAquisition), Number of Employees and Your Revenue per Employee(in Organization Details)' to get this value"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="recruting_cost_savings"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="benefit_of_reduced_cycle_time" class="custom_for_label "
                          title="Benefit of Reduced Cycle Time">
                          <span class="singleLine">
                            Benefit of Reduced Cycle
                            Time
                          </span>

                          <i class="fa fa-info-circle info_icon_data"
                            title="Faster recycle time helps to reduce 'ghosting' and enables you to engage more talented candidates. We assume a benefit equal to 80% higher engagement in talent hired because of candidate satisfaction and the ability to compete for top talent."></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="benefit_of_reduced_cycle_time"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="benefit_of_quality_of_hire" class="custom_for_label "
                          title="Benefit Of Quality of Hire">
                          <span class="singleLine">
                            Benefit Of Quality of
                            Hire
                          </span>

                          <i class="fa fa-info-circle info_icon_data"
                            title="Better hiring decisions will drive lower attrition rates. We assume a 10% decrease in turnover."></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="benefit_of_quality_of_hire"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="total_benefit_of_recruiting_automation" class="custom_for_label "
                          title="Total Benefit of Recruiting Automation">
                          <span class="singleLine">
                            Total Benefit of
                            Recruiting Automation
                          </span>

                          <i class="fa fa-info-circle info_icon_data" title="Total Recruiting Automation Benefit."></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="total_benefit_of_recruiting_automation" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                    </div>
                  </div>

                  <div id="old_onboarding_automation" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 onboarding_automation_info_fields">

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="old_onboarding_cost_savings" class="custom_for_label "
                          title="Onboarding Cost Savings">
                          <span class="singleLine">
                            Onboarding Cost
                            Savings
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Average $182 per hire (Nucleus Research)"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="old_onboarding_cost_savings"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="old_onboarding_benefit" class="custom_for_label " title="Benefits of Onboarding">
                          <span class="singleLine">
                            Benefits of Onboarding
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Employees who have a positive onboarding experience are 18 time more likely to be committed to their organization"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="old_onboarding_benefit"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="old_total_benefit_of_onboarding_automation" class="custom_for_label "
                          title="Total Onboarding Automation Benefit">
                          <span class="singleLine">
                            Total Onboarding
                            Automation Benefit
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Total benefit of onboarding automation"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="old_total_benefit_of_onboarding_automation" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>
                    </div>
                  </div>

                  <div id="hr_services_delivery_automation" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 hr_services_delivery_automation_info_fields">

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="expected_reduction_in_HR_service_calls_old" class="custom_for_label "
                          title="Expected Reduction in HR Service Calls">
                          <span class="singleLine">
                            Expected
                            Reduction in HR Service Calls
                          </span>

                          <i class="fa fa-info-circle info_icon_data"
                            title="With on-demand resources and HR chatbot"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class"
                          id="expected_reduction_in_HR_service_calls_old" value="50" placeholder="%">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="hr_staff_reallocated_resources" class="custom_for_label "
                          title="Hr Staff Reallocated Resources">
                          <span class="singleLine">
                            Hr Staff Reallocated
                            Resources
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Based on 50% of staff assigned to respond to service calls, 40% of time spent on service calls"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="hr_staff_reallocated_resources"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="assumed_employee_productivity_gain" class="custom_for_label "
                          title="Assumed Employee Productivity Gain">
                          <span class="singleLine">
                            Assumed Employee
                            Productivity Gain
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Assumed 2% productivity gain from improved employee experience"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text" value="2"
                          class="form-control input_custom_style padding_class" id="assumed_employee_productivity_gain"
                          placeholder="%">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="expected_employee_productivity_gain" class="custom_for_label "
                          title="Expected Employee Productivity Gain">
                          <span class="singleLine">
                            Expected Employee
                            Productivity Gain
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Value of employee experience gain"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="expected_employee_productivity_gain" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>
                    </div>
                  </div>

                  <div id="performance_compensation_management"
                    class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 performance_compensation_management_info_fields">

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="manager_headcount" class="custom_for_label " title="Manager Headcount">
                          <span class="singleLine">
                            Manager Headcount
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="How many managers do you have?"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){performanceCompendationFunction()}"
                          type="text" class="form-control input_custom_style" id="manager_headcount" placeholder="">
                        <p class="tag_line pull-right"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="manager_cost" class="custom_for_label " title="Manager Cost">
                          <span class="singleLine">
                            Manager Cost
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Estimate $90,000 per manager"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="manager_cost" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="reduction_in_manager_admin_time" class="custom_for_label "
                          title="Reduction in Manager Admin Time">
                          <span class="singleLine">
                            Reduction in Manager
                            Admin Time
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Estimate 5 hours per year"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){performanceCompendationFunction()}"
                          readonly type="text" class="form-control input_custom_style padding-left-class"
                          id="reduction_in_manager_admin_time" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="increased_workforce_productivity" class="custom_for_label "
                          title="Increased Workforce Productivity">
                          <span class="singleLine">
                            Increased Workforce
                            Productivity
                          </span>

                          <i class="fa fa-info-circle info_icon_data" title="Estimate 2%"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){performanceCompendationFunction()}"
                          readonly type="text" class="form-control input_custom_style padding-left-class"
                          id="increased_workforce_productivity" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="reduction_in_turnover" class="custom_for_label " title="Reduction in Turnover">
                          <span class="singleLine">
                            Reduction in Turnover
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Estimate 1%"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){performanceCompendationFunction()}"
                          readonly type="text" class="form-control input_custom_style padding-left-class"
                          id="reduction_in_turnover" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="reduction_in_turnover" class="custom_for_label " title="Total PM CM Benefit">
                          <span class="singleLine">
                            Total PM CM Benefit
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Sum of the 'Reduction in Manager Admin Time' and 'Reduction in Turnover'"></i>
                        </label>


                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="reduction_in_turnover"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <!-- old talent Management End -->
              <!-- New Talent Management -->
              <div class="tabs_Data talent_management_info col-md-12 pad_null hidden" id="talent_management_info_id">
                <p class="title_headings">Talent Management</p>

                <div class="form-group col-md-12 fg_custom_class ">
                  <label for="global_target_engagement_score" class="custom_for_label "
                    title="What is your target percentage of employees engaged?">
                    <span class="singleLine">
                      What is your target percentage of employees engaged?
                    </span>
                    <i class="fa fa-info-circle info_icon_data" title="Your internal engagement target"></i>
                  </label>
                  <input autocomplete="off" step="any"
                    onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){new_employee_engagement_tabfunction(event)}"
                    type="text" class="form-control input_custom_style padding_class"
                    id="global_target_engagement_score" placeholder="%" required="required">
                  <p class="percentage_tag_line"></p>
                </div>

                <div class="form-group col-md-12 fg_custom_class ">
                  <label for="global_engagement_score_talentManagement" class="custom_for_label "
                    title="What percentage of your employees are engaged?">
                    <span class="singleLine">
                      What percentage of your employees are engaged?
                    </span>
                    <i class="fa fa-info-circle info_icon_data"
                      title="Measurements and scales differ, so we only ask for the percentage you regard as 'engaged.' "></i>
                  </label>
                  <input autocomplete="off" step="any"
                    onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){new_employee_engagement_tabfunction(event)}"
                    type="text" class="form-control input_custom_style padding_class"
                    id="global_engagement_score_talentManagement" placeholder="%" required="required">
                  <p class="percentage_tag_line"></p>
                </div>


                <!-- AccordianModals -->

                <div class="accordion_container col-md-12 col-sm-12 col-xs-12 pad-null">
                  <div class="accordion_head">
                    Click here to learn why it is important<span class="plusminus">+</span>
                  </div>
                  <div class="accordion_body" style="display: none;">
                    <p>
                      If you don't have a unified HR system, you are missing out not just in efficiency, but in your
                      ability to be
                      competitive. Whether your organization is a startup or a huge enterprise, the most important thing
                      you do
                      is to hire and manage the talent that makes the business successful.
                      How will you do that determines whether your organization will fail or succeed.
                      Without automation, the myriad tasks involved in hiring and managing talent can overwhelm your
                      staff
                      and cost you money in terms of paying people to accomplish simple, repetitive tasks that
                      automation
                      can do faster and much cheaper.
                      The quality of services you provide to your employees, affects your ability to hire and keep
                      talented
                      people.
                      You also incur risks of noncompliance in hiring practices, personnel decisions, regulated employee
                      benefits, payroll practices, safety, and ethics.
                    </p>
                  </div>
                </div>


                <ul class="row d-flex align-items-center justify-content-center nav nav-tabs m-0"
                  id="talentmanagement_data_tabs">
                  <li class="col-md-4 nav-item p-0 text-left">
                    <a data-toggle="tab"
                      class="nav-link active employee_engagement_tabs talent_management_nav_tab_styles"
                      href="#new_employee_engagement_tab">
                      Employee Engagement
                    </a>
                  </li>
                  <li class="col-md-4 nav-item p-0 text-left">
                    <a data-toggle="tab"
                      class="nav-link employee_experience_automation_tabs talent_management_nav_tab_styles"
                      href="#employee_experience_automation">
                      Employee Experience
                      Automation
                    </a>
                  </li>
                  <li class="col-md-4 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link performance_management_tabs talent_management_nav_tab_styles"
                      href="#performance_management">Performance
                      Management</a>
                  </li>
                </ul>
                <div class="tab-content">

                  <!-- newUpdatedTabs -->
                  <div id="new_employee_engagement_tab" class="tab-pane active col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 employee_engagement_tab_info_fields">
                      <p class="beforeText right_header">
                        People who are engaged in learning are engaged with work IF they see an opportunity to use what
                        they learn. When you provide that opportunity, you will see an increase in employee engagement,
                        which drives productivity.
                      </p>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="new_engagement_to_target_talentManagement" class="custom_for_label "
                          title="Engagement to target">
                          <span class="singleLine">
                            Engagement to target
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Number engaged / your internal target"></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class"
                          id="new_engagement_to_target_talentManagement" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                        <p class="engagementGapDataLessthanOne orangeColor hidden "></p>
                        <p class="engagementGapDataGreaterthanOne greenColor hidden"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class "
                        id="new_potential_revenue_gain_talentManagement_id">
                        <label for="new_potential_revenue_gain_talentManagement" class="custom_for_label "
                          title="Potential revenue gain">
                          <span class="singleLine">
                            Potential revenue gain
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="According to a report by Aon Hewitt, a 1% improvement in engagement can boost sales by 0.6%"></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="new_potential_revenue_gain_talentManagement" placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                        <p style="font-size: 13px;">Revenue potential of a 1% improvement in engagement from
                          streamlining your HR services
                          delivery*<i class="fa fa-info-circle info_icon_data"
                            title="* Based on research by Aon Hewitt, you can realize a 0.6% increase in in total sales for each 1% in employee engagement"></i>
                        </p>
                      </div>

                    </div>

                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="employee_experience_automation_tabs" data-infoKPI="talent_management_KPI"
                          value="Proceed to employee experience automation tab">
                      </div>
                    </div>
                  </div>

                  <div id="onboarding_automation hidden" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 onboarding_automation_info_fields">

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="onboarding_cost_savings" class="custom_for_label " title="Onboarding cost savings ">
                          <span class="singleLine">
                            Onboarding cost savings
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Average $182 per hire (Nucleus Research)"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="onboarding_cost_savings"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="new_onboarding_benefit" class="custom_for_label " title="Benefits of onboarding ">
                          <span class="singleLine">
                            Benefits of onboarding
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Employees who have a positive onboarding experience are 18 time more likely to be committed to their organization"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="new_onboarding_benefit"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="total_benefit_of_onboarding_automation" class="custom_for_label "
                          title="Total onboarding automation benefit ">
                          <span class="singleLine">
                            Total onboarding automation benefit
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Total benefit of onboarding automation"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="total_benefit_of_onboarding_automation" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>
                    </div>
                  </div>

                  <!-- end -->
                  <div id="employee_experience_automation" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 employee_experience_automation_info_fields">


                      <div class="accordion_container col-md-12 col-sm-12 col-xs-12 pad-null">
                        <div class="accordion_head">
                          Click here to learn why it is important<span class="plusminus">+</span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                          <p>
                            The recent development of Employee Experience Platforms (EXP) and similar new functionally
                            in major HCM vendors (ex.
                            ServiceNow and SuccessFactors) simplifies and streamlines the employee experience, removing
                            a significant dissatisfier for
                            employees. It can have an impact on employee experience and engagement.
                          </p>
                        </div>
                      </div>


                      <div class="form-group col-md-12 fg_custom_class usedButHiddenDataInput">
                        <label for="expected_reduction_in_HR_service_calls_new" class="custom_for_label "
                          title="The expected reduction in HR service calls realized by employee experience automation">
                          <span class="singleLine">
                            The expected reduction in HR service calls realized by employee experience automation
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Chatbots and self-service assets can have a huge impact on ticket volume. This is a conservative estimate"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){topLineRevenuedata(event)}" type="text"
                          class="form-control input_custom_style padding_class" value="50 %"
                          id="expected_reduction_in_HR_service_calls_new" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="avg_HR_headcount_ratio" class="custom_for_label "
                          title="We estimate HR Headcount Ratio to FTE at 1.4%.">
                          <span class="singleLine">
                            We estimate HR Headcount Ratio to FTE at 1.4%.
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="SHRM benchmark average"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){topLineRevenuedata(event)}" type="text"
                          class="form-control input_custom_style padding_class" value="1.4 %"
                          id="avg_HR_headcount_ratio" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class twoLinesInLabel">
                        <label for="avg_hr_staff_reallocated_resources" class="custom_for_label "
                          title="HR cost savings, based on 50% of staff assigned to respond to service calls, 40% of time spent on service calls, this is the HR productivity gain of implementing employee experience automation ">
                          <span class="singleLine">
                            HR cost savings, based on 50% of staff assigned to respond to service calls, 40% of time
                            spent on service calls, this is the HR productivity gain of implementing employee experience
                            automation.
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Your actual results will differ"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="avg_hr_staff_reallocated_resources" placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="expected_employee_productivity_gain_percentage_new" class="custom_for_label "
                          title="Expected productivity gain from improved employee experience in the first year">
                          <span class="singleLine">
                            Expected productivity gain from improved employee experience in the first year
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Conservative estimate"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class" value="2 %"
                          id="expected_employee_productivity_gain_percentage_new" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="expected_employee_productivity_gain_doller_new" class="custom_for_label "
                          title="The estimated productivity value of employee experience gain">
                          <span class="singleLine">
                            The estimated productivity value of employee experience gain
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Productivity gain X top line revenue. "></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="expected_employee_productivity_gain_doller_new" placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="cost_savings_and_productivity_gain" class="custom_for_label "
                          title="Total of cost savings and productivity gain ">
                          <span class="singleLine">
                            Total of cost savings and productivity gain
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Cost savings and productivity gain"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="cost_savings_and_productivity_gain" placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                    </div>
                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="performance_management_tabs" data-infoKPI="talent_management_KPI"
                          value="Proceed to performance management tab">
                      </div>
                    </div>
                  </div>

                  <div id="performance_management" class="tab-pane fade col-md-12 pad_null tab_conetent_data">


                    <div class="pad_null col-md-12 performance_management_info_fields">
                      <div class="form-group col-md-12 fg_custom_class  turnOverRate">
                        <label for="pm_turnOverRate" class="custom_for_label " title="Your annual turnover rate">
                          <span class="singleLine">
                            Your annual turnover rate
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="From your organizational details"></i>
                        </label>

                        <input autocomplete="off" readonly step="any" type="text"
                          class="form-control input_custom_style padding_class valid" id="pm_turnOverRate"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  pm_estimated_average_hiring_cost_data">
                        <label for="pm_estimated_average_hiring_cost" class="custom_for_label "
                          title="Estimated average hiring cost">
                          <span class="singleLine">
                            Estimated average hiring cost
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="See how we calculate hiring cost (link)"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="pm_estimated_average_hiring_cost" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  pm_estimated_total_turnover_cost_data">
                        <label for="pm_estimated_total_turnover_cost" class="custom_for_label"
                          title="Estimated total turnover cost">
                          <span class="singleLine">
                            Estimated total turnover cost
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Turnover X employees X average hiring costs"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="pm_estimated_total_turnover_cost" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  pm_reduction_turnover_cost_data">
                        <label for="pm_reduction_turnover_cost" class="custom_for_label"
                          title="Potential reduction in turnover costs realized by giving employees frequent strength-based feedback">
                          <span class="singleLine">
                            Potential reduction in turnover costs realized by giving employees frequent strength-based
                            feedback
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Gallup found that by simply giving more continuous strength-based feedback, companies can reduce turnover by 14.9%"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="pm_reduction_turnover_cost"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>
                    </div>

                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="employee_engagement_tabs" data-infoKPI="talent_management_KPI"
                          value="Proceed to employee engagement tab">
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <!-- Leadership Development_modal -->
              <div class="tabs_Data leadership_development_info_modal_hidden col-md-12 pad_null hidden"
                id="leadership_development_info_id">
                <p class="title_headings">Leadership Development</p>
              </div>

              <!-- Learning tabs Part -->
              <div class="tabs_Data learning_info col-md-12 pad_null hidden" id="learning_info_id">
                <p class="title_headings">Learning</p>

                <ul class="row d-flex align-items-center justify-content-center nav nav-tabs m-0">
                  <li class="col-md-6 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link active learning_engagement_tabs"
                      href="#learning_engagement">Learning
                      Engagement</a>
                  </li>

                  <li class="col-md-6 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link leadership_development_tabs"
                      href="#leadership_development">Leadership
                      Development</a>
                  </li>

                </ul>

                <div class="tab-content">
                  <div id="learning_engagement" class="tab-pane active col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 learning_engagement_info_fields">

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="voluntary_learning" class="custom_for_label "
                          title="Do you provide voluntary self-development for your employees?">
                          <span class="singleLine">
                            Do
                            you provide voluntary self-development for your
                            employees?
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Company-provided or supported voluntarily learning with or without manager's recommendations"></i>
                        </label>

                        <select class="form-control input_custom_style" id="voluntary_learning" required="required">
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>

                      </div>

                      <div class="form-group col-md-12 fg_custom_class  learners_engaged_data">
                        <label for="learners_engaged" class="custom_for_label "
                          title="What percent of your learners participate in self-development learning?">
                          <span class="singleLine">
                            What
                            percent of your learners participate in self-development
                            learning?
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Company-provided or supported"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){learn_engage_product_gain(event)}"
                          type="text" class="form-control input_custom_style padding_class" id="learners_engaged"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class le_benchmark_data">
                        <label for="le_benchmark" class="custom_for_label " title="Participation Benchmark">
                          <span class="singleLine">
                            Participation benchmark
                          </span>
                          <i class="fa fa-info-circle info_icon_data" style="font-size:16px"
                            title="Our benchmarks based on industry stats"></i>
                        </label>

                        <input readonly autocomplete="off" step="any" type="text" value="42 %"
                          class="form-control input_custom_style padding_class" id="le_benchmark" placeholder="%"
                          required="required">
                        <p class="percentage_tag_line"></p>

                      </div>

                      <div class="form-group col-md-12 fg_custom_class learning_engagement_score_data">
                        <label for="learning_engagement_score" class="custom_for_label "
                          title="Learning engagement score">
                          <span class="singleLine">
                            Learning engagement score
                          </span>
                          <i class="fa fa-info-circle info_icon_data" style="font-size:16px"
                            title="Participation / benchmark"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){learn_engage_product_gain(event)}"
                          type="text" class="form-control input_custom_style padding_class"
                          id="learning_engagement_score" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                        <div class="dynamic_engagement_score_data"></div>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  learning_engagement_productivity_gain_data">
                        <label for="learning_engagement_productivity_gain" class="custom_for_label "
                          title="Your potential productivity gain if you close the gap.">
                          <span class="singleLine">
                            Your potential productivity gain if you close the gap.
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="According to a report by Aon Hewitt, improvement of 1% in employee engagement can drive a 0.6% increase in sales"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="learning_engagement_productivity_gain" placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>
                    </div>

                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="leadership_development_tabs" data-infoKPI="learning_KPI"
                          value="Proceed to leadership development tab">
                      </div>
                    </div>

                  </div>

                  <div id="leadership_development" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 leadership_development_info_fields">
                      <!-- Inputs Values -->

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="ld_number_of_managers" class="custom_for_label "
                          title="How many managers do you have?">
                          <span class="singleLine">
                            How many managers do you have?
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Your estimate"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){LeaderShipTrainingFunction()}" type="text"
                          class="form-control input_custom_style" id="ld_number_of_managers" placeholder=""
                          required="required">
                        <p class="tag_line pull-right"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="percent_of_managers" class="custom_for_label "
                          title="Percent of your managers completing training">
                          <span class="singleLine">
                            Percent of your managers completing training
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Initial manager training"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){LeaderShipTrainingFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class" id="percent_of_managers"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="current_percent_of_employees" class="custom_for_label "
                          title="Percent of your employees engaged">
                          <span class="singleLine">
                            Percent of your employees engaged
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Engagement scales and measures different, so we consider what percentage you consider 'engaged'"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){LeaderShipTrainingFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class"
                          id="current_percent_of_employees" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="estimated_increase_in_manager_engagement" class="custom_for_label "
                          title="Estimated increase in manager engagement for those that complete training">
                          <span class="singleLine">
                            Estimated increase in manager engagement for those that complete training
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="How much do you believe training impacts manager engagement?"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){LeaderShipTrainingFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class"
                          id="estimated_increase_in_manager_engagement" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="est_change_employee_engagement" class="custom_for_label "
                          title="We offer a conservative estimate of a 10% increase in total employee engagement. You can provide your own estimate.">
                          <span class="singleLine">
                            We offer a conservative estimate of a 10% increase in total employee engagement. You can
                            provide your own estimate.
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Gallup's state of the American manager report showed that employees who work for engaged managers are more productive"></i>
                        </label>

                        <input autocomplete="off" step="any" value="10 %"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){LeaderShipTrainingFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class"
                          id="est_change_employee_engagement" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <!-- Input Values ended -->

                      <!-- output Values -->

                      <!-- ReadingValues from organization Details -->
                      <div class="form-group col-md-12 fg_custom_class">
                        <label for="topLineRevenue_LD" class="custom_for_label"
                          title="Your top line revenue in last fiscal year">
                          <span class="singleLine">
                            Your top line revenue in last fiscal year
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="From your Organization Details"></i>
                        </label>
                        <input autocomplete="off" readonly step="any" type="text" name="topLineRevenue_LD"
                          class="form-control input_custom_style padding-left-class" id="topLineRevenue_LD"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class hidden">
                        <label for="noOfEmployees_LD" class="custom_for_label "
                          title="Full time equivant (FTE) employees">
                          <span class="singleLine">
                            Full time equivant (FTE) employees
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Headcount"></i>
                        </label>

                        <input autocomplete="off" readonly step="any" type="text"
                          class="form-control input_custom_style" id="noOfEmployees_LD" placeholder=""
                          required="required">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class hidden">
                        <label for="yourRevPerEmployee_LD" class="custom_for_label " title="Your Revenue per Employee">
                          <span class="singleLine">
                            Your Revenue per Employee
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Turnover"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="yourRevPerEmployee_LD"
                          placeholder="">
                        <p class="dollar_tag_line">$</p>
                      </div>


                      <div class="form-group col-md-12 fg_custom_class hidden">
                        <label for="impact_manager_engagement" class="custom_for_label "
                          title="Employees who work for engaged managers are 59% more likely to be engaged*">
                          <span class="singleLine">
                            Employees who work for engaged managers are 59% more likely to be engaged*
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="The impact managers have on engagement"></i>
                        </label>

                        <input autocomplete="off" readonly step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){LeaderShipTrainingFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class" value="59"
                          id="impact_manager_engagement" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="increase_revenue_per_employee" class="custom_for_label "
                          title="Potential increase in revenue per employee">
                          <span class="singleLine">
                            Potential Increase in revenue per employee
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="According to Gallup, engaged employees are 14.9% more productive"></i>
                        </label>

                        <input autocomplete="off" readonly step="any" type="text"
                          class="form-control input_custom_style padding-left-class" id="increase_revenue_per_employee"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="est_annual_revenue_growth" class="custom_for_label "
                          title="Total estimated revenue growth ">
                          <span class="singleLine">
                            Total estimated revenue growth
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Revenue per employee X number of employees"></i>
                        </label>

                        <input autocomplete="off" readonly step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){LeaderShipTrainingFunction(event)}"
                          type="text" class="form-control input_custom_style padding-left-class"
                          id="est_annual_revenue_growth" placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="est_leadership_program_cost" class="custom_for_label "
                          title="Cost of a comprehensive manager training program. Based on $900 per year per manager. ">
                          <span class="singleLine">
                            Cost of a comprehensive manager training program. Based on $900 per year per manager.
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="$900 per year without the cost of mentor consultants"></i>
                        </label>

                        <input autocomplete="off" readonly step="any" type="text"
                          class="form-control input_custom_style padding-left-class" id="est_leadership_program_cost"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class " id="net_productivity_gain_id">
                        <label for="net_productivity_gain" class="custom_for_label "
                          title="Your potential net productivity gain">
                          <span class="singleLine">
                            Your potential net productivity gain
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Total Revenue Growth - Program Cost"></i>
                        </label>

                        <input autocomplete="off" readonly step="any" type="text"
                          class="form-control input_custom_style padding-left-class" id="net_productivity_gain"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>
                      <!-- output Values ended -->

                    </div>

                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="learning_engagement_tabs" data-infoKPI="learning_KPI"
                          value="Proceed to learning engagement tab">
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <!-- Learning page end -->

              <!-- old Analytics page -->
              <div class="tabs_Data old_analytics_info col-md-12 pad_null hidden">
                <p class="title_headings">People Analytics</p>

                <ul class="row d-flex align-items-center justify-content-center nav nav-tabs m-0">
                  <li class="col-md-6 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link active" href="#employee_engagement_tab">
                      Employee Engagement Impact on
                      Revenue
                    </a>
                  </li>
                  <li class="col-md-6 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link" href="#cost_impact_of_lowering_turnover">
                      Cost Impact of Lowering
                      Turnover
                    </a>
                  </li>
                </ul>

                <div class="tab-content">

                  <div id="employee_engagement_tab" class="tab-pane active col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 employee_engagement_tab_info_fields">

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="potential_additional_revenue" class="custom_for_label "
                          title="What percentage of your employees are engaged?">
                          <span class="singleLine">
                            What
                            percentage of your employees are engaged?
                          </span>

                          <i class="fa fa-info-circle info_icon_data" title="Potential Additional Revenue"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){employee_engagement_tabFunction()}"
                          type="text" class="form-control input_custom_style padding_class"
                          id="potential_additional_revenue" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="engagement_score" class="custom_for_label " title="Your Engagement Score">
                          <span class="singleLine">
                            Your Engagement Score
                          </span>

                          <i class="fa fa-info-circle info_icon_data"
                            title="The US composite engagement rate is 71% (Predictive Index)"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class" id="engagement_score" placeholder="%"
                          required="required">
                        <p class="percentage_tag_line"></p>
                        <div class="dynamic_engagement_text"></div>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  hidden">
                        <label for="engagement_opportunity" class="custom_for_label "
                          title="Potential Gain in Engagement">
                          <span class="singleLine">
                            Potential Gain in
                            Engagement
                          </span>


                          <i class="fa fa-info-circle info_icon_data"
                            title="Fully engaged employees perform 21% better than lower performing peers (Gallup)"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="engagement_opportunity"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="engagement_revenue_potential" class="custom_for_label "
                          title="The potential revenue increase of improving employee engagement by one standard deviation.">
                          <span class="singleLine">
                            The
                            potential revenue increase of improving employee engagement by
                            one standard deviation.
                          </span>


                          <i class="fa fa-info-circle info_icon_data" title="Potential Total Gain in Revenue"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="engagement_revenue_potential"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                    </div>
                  </div>

                  <div id="cost_impact_of_lowering_turnover" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 cost_impact_of_lowering_turnover_info_fields">

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="employee_turnover_rate" class="custom_for_label "
                          title="What is your employee turnover rate?">
                          <span class="singleLine">
                            What is your
                            employee turnover rate?
                          </span>

                          <i class="fa fa-info-circle info_icon_data" title="Your Employee Turnover Rate"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analyticsCostImpactOdLoweringFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class" id="employee_turnover_rate"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="old_average_industry_turnover" class="custom_for_label "
                          title="Your industry turnover rate">
                          <span class="singleLine">
                            Your industry turnover
                            rate
                          </span>

                          <i class="fa fa-info-circle info_icon_data" title="Average Industry Turnover"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class" id="old_average_industry_turnover"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="old_turnover_gap" class="custom_for_label " title="Your Turnover Gap">
                          <span class="singleLine">
                            Your Turnover Gap
                          </span>

                          <i class="fa fa-info-circle info_icon_data" title="Turnover Gap (if <= 0 return (N/A))"></i>
                        </label>


                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class" id="old_turnover_gap" placeholder="%"
                          required="required">
                        <p class="percentage_tag_line"></p>
                        <div class="old_dynamic_turnover_gap_message"></div>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="old_turnover_opportunity" class="custom_for_label "
                          title="Potential cost saving realized by closing the gap in employee retention">
                          <span class="singleLine">
                            Potential
                            cost saving realized by closing the gap in employee
                            retention
                          </span>


                          <i class="fa fa-info-circle info_icon_data" title="Your Cost Avoidance Opportunity"></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="old_turnover_opportunity"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Old Analytics Page End -->

              <!-- New Analytics page -->
              <div class="tabs_Data analytics_info col-md-12 pad_null hidden" id="analytics_info_id">
                <p class="title_headings">People Analytics</p>

                <ul class="row d-flex align-items-center justify-content-center nav nav-tabs m-0">
                  <li class="col-md-3 nav-item p-0 text-left foranalytics_in_talent_aquisition_1">
                    <a data-toggle="tab" class="nav-link active analytics_in_talent_aquisition_tabs"
                      href="#analytics_in_talent_aquisition" title="Analytics in Talent Aquisition">
                      Impact of Commuting Time
                    </a>
                  </li>
                  <li class="col-md-3 nav-item p-0 text-left foranalytics_in_talent_management_1">
                    <a data-toggle="tab" class="nav-link analytics_in_talent_management_tabs"
                      href="#analytics_in_talent_management" title="Analytics in Talent Management">
                      Predicting Employee Turnover
                    </a>
                  </li>
                  <li class="col-md-3 nav-item p-0 text-left foranalytics_in_learning_1">
                    <a data-toggle="tab" class="nav-link analytics_in_learning_tabs" href="#analytics_in_learning"
                      title="Analytics in Learning">
                      Self-Directed Learning
                    </a>
                  </li>
                  <li class="col-md-3 nav-item p-0 text-left">
                    <a data-toggle="tab" class="nav-link analytics_in_employee_engagement_tabs"
                      href="#analytics_in_employee_engagement" title="Analytics in Employee Engagement">
                      Hiring and Developing Managers
                    </a>
                  </li>
                </ul>

                <div class="tab-content">

                  <div id="analytics_in_talent_aquisition" class="tab-pane active col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 analytics_in_talent_aquisition_tab_info_fields">

                      <!-- AccordianModals -->

                      <div class="accordion_container col-md-12 col-sm-12 col-xs-12 pad-null">
                        <div class="accordion_head">
                          Click here to learn why it is important<span class="plusminus">+</span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                          <p>
                            Some organizations have discovered that commuting time can have a significant impact on new
                            hire
                            turnover and a tendency to be a no-show on the first day of employment. See what the
                            potential
                            savings could be if you offer incentives to live closer to work or address the issue during
                            recruitment.
                            You also incur risks of noncompliance in hiring practices, personnel decisions, regulated
                            employee
                            benefits, payroll practices, safety, and ethics.
                          </p>
                        </div>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="employees_hired" class="custom_for_label "
                          title="How many people did you hire last fiscal year?">
                          <span class="singleLine">
                            How many people did you hire last fiscal year?
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="We will be looking at how commute time impacts new hire turnover."></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_talent_aquisition_tabFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class" id="employees_hired"
                          placeholder="" required="required">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class twoLinesInLabelpercentage">
                        <label for="new_hire_long_commute" class="custom_for_label "
                          title="Percent of new hires who commute more than one hour per day. We estimate that 15% of new hires commute more than one hour per day. If you know your actual percent, enter it here.">
                          <span class="singleLine">
                            Percent of new hires who commute more than one hour per day. We estimate that 15% of new
                            hires commute more than one hour per day. If you know your actual percent, enter it here.
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="If you don't know the percentage, please estimate."></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_talent_aquisition_tabFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class" id="new_hire_long_commute"
                          placeholder="%" required="required" value="15 %">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="new_hire_turnover" class="custom_for_label "
                          title="Estimate your new hire turnover rate (< 90 days)">
                          <span class="singleLine">
                            Estimate your new hire turnover rate (< 90 days) </span>
                              <i class="fa fa-info-circle info_icon_data"
                                title="The time to productivity varies with job type. We use 90 days for estimate purposes. A complete analysis would segment jobs. "></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_talent_aquisition_tabFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class" id="new_hire_turnover"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class hidden">
                        <label for="attrition_probability" class="custom_for_label "
                          title="Probability that new hires that commute more than one hour per day will leave within 90 days.">
                          <span class="singleLine">
                            Probability that new hires that commute more than one hour per day will leave within 90
                            days.
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Attrition Probability"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_talent_aquisition_tabFunction(event)}"
                          value="90" type="text" class="form-control input_custom_style padding_class"
                          id="attrition_probability" placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class">
                        <label for="commute_attrition_impact" class="custom_for_label "
                          title="Percent of new hires who will fail because of commute time">
                          <span class="singleLine">
                            Percent of new hires who will fail because of commute time
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="We assume commute time to be the primary factor."></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class" id="commute_attrition_impact"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class" id="potential_recovery_id">
                        <label for="potential_recovery" class="custom_for_label "
                          title="Potential recovery of direct hiring cost">
                          <span class="singleLine">
                            Potential recovery of direct hiring cost
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="How much you could lower direct hiring cost if you solved the commute time issue. "></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="potential_recovery"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                    </div>

                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="analytics_in_talent_management_tabs" data-infoKPI="analytics_KPI"
                          value="Proceed to predicting employee turnover tab">
                      </div>
                    </div>

                  </div>

                  <div id="analytics_in_talent_management" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 analytics_in_talent_management_info_fields">

                      <!-- AccordianModals -->

                      <div class="accordion_container col-md-12 col-sm-12 col-xs-12 pad-null">
                        <div class="accordion_head">
                          Click here to learn why it is important<span class="plusminus">+</span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                          <p>
                            Using analytics, we can measure dozens of behavior factors that may be indicators of
                            potential
                            turnover: team participation, social interaction, tardiness, absences, and many more. This
                            analysis can
                            give you an edge in combatting turnover.
                          </p>
                        </div>
                      </div>
                      <!-- Inputs -->

                      <div class="form-group col-md-12 fg_custom_class  Analytics_timeToFill_from_user_data hidden">
                        <label for="Analytics_timeToFill_from_user" class="custom_for_label "
                          title="What is your average time to fill (in days)?">
                          <span class="singleLine">
                            What is
                            your average time to fill (in days)?
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Time to fill"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){dailyandStaffing_staffing_cost_sum()}"
                          type="text" class="form-control input_custom_style" id="Analytics_timeToFill_from_user"
                          placeholder="" required="required">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="analytics_industry" class="custom_for_label " title="Your industry">
                          <span class="singleLine">
                            Your industry
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Sources differ in how they segment industries, so we need to capture the analyst segmentation for this particular benchmark."></i>
                        </label>

                        <div id="dynamic_analytics_industry_input"></div>
                        <p id="errorMessage"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class">
                        <label for="turnover_ratio" class="custom_for_label " title="Your turnover ratio">
                          <span class="singleLine">
                            Your turnover ratio
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="From your Organization Details."></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analyticsCostImpactOdLoweringFunction(event)}"
                          type="text" class="form-control input_custom_style padding_class" id="turnover_ratio"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="average_industry_turnover" class="custom_for_label "
                          title="Your industry turnover rate">
                          <span class="singleLine">
                            Your industry turnover
                            rate
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Bureau of Labor Statistics."></i>
                        </label>

                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class" id="average_industry_turnover"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <!-- end -->

                      <!-- outPuts -->

                      <div class="form-group col-md-12 fg_custom_class ">
                        <label for="turnover_gap" class="custom_for_label " title="Your turnover gap">
                          <span class="singleLine">
                            Your turnover gap
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Your turnover / industry benchmark"></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding_class" id="turnover_gap" placeholder="%"
                          required="required">
                        <p class="percentage_tag_line"></p>
                        <div class="dynamic_turnover_gap_message"></div>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class fieldisUsedbutitisinHidden">
                        <label for="predictive_model_impact" class="custom_for_label "
                          title="Suggested impact of a predictive model of 100+ turnover risks">
                          <span class="singleLine">
                            Suggested impact of a predictive model of 100+ turnover risks
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="We analyze dozens of behavior factors to see which ones drive turnover."></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text" value="2 %"
                          class="form-control input_custom_style padding_class" id="predictive_model_impact"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class" id="turnover_opportunity_id">
                        <label for="turnover_opportunity" class="custom_for_label "
                          title=" Your potential cost savings of a predictive model for turnover risks">
                          <span class="singleLine">
                            Your potential cost savings of a predictive model for turnover risks
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="A predictive model and help you avoid high risk situations."></i>
                        </label>


                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="turnover_opportunity"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <!-- end -->

                    </div>
                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="analytics_in_learning_tabs" data-infoKPI="analytics_KPI"
                          value="Proceed to self-directed learning tab">
                      </div>
                    </div>
                  </div>

                  <div id="analytics_in_learning" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 analytics_in_learning_info_fields">

                      <div class="form-group col-md-12 fg_custom_class"
                        id="engagement_impact_of_employee_development_id">
                        <label for="engagement_impact_of_employee_development" class="custom_for_label "
                          title="Potential impact of a 5% increase in engagement">
                          <span class="singleLine">
                            Potential impact of a 5% increase in engagement
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="According to a report by Aon Hewitt, a 1% increase in engagement can drive a 0.6% increase in sales"></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="engagement_impact_of_employee_development" placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class" id="employee_development_budget_id">
                        <label for="employee_development_budget" class="custom_for_label "
                          title="Budget of $1500 per employee for personal and professional development">
                          <span class="singleLine">
                            Budget of $1500 per employee for personal and professional development
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Potential cost of on-demand learning services and support"></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="employee_development_budget"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class"
                        id="learning_effectiveness_productivity_boost_id">
                        <label for="learning_effectiveness_productivity_boost" class="custom_for_label "
                          title="Estimate of 10% increase in learning effectiveness for a single program for 400 employees ">
                          <span class="singleLine">
                            Estimate of 10% increase in learning effectiveness for a single program for 400 employees
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Budget for personal and professional development"></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class"
                          id="learning_effectiveness_productivity_boost" placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class " id="est_gain_learning_analytics_id">
                        <label for="est_gain_learning_analytics" class="custom_for_label "
                          title="Net productivity gain">
                          <span class="singleLine">
                            Net productivity gain
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Measured by employee engagement analytics"></i>
                        </label>
                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="est_gain_learning_analytics"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                    </div>

                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="analytics_in_employee_engagement_tabs" data-infoKPI="analytics_KPI"
                          value="Proceed to hiring and developing managers tab">
                      </div>
                    </div>
                  </div>

                  <div id="analytics_in_employee_engagement" class="tab-pane fade col-md-12 pad_null tab_conetent_data">
                    <div class="pad_null col-md-12 analytics_in_employee_engagement_info_fields">
                      <p class="beforeText right_header">
                        Most companies don't do a good job of selecting and hiring managers. Analytics driven by AI can
                        help hiring managers make better decisions
                      </p>

                      <div class="form-group col-md-12 fg_custom_class  managers_hired_data">
                        <label for="managers_hired" class="custom_for_label "
                          title="How many managers do you hire per year?">
                          <span class="singleLine">
                            How many managers do you hire per year?
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="New employees hired in the manager roles"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_employee_engagementFunction()}"
                          type="text" class="form-control input_custom_style" id="managers_hired" placeholder=""
                          required="required">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  managers_promoted_data">
                        <label for="managers_promoted" class="custom_for_label "
                          title="How many employees or managers are promoted into more responsible management roles?">
                          <span class="singleLine">
                            How many employees or managers are promoted into more responsible management roles?
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="New roles as managers"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_employee_engagementFunction()}"
                          type="text" class="form-control input_custom_style" id="managers_promoted" placeholder=""
                          required="required">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  people_managers_data">
                        <label for="people_managers" class="custom_for_label "
                          title="How many total people managers do you have?">
                          <span class="singleLine">
                            How many total people managers do you have?
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="One or more direct reports"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_employee_engagementFunction()}"
                          type="text" class="form-control input_custom_style" id="people_managers" placeholder=""
                          required="required">
                      </div>

                      <!-- used but Hide -->

                      <div class="form-group col-md-12 fg_custom_class predicted_increase_manager_engagement_data">
                        <label for="predicted_increase_manager_engagement" class="custom_for_label "
                          title="Projected increase in engagement in managers hired or promoted using comprehensive analytical assessments">
                          <span class="singleLine">
                            Projected increase in engagement in managers hired or promoted using comprehensive
                            analytical assessments
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Well-trained managers are more engaged and productive"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_employee_engagementFunction()}"
                          type="text" value="35 %" class="form-control input_custom_style"
                          id="predicted_increase_manager_engagement" placeholder="" required="required">
                      </div>

                      <div class="form-group col-md-12 fg_custom_class development_impact_data">
                        <label for="development_impact" class="custom_for_label "
                          title="Estimated annual impact of continual skill-building, 360 feedback, and dedicated coaching on manager population">
                          <span class="singleLine">
                            Estimated annual impact of continual skill-building, 360 feedback, and dedicated coaching on
                            manager population
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="This is our estimate of the engagement impact on managers in the first year"></i>
                        </label>

                        <input autocomplete="off" step="any"
                          onkeyup="if(!(event.keyCode>36&&event.keyCode<41)){analytics_in_employee_engagementFunction()}"
                          type="text" value="10 %" class="form-control input_custom_style" id="development_impact"
                          placeholder="%" required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <!-- end -->

                      <div class="form-group col-md-12 fg_custom_class  assessment_impact_data"
                        id="assessment_impact_id">
                        <label for="assessment_impact" class="custom_for_label "
                          title="Annual Impact of assessments on total manager engagement">
                          <span class="singleLine">
                            Annual Impact of assessments on total manager engagement
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Greater manager engagement from better hiring decisions"></i>
                        </label>

                        <input autocomplete="off" step="any" type="text" readonly
                          class="form-control input_custom_style" id="assessment_impact" placeholder="%"
                          required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  increase_manager_engagement_data"
                        id="increase_manager_engagement_id">
                        <label for="increase_manager_engagement" class="custom_for_label "
                          title="Total annual increase in manager engagement">
                          <span class="singleLine">
                            Total annual increase in manager engagement
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Total impact of hiring better managers and supporting them"></i>
                        </label>

                        <input autocomplete="off" step="any" type="text" readonly
                          class="form-control input_custom_style" id="increase_manager_engagement" placeholder="%"
                          required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class  increase_employee_emgagement_data"
                        id="increase_employee_emgagement_id">
                        <label for="increase_employee_emgagement" class="custom_for_label "
                          title="Increase in employee engagement.">
                          <span class="singleLine">
                            Increase in employee engagement.
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="According to Gallup's state of the American manager report, employees who work for engaged managers are 14.9% more productive"></i>
                        </label>

                        <input autocomplete="off" step="any" type="text" readonly
                          class="form-control input_custom_style" id="increase_employee_emgagement" placeholder="%"
                          required="required">
                        <p class="percentage_tag_line"></p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class mgr_eng_revenue_increase_data"
                        id="mgr_eng_revenue_increase_id">
                        <label for="mgr_eng_revenue_increase" class="custom_for_label "
                          title="Potential revenue increase driven by manager engagement.">
                          <span class="singleLine">
                            Potential revenue increase driven by manager engagement.
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Based on higher productivity of employees with engaged managers"></i>
                        </label>


                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="mgr_eng_revenue_increase"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class mgr_assessment_cost_data"
                        id="mgr_assessment_cost_id">
                        <label for="mgr_assessment_cost" class="custom_for_label "
                          title="Cost of manager assessments at $350 per assesment.">
                          <span class="singleLine">
                            Cost of manager assessments at $350 per assesment.
                          </span>
                          <i class="fa fa-info-circle info_icon_data"
                            title="Individual assessments based on your criteria"></i>
                        </label>


                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="mgr_assessment_cost"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class mgr_development_cost_data"
                        id="mgr_development_cost_id">
                        <label for="mgr_development_cost" class="custom_for_label "
                          title="Cost of manager development at $900 per year per manager">
                          <span class="singleLine">
                            Cost of manager development at $900 per year per manager
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Feedback and mentoring"></i>
                        </label>


                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="mgr_development_cost"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                      <div class="form-group col-md-12 fg_custom_class mgr_eng_net_revenue_increase_data"
                        id="mgr_eng_net_revenue_increase_id">
                        <label for="mgr_eng_net_revenue_increase" class="custom_for_label "
                          title="Manager engagement net revenue increase">
                          <span class="singleLine">
                            Manager engagement net revenue increase
                          </span>
                          <i class="fa fa-info-circle info_icon_data" title="Total Impact - Costs"></i>
                        </label>


                        <input autocomplete="off" step="any" readonly type="text"
                          class="form-control input_custom_style padding-left-class" id="mgr_eng_net_revenue_increase"
                          placeholder="" required="required">
                        <p class="dollar_tag_line">$</p>
                      </div>

                    </div>

                    <div class="switchData">
                      <div class="pad-null">
                        <input type="button" class="btn btn-custom-style" onclick="tabSwitchDynamic(event)"
                          data-name="analytics_in_talent_aquisition_tabs" data-infoKPI="analytics_KPI"
                          value="Proceed to impact of commuting time tab">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- New Analytics Page End -->

            </div>

            <div class="footerNote hidden col-md-12 pad-null">
              <div class="pad-null col-md-6 text-left informationRegardingKPI">
                <p class="btn_infoData" id="clickNext_text">Would you like to look at other KPIs ?</p>
                <p class="nextButtonClick_info_data hidden " id="clickNext_text">Please click on Continue button to get
                  Results</p>
              </div>
              <div class="pad-null">
                <input type="button" class="btn btn-custom-style" onclick="yesOrNoClickButton(event)" id="Yes"
                  value="Yes">
                <input type="button" class="btn btn-custom-style" onclick="yesOrNoClickButton(event)" id="No"
                  value="No">

              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 step_button text-center">
          <a class="nextBtn button_continue secondStepButton" type="button">Continue <i
              class='fa fa arrow_icon'>&#xf061;</i></a>
        </div>
      </div>
    </div>

    <!-- step3 -->
    <div class="row setup-content" id="step-3">
      <div class="col-xs-12 pad_null mar_btm" id="results">
        <div class="row col-md-12 pad_null inner_data">
          <div class="col-md-3 col-sm-6 col-xs-12 left_side_section pad_left_one_null">
            <div class="col-md-12 data_left_part">
              <h4 class="left_header">
                Which functional area would you like to review on?
              </h4>
              <div class="functional_check1">

              </div>
            </div>
          </div>

          <div class="col-md-8 col-sm-6 col-xs-12 right_side_section">
            <div class="ResultsDataSection">
              <div id="inputResults" class="tab-pane active">
                <div class="col-md-12 col-sm-12 col-xs-12 canvas_div_pdf pad-null">

                  <div class="col-md-12" id="inputsAndOutputsData">

                    <div class="pleaseFill_message">
                      <p class="toast_allert_message">Please Fill the Organization Data to get the Results</p>
                      <div class="col-md-12">
                        <img src="../../assets/images/ROI_Images/Emaild.png">
                      </div>
                    </div>

                    <div id="pdfOne" class="col-md-12 clearBothClass commonPdfClass"
                      style="background-image: none; background-repeat: no-repeat; text-align: center;">
                      <!-- inputs -->
                      <div class="orgDetailsResultsData col-md-12 hidden pad-null" id="organization_heading"
                        style="margin-top: 10px;">
                        <div class="heading_table col-md-12 text-center" style="margin: 2% 0px;">
                          <h4 class="header_heading">
                            Organization Details
                          </h4>
                        </div>
                        <div class="col-md-12 inputprintText" style="padding-bottom: 20px;">
                          <h4 class="text-left col-md-6 inputsheadTextForOrg pad-null" style="margin-bottom: 25px;">
                            Inputs
                          </h4>
                          <div class="col-md-6 text-right" style="margin: 0% 0px;">
                            <h4 class="emailData hidden"></h4>
                          </div>
                          <table id="organization_details_data_inputs"
                            class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="inputValues" id="topLineRevenue_result"></tr>
                            <tr class="inputValues" id="Business_sector_result"></tr>
                            <tr class="inputValues" id="HeadCount_result"></tr>
                            <tr class="inputValues" id="timeToFill_result"></tr>
                            <tr class="inputValues" id="your_turnover_rate_result"></tr>
                            <tr class="inputValues" id="arpe_result"></tr>
                          </table>
                        </div>

                      </div>

                      <!-- outputs -->
                      <div class="col-md-12 orgDetailsResultsData hidden pad-null" id="organization_outPuts_heading">

                        <div class="col-md-12 outputPrintPart">
                          <h4 class="text-left outputssheadTextForOrg" style="margin-bottom: 25px;">
                            Outputs
                          </h4>

                          <table id="organization_details_data_outputs"
                            class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="outputValues" id="yourRevPerEmployee_result_output"></tr>
                            <tr class="outputValues" id="totalGain_result_output"></tr>
                          </table>
                          <table id="organizationOutptMessage" class="text-center col-md-12">
                            <tr class="outputValues orangeColor" id="compare_dynamic_display_text"
                              style="color: #ff8014">
                            </tr>
                            <tr class="outputValues greenColor" id="compare_congrats_message_text"
                              style="color: #008000;"></tr>
                          </table>
                          <!-- org Outputs End -->
                        </div>
                      </div>
                    </div>

                    <!-- Talent aquisition Data -->
                    <div id="pdfTwo" class="hidden col-md-12 clearBothClass commonPdfClass">
                      <!-- inputs -->
                      <div class="col-md-12 talentAquisitionData hidden pad-null" id="aquisition_heading">
                        <div class="heading_table col-md-12 text-center" style="margin: 2% 0px;">
                          <h4 class="header_heading">
                            Talent Aquisition
                          </h4>

                        </div>
                        <div class="col-md-12 inputPrintText" style="padding-bottom: 20px;">
                          <h4 class="text-left inputsheadTextForTA pad-null col-md-6" style="margin-bottom: 25px;">
                            Inputs
                          </h4>
                          <div class="col-md-6 text-right hidden" style="margin: 0% 0px;">
                            <h4 class="emailData"></h4>
                          </div>
                          <table id="talentAquisition_details_data_inputs"
                            class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="inputValues" id="nintyDaysAttritionRate_input"></tr>
                            <tr class="inputValues" id="annualHire_input"></tr>
                            <tr class="inputValues" id="industryRecruit_input"></tr>
                          </table>
                        </div>
                      </div>

                      <!-- outputs -->
                      <div class="talentAquisitionResultsData col-md-12 hidden pad-null"
                        id="aquisition_outputs_heading">

                        <div class="col-md-12 outputPrintPart">
                          <h4 class="text-left outputssheadTextForTA" style="margin-bottom: 10px;">
                            Output for Quality of Hire
                          </h4>

                          <table id="talentAquisition_details_data_inputs"
                            class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="outputValues" id="revenueLossInMillions_result_output"></tr>
                            <tr class="outputValues" id="noOf90_day_attrition_number_result_output"></tr>
                            <tr class="outputValues" id="cost_of_bad_hiresValueInMillions_result_output"></tr>
                          </table>

                          <h4 class="text-left outputssheadTextForTA" style="margin-bottom: 10px;">
                            Output for Time to fill
                          </h4>

                          <table id="talentAquisition_details_data_inputs"
                            class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <!-- timeTofill -->
                            <tr class="outputValues" id="recruiting_velocity_gap_result_output"></tr>
                            <tr class="outputValues" id="total_staffing_cost_result_output"></tr>
                            <tr class="outputValues" id="velocity_gap_costValueInMillions_result_output"></tr>
                            <tr class="outputValues" id="totalCost_savingsInMillions_result_output"></tr>
                          </table>
                        </div>
                      </div>

                    </div>

                    <!-- Talent Management Data -->
                    <div id="pdfThree" class="hidden col-md-12 clearBothClass commonPdfClass">
                      <!-- inputs -->
                      <div class="talentManagementResultsData hidden col-md-12 pad-null" id="management_heading">
                        <div class="heading_table col-md-12 text-center" style="margin: 2% 0px;">
                          <h4 class="header_heading">
                            Talent Management
                          </h4>
                        </div>
                        <div class="col-md-12 inputPrintText" style="padding-bottom: 25px;">
                          <h4 class="text-left inputsheadTextForTM pad-null col-md-6" style="margin-bottom: 25px;">
                            Inputs
                          </h4>
                          <div class="col-md-6 text-right hidden" style="margin: 0% 0px;">
                            <h4 class="emailData"></h4>
                          </div>

                          <table id="talentManagement_details_data_inputs"
                            class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="inputValues" id="global_target_engagement_score_result"></tr>
                            <tr class="inputValues" id="global_engagement_score_talentManagement_result"></tr>
                          </table>
                        </div>
                      </div>

                      <!-- outputs -->
                      <div class="col-md-12 tmResultsData hidden pad-null" id="tm_outPuts_heading">
                        <div class="col-md-12 outputPrintPart">
                          <h4 class="text-left outputssheadTextForTM" style="margin-bottom: 10px;">
                            Output for Employee engagement
                          </h4>

                          <table id="tm_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="outputValues" id="new_engagement_to_target_talentManagementValue_result_output">
                            </tr>
                            <tr class="outputValues"
                              id="new_potential_revenue_gain_talentManagementValueInMillions_result_output"></tr>

                          </table>

                          <h4 class="text-left outputssheadTextForTM" style="margin-bottom: 10px;">
                            Output for Employee experiance automation
                          </h4>

                          <table id="tm_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">

                            <tr class="outputValues"
                              id="cost_savings_and_productivity_gainValueInMillions_result_output">
                            </tr>
                          </table>

                          <h4 class="text-left outputssheadTextForTM" style="margin-bottom: 10px;">
                            Output for Performance management
                          </h4>

                          <table id="tm_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="outputValues" id="estimated_average_hiring_costValueInMillions_result_output">
                            </tr>
                            <tr class="outputValues" id="pm_estimated_total_turnover_costValueInMillions_result_output">
                            </tr>
                            <tr class="outputValues" id="pm_reduction_turnover_costValueInMillions_result_output"></tr>
                          </table>
                          <!-- org Outputs End -->
                        </div>
                      </div>

                    </div>

                    <!-- Learning Data -->
                    <div id="pdfFour" class="hidden col-md-12 clearBothClass commonPdfClass">
                      <!-- inputs -->
                      <div class="LearningResultsData hidden col-md-12 pad-null" id="Learning_heading">
                        <div class="heading_table col-md-12 text-center" style="margin: 2% 0px;">
                          <h4 class="header_heading">
                            Learning
                          </h4>
                        </div>
                        <div class="col-md-12 inputprintText" style="padding-bottom: 25px;">
                          <h4 class="text-left col-md-6 inputsheadTextForLearning pad-null"
                            style="margin-bottom: 25px;">
                            Inputs
                          </h4>
                          <div class="col-md-6 text-right hidden" style="margin: 0% 0px;">
                            <h4 class="emailData"></h4>
                          </div>

                          <table id="learning_details_data_inputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="inputValues" id="voluntary_learningValue_result"></tr>
                            <tr class="inputValues" id="learners_engagedValue_result"></tr>
                            <tr class="inputValues" id="ld_number_of_managersValue_result"></tr>
                            <tr class="inputValues" id="percent_of_managersValue_result"></tr>
                            <tr class="inputValues" id="current_percent_of_employeesValue_result"></tr>
                            <tr class="inputValues" id="estimated_increase_in_manager_engagementValue_result"></tr>
                            <tr class="inputValues" id="est_change_employee_engagementValue_result"></tr>
                          </table>
                        </div>
                      </div>

                      <!-- outputs -->
                      <div class="col-md-12 LearningResultsData hidden pad-null" id="learning_outPuts_heading">
                        <div class="col-md-12 outputPrintPart">
                          <h4 class="text-left outputssheadTextForLearning" style="margin-bottom: 10px;">
                            Output for learning engagement
                          </h4>

                          <table id="tm_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="outputValues" id="learning_engagement_scoreValue_result_output"></tr>
                            <tr class="outputValues"
                              id="learning_engagement_productivity_gainValueInMillions_result_output"></tr>

                          </table>

                          <h4 class="text-left outputssheadTextForLearning" style="margin-bottom: 10px;">
                            Output for leadership development
                          </h4>

                          <table id="tm_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">

                            <tr class="outputValues" id="increase_revenue_per_employeeValueInMillions_result_output">
                            </tr>
                            <tr class="outputValues" id="est_annual_revenue_growthValueInMillions_result_output"></tr>
                            <tr class="outputValues" id="net_productivity_gainValueInMillions_result_output"></tr>
                          </table>
                          <!-- Learning Outputs End -->
                        </div>
                      </div>

                    </div>

                    <!-- analytics Data -->
                    <div id="pdfFive" class="hidden col-md-12 clearBothClass commonPdfClass">
                      <!-- inputs -->
                      <div class="AnalyticsResultsData hidden col-md-12 pad-null" id="analytics_heading">
                        <div class="heading_table col-md-12 text-center" style="margin: 2% 0px;">
                          <h4 class="header_heading">
                            People Analytics
                          </h4>
                        </div>

                        <!-- Analytics in Talent Aquisition -->
                        <div class="col-md-12 inputprintText" style="padding-bottom: 25px;">
                          <h4 class="text-left col-md-6 inputsheadTextForAnalytics pad-null"
                            style="margin-bottom: 15px;">
                            Inputs
                          </h4>
                          <div class="col-md-6 text-right hidden" style="margin: 0% 0px;">
                            <h4 class="emailData"></h4>
                          </div>

                          <table id="analytics_in_TA_details_data_inputs"
                            class="col-md-12 col-sm-12 col-xs-12 results_input"
                            style="border: 1px solid #dddddd; padding: 8px;">
                            <tr class="inputValues" id="employees_hiredValue_result"></tr>
                            <tr class="inputValues" id="new_hire_long_commuteValue_result"></tr>
                            <tr class="inputValues" id="new_hire_turnoverValue_result"></tr>
                            <!-- Analytics in Talent Management -->
                            <tr class="inputValues" id="industryAnalyticsOption_result"></tr>
                            <tr class="inputValues" id="turnover_ratioValue_result"></tr>
                            <!-- Analytics in Employee Engagement -->
                            <tr class="inputValues" id="managers_hiredValue_result"></tr>
                            <tr class="inputValues" id="managers_promotedValue_result"></tr>
                            <tr class="inputValues" id="people_managersValue_result"></tr>
                          </table>
                        </div>
                        <!-- Analytics in Talent Management -->
                      </div>
                      <!-- outputs -->

                    </div>

                    <!-- outputs -->
                    <div class="col-md-12 AnalyticsResultsData hidden pad-null" id="analytics_outPuts_heading">
                      <div class="col-md-12 outputPrintPart">
                        <h4 class="text-left outputssheadTextForanalytics" style="margin-bottom: 10px;">
                          Output for impact of commuting time
                        </h4>

                        <table id="analytics_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                          style="border: 1px solid #dddddd; padding: 8px;">
                          <tr class="outputValues" id="commute_attrition_impactValue_result_output"></tr>
                          <tr class="outputValues" id="potential_recoveryValueInMillions_result_output"></tr>

                        </table>

                        <h4 class="text-left outputssheadTextForanalytics" style="margin-bottom: 10px;">
                          Output for predicting employee turnover
                        </h4>

                        <table id="analytics_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                          style="border: 1px solid #dddddd; padding: 8px;">

                          <tr class="outputValues" id="averageIndustryTurnOverValue_result_output"></tr>
                          <tr class="outputValues" id="turnover_gapValue_result_output"></tr>
                          <tr class="outputValues" id="predictive_model_impactValueInMillions_result_output"></tr>

                        </table>

                        <h4 class="text-left outputssheadTextForanalytics" style="margin-bottom: 10px;">
                          Output for self-directed learning
                        </h4>

                        <table id="analytics_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                          style="border: 1px solid #dddddd; padding: 8px;">

                          <tr class="outputValues"
                            id="engagement_impact_of_employee_developmentValueInMillions_result_output"></tr>
                          <tr class="outputValues" id="employee_development_budgetValueInMillions_result_output"></tr>
                          <tr class="outputValues"
                            id="learning_effectiveness_productivity_boostValueInMillions_result_output"></tr>
                          <tr class="outputValues" id="est_gain_learning_analyticsValueInMillions_result_output"></tr>

                        </table>

                        <h4 class="text-left outputssheadTextForanalytics" style="margin-bottom: 10px;">
                          Output for hiring and developing managers
                        </h4>

                        <table id="analytics_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_input"
                          style="border: 1px solid #dddddd; padding: 8px;">
                          <tr class="outputValues" id="mgr_eng_revenue_increaseValueInMillions_result_output"></tr>
                          <tr class="outputValues" id="mgr_assessment_costValueInMillions_result_output"></tr>
                          <tr class="outputValues" id="mgr_development_costValueInMillions_result_output"></tr>
                          <tr class="outputValues" id="mgr_eng_net_revenue_increaseValueInMillions_result_output"></tr>
                        </table>
                        <!-- Outputs End -->
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div id="potentialGain" class="tab-pane fade hidden">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <!-- Analytics -->
                  <table id="analytics_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_output">
                    <tr class="inputValues" id="engagement_revenue_potential_result"></tr>
                    <tr class="inputValues" id="turnover_gap_result"></tr>
                    <tr class="inputValues" id="turnover_opportunity_result"></tr>
                  </table>

                  <!-- Management -->
                  <table id="management_details_data_outputs" class="col-md-12 col-sm-12 col-xs-12 results_output">
                    <tr class="inputValues" id="expected_employee_productivity_gain_doller_new_result"></tr>
                    <tr class="inputValues" id="cost_savings_and_productivity_gain_result"></tr>
                    <tr class="inputValues" id="reduction_hiring_administration_cost_result"></tr>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 text-center step_button">

          <a class="button_continue exportPdf col-md-3 col-sm-3 col-md-offset-2 col-xs-offset-2 col-sm-offset-2"
            data-target="#myMailModal" onclick="getPDF();">
            Email me the results <i class='fa fa arrow_icon'>&#xf061;</i>
          </a>
          <div class="col-md-5 col-sm-5 col-xs-5 emailMainClass">
            <div class="form-group email_data">
              <input autocomplete="off" step="any" class="input_custom_style col-md-12 col-sm-12 col-xs-12" type="email"
                name="email" id="email"
                placeholder="Enter your email address here. We will send you a report you can share." />
              <p class="errorMesageForEmail hidden text-center redColor">Please enter valid Email address</p>
            </div>
          </div>
        </div>
        <div data-overlay="overlay" class="loaderNew hidden text-center">

          <!-- Animated cube -->
          <div class="cssload-container text-center">
            <div class="cssload-cube">
              <div class="cssload-half1">
                <div class="cssload-side cssload-s1"></div>
                <div class="cssload-side cssload-s2"></div>
                <div class="cssload-side cssload-s5"></div>
              </div>
              <div class="cssload-half2">
                <div class="cssload-side cssload-s3"></div>
                <div class="cssload-side cssload-s4"></div>
                <div class="cssload-side cssload-s6"></div>
              </div>
            </div>
          </div>
          <!-- / Animated cube -->
          <span data-overlay-msg></span>
        </div>
      </div>
    </div>
  </form>

  <div class="modal fade" id="myMailModal" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <div class="loader col-md-12 hidden">
            <i class="fa fa-spinner fa-spin text-center"></i>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
