<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps">Chasma Apps</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps/chasma-natiodesk">Chasma Natio Desk</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Natio Desk</h3>
                <p>Support portal management for customers and internal <span class="d-inline-block">users in one application</span>
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/natiodesk/natio-desk-image.webp" alt="Chasma Quale image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">Modernize Your Helpdesk Service Delivery
                </h1>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/natiodesk/Trust.png" alt="Trust">
                </div>
                <div class="px-5">
                    <p>
                        Trust, timely information, and rapid resolution are the lifeblood of <span class="d-inline-block">support relationships.</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/natiodesk/Step up.png" alt="Step up to tools">
                </div>
                <div class="px-5">
                    <p>
                        Step up to tools you can rely on to <span class="d-inline-block">deliver results.</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/natiodesk/Chasma Natio Desk Enables.png" alt="personalized service to your customers">
                </div>
                <div class="px-2">
                    <p>
                        Chasma Natio Desk enables you to provide fast, personalized service to your customers and internal user groups.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center mb-4">
                <h4 class="font-weight-bold py-4 mb-0">Provide fast, personalized service to your customers and internal user groups.
                </h4>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/natiodesk/Quick and easy.png" alt="Quick and easy administration" class="mb-3">
                    <p class="px-4">
                        Quick and easy administration to set up and manage unlimited customer accounts.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/natiodesk/Customize SLA.png" alt="Customize SLA rules" class="mb-3">
                    <p class="px-4">
                        Customize SLA rules for each customer to make your service flexible and responsive to their needs.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/natiodesk/Three Pricing.png" alt="Three pricing levels to fit your budget" class="mb-3">
                    <p class="px-4">
                        Three pricing levels to fit your budget and support needs.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/natiodesk/Custom Branding.png" alt="Custom branding" class="mb-3">
                    <p class="px-4">
                        Custom branding to make the application uniquely your own.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/natiodesk/Dashboards and reports.png" alt="Dashboards and reports" class="mb-3">
                    <p class="px-4">
                        Dashboards and reports to view status and progress of issues, manage workflow, and analyze bottlenecks <span class="d-inline-block">and gaps.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/natiodesk/Stat With FAQs.png" alt="Start with FAQs" class="mb-3">
                    <p class="px-4">
                        Start with FAQs and capture Q&As to build a customer knowledge base.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Natio Desk?</h4>
                    <p class="">
                        A ticketing, tracking, and information hub you can use to support customers, products, partners, or your in-house help desk to support employees. Contact us to see how you can provide fast, personalized service to your customers and internal
                        <span
                            class="d-inline-block">user groups with Natio desk.</span>
                    </p>
                    <p>
                        <a href="contact-us" class="video_btn_style text-white">Contact Us <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <img src="../../../assets/images/products/natiodesk/Natiodesk-video-image.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/images/products/event/Need more.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-white pl-md-5 mt-5 mt-md-0">
                        <h2 class="font-weight-bold text-uppercase">Need More </h2>
                        <p class="mb-4">Talk to us about your business goals</p>
                        <div class="more_info_btn">
                            <a href="contact-us">Contact Us <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>