import { AppConstants } from './../../../shared/models/appConstants';
import { ApicallsService } from './../../apicalls.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {

  email: string;
  errorMessage: string;
  isSaveButtonVisible: boolean = false;

  constructor(public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private apicallsService: ApicallsService) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  forgotPasswordDetails() {
    this.apicallsService.postForgotPasswordDetails(this.email).subscribe(result => {
      this.errorMessage = result;
      if (result == 'Password details has been sent to your registered email.') {
        this.isSaveButtonVisible = !this.isSaveButtonVisible;
      }
    });
  }

}
