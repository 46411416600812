import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-managedtalentdevelopmentservices',
  templateUrl: './managedtalentdevelopmentservices.component.html',
  styleUrls: ['./managedtalentdevelopmentservices.component.scss']
})

export class ManagedtalentdevelopmentservicesComponent implements OnInit {
  title = 'Managed Talent Development Services ';
  constructor(private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Let Pixentia services manage your talent development technology so you can focus on your people.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Managed Talent Development Services' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Let Pixentia services manage your talent development technology so you can focus on your people.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/managed-talent-development-services' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Managed Talent Development Services' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Let Pixentia services manage your talent development technology so you can focus on your people.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/managed-talent-development-services' }      
    );
  }

}
