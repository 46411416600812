<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/employee-portals">Employee Portals</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Unleash Human Ingenuity with an Employee Portal</h3>
                <p>Designed for Your Business
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Employee Portals Image.png" alt="Employee Portals Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.kubota.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/kuboto.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="http://www.intelsecurity.com" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/mcafee_logo.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.pheaa.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/pheaa.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.linde.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/linde.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.tmhcc.com/en/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/tokia-marine.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Create a Whole-Experience Employee Portal</h1>
                </div>
                <div>
                    <p class="mb-3">
                        Over the past year and a half, companies have made tremendous strides in understanding that the employee experience drives business results.
                    </p>
                    <p class="mb-0">
                        Technology has much to do with that experience, and it is our mission to make yours the best it can be.
                    </p>
                </div>
                <div class="sub_head mt-4">
                    <h3 class="font-weight-bold">The Impact of Technology on Wellbeing
                    </h3>
                </div>
                <div>
                    <p class="mb-3">
                        Technology isn’t the primary driver in employee engagement.
                    </p>
                    <p class="mb-3">
                        (Hint: It’s the manager.)
                    </p>
                    <p>
                        Yet, technology has a role in nearly everything they do and every relationship they have.
                    </p>
                    <p>
                        As we will see, most of it is in how they use tech tools:
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="employee_section mb-4">
    <div class="template_width">
        <div class="row">
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Finding the information.png" alt="Finding the information" class="mb-3">
                    <p class="px-4">
                        Finding the information they need to <span class="d-inline-block">carry out their work.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Accomplishing those.png" alt="Accomplishing those tasks" class="mb-3">
                    <p class="px-4">
                        Accomplishing those tasks and contributing to the flow of work.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Interacting with colleagues.png" alt="Interacting with colleagues" class="mb-3">
                    <p class="px-4">
                        Interacting with colleagues, teammates, leaders, partners, and customers.
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Learning how to succeed.png" alt="Learning how to succeed in their current job" class="mb-3">
                    <p class="px-4">
                        Learning how to succeed in their current job and prepare for future roles.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Caring for their wellbeing.png" alt="Caring for their wellbeing" class="mb-3">
                    <p class="px-4">
                        Caring for their wellbeing and that <span class="d-inline-block">of their families.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-12 text-center mt-4">
                <p>Because those things are important to them, anything that frustrates them affects their mental wellbeing and engagement.</p>
            </div>
        </div>
    </div>
</div>
<div class="portal_section text-white">
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center text-white mt-3">
                    <h4 class="font-weight-bold">Deliver a Better Experience with an Employee Portal</h4>
                    <p class="mb-0">
                        An employee portal can bring together the information and resources your people need in their daily work life.
                    </p>
                    <p class="mb-0">
                        In addition, it can deliver and what they need to care for themselves and their families.
                    </p>
                    <p class="mb-4">
                        With careful planning and execution, you can provide an environment that will enable them to:
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Balance wellbeing.png" alt="Balance wellbeing" class="mb-3">
                    <p class="px-4">
                        Balance wellbeing with the need to <span class="d-inline-block">work productively.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Use the information.png" alt="Use the information they need to be productive" class="mb-3">
                    <p class="px-4">
                        Use the information they need to be productive and innovative when and <span class="d-inline-block">where they need it.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Create collaboration.png" alt="Create collaboration" class="mb-3">
                    <p class="px-4">
                        Create collaboration across the organization and your extended enterprise.
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Pursue their personal.png" alt="Pursue their personal and professional growth" class="mb-3">
                    <p class="px-4">
                        Pursue their personal and professional growth.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Celebrate and be.png" alt="Celebrate and be recognized for their accomplishments" class="mb-3">
                    <p class="px-4">
                        Celebrate and be recognized for <span class="d-inline-block">their accomplishments.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-8 offset-md-2 text-center mt-5">
                <p>In other words, an effective portal will address the needs of the whole person for each member of your workforce, so they can be the best they can be.</p>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="text-center my-5 ">
        <div class="row">
            <div class="col-md-6 offset-md-3 slogan">
                <p class="font-weight-bold">
                    "..Human productivity is not solely measured by efficiency. We need to empower human ingenuity by reimagining the employee experience."

                </p>
                <p class="">Microsoft: The People Powered Workplace</p>

            </div>
        </div>
    </div>
    <div class="text-center">
        <div class="row">
            <div class="col-md-12">
                <div class="sub_head mb-5">
                    <h3 class="font-weight-bold">Create the Employee Portal that’s Right for Your Business
                    </h3>
                    <p class="mb-0">There are many employee portal solutions available.</p>
                    <p class="mb-0">However, creating the right one for your company can be a challenge. Trial and error may never get you what you need.</p>
                    <p class="mb-0">To overcome that risk, we take a structured approach to identify your needs and budget, focusing relentlessly on your success.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="services_section">
        <div class="template_width">
            <div class="row mb-4">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified">
                        <li class="nav-item text-center">
                            <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Optimization
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <!-- Tab panes -->
                <div class="tab-content mb-5 mt-3">
                    <div class="tab-pane active" id="strategy">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Strategy and Planning
                                    </h4>
                                </div>
                                <p>
                                    We provide a structured approach to supporting your workforce. Our services include:
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Strategy Workshops.png" alt="Strategy Workshops">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Strategy Workshops</p>
                                        <p>
                                            Work sessions to develop an understanding of your business challenges and objectives.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Governance.png" alt="Governance">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Governance</p>
                                        <p>
                                            Establishing a framework for data and information governance, so you can deliver accurate information and valuable tools to your people.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Change Management.png" alt="Change Management">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Change Management</p>
                                        <p>
                                            Change management tools help you create a wave of change across your organization.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Technology Roadmap.png" alt="Technology Roadmap">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Technology Roadmap</p>
                                        <p>
                                            No matter where you are in your journey, we help you develop a road map to providing a whole-person employee experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Technology Selection.png" alt="Technology Selection">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Technology Selection</p>
                                        <p>
                                            We provide tools that assist you in selecting the right solution for your workforce.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="deployment">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Preparation and Deployment
                                    </h4>
                                </div>
                                <p>
                                    We prepare you to create and maintain your technology with a collaborative approach to project management.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-preparation/Project Planning.png" alt="Project Planning">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Project Planning</p>
                                        <p>
                                            We provide tools for managing your resources, people, and time to ensure a successful <span class="d-inline-block">on-time launch.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-preparation/Data Cleansing.png" alt="Data Cleansing and Migration">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Data Cleansing and Migration</p>
                                        <p>
                                            We provide tools to ensure consistent and reliable data before you migrate it to your portal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-preparation/Testing.png" alt="Testing">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Testing</p>
                                        <p>
                                            We provide training and tools to manage your testing to ensure a successful launch.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="optimization">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Optimization
                                    </h4>
                                </div>
                                <p>
                                    After you launch your new portal, your deployment continues as you adopt new tools and practices to optimize <span class="d-inline-block">your employee experience.</span>
                                </p>
                                <p>
                                    In addition, we provide ongoing support and services to enable you to grow with your technology as your technology grows with you.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-optimization/Business Process Optimization.png" alt="Business Process Optimization">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Business Process Optimization</p>
                                        <p>
                                            Direct help for your administrators to ensure timely information delivery and workflow <span class="d-inline-block">as your needs change.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-optimization/User Experience Optimization.png" alt="User Experience Optimization">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">User Experience Optimization</p>
                                        <p>
                                            We assist you in monitoring and fine-tuning the user experience to make it the best it can be.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>