import { ApicallsService } from './../../apicalls.service';
import { eLearningDemosModel } from './../../../shared/models/eLearningDemos';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-demosmain',
  templateUrl: './demosmain.component.html',
  styleUrls: ['./demosmain.component.scss']
})
export class DemosmainComponent implements OnInit, OnChanges {

  @Input('loginDemoNumber') loginDemoNumber: number;

  eLearningDemo: eLearningDemosModel;
  isSignUpForm: boolean;
  demosView: boolean = true;
  isLoggedIn: boolean;
  signUpView: boolean;
  loginView: boolean;
  demoVedioSource: any;
  demoDescription: any;
  demoTitle: string;

  title = 'Pixentia eLearning Demos';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private apicallsService: ApicallsService,
    private bnIdle: BnNgIdleService,
    private router: Router) {
    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.apicallsService.logoutDemoUser();
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.isLoggedIn = localStorage.getItem('isLoggedIn') == null ? false : true;

    this.metaTagService.updateTag(
      { name: 'description', content: "Pixentia’s custom courses engage your learners from the start to the end, helping your learners meet learning goals in time and perform better at work." }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia eLearning Demos' }
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: "Pixentia’s custom courses engage your learners from the start to the end, helping your learners meet learning goals in time and perform better at work." }
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/learning/demosmain' }
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia eLearning Demos' }
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: "Pixentia’s custom courses engage your learners from the start to the end, helping your learners meet learning goals in time and perform better at work." }
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/learning/demosmain' }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.loginDemoNumber) {
      this.onLoginClick(this.loginDemoNumber);
    }
  }

  onSignUpClick(data: number) {
    this.demosView = !this.demosView;
    this.isSignUpForm = true;
    this.learningDemo(data);
  }

  onLoginClick(data: number) {
    this.demosView = !this.demosView;
    this.isSignUpForm = false;
    this.learningDemo(data);
  }

  backClicked() {
    this.demosView = !this.demosView;
    location.reload();
    window.scrollTo(0, 0);
  }

  logout() {
    this.apicallsService.logoutDemoUser();
  }

  learningDemo(data: any) {
    switch (data) {
      case 1:
        this.eLearningDemo = {
          demoNumber: 1,
          demoVedioSource: "../../assets/videos/mod-1-intro.mp4",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "Do you want to enhance your email communication skills ? Are you looking to build your own Email Policy for your organization ? Check out this engaging Course on “Email Essentials for Business” which not only makes your email communication effective but also helps you manage any critical business situation through your emails.",
          demoTitle: "Email Essentials for Business",
        };
        break;
      case 2:
        this.eLearningDemo = {
          demoNumber: 2,
          demoVedioSource: "../../assets/videos/InterPersonal_Communication.mp4",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "Are you an Effective Listener? Are you Assertive and also Approachable? One of the important skills to succeed in a corporate world is Interpersonal Communication Skills.If you want make a lasting impact with your communication, check out this course on Interpersonal Communication Skills that takes you through an immersive path of real learning.",
          demoTitle: "Interpersonal Communication",
        };
        break;
      case 3:
        this.eLearningDemo = {
          demoNumber: 3,
          demoVedioSource: "../../assets/videos/Brain Training Video_video.m4v",
          demoVedioLink: "../../assets/videos/challenge173/index.html",
          demoDescription: "Gamified learning and learning that involves games engage learners better than courses that just have static content of text and images.This interaction is a language game worked out for the e- learning challenge on, ‘Brain Training Games, Puzzles and Exercises in E - learning’.",
          demoTitle: "Brain Training Games, Puzzles and Exercises in E-learning",
        };
        break;
      case 4:
        this.eLearningDemo = {
          demoNumber: 4,
          demoVedioSource: "../../assets/videos/Drag And Drop_video.m4v",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "Drag and drop is one of the common interactions used in e-learning courses, more often it appears in assessments.But we can also create custom drag and drop to engage learners better.This interaction is a custom drag and drop sample worked out for the e- learning challenge on, ‘Creating Custom drag and drop interactions’.",
          demoTitle: "Creating Custom drag and drop interactions",
        };
        break;
      case 5:
        this.eLearningDemo = {
          demoNumber: 5,
          demoVedioSource: "../../assets/videos/blak and white_video.mp4",
          demoVedioLink: "../../assets/videos/challenge172/index.html",
          demoDescription: "Picking the right colors for your course design is a crucial part while building your courses.This also has its part creating interest in the learners.But how about using only white and black in your courses? This interaction is a sample worked out for the e- learning challenge on, ‘Using only Black and White in course design.’",
          demoTitle: "Using only Black and White in course design",
        };
        break;
      case 6:
        this.eLearningDemo = {
          demoNumber: 6,
          demoVedioSource: "../../assets/videos/mod-1-intro.mp4",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "Making choices is many times hard. When it is to make a choice about employees, an HR coordinator needs to be cautious enough to avoid conflicts.Check out this branching scenario which helps you make right choices when you have to deal with an employee, who is excessively tardy.",
          demoTitle: "Workplace Ethics",
        };
        break;
      case 7:
        this.eLearningDemo = {
          demoNumber: 7,
          demoVedioSource: "../../assets/videos/Cisco.mp4",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "When you are trained on how to use a device, you get to know how its features work, and what to do when something goes wrong.Check out this interaction which helps you learn some of the features of VoIP phone.",
          demoTitle: "Getting to know your CISCO",
        };
        break;
      case 8:
        this.eLearningDemo = {
          demoNumber: 8,
          demoVedioSource: "../../assets/videos/Racer.mp4",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "Racing is exciting and when you win that’s even more thrilling. Racing games also give you the same kind of excitement of competing in a race.Have you ever been in a race? Check out this gamified interaction that takes you on a race with a big reward.",
          demoTitle: "Street Racer",
        };
        break;
      case 9:
        this.eLearningDemo = {
          demoNumber: 9,
          demoVedioSource: "../../assets/videos/Brain Training Video_video.m4v",
          demoVedioLink: "../../assets/videos/challenge173/index.html",
          demoDescription: "Gamified learning and learning that involves games engage learners better than courses that just have static content of text and images.This interaction is a language game worked out for the e- learning challenge on, ‘Brain Training Games, Puzzles and Exercises in E - learning’.",
          demoTitle: "Brain Training Games, Puzzles and Exercises in E-learning",
        };
        break;
      case 10:
        this.eLearningDemo = {
          demoNumber: 10,
          demoVedioSource: "../../assets/videos/Drag And Drop_video.m4v",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "Drag and drop is one of the common interactions used in e-learning courses, more often it appears in assessments.But we can also create custom drag and drop to engage learners better.This interaction is a custom drag and drop sample worked out for the e- learning challenge on, ‘Creating Custom drag and drop interactions’.",
          demoTitle: "Creating Custom drag and drop interactions",
        };
        break;
      case 11:
        this.eLearningDemo = {
          demoNumber: 11,
          demoVedioSource: "../../assets/videos/blak and white_video.mp4",
          demoVedioLink: "../../assets/videos/challenge172/index.html",
          demoDescription: "Picking the right colors for your course design is a crucial part while building your courses.This also has its part creating interest in the learners.But how about using only white and black in your courses? This interaction is a sample worked out for the e- learning challenge on, ‘Using only Black and White in course design.’",
          demoTitle: "Using only Black and White in course design",
        };
        break;
      case 12:
        this.eLearningDemo = {
          demoNumber: 12,
          demoVedioSource: "../../assets/videos/mod-1-intro.mp4",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "Making choices is many times hard. When it is to make a choice about employees, an HR coordinator needs to be cautious enough to avoid conflicts.Check out this branching scenario which helps you make right choices when you have to deal with an employee, who is excessively tardy.",
          demoTitle: "Workplace Ethics",
        };
        break;
      case 13:
        this.eLearningDemo = {
          demoNumber: 13,
          demoVedioSource: "../../assets/videos/Cisco.mp4",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "When you are trained on how to use a device, you get to know how its features work, and what to do when something goes wrong.Check out this interaction which helps you learn some of the features of VoIP phone.",
          demoTitle: "Getting to know your CISCO",
        };
        break;
      case 14:
        this.eLearningDemo = {
          demoNumber: 14,
          demoVedioSource: "../../assets/videos/Racer.mp4",
          demoVedioLink: "../../assets/videos/challenge16/index.html",
          demoDescription: "Racing is exciting and when you win that’s even more thrilling. Racing games also give you the same kind of excitement of competing in a race.Have you ever been in a race? Check out this gamified interaction that takes you on a race with a big reward.",
          demoTitle: "Street Racer",
        };
        break;
      default:
        alert("Demo doesn't exists.........");
    }
  }
}
