<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Company</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="partners">Partners</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Our Partners</h3>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/partners/Partner Image.png" alt="" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-12">
            <div class="text-center">
                <img src="../../assets/images/partners/PixSumTotal_logo.png" class="img-fluid" alt="">
            </div>
        </div>
    </div>
    <div class="row my-5">
        <div class="text-center mx-auto">
            <img src="../../assets/images/partners/SumTotal_Partner_Badge_Implementation_903ae6152df5f9be5dc07b4077c72995.png" alt="">
            <img src="../../assets/images/partners/SumTotal_Partner_Badge_OnPremise_903ae6152df5f9be5dc07b4077c72995.png" class="ml-2" alt="">
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row mb-5 align-items-center info_section">
        <div class="col-md-12 text-center sub_head">
            <h3 class="text-center mb-3 font-weight-bold">
                We're A Certified SumTotal Services Partner!
            </h3>
            <h6 class="font-weight-bold">We focus on your objectives:</h6>
            <div class="row">
                <div class="col-md-4 text-center border-right">
                    <div class="py-2 px-5">
                        <div class="mb-3 mt-4">
                            <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Adapting SumTotal.png" alt="SumTotal’s learning capabilities">
                        </div>
                        <div>
                            <p class="mb-1 font-weight-bold">Adapting SumTotal’s learning capabilities to your business goals
                            </p>
                            <p>
                                Using Pixentia’s extensive SumTotal experience to make smart recommendations on how SumTotal can meet your organization’s specific needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center border-right">
                    <div class="py-2 px-5">
                        <div class="mb-3 mt-4">
                            <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Improving organizational.png" alt="Improving organizational processes">
                        </div>
                        <div>
                            <p class="mb-1 font-weight-bold">Improving organizational processes and outcomes</p>
                            <p>
                                Leverage our expert knowledge of the SumTotal user interface to create simplified processes and intuitive workflows that cut costs, increase proficiency, and improve performance.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center">
                    <div class="py-2 px-5">
                        <div class="mb-3 mt-4">
                            <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Increasing the return.png" alt="SumTotal investment">
                        </div>
                        <div>
                            <p class="mb-1 font-weight-bold">Increasing the return on your SumTotal investment</p>
                            <p>
                                Using our human capital management expertise to exploit SumTotal’s features to maximize its impact on your workforce performance.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="text-center">
                        <a href="https://www.sumtotalsystems.com/explore-partners/pixentia" rel="noopener noreferrer" target="_blank" class="sumtotal_btn">Learn More<i class="fa fa-angle-right ml-2"
                            aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>