<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Products</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-connect">Chasma Connect</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Connect</h3>
                <p>Integrate your HR Applications data</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/connect/chasma-connect-image.png" alt="Chasma apps image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.kubota.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/kuboto.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="http://www.intelsecurity.com" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/mcafee_logo.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.pheaa.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/pheaa.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.linde.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/linde.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.tmhcc.com/en/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/tokia-marine.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">Increase your organizational agility</h1>
                <div>
                    <p class="mb-3">
                        A community of practitioners who believe technology should be an enabler that breaks down barriers to getting things done.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Automate.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p>
                        Avoid costly programming.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Offer.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p>
                        Bypass expensive middleware.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Deploy.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p>
                        Deploy in days, not weeks.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">Let Chasma Connect help you create a more agile organization
                </h4>
                <p>
                    Improve the user experience and make an impact far beyond data and administration.
                </p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/connect/Rapid Deployment.png" alt="Rapid Deployment" class="mb-3">
                    <p class="font-weight-bold mb-0">Rapid Deployment</p>
                    <p class="px-4">
                        Reduce implementation costs and accelerate your ability to respond to <span class="d-inline-block">changing business needs.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/connect/Improved Data Quality.png" alt="Improved Data Quality" class="mb-3">
                    <p class="font-weight-bold mb-0">Improved Data Quality</p>
                    <p class="px-4">
                        Reduce data preparation time and eliminate reporting errors.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/connect/Prefabricated Connectors.png" alt="Prefabricated Connectors" class="mb-3">
                    <p class="font-weight-bold mb-0">Prefabricated Connectors</p>
                    <p class="px-4">
                        Eliminate development costs and long deployment schedule.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/connect/Industry Standard Protocols.png" alt="Industry Standard Protocols" class="mb-3">
                    <p class="font-weight-bold mb-0">Industry Standard Protocols</p>
                    <p class="px-4">
                        Reduce maintenance costs and reprogramming effort.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/connect/Improved Data Flow.png" alt="Improved Data Flow" class="mb-3">
                    <p class="font-weight-bold mb-0">Improved Data Flow</p>
                    <p class="px-4">
                        Get information to the right people <span class="d-inline-block">when they need it.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/connect/Complete Documentation.png" alt="Complete Documentation" class="mb-3">
                    <p class="font-weight-bold mb-0">Complete Documentation</p>
                    <p class="px-4">
                        Eliminate the risk of obsolescence.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Connect?</h4>
                    <p class="">
                        You don’t need to spend weeks or months developing custom integrations for your HR application platforms. Watch the video to see how Chasma Connect offers you prefabricated plug and play connectors, integrators, ready to deploy into your applications.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/417196028?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div>
                            <img src="../../../assets/images/products/connect/Explore-Chasma-Connectors.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-7 text-white pl-md-5 mt-5 mt-md-0">
                        <h2 class="font-weight-bold mb-4">Frustrated by disconnected HR applications?
                        </h2>
                        <p>
                            Ease the pain with Chasma Connect's pre-fabricated connectors.
                        </p>
                        <div class="more_info_btn">
                            <a href="http://resources.chasma.io/data-ready-connections-for-human-capital-management-applications" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>