<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Products</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-analytics">Chasma Analytics</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Analytics</h3>
                <p>Data-Driven Visual Insight</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/analytics/chasma-analytcs-image.png" alt="Chasma analytcs image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.kubota.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/kuboto.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="http://www.intelsecurity.com" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/mcafee_logo.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.pheaa.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/pheaa.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.linde.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/linde.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.tmhcc.com/en/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/tokia-marine.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">Chasma Analytics for your HR System</h1>
                <div>
                    <p class="mb-3">
                        Takes you from raw data to visualization without weeks or months of costly development.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Automate.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p>
                        Dashboards and data models that address operational <span class="d-inline-block">reporting concerns.</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Offer.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p>
                        Real-time business intelligence for accurate decision making.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Deploy.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p>
                        Avoid costly customizations and enjoy rapid deployment.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">Visualizations That Help You Make Decisions At the Speed of Business
                </h4>
                <p>
                    Chasma Analytics provides operational dashboards any business can use. We avoid costly customizations by addressing the reporting needs that almost every organization faces and by doing so we can provide them at a rapid pace and at a much lower cost.
                </p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Out-of-the-box Apps.png" alt="Out-of-the-box Apps" class="mb-3">
                    <p class="font-weight-bold mb-0">Pre configured Dashboards</p>
                    <p class="px-4">
                        Pre configured dashboards you can deploy immediately into your <span class="d-inline-block">HR applications.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Modern User Interface.png" alt="Modern User Interface" class="mb-3">
                    <p class="font-weight-bold mb-0">Real-time Visualization</p>
                    <p class="px-4">
                        Real-time visualizations of important metrics like employee cost vs performance, headcount <span class="d-inline-block">analysis and more.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Cloud Advantage.png" alt="Cloud Advantage" class="mb-3">
                    <p class="font-weight-bold mb-0">Data Visualizations</p>
                    <p class="px-4">
                        Data visualizations that help your executives make decisions at the <span class="d-inline-block">speed of business.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Rapid Implementation.png" alt="Rapid Implementation" class="mb-3">
                    <p class="font-weight-bold mb-0">Advanced Analytics</p>
                    <p class="px-4">
                        Advanced analytics to give you deep insights into your workforce data.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Support Tools.png" alt="Support Tools" class="mb-3">
                    <p class="font-weight-bold mb-0">Analytical Visualizations</p>
                    <p class="px-4">
                        Analytical visualizations that help your talent professionals analyze the impact of their programs and processes
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Collaboration.png" alt="Collaboration" class="mb-3">
                    <p class="font-weight-bold mb-0">Predictive Models</p>
                    <p class="px-4">
                        Predictive models to increase efficiencies with data driven decisions that capitalize on your people potential.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Analytics?</h4>
                    <p class="">
                        Discover profound insights into the state of your operations and get started on your journey toward a data-driven organization. Watch the video to see how you can achieve reporting excellence without a long deployment cycle or the cost of a custom solution.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/231573767?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div>
                            <img src="../../assets/images/products/analytics/Analytics CTA.png" width="80%" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-7 text-white pl-md-5 mt-5 mt-md-0">
                        <p class="font-weight-bold mb-4">Get hooked up with updates on Chasma Analytics to know more on our latest solutions and offerings.</p>
                        <div class="more_info_btn">
                            <a href="contact-us">Contact Us <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>