import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  title = 'Page Not Found | Pixentia';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: '' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Page Not Found | Pixentia' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: '' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://www.pixentia.com/notfound' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Page Not Found | Pixentia' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: '' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://www.pixentia.com/notfound' }      
    );
  }

}
