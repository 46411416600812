import { ApicallsService } from './../../apicalls.service';
import { APIService } from './../../api.service';
import { environment } from './../../../../environments/environment';
import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { eLearningDemosModel, loginModel, signUpModel } from 'src/app/shared/models/eLearningDemos';
import { EmailValidator, removeSpaces } from 'src/app/shared/models/helper';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-learning-demo-signup',
  templateUrl: './learning-demo-signup.component.html',
  styleUrls: ['./learning-demo-signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LearningDemoSignupComponent implements OnInit, OnChanges, DoCheck {


  @Output() backClicked = new EventEmitter<any>();
  @Input('eLearningDemo') eLearningDemo: eLearningDemosModel;
  @Input('isSignUpForm') isSignUpForm: boolean;
  demoLink: SafeResourceUrl;
  signUpForm: FormGroup;
  loginForm: FormGroup;
  demoDetails: any;
  loginDemoNumber: number;
  signUpUserId: string;
  loggedInUserId: string;
  url: string;
  errorMessage: string;
  isSignUpSuccess: boolean = false;
  isLoggedIn: boolean;
  isSignUpButtonVisible: boolean = false;
  isLoginButtonVisible: boolean = false;
  loginAttempt: string;
  isFirstLogin: boolean;

  constructor(private matDialog: MatDialog,
    public sanitizer: DomSanitizer,
    private apiService: APIService,
    private apicallsService: ApicallsService,
    private fb: FormBuilder,
    private bnIdle: BnNgIdleService) {
    this.url = environment.apiURL;
    this.userSignUpForm();
    this.userLoginForm();
    this.isLoggedIn = localStorage.getItem('isLoggedIn') == null ? false : true;
    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.apicallsService.logoutDemoUser();
      }
    });
  }

  ngOnInit() {
    this.demoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.demoDetails.demoVedioLink);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.demoDetails = this.eLearningDemo;
    window.scrollTo(0, 980);
  }

  ngDoCheck() {
    this.isLoggedIn = localStorage.getItem('isLoggedIn') == null ? false : true;
    this.loginAttempt = localStorage.getItem('isFirstLogin');
    this.isFirstLogin = this.loginAttempt == null ? false : this.loginAttempt == 'true' ? true : false;

  }

  goBack() {
    this.backClicked.emit();
  }

  signUpClicked() {
    this.isSignUpForm = !this.isSignUpForm;
  }

  loginClicked() {
    this.isSignUpForm = !this.isSignUpForm;
  }

  forgotPassword() {
    const dialogRef = this.matDialog.open(ForgotPasswordDialogComponent, {
      disableClose: true,
      // width: '375px',
      // data: { visitorId: visitorId, organisationId: this.organisationId, organisationLocationId: this.organisationLocationId }
    })
  }

  userSignUp() {
    var signUpDetails: signUpModel;
    signUpDetails = this.signUpForm.value;
    this.apicallsService.postSignUpDetails(signUpDetails).subscribe(userId => {
      if (userId) {
        this.isSignUpSuccess = true;
        this.signUpUserId = userId;
      }
      this.isSignUpButtonVisible = !this.isSignUpButtonVisible;
    });
  }

  userLogin() {
    var loginDetails: loginModel;
    loginDetails = this.loginForm.value;
    this.apicallsService.loginDemoUser(loginDetails).subscribe(loggedInUserModel => {
      if (loggedInUserModel.userId != null) {
        this.loggedInUserId = loggedInUserModel.userId;
        localStorage.setItem('loggedInUserId', this.loggedInUserId);
        localStorage.setItem('isLoggedIn', 'true');

        let isStartingDemoLogin = localStorage.getItem('isFirstLogin');
        if (isStartingDemoLogin == null) {
          localStorage.setItem('isFirstLogin', 'true');
        }
        else {
          if (isStartingDemoLogin == 'true') {
            localStorage.setItem('isFirstLogin', 'false');
          }
        }


        this.loginDemoNumber = this.demoDetails.demoNumber;

      }
      else {
        this.errorMessage = loggedInUserModel.errorMessage;
      }
    })
  }

  logout() {
    this.apicallsService.logoutDemoUser();
  }

  userSignUpForm() {
    this.signUpForm = this.fb.group({
      fName: ['', [Validators.required, removeSpaces]],
      lName: ['', [Validators.required, removeSpaces]],
      company: ['', [Validators.required, removeSpaces]],
      jobTitle: ['', [Validators.required, removeSpaces]],
      email: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), EmailValidator.isValidMailFormat()]],
      contact: ['', [Validators.required, removeSpaces]],
    });
  }

  userLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), EmailValidator.isValidMailFormat()]],
      password: ['', [Validators.required, removeSpaces, Validators.maxLength(8)]],
    });
  }

}
