import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BiopageService } from '../shared/biopage.service'

@Component({
  selector: 'app-pixentiabio',
  templateUrl: './pixentiabio.component.html',
  styleUrls: ['./pixentiabio.component.scss']
})
export class PixentiabioComponent implements OnInit {
  title = 'Pixentia | Bio';
  navbarhide: boolean = true;
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private bioservice: BiopageService

  ) {
    this.bioservice.getnavbarhidden(this.navbarhide)
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Pixentia is a technology consulting and support company helping businesses to optimize the value in their HR and Learning technology' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia | Technology Consulting and Support' }
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Pixentia is a technology consulting and support company helping businesses to optimize the value in their HR and Learning technology' }
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/' }
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia | Technology Consulting and Support' }
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Pixentia is a technology consulting and support company helping businesses to optimize the value in their HR and Learning technology' }
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/' }
    );
  }
}
