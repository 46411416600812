<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-analytics">Chasma Analytics</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-analytics/chasma-hrbox">Chasma HRBox</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma HRBox</h3>
                <p>Dashboard Design for All your HR systems — without coding
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/hrbox/HRBox Image.png" alt="Chasma HRBox image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">Drag and Drop Dashboard Design</h1>
                <div>
                    <p class="mb-3">
                        Chasma’s HRBox library of templates enables anyone to create colorful and useful dashboard designs. Use our library of metrics and KPIs to get a head start on insights into your HR operations.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/hrbox/Create Visual.png" alt="Create visual insights">
                </div>
                <div class="px-5">
                    <p>
                        Create visual insights into your HR operations without programming.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/hrbox/Aggregate.png" alt="Aggregate data from any of your HR applications">
                </div>
                <div class="px-5">
                    <p>
                        Aggregate data from any of your <span class="d-inline-block">HR applications.</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/hrbox/Share Dashboard.png" alt="Share dashboards with anyone in your organization">
                </div>
                <div class="px-5">
                    <p>
                        Share dashboards with anyone in <span class="d-inline-block">your organization.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">Chasma HRBox Advantage
                </h4>
                <p>
                    Use data from any of your HR applications to create visual insights and group them into dashboards without coding.
                </p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/hrbox/Complete Library.png" alt="Complete Library of Templates" class="mb-3">
                    <p class="font-weight-bold mb-0">Complete Library of Templates</p>
                    <p class="px-4">
                        No programming skills required to create deep insights into HR operations.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/hrbox/API.png" alt="API Data Connections" class="mb-3">
                    <p class="font-weight-bold mb-0">API Data Connections</p>
                    <p class="px-4">
                        Connect to any HR application or <span class="d-inline-block">data source.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/hrbox/Easy dashboard.png" alt="Easy Dashboard Design" class="mb-3">
                    <p class="font-weight-bold mb-0">Easy Dashboard Design</p>
                    <p class="px-4">
                        Drag-and-drop visualizations <span class="d-inline-block">into visual design framework.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/hrbox/control data.png" alt="Control Your Data" class="mb-3">
                    <p class="font-weight-bold mb-0">Control Your Data</p>
                    <p class="px-4">
                        Create security groups to control <span class="d-inline-block">sensitive data.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/hrbox/Create unified.png" alt="Create a Unified HR Dashboard" class="mb-3">
                    <p class="font-weight-bold mb-0">Create a Unified HR Dashboard</p>
                    <p class="px-4">
                        Keep managers and business leaders informed of metrics important to them.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/hrbox/One click.png" alt="One-Click Dashboard Sharing" class="mb-3">
                    <p class="font-weight-bold mb-0">One-Click Dashboard Sharing</p>
                    <p class="px-4">
                        Share dashboards with anyone in <span class="d-inline-block">your organization.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma HRBox?</h4>
                    <p class="">
                        Aggregate information from any of your HR applications and use our flexible, easy to use design tool to create visual dashboards for your people data. Watch the video to see how HRBox helps you to create dashboards in minutes.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/267607899?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div>
                            <img src="../../assets/images/products/hrbox/HR Box CTA.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-7 text-white pl-md-5 mt-5 mt-md-0">
                        <h4 class="font-weight-bold">HR Dashboard Design for Everyone </h4>
                        <p class="mb-4">Point and click your way to data insights in all your HR systems with unified HR dashboards that keep leaders and managers informed of the metrics that are important to them.</p>
                        <div class="more_info_btn">
                            <a href="http://resources.chasma.io/chasma-hrbox-dashboards-for-people-analytics" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>