import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-learning-resource-center',
  templateUrl: './learning-resource-center.component.html',
  styleUrls: ['./learning-resource-center.component.scss']
})
export class LearningResourceCenterComponent implements OnInit {
  title = 'Pixentia Learning Resource Center';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: "Our learning center provides support for all your learning technology and content needs, from admin support to complete managed services."}
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Learning Resource Center' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: "Our learning center provides support for all your learning technology and content needs, from admin support to complete managed services." }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/learning/learning-resource-center' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Learning Resource Center' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: "Our learning center provides support for all your learning technology and content needs, from admin support to complete managed services." }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/learning/learning-resource-center' }      
    );
  }
}
