<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Products</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps">Chasma Apps</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Apps</h3>
                <p>Update, extend and complement your HR applications</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/apps/Chasma apps image.webp" alt="Chasma apps image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">Your independent source for HR apps to extend the capability of your HR technology</h1>
                <div>
                    <p class="mb-3">
                        Frustrated with working around your HR Applications?
                    </p>
                    <p class="mb-3">
                        Streamline your processes and extend your capability without disruptive and expensive replacement.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Automate.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p>
                        Automate processes to save hours of manual work.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Offer.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p>
                        Offer a modern and intuitive user experience.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/apps/Deploy.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p>
                        Deploy solutions in days, not weeks.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">Chasma Apps Advantage</h4>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Out-of-the-box Apps.png" alt="Out-of-the-box Apps" class="mb-3">
                    <p class="font-weight-bold mb-0">Out-of-the-box Apps</p>
                    <p class="px-4">
                        Apps that avoid the disruption and expense of an enterprise system replacement and complement your current software.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Modern User Interface.png" alt="Modern User Interface" class="mb-3">
                    <p class="font-weight-bold mb-0">Modern User Interface</p>
                    <p class="px-4">
                        Remove the bottlenecks and friction of an outdated user interface.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Cloud Advantage.png" alt="Cloud Advantage" class="mb-3">
                    <p class="font-weight-bold mb-0">Cloud Advantage</p>
                    <p class="px-4">
                        Enjoy the stability and <span class="d-inline-block">security of the cloud.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Rapid Implementation.png" alt="Rapid Implementation" class="mb-3">
                    <p class="font-weight-bold mb-0">Rapid Implementation</p>
                    <p class="px-4">
                        Implement solutions in days or weeks instead of months or years.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Support Tools.png" alt="Support Tools" class="mb-3">
                    <p class="font-weight-bold mb-0">Support Tools</p>
                    <p class="px-4">
                        Reduce support calls by giving your users all the tools they need to get things done in the flow of work.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Collaboration.png" alt="Collaboration" class="mb-3">
                    <p class="font-weight-bold mb-0">Collaboration</p>
                    <p class="px-4">
                        Facilitate collaboration inside your organization and with the partners in your value chain.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Unify user experience.png" alt="Unify user experience" class="mb-3">
                    <p class="font-weight-bold mb-0">Unify user experience</p>
                    <p class="px-4">
                        Unify user experience with no code dashboards for <span class="d-inline-block">user portals and
                            landing pages.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Data Quality.png" alt="Data Quality" class="mb-3">
                    <p class="font-weight-bold mb-0">Data Quality</p>
                    <p class="px-4">
                        Improve the quality of your data.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/apps/Automated Processes.png" alt="Automated Processes" class="mb-3">
                    <p class="font-weight-bold mb-0">Automated Processes</p>
                    <p class="px-4">
                        Automate manual processes to save your hours of work.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Apps?</h4>
                    <p class="">
                        Avoid the expense of ripping and replacing your HR platform with apps that adapt your technology to the way people work. Watch the video to see how Chasma offers you sleek modern apps that plug into your enterprise platforms and enhance your enterprise
                        efficiency.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/228929858?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/images/products/event/Need more.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-white pl-md-5 mt-5 mt-md-0">
                        <h2 class="font-weight-bold">Transform Your Employee Experience</h2>
                        <p class="mb-4">Learn about the low-cost, rapid-deployment approach to extending and modernizing your human capital management applications</p>
                        <div class="more_info_btn">
                            <a href="http://resources.chasma.io/chasma-apps" rel="noopener noreferrer" target="_blank">Get the details Here <i
                                    class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>