import { ApicallsService } from './../../apicalls.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { demoUserApprovalDetails, validateDemoSignUpUserDetails } from 'src/app/shared/models/eLearningDemos';

export interface DialogData {
  message: string;
  approvedBy: string;
  userDetails: validateDemoSignUpUserDetails;
}

@Component({
  selector: 'app-approval-confirmation-dialog',
  templateUrl: './approval-confirmation-dialog.component.html',
  styleUrls: ['./approval-confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApprovalConfirmationDialogComponent implements OnInit {

  confirmDialogMessage: string;
  approvalDetails: demoUserApprovalDetails;
  comments: string;
  escalatedto: string;
  isEscalateTo: boolean = false;
  isReject: boolean = false;

  constructor(public dialogRef: MatDialogRef<ApprovalConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apicallsService: ApicallsService) {

  }

  ngOnInit() {
    if (this.data.message == 'Approved') {
      this.confirmDialogMessage = 'Do you really want to approve this user?';
    }
    else if (this.data.message == 'Onhold') {
      this.confirmDialogMessage = 'Do you want to keep this user on hold?';
    }
    else if (this.data.message == 'Escalated') {
      this.confirmDialogMessage = 'Do you want to escalate this user?';
    }
    else {
      this.confirmDialogMessage = 'Do you want to reject this user?';
    }
  }

  closeDialog(message: any) {
    this.dialogRef.close(message);
    location.reload();
  }

  confirmStatus(dialogMessage: string) {
    if (dialogMessage == "" && (this.data.message == 'Escalated' || this.data.message == 'Rejected')) {
      if (this.data.message == 'Escalated') {
        this.isEscalateTo = true;
      }
      else {
        this.isReject = true;
      }
    }
    else {
      let updateUserDetails: demoUserApprovalDetails = {
        userID: this.data.userDetails.userID,
        fName: this.data.userDetails.fName,
        lName: this.data.userDetails.lName,
        company: this.data.userDetails.company,
        jobTitle: this.data.userDetails.job,
        email: this.data.userDetails.email,
        contact: this.data.userDetails.contact,
        isApproved: this.data.message,
        approvedBy: this.data.approvedBy,
        escalatedto: this.data.message == 'Escalated' ? this.escalatedto : null,
        comments: this.data.message == 'Rejected' ? this.comments : null,
      }
      this.apicallsService.updateDemoUserValidateDetails(updateUserDetails).subscribe(result => {
        this.closeDialog(result);
      })
    }

  }

}
