import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hcm-technology',
  templateUrl: './hcm-technology.component.html',
  styleUrls: ['./hcm-technology.component.scss']
})
export class HcmTechnologyComponent implements OnInit {

  title = 'Pixentia HR Technology Resource Center';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Our  technology resource center serves your HCM needs with on-call technical assistance, integrations, testing, updates, and  upgrades.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia HR Technology Resource Center' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Our  technology resource center serves your HCM needs with on-call technical assistance, integrations, testing, updates, and  upgrades.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/hcm/hcm-coe/technology' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia HR Technology Resource Center' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Our  technology resource center serves your HCM needs with on-call technical assistance, integrations, testing, updates, and  upgrades.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/hcm/hcm-coe/technology' }      
    );
  }

}