import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enterprise-integrations',
  templateUrl: './enterprise-integrations.component.html',
  styleUrls: ['./enterprise-integrations.component.scss']
})
export class EnterpriseIntegrationsComponent implements OnInit {

  title = 'Pixentia Enterprise Integration Services';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Pixentia delivers state-of-the-art integration services, from a single application to integrating your entire enterprise.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Enterprise Integration Services' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Pixentia delivers state-of-the-art integration services, from a single application to integrating your entire enterprise.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/hcm/enterprise-integrations' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Enterprise Integration Services' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Pixentia delivers state-of-the-art integration services, from a single application to integrating your entire enterprise.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/hcm/enterprise-integrations' }      
    );
  }

}
