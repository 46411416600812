import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss']
})
export class SiteMapComponent implements OnInit {

  title = 'Site Map XML | Pixentia | Website Map';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Pixentia is a technology consulting & support company, helping businesses to optimize their learning and HR technology, meet goals & make a better tomorrow.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Site Map XML | Pixentia | Website Map' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Pixentia is a technology consulting & support company, helping businesses to optimize their learning and HR technology, meet goals & make a better tomorrow.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://www.pixentia.com/site-map' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Site Map XML | Pixentia | Website Map' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Pixentia is a technology consulting & support company, helping businesses to optimize their learning and HR technology, meet goals & make a better tomorrow.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://www.pixentia.com/site-map' }      
    );
  }

}
