<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Cloud Transformation</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/cloud-transformation/cornerstone">Cornerstone<sup>&reg;</sup></a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Consulting and Advisory Services for Cornerstone<sup>&reg;</sup> Human Capital Management
                </h1>
                <p>Boost your technology’s potential for workforce development
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Cornerstone image.png" alt="Cornerstone® Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-4 align-items-center info_section">
            <div class="col-md-12 sub_head">
                <h3 class="text-center mb-3 font-weight-bold cornerstone-color">
                    Human Capital Management for the Digital Age
                </h3>
                <div class="col-md-8 offset-md-2">
                    <p class="text-center">
                        Developing a deep understanding of your business and your workforce strategy is the first step toward advancing your ability to attract and develop an agile workforce. Pixentia’s implementation, support, and optimization services enable you to maximize
                        the impact of your HCM technology.
                    </p>
                </div>
                <div class="col-md-10 offset-md-1 my-4 ceridian">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <ul class="nav nav-pills flex-column" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active border-b0" id="home-tab" data-toggle="tab" href="#hr" role="tab" aria-controls="home" aria-selected="true"><img src="../../../../assets/images/cloud-transformations/cornerstone/Cornerstone Recruiting.png" alt="" class="mr-2">Recruiting</a>
                                </li>
                                <li class="nav-item border-b0">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#payroll" role="tab" aria-controls="profile" aria-selected="false"><img src="../../../../assets/images/cloud-transformations/cornerstone/Cornerstone Learning.png" alt="" class="mr-2">Learning</a>
                                </li>
                                <li class="nav-item border-b0">
                                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#benefit" role="tab" aria-controls="contact" aria-selected="false"><img src="../../../../assets/images/cloud-transformations/cornerstone/Cornerstone Performance.png" alt="" class="mr-2">Performance</a>
                                </li>
                                <li class="nav-item border-b0">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#talent" role="tab" aria-controls="profile" aria-selected="false"><img src="../../../../assets/images/cloud-transformations/cornerstone/Cornerstone HR.png" alt="" class="mr-2">HR</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#workforce" role="tab" aria-controls="contact" aria-selected="false"><img src="../../../../assets/images/cloud-transformations/cornerstone/Cornerstone Content Anytime.png" alt="" class="mr-2">Content Anytime</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-8">
                            <div class="tab-content ceridian_list_content" id="myTabContent">
                                <div class="tab-pane fade show active" id="hr" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Source and recruit the.png" class="mr-2" alt="Intuitive, consistent, and transparent navigation">
                                            </p>
                                            <p>
                                                Source and recruit the talent you need to grow your workforce.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Boost engagement.png" class="mr-2" alt="Simple, clutter-free layout">
                                            </p>
                                            <p>
                                                Boost engagement and retention with a welcoming onboarding experience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="payroll" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="">
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Grow the skills and.png" class="mr-2" alt="Payroll and time">
                                            </p>
                                            <p>
                                                Grow the skills and competencies your people need to stay competitive.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Deliver learning from.png" class="mr-2" alt="Simple, clutter-free layout">
                                            </p>
                                            <p>
                                                Deliver learning from any source to your people whenever and wherever they need it.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Help your peo.png" class="mr-2" alt="Simple, clutter-free layout">
                                            </p>
                                            <p>
                                                Help your people take control of their development.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="benefit" role="tabpanel" aria-labelledby="contact-tab">
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Build high-performing teams.png" class="mr-2" alt="Benefits specifically tailored">
                                            </p>
                                            <p>
                                                Build high-performing teams.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Align objectives throughout.png" class="mr-2" alt="Support tools">
                                            </p>
                                            <p>
                                                Align objectives throughout your body.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Support continuous.png" class="mr-2" alt="Improve process efficiency">
                                            </p>
                                            <p>
                                                Support continuous development.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Use gamification to.png" class="mr-2" alt="Improve process efficiency">
                                            </p>
                                            <p>
                                                Use gamification to boost engagement and performance.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="talent" role="tabpanel" aria-labelledby="contact-tab">
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/All your critical records.png" class="mr-2" alt="Attract and onboard top talent">
                                            </p>
                                            <p>
                                                All your critical records in one place for smarter workforce decisions.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Unified.png" class="mr-2" alt="Align and develop your employees">
                                            </p>
                                            <p>
                                                Unified, integrated HR, talent, and learning operations across your organization.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Optimize your opera.png" class="mr-2" alt="Reward and retain your talent">
                                            </p>
                                            <p>
                                                Optimize your operations to reduce administrative overhead and eliminate errors.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="workforce" role="tabpanel" aria-labelledby="contact-tab">
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Deliver top-rated.png" class="mr-2" alt="Improve operational efficiency">
                                            </p>
                                            <p>
                                                Deliver top-rated, curated content whenever and wherever your people need it.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Enable your people to continuously.png" class="mr-2" alt="Decrease labor costs">
                                            </p>
                                            <p>
                                                Enable your people to continuously develop their professional skills, leadership ability, and digital fluency.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/Optimize your compliance.png" class="mr-2" alt="Reduce compliance issues">
                                            </p>
                                            <p>
                                                Optimize your compliance programs.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="text-center mb-3 font-weight-bold cornerstone-color">
                    Why Is Pixentia Your Ideal Technology Partner?
                </h3>
                <p class="font-weight-bold text-center">It’s our focus on your objectives</p>
                <div class="col-md-8 offset-md-2">
                    <p class="text-center">
                        We learn your business and your people strategy before we make recommendations on how your technology can meet your organization’s workforce needs. Leverage our expert knowledge of configuration and tools to develop intuitive workflows that cut costs,
                        increase proficiency, and improve performance.
                    </p>
                    <p class="text-center">
                        Let us help you exploit your technology to maximize its impact on workforce growth and performance.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <h3 class="text-center mb-3 font-weight-bold cornerstone-color">
                    Cornerstone<sup>&reg;</sup> HCM Center of Excellence
                </h3>
            </div>
        </div>
    </div>
    <div class="community_section mb-3">
        <div class="services_section">
            <div class="template_width">
                <div class="row mb-5">
                    <div class="col-md-12">
                        <ul class="nav nav-pills nav-justified">
                            <li class="nav-item text-center">
                                <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                    <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                    <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                    <img src="../../assets/images/hcm/talent/Resource Centers.png" class="d-block mx-auto" alt=""> Learning Content
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#resource">
                                    <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Optimization
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <!-- Tab panes -->
            <div class="tab-content mt-5">
                <div class="tab-pane active" id="strategy">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4 cornerstone-color">
                                    Pixentia Strategy and Planning Services
                                </h4>
                            </div>
                            <p>
                                Whether your organization is evaluating Cornerstone or already using it, Pixentia starts its engagements by developing a deep understanding of your business and workforce goals so we can make informed recommendations.
                            </p>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Technology Assessment Services
                                        </h4>
                                        <p>
                                            A structured workshop to map your current situation and your vision so you can create a solid foundation for your strategy.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Your current technology.png" class="mr-2" alt="Your current technology, plans">
                                        </p>
                                        <p>
                                            Your current technology, plans, and where your project fits in your workforce strategy.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Maximizing the.png" class="mr-2" alt="Maximizing the impact of your investment">
                                        </p>
                                        <p>
                                            Maximizing the impact of your investment.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/An integrated.png" class="mr-2" alt="An integrated approach to human capital management">
                                        </p>
                                        <p>
                                            An integrated approach to <a href="services/hcm/hcm-coe">human capital management</a>.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Technology Assessment Services.png" alt="Technology Assessment Services" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Platform Recommendations.png" alt="Platform Recommendations" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Platform Recommendations</h4>
                                        <p>
                                            Tools and support for evaluating potential solutions that help you execute your HCM strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Business Case Development
                                        </h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Tools and support.png" class="mr-2" alt="Working with operational managers">
                                            </p>
                                            <p>
                                                Tools and support for building your business case
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Work to get realistic.png" class="mr-2" alt="Methods and tools for calculating ROI">
                                            </p>
                                            <p>
                                                Work to get realistic estimates of your project’s impact.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Methods and tools.png" class="mr-2" alt="Guidance and tools for analyzing risks">
                                            </p>
                                            <p>
                                                Methods and tools to calculate ROI and breakeven analysis.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Guidance and tools.png" class="mr-2" alt="Guidance and tools for analyzing risks">
                                            </p>
                                            <p>
                                                Guidance and tools to analyze risks and develop mitigation plans.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Business Case Development.png" alt="Business Case Development" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Learning Technology Roadmap.png" class="img-fluid" alt="Learning Technology Roadmap">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Learning Technology Roadmap</h4>
                                        <p>
                                            Develop a long-term technology plan that grows with you as your needs change.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Potential new initiatives.png" class="mr-2" alt="Potential new initiatives based on technology">
                                            </p>
                                            <p>
                                                Potential new initiatives based on technology and market trends.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Estimated costs.png" class="mr-2" alt="Estimated costs">
                                            </p>
                                            <p>
                                                Estimated costs based on current information.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Evaluation of.png" class="mr-2" alt="Evaluation of technology life cycles">
                                            </p>
                                            <p>
                                                Evaluation of technology life cycles.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Project Governance</h4>
                                        <p>
                                            A framework for communication, decision-making, and risk management.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Establishing stakeholder.png" class="mr-2" alt="Establishing stakeholder relationships">
                                            </p>
                                            <p>
                                                Establishing stakeholder relationships.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Establishing responsibilities.png" class="mr-2" alt="Establishing responsibilities and accountability abilities">
                                            </p>
                                            <p>
                                                Establishing responsibilities and accountability abilities for each phase.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Creating a framework.png" class="mr-2" alt="Creating a framework for project communication">
                                            </p>
                                            <p>
                                                Creating a framework for project communication.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Project Governance.png" alt="Project Governance" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Change Management.png" alt="Change Management" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Change Management</h4>
                                        <p>
                                            Increase employee adoption of Cornerstone applications and provide leadership with timely data on utilization and proficiency.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Recommendations for two.png" class="mr-2" alt="Recommendations for two-way communication">
                                            </p>
                                            <p>
                                                Recommendations for two-way communication and project feedback.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Program marketing.png" class="mr-2" alt="Program marketing techniques">
                                            </p>
                                            <p>
                                                Program marketing techniques using gamification techniques.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Resource guides for.png" class="mr-2" alt="Resource guides for learners">
                                            </p>
                                            <p>
                                                Resource guides for learners, administrators, and managers.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/change-management">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">People Analytics Strategy
                                        </h4>
                                        <p>
                                            Assess and report on progress toward your goals and measure the impact on performance.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Develop metrics.png" class="mr-2" alt="Develop metrics and measures">
                                            </p>
                                            <p>
                                                Develop metrics and measures to report on project progress.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Map metrics to.png" class="mr-2" alt="Map metrics to key performance indicators">
                                            </p>
                                            <p>
                                                Map metrics to key performance indicators.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/Evaluate the impact.png" class="mr-2" alt="Evaluate the impact on performance">
                                            </p>
                                            <p>
                                                Evaluate the impact on performance and productivity.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/strategy/People Analytics Strategy.png" alt="People Analytics Strategy" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="deployment">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4 cornerstone-color">
                                    A Structured, Scalable Approach to Technology Deployment
                                </h4>
                            </div>
                            <p class="mx-md-5">
                                Preparation, accountability, communication, and control are the foundations of a successful deployment. Our teams provide guidance, expertise, and methods to keep your project on schedule. Our workshops prepare you to become expert managers of your technology
                                platform so you can achieve your objectives.
                            </p>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold mb-3">Project Planning</h4>
                                        <p>
                                            Tools and expertise for managing your timeline and resources, plus in-depth risk assessment and mitigation planning.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/A phased project plan.png" class="mr-2" alt="Phased project plan helps you manage timelines">
                                            </p>
                                            <p>
                                                A phased project plan to establish timelines and assign accountability.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Resource planning.png" class="mr-2" alt="Resource planning helps you control your budget">
                                            </p>
                                            <p>
                                                Resource planning to identify the people, skills, and assets.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Project governance.png" class="mr-2" alt="Project governance establishes communication">
                                            </p>
                                            <p>
                                                Project governance to establish communication channels with stakeholders.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Project Planning.png" alt="Project Planning" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Data Cleansing.png" alt="Data Cleansing" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white mb-3">Data Cleansing</h4>
                                        <p>
                                            Ensuring consistent and reliable legacy data before you migrate to a new  platform.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Guiding you.png" class="mr-2" alt="Guiding you through evaluating the condition of your data">
                                            </p>
                                            <p>
                                                Guiding you through evaluating the condition of your data.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Assisting you in.png" class="mr-2" alt="Assisting you in the decision on whether and what to migrate">
                                            </p>
                                            <p>
                                                Assisting you in the decision on whether and what to migrate.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Providing data.png" class="mr-2" alt="Providing data cleansing tools and expertise to get your data in shape">
                                            </p>
                                            <p>
                                                Providing data cleansing tools and expertise to get your data in shape.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold mb-3">Data Migration</h4>
                                        <p>
                                            Preparing and testing your legacy data. Our support includes:
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Mapping and.png" class="mr-2" alt="Mapping and importing data to the new platform">
                                            </p>
                                            <p>
                                                Mapping and importing data to the new platform.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Eliminating outdated.png" class="mr-2" alt="Eliminating outdated field names and data structures">
                                            </p>
                                            <p>
                                                Eliminating outdated field names and data structures.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Testing the quality.png" class="mr-2" alt="Testing the quality and usability of the migrated data">
                                            </p>
                                            <p>
                                                Testing the quality and usability of the migrated data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Data Migration.png" alt="Data Migration" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Workshops.png" alt="Workshops" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head text-white">
                                        <h4 class="font-weight-bold text-white mb-3">Workshops</h4>
                                        <p>
                                            Structured to give platform owners the skills to master Cornerstone® capabilities.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Our Business Workshop.png" class="mr-2" alt="Our Business Workshop maps your existing processes">
                                            </p>
                                            <p>
                                                Our Business Workshop maps your existing processes, examines pain points and inefficiencies, and develops improved workflows.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/The Training Workshop.png" class="mr-2" alt="Training Workshop prepares you to manage processes and workflow">
                                            </p>
                                            <p>
                                                The Training Workshop prepares you to manage your processes and workflows in Cornerstone HCM.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Our Configuration.png" class="mr-2" alt="Configuration workshop aligns technology to your business requirements">
                                            </p>
                                            <p>
                                                Our Configuration Workshop shows you how to align your new technology to your business.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/business-workshop">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row align-items-center my-5">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Configuration and review</h4>
                                        <p>
                                            We involve you in the design so you can evaluate and approve each configuration phase.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Iterative process.png" class="mr-2" alt="Iterative process to review progress of each module and function">
                                            </p>
                                            <p>
                                                Iterative process to review progress of each module and function.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/You conduct a final.png" class="mr-2" alt="You conduct a final review to ensure that the configuration meets your requirements">
                                            </p>
                                            <p>
                                                You conduct a final review to ensure the configuration meets your requirements.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/You sign off on the.png" class="mr-2" alt="You sign off on the configuration and you are ready to begin testing">
                                            </p>
                                            <p>
                                                You sign off on the configuration and you are ready to begin testing.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/configuration-workshop">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center text-md-right mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Configuration and review.png" alt="Configuration and review" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-md-left text-center mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Integration.png" alt="Integration" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6 text-white">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Integration</h4>
                                        <p>
                                            Cornerstone Edge provides easy integration with core systems and many other applications with prebuilt integrations, intuitive configuration tools, and flexible APIs. Pixentia provides additional support for integrations.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Custom APIs and.png" class="mr-2" alt="Identify the single source of truth for each dataset">
                                            </p>
                                            <p>
                                                Custom APIs and Web Services integrations and support.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Support optional integrations.png" class="mr-2" alt="Connect your new platform to core systems to maintain accurate user records">
                                            </p>
                                            <p>
                                                Support optional integrations with your applications and third-party providers.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Data feed monitoring.png" class="mr-2" alt="Perform optional integrations with any of your business applications and your third-party providers">
                                            </p>
                                            <p>
                                                Data feed monitoring and support services as a part of our Managed Services.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/integration">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row align-items-center my-5">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Analytics</h4>
                                        <p>
                                            Cornerstone provides reporting capabilities for HCM customers in Cornerstone Reporting. You may choose to deploy Reporting 2.0, with its simple and easy tools for building reports, as an “early adopter.” You may also opt for
                                            <a
                                                href="products/chasma-analytics/cornerstone-analytics">Cornerstone Analytics</a> so you can leverage predictive analytics in your people data. Our services include.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Enabling and supporting.png" class="mr-2" alt="Enabling and supporting your reporting">
                                            </p>
                                            <p>
                                                Enable you to gain business intelligence from your Cornerstone platform.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Supporting your.png" class="mr-2" alt="Supporting your report developers">
                                            </p>
                                            <p>
                                                Support your developers and administrators in report creation and distribution.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Support your.png" class="mr-2" alt="Support your analytics team">
                                            </p>
                                            <p>
                                                Advanced analytics to accelerate your ability to make better and faster decisions.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/analytics-deployment">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center text-md-right mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Analytics.png" alt="Analytics" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Testing.png" alt="Testing" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6 text-white">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Testing</h4>
                                        <p>
                                            Support for User Acceptance Testing with triage and issue management. We also offer automated testing to reduce manual errors and manual testing costs.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Script building.png" class="mr-2" alt="Script building and tracking for your User Acceptance Testing">
                                            </p>
                                            <p>
                                                Script building and tracking for your User Acceptance Testing.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Best practices.png" class="mr-2" alt="Best practices for managing testing issue triage">
                                            </p>
                                            <p>
                                                Best practices for managing testing issue triage.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/preparation/Focusing on evaluating.png" class="mr-2" alt="Focusing on evaluating the user experience">
                                            </p>
                                            <p>
                                                Focusing on evaluating the user experience.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/testing">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="optimization">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4 cornerstone-color">
                                    When off-the-shelf learning simply won’t do
                                </h4>
                            </div>
                            <p>
                                Learning content is easy to find. Sourcing content that fits your objectives, your audience, and your culture is a lot harder. We fill the gap with learning specific to your organizational standards, your business objectives, and the needs of your audience.
                            </p>
                            <p>
                                Our primary goal is to ensure we bring success to your learning initiatives so you can realize the full value of your learning investment. Our services include:
                            </p>
                        </div>
                    </div>
                    <div class="template_width">
                        <div class="col-md-12 mt-4">
                            <div class="row">
                                <div class="col-md-4 text-center border-right border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Content strategy.png" alt="Content strategy and planning">
                                        </div>
                                        <div>
                                            <p>
                                                Content strategy and planning to align content blending and delivery to your objectives.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-right border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Design services.png" alt="Design services">
                                        </div>
                                        <div>
                                            <p>
                                                Design services that make technology transparent so your people can focus on learning.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/E-Learning developmen.png" alt="E-Learning development">
                                        </div>
                                        <div>
                                            <p>
                                                E-Learning development using tools most compatible with your organization <span class="d-inline-block">and learning needs.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                                <div class="col-md-4 text-center border-right">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Deployment and.png" alt="Deployment and testing">
                                        </div>
                                        <div>
                                            <p>
                                                Deployment and testing using strategies that ensure the design meets your specifications.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Content integration.png" alt="Content integration supporting">
                                        </div>
                                        <div>
                                            <p>
                                                Content integration supporting third-party and blended content sourcing, so you can leverage curated and created content to meet your needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Strategy & Planning</h4>
                                        <p>
                                            At Pixentia, we begin our learning content partnership by getting to know you. We want to know about your business, your values, and how you work, so we can form a solid partnership.
                                        </p>
                                        <p>
                                            Strategy: Our strategy and planning work sessions define your objectives and how we will work together.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Strategy  Planning.png" class="img-fluid" alt="Strategy & Planning">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Delivery Modalities.png" class="img-fluid" alt="Delivery Modalities">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Delivery Modalities</h4>
                                        <p>
                                            We select the blend of learning modalities that will enable you to <span class="d-inline-block">meet your objectives.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Gamification</h4>
                                        <p>
                                            Game mechanics, aesthetics, and gamification thinking to engage and motivate your learners.
                                        </p>
                                        <p class="orange_color">
                                            Learn more about Gamification
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/gamification">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Gamification.png" class="img-fluid" alt="Gamification">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Augmented Reality.png" class="img-fluid" alt="Augmented Reality">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Augmented Reality</h4>
                                        <p>
                                            Augmented Reality learning provides real-time information so your people can learn faster and develop confidence in their ability to perform.
                                        </p>
                                        <p class="text-white">
                                            Learn more about Augmented Reality
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/augmented-reality">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Virtual Reality</h4>
                                        <p>
                                            Creates a lifelike, computer-generated work context.
                                        </p>
                                        <p class="orange_color">
                                            Learn more about Virtual Reality
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/virtual-reality">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Virtual Reality.png" class="img-fluid" alt="Virtual Reality">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Simulation.png" class="img-fluid" alt="Simulation">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Simulation</h4>
                                        <p>
                                            Created to fit the job context to help your people learn by doing.
                                        </p>
                                        <p class="text-white">
                                            Learn more about Simulations.
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/simulation">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Sourcing</h4>
                                        <p>
                                            Sourcing available content can reduce your costs and speed up your implementation.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Sourcing.png" class="img-fluid" alt="Sourcing">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/UI and Design.png" class="img-fluid" alt="UI and Design">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">User Experience and Design</h4>
                                        <p>
                                            Our experts use LX and UI design principles together to create a learner-centric experience.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Consider every design.png" class="mr-2" alt="Consider every design">
                                            </p>
                                            <p>
                                                Consider every design decision from the learner’s perspective
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Create an easy-to-use.png" class="mr-2" alt="Create an easy-to-use interface">
                                            </p>
                                            <p>
                                                Create an easy-to-use interface that makes the delivery technology transparent.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Design for the emotional.png" class="mr-2" alt="Design for the emotional effect of achievement">
                                            </p>
                                            <p>
                                                Design for the emotional effect of achievement.
                                            </p>
                                        </div>
                                        <p class="">
                                            Learn more about our UI and Design services
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/user-experience-and-design">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Learning Development</h4>
                                        <p>
                                            We use the design tools that best support the learning context and your team’s preferences, including
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/adobe-captivate.png" class="mr-2" alt="Adobe Captivate">
                                            </p>
                                            <p>
                                                Adobe Captivate
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/adaptive-html-5.png" class="mr-2" alt="Adapt HTML5">
                                            </p>
                                            <p>
                                                Adapt HTML5.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/articulate.png" class="mr-2" alt="Articulate Storyline">
                                            </p>
                                            <p>
                                                Articulate Storyline.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/tools.png" class="mr-2" alt="Tools embedded in your LMS">
                                            </p>
                                            <p>
                                                Tools embedded in your <a href="services/learning/learningmanagementsystems">LMS</a>.
                                            </p>
                                        </div>
                                        <p class="orange_color">
                                            Learn more about our learning development services.
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/development">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Learning Development.png" class="img-fluid" alt="Learning Development">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Deployment and Testing.png" class="img-fluid" alt="Deployment and Testing">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Deployment and Testing</h4>
                                        <p>
                                            To assist you in testing your learning design, we provide
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/tools-for.png" class="mr-2" alt="Tools for capturing user feedback">
                                            </p>
                                            <p>
                                                Tools for capturing user feedback.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/issue-management.png" class="mr-2" alt="Issue management and triage">
                                            </p>
                                            <p>
                                                Issue management and triage.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/issue-remidiation.png" class="mr-2" alt="Issue remediation">
                                            </p>
                                            <p>
                                                Issue remediation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Content Integration</h4>
                                        <p>
                                            We can integrate content from any source, including third-party providers or your internal resource repositories.
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/SCORM or xAPI content.png" class="mr-2" alt="SCORM or xAPI content">
                                        </p>
                                        <p>
                                            SCORM or xAPI content.
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Curated video and audio.png" class="mr-2" alt="Curated video and audio">
                                        </p>
                                        <p>
                                            Curated video and audio.
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/External e-learning courses.png" class="mr-2" alt="External e-learning courses">
                                        </p>
                                        <p>
                                            External e-learning courses.
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Single Sign-on implementation.png" class="mr-2" alt="Single Sign-on implementation">
                                        </p>
                                        <p>
                                            Single Sign-on implementation.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/learning-content/Content Integration-1.png" class="img-fluid" alt="Content Integration">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="resource">
                    <div class="row mx-0">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4 cornerstone-color">
                                        Banish Bottlenecks, Workarounds, And Inefficient Processes
                                    </h4>
                                </div>
                                <p>
                                    Your platform is not fully implemented until you adopt the tools and practices you need to optimize your service delivery. As your needs change, we help your technology grow with you.
                                </p>
                            </div>
                            <div class="text-center">
                                <p class="font-weight-bold mb-0">
                                    Pixentia provides ongoing services to support your organizational growth and development:
                                </p>
                                <p>
                                    Expanding your use and mastery of the Cornerstone platform to enhance its function and value. Application and content development to grow your ability to deliver efficiency and excellence. Supporting your digital transformation to a data-driven organization.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Administration Optimization</h4>
                                        <p>
                                            Optimize your administrative workflow to remove bottlenecks and pain points.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Map administrative.png" class="mr-2" alt="Map administrative workflow to develop a visual representation of task alignment">
                                            </p>
                                            <p>
                                                Map workflow to develop a visual representation of task alignment.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Identify pain points.png" class="mr-2" alt="Identify pain points and bottlenecks">
                                            </p>
                                            <p>
                                                Identify pain points and bottlenecks.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Automate offline.png" class="mr-2" alt="Automate offline processes and workarounds">
                                            </p>
                                            <p>
                                                Automate offline processes and workarounds.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Administration Optimization.png" class="img-fluid" alt="Administration Optimization">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Security Optimization.png" class="img-fluid" alt="Security Optimization">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Security Optimization</h4>
                                        <p>
                                            Align your security configuration to achieve the best fit for the needs of your organization.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Apply governance.png" class="mr-2" alt="Apply governance principles to security role assignments">
                                            </p>
                                            <p>
                                                Apply governance principles to security role assignments.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Remove duplication.png" class="mr-2" alt="Remove duplication in security roles assignments">
                                            </p>
                                            <p>
                                                Remove duplication in security roles assignments.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Review constraints.png" class="mr-2" alt="Review constraints to ensure data security">
                                            </p>
                                            <p>
                                                Review constraints to ensure data security.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Business Process Optimization</h4>
                                        <p>
                                            Analysis of your business processes and workflow to remove chokepoints and workarounds.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Analyze and map.png" class="mr-2" alt="Analyze and map business processes to identify workarounds and offline sub-processes">
                                            </p>
                                            <p>
                                                Analyze and map business processes to identify workarounds and offline sub-processes.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Review decision.png" class="mr-2" alt="Review decision points and options to align them with standard configurations">
                                            </p>
                                            <p>
                                                Review decision points and options to align them with standard configurations.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Automate offline-1.png" class="mr-2" alt="Automate offline processes that create bottlenecks">
                                            </p>
                                            <p>
                                                Automate offline processes that create bottlenecks.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Business Process Optimization.png" class="img-fluid" alt="Business Process Optimization">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/User Experience Optimization.png" class="img-fluid" alt="User Experience Optimization">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">User Experience Optimization</h4>
                                        <p>
                                            Analyze user behavior and feedback to create an engaging, useful experience.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Analyze workflows.png" class="mr-2" alt="Analyze workflows from the user perspective">
                                            </p>
                                            <p>
                                                Analyze workflows from the user perspective.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Gather user feedback.png" class="mr-2" alt="Gather user feedback and analyze behavior tracking to identify user pain points">
                                            </p>
                                            <p>
                                                Gather user feedback and analyze behavior tracking to identify user pain points.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Include end users.png" class="mr-2" alt="Include end users in experience analysis and recommendations">
                                            </p>
                                            <p>
                                                Include end users in experience analysis and recommendations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Analytics Optimization </h4>
                                        <p>
                                            Develop insights about people and work to help you move toward becoming a data-driven learning organization.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Statistical analysis so.png" class="mr-2" alt="Apply statistical analysis in Dayforce® Analytics so you can identify trends and anomalies in your workforce">
                                            </p>
                                            <p>
                                                Statistical analysis so you can identify trends and anomalies in your workforce.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Determine what.png" class="mr-2" alt="Determine what statistical trends and anomalies require further study">
                                            </p>
                                            <p>
                                                Determine what statistical trends and anomalies require further study.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Analytical models.png" class="mr-2" alt="Develop analytical models to determine root causes so you can understand the why of your data">
                                            </p>
                                            <p>
                                                Analytical models to determine root causes so you can understand the why of your data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Analytics Optimization.png" class="img-fluid" alt="Analytics Optimization">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Content Optimization.png" class="img-fluid" alt="Content Optimization">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Content Optimization</h4>
                                        <p>
                                            Identify the learning methodologies, delivery modes, and technology that achieve your learning objectives — within your business and budget constraints.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Understand your.png" class="mr-2" alt="Understand your learning needs and business priorities">
                                            </p>
                                            <p>
                                                Understand your learning needs and business priorities.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Analyze current.png" class="mr-2" alt="Analyze current methodologies, delivery modes, and technology to identify inefficient delivery">
                                            </p>
                                            <p>
                                                Analyze current methodologies, delivery modes, and technology.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Analyze recommendations.png" class="mr-2" alt="Analyze recommendations to help you develop a long-term workforce development strategy">
                                            </p>
                                            <p>
                                                Analyze recommendations to develop a long-term development strategy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="template_width">
                        <div class="row">
                            <div class="col-md-12 text-center my-5">
                                <h4 class="font-weight-bold mb-4">
                                    Pixentia Cornerstone® Support Centers
                                </h4>
                                <p>
                                    Keeping your Cornerstone platform updated and current with your organization’s needs requires ongoing, hands-on support. Let Pixentia’s specialists support your administrators, service as your support backup, or provide comprehensive managed services.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Admin Resource Center</h4>
                                        <p>
                                            Assisting administrators in day-to-day operations and maintenance.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Support organization.png" class="mr-2" alt="Support organization changes, group and role assignments, and security">
                                            </p>
                                            <p>
                                                Support organization changes, group and role assignments, and security.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Assist with user.png" class="mr-2" alt="Assist with user management to keep your data in shape and better manage your licensing costs">
                                            </p>
                                            <p>
                                                Assist with user management to keep your data in shape and better manage your licensing costs.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Assist administrators.png" class="mr-2" alt="Assist administrators in service delivery and user support">
                                            </p>
                                            <p>
                                                Assist administrators in service delivery and user support.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Admin Resource Center.png" class="img-fluid" alt="Admin Resource Center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Analytics Resource Center.png" class="img-fluid" alt="Analytics Resource Center">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Analytics Resource Center</h4>
                                        <p>
                                            We provide support for reporting, dashboards and visualizations, advanced analytics, and data aggregation and storage.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Scheduling and distributing.png" class="mr-2" alt="Scheduling and distributing reports so your users have the information they need">
                                            </p>
                                            <p>
                                                Scheduling and distributing reports so your users have the information they need.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Creating and customizing reports.png" class="mr-2" alt="Creating and customizing reports, including custom and calculated fields">
                                            </p>
                                            <p>
                                                Creating and customizing reports, including custom and calculated fields.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Referral to our Analytics.png" class="mr-2" alt="Referral to our Analytics">
                                            </p>
                                            <p>
                                                Referral to our Analytics team when you need support for advanced analytics, predictive models, and prescriptive analytics to tackle your most pressing challenges.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Technology Resource Center</h4>
                                        <p>
                                            We offer support for technical tasks beyond day-to-day administration.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Evaluation of.png" class="mr-2" alt="Evaluation of Dayforce® updates to assess potential benefits to your business processes">
                                            </p>
                                            <p>
                                                Evaluation of Cornerstone updates to assess potential benefits to your business processes.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Validating updates.png" class="mr-2" alt="Validating updates and upgrades to catch regression errors before they happen">
                                            </p>
                                            <p>
                                                Validating updates and upgrades to catch regression errors before they happen.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Integration support.png" class="mr-2" alt="Integration support, including third-party connections, and nightly data feed issues">
                                            </p>
                                            <p>
                                                Integration support, including third-party connections, and nightly data feed issues.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Technology Resource Center.png" class="img-fluid" alt="Technology Resource Center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Content Resource Center.png" class="img-fluid" alt="Content Resource Center">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Content Resource Center</h4>
                                        <p>
                                            We support your administrators and learning professionals with technical support in program management and deploying your learning programs.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/New program.png" class="mr-2" alt="New program launch and administration">
                                            </p>
                                            <p>
                                                New program launch and administration.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Sourcing and.png" class="mr-2" alt="Sourcing and third-party integrations">
                                            </p>
                                            <p>
                                                Sourcing and third-party integrations.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Back-end data.png" class="mr-2" alt="Back-end data administration and reporting">
                                            </p>
                                            <p>
                                                Back-end data administration and reporting.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Managed Services Center </h4>
                                        <p>
                                            Dedicated experts to handle all or any part of your HCM management, operations, and administration.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/You choose what.png" class="mr-2" alt="You choose what services you need and your level of support">
                                            </p>
                                            <p>
                                                You choose what services you need and your level of support.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Includes monitoring.png" class="mr-2" alt="Includes monitoring and technical support for integrations and data feeds">
                                            </p>
                                            <p>
                                                Includes monitoring and technical support for integrations and data feeds.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Services include.png" class="mr-2" alt="Services include periodic review of services to fine-tune them to your growing needs">
                                            </p>
                                            <p>
                                                Services include periodic review of services to fine-tune them to your growing needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Managed Services Center.png" class="img-fluid" alt="Managed Services Center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Staffing and Augmentation.png" class="img-fluid" alt="Staffing and Augmentation ">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Staffing and Augmentation </h4>
                                        <p>
                                            Comprehensive staffing services for the specialized roles in your HCM administration and maintenance.  Our services include:
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Staff augmentation.png" class="mr-2" alt="Staff augmentation when you need specialized skills or to feel a temporary critical gap on your team">
                                            </p>
                                            <p>
                                                Staff augmentation for specialized skills or to fill a temporary critical gap.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Direct hire when yo.png" class="mr-2" alt="Direct hire when you need a full-time replacement">
                                            </p>
                                            <p>
                                                Direct hire when you need a full-time replacement.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/cornerstone/optimization/Contract-to-hire.png" class="mr-2" alt="Contract-to-hire, which can be the perfect solution in times of uncertainty">
                                            </p>
                                            <p>
                                                Contract-to-hire, which can be the perfect solution in times of uncertainty.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-5">
            <div class="template_width">
                <div class="row align-items-center">
                    <div class="col-md-10 offset-md-1">
                        <div class="row align-items-center">
                            <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                                <p class="sub_heading_cta mb-4">Let's talk about how Pixentia can help you better understand your users.
                                </p>
                                <div class="more_info_btn datasheet_btn">
                                    <a href="contact-us" rel="noopener noreferrer" target="_blank"> Contact Us <i class="fa fa-angle-right ml-2"
                                            aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <div class="col-md-5 mt-5 mt-md-0">
                                <div>
                                    <img src="../../../../assets/images/cta_images/CTA Contact.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-vendors></app-vendors>
    </div>
    <div class="template_width">
        <app-get-started></app-get-started>
    </div>
</div>