<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-analytics">Chasma Analytics</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-analytics/people-analytics-power-bi">Chasma Analytics For Power BI</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Analytics For Power BI</h3>
                <p>Dashboards and visualizations ready to deploy now</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/powerbi/powerbi-image.png" alt="Chasma apps image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">Your business runs on information … Run Faster</h1>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <p class="mb-3">
                            Chasma Analytics for SumTotal provides dashboards and visualizations for hundreds of use cases from in-depth research into over 1000 global organizations.
                        </p>
                        <p class="mb-3">
                            We provide many ways to deliver KPIs and vital metrics deployed directly into Microsoft Power BI.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="mt-5 mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">Chasma Analytics for Power BI Advantage
                </h4>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <p>
                            Enjoy the benefits of rapid deployment and low-cost, pre-configured visualizations and dashboards.
                        </p>
                        <p>
                            We are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential, so you can reduce your administrative overhead and focus on strategy.
                        </p>
                        <p>
                            Your technology should not call the tune for you to dance; it should dance to the music in <span class="d-inline-block">your
                            critical processes.</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/powerbi/Pre-configured.png" alt="Pre-configured dashboards" class="mb-3">
                    <p class="px-4">
                        Pre-configured dashboards you can deploy immediately into <span class="d-inline-block">Microsoft
                            Power BI.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/powerbi/Data Visulizations.png" alt="Data visualizations" class="mb-3">
                    <p class="px-4">
                        Data visualizations that help you make decisions at the speed of business.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/powerbi/Advacned analytics.png" alt="Advanced analytics" class="mb-3">
                    <p class="px-4">
                        Advanced analytics to give you deeper insights into your workforce data.
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/powerbi/Compare critical.png" alt="critical operational metrics" class="mb-3">
                    <p class="px-4">
                        Compare critical operational metrics like employee cost and performance, learning transfer and
                        <span class="d-inline-block">roductivity, and more.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/powerbi/Predective models.png" alt="Predictive models" class="mb-3">
                    <p class="px-4">
                        Predictive models that drive decisions to capitalize on your people potential.
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Analytics for Power BI?</h4>
                    <p class="">
                        Pre-configured dashboards ready to deploy right now in Power BI, at a fraction of custom development cost. Watch the video to see how you can help your executives make the decisions at the speed of business with Chasma Analytics for Power BI.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/466502294" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/images/products/event/Need more.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-white pl-md-5 mt-5 mt-md-0">
                        <h2 class="font-weight-bold text-uppercase">
                            Achieve reporting excellence in days, not weeks.
                        </h2>
                        <p class="mb-4">Deploy Chasma dashboards and reports into your Microsoft Power BI platform and transform your raw data into insightful reports. </p>
                        <div class="more_info_btn">
                            <a href="http://resources.chasma.io/chasma-analytics-for-power-bi" rel="noopener noreferrer" target="_blank">Get the
                                details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>