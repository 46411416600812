<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe">HCM COE</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe/managed-services">Managed Services Center</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Offload your administration so you can focus on your people
                </h3>
                <p>Your Core HR, talent, and learning applications are complex tools. Managing them well takes knowledge, time, and effort. If your administration is an unwelcome burden, you can let Pixentia carry the entire load or any part of it.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/hcm-coe/managed-services/Managed Services Center.png" alt="Managed Services Center image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Why would you need managed services?
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-6 border-bottom border-right py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/You have only one administrator.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        You have only one administrator and no ready backup.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-bottom py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Your administrators can.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Your administrators can’t seem to find the time for advanced training.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-right py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Your administrators have.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Your administrators have other mission-critical roles that may impact their capacity to manage learning and some tasks are too much of a burden.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Youve lost your initial.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        You’ve lost your initial edge. Over time, your administrators have trained other administrators, who taught others, and now, some critical tasks go undone.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Administration</h4>
                <p>
                    With Pixentia managed services, you can enjoy the peace of mind of knowing seasoned experts are handling your daily administration.
                </p>
                <div class="row my-5">
                    <div class="col-md-6 border-bottom border-right py-5">
                        <div class="text-center">
                            <div class="mb-3">
                                <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Maintaining organizations.png" alt="Maintaining organizations">
                            </div>
                            <div class="px-5">
                                <p class="font-weight mb-2">
                                    Maintaining organizations, domains, groups, audiences, user access, roles, and security.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 border-bottom py-5">
                        <div class="text-center">
                            <div class="mb-3">
                                <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Managing catalogs.png" alt="Managing catalogs and learning resources">
                            </div>
                            <div class="px-5">
                                <p class="font-weight mb-2">
                                    Managing catalogs and learning resources.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 border-right py-5">
                        <div class="text-center">
                            <div class="mb-3">
                                <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Program and course.png" alt="Program and course management including course set up and testing">
                            </div>
                            <div class="px-5">
                                <p class="font-weight mb-2">
                                    Program and course management including course set up and testing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 py-5">
                        <div class="text-center">
                            <div class="mb-3">
                                <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Troubleshooting and.png" alt="Troubleshooting and working with SumTotal support">
                            </div>
                            <div class="px-5">
                                <p class="font-weight mb-2">
                                    Troubleshooting and working with SumTotal support.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-4">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-12 text-center sub_head">
                <h4 class="font-weight-bold pt-4 mb-3">Technology</h4>
                <p>
                    Some technical issues can be difficult and time-consuming, especially if they happen so infrequently that you don’t develop the experience to manage them. Let Pixentia’s experts handling your critical technical tasks.
                </p>
            </div>
            <div class="col-md-6 border-bottom border-right py-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Technical issues and suppor.png" alt="Technical issues and support for end-users">
                    </div>
                    <div class="px-5">
                        <p class="font-weight mb-2">
                            Technical issues and support for end-users.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 border-bottom py-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Managing updates and update.png" alt="Managing updates and update validation">
                    </div>
                    <div class="px-5">
                        <p class="font-weight mb-2">
                            Managing updates and update validation.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 border-right py-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Liaison with your vendor.png" alt="Liaison with your vendor support team">
                    </div>
                    <div class="px-5">
                        <p class="font-weight mb-2">
                            Liaison with your vendor support team.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 py-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Integrations and data.png" alt="Integrations and data feeds, including monitoring and resolution of data issues">
                    </div>
                    <div class="px-5">
                        <p class="font-weight mb-2">
                            Integrations and data feeds, including monitoring and resolution of data issues.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white align-items-center">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 mb-3">Learning Content</h4>
                <p>
                    If you don’t have the expert resources to manage content, you can offload that burden to Pixentia. Our experts can curate content from any source and develop custom content for any purpose. We can handle your content administration, including:
                </p>
            </div>
            <div class="col-md-4">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Program and course-1.png" alt="Program and course management including course set up and testing">
                    </div>
                    <div class="px-5">
                        <p>
                            Program and course management including course set up and testing.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Content sourcing and.png" alt="Content sourcing and curation">
                    </div>
                    <div class="px-5">
                        <p>
                            Content sourcing and curation.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Integrations with 3rd.png" alt="Integrations with 3rd party learning providers">
                    </div>
                    <div class="px-5">
                        <p>
                            Integrations with 3rd party learning providers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-12 text-center">
            <div class="sub_head pb-4">
                <h4 class="font-weight-bold">Reporting and analytics</h4>
                <p>
                    People analytics has become an essential competency for HR, but some organizations have not yet developed it. Let Pixentia fill the gap while you build your team or handle all your reporting administration.
                </p>
            </div>
        </div>
        <div class="col-md-6 border-bottom border-right py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Generating.png" alt="Generating, scheduling, and distributing reports">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Generating, scheduling, and distributing reports.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-bottom py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Maintaining dashboards-1.png" alt="Maintaining dashboards, troubleshooting reports and data tables">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Maintaining dashboards, troubleshooting reports and data tables.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-right py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Maintaining dashboards-1.png" alt="Maintaining dashboards and visualizations">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Maintaining dashboards and visualizations.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/managed-services/Referral to Pixentia.png" alt="Referral to Pixentia’s advanced analytics team">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Referral to Pixentia’s advanced analytics team.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">
                            Learn more about how you can offload your HR technology administration with our Managed Services
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="https://resources.pixentia.com/data-sheets/cornerstone-managed-services" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/hcm-coe"> Back to
                                    Resource Centers <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>