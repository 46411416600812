import { environment } from 'src/environments/environment';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input('searchResults') searchResults: any;
  @Input('searchTerm') searchTerm: any;
  resultsLength: number;
  url: string;
  results: [];
  constructor() { }

  ngOnInit() {
    this.results = this.searchResults;
    this.resultsLength = this.results.length;
    this.url = environment.baseURL;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.results = this.searchResults;
  }

  ngDoCheck() {
    this.results = this.searchResults;
    this.resultsLength = this.results.length;
    this.url = environment.baseURL;
  }

}
