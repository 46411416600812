<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/peopleanalytics">People Analytics</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">People-Centered People analytics Implementation, Optimization, and Support
                </h1>
                <p>Select and implement the people analytics platform and processes that support your talent strategy and culture </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/hcm/peopleanalytics/people-analytics-image.png" alt="" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-5">
                <img src="../../assets/images/hcm/peopleanalytics/Leading companies.png" class="img-fluid" style="width: 100%;" alt="Our approach to people analytics enables you to ask the right questions, deliver real business value, and control privacy and reputation risks.">
            </div>
            <div class="col-md-7">
                <p>
                    Leading companies are tossing out conventional wisdom and revealing new insights into their people data to hire better candidates, transform learning, keep talented people, and boost productivity. Pixentia’s approach to people analytics enables you to
                    ask the right questions, deliver real business value, and control privacy and reputation risks.
                </p>
                <p>
                    Pixentia’s analytics services do more than enable your analytical tools. Our team assists you in analyzing your strategy, engaging your leaders, developing your analytics capability, and understanding how people impact your business.
                </p>
                <div class="px-3 row">
                    <div class="col-md-7 right_box py-4">
                        <div class="d-flex mb-3 align-items-center">
                            <div>
                                <img src="../../../assets/images/digital-transformation/Are you looking for specific apps.png" alt="">
                            </div>
                            <div>
                                <p class="font-weight-bold mb-0">
                                    Searching for the right People Analytics Strategy?
                                </p>
                            </div>
                        </div>

                        <p class="hcm_link">
                            <a href="contact-us">Contact us today about building your strategy and governance framework.</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="services_section">
        <div class="template_width">
            <div class="row mb-5">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified">
                        <li class="nav-item text-center">
                            <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Optimization
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <!-- Tab panes -->
                <div class="tab-content my-5">
                    <div class="tab-pane active" id="strategy">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h5 class="font-weight-bold">People Analytics Strategy and Planning</h5>
                                </div>
                                <p>
                                    Industry experts give us dozens of reasons only 20% of analytics projects deliver expected results. Not coincidentally, they look a lot like the reasons any technology project fails. All of them come down to four essentials: strategy, planning, preparation,
                                    and execution.
                                </p>
                                <p>
                                    At Pixentia, we begin every analytics project with a detailed review of your strategy. Then we work with you to develop a comprehensive plan and governance to mobilize the people, resources, and services involved in your project.
                                </p>
                            </div>
                            <div class="sub_head text-center mt-4">
                                <h5 class="font-weight-bold">Our services include:</h5>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Strategy/Analytics Platform.png" alt="Analytics platform selection">
                                    </div>
                                    <div>
                                        <p>
                                            Analytics platform selection, encompassing RFP planning and vendor evaluation tools to help you choose the right platform <span class="d-inline-block">for your strategy.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Strategy/Business Case.png" alt="Business case analysis">
                                    </div>
                                    <div>
                                        <p>
                                            Business case analysis, project justification, and ROI valuation that show how your people analytics project will strengthen <a href="services/hcm/hcm-coe">human capital management</a> and <span class="d-inline-block">improve the bottom line.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Strategy/Change Managment.png" alt="Change management">
                                    </div>
                                    <div>
                                        <p>
                                            Change management and communications consulting and support to engage stakeholders at every level <span class="d-inline-block">of your organization.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Strategy/Data mapping.png" alt="Data mapping">
                                    </div>
                                    <div>
                                        <p>
                                            Data mapping, capture, and cleansing to assure smooth integration of information into the <span class="d-inline-block">analytics platform.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Strategy/Technology Evaluation.png" alt="Technology evaluation">
                                    </div>
                                    <div>
                                        <p>
                                            Technology evaluation and selection, including RFP support to ensure you select the right technology for your people analytics strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="deployment">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h5 class="font-weight-bold">People Analytics Preparation and Deployment</h5>
                                </div>
                                <p>
                                    Together, we form a team of business leaders, HR professionals, analytics specialists, and data experts. We provide workshops to transfer critical knowledge to the team and build a project plan that enables you to manage your resources and timeline.
                                </p>
                                <div class="sub_head mt-4">
                                    <h5 class="font-weight-bold">Our services also include:
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Preparation/Hands on.png" alt="Hands-on support">
                                    </div>
                                    <div>
                                        <p>
                                            Hands-on support to integrate data from your organization’s existing sources, create curation processes, and build flexible <span class="d-inline-block">data warehousing and
                                            management.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Preparation/Experienced analytics.png" alt="Experienced analysts">
                                    </div>
                                    <div>
                                        <p>
                                            Experienced analysts who can validate both incoming data and <span class="d-inline-block">statistical
                                            methods.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Preparation/Analytics Expertise.png" alt="Analytics">
                                    </div>
                                    <div>
                                        <p>
                                            Analytics expertise that can create custom models to enable operational intelligence, trend analysis, and predictive and prescriptive analytics solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Preparation/HCM Speclists.png" alt="HCM specialists">
                                    </div>
                                    <div>
                                        <p>
                                            HCM specialists who can evaluate modeling results and make practical recommendations on ways to improve organization performance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Preparation/Custom Portals.png" alt="Custom portals">
                                    </div>
                                    <div>
                                        <p>
                                            Custom portals, dashboards, and visualization platforms that give real-time access to updated analytics and reports.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="optimization">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h5 class="font-weight-bold">People Analytics Optimization
                                    </h5>
                                </div>
                                <p>
                                    Achieving the full value of your analytics platform takes ongoing effort to grow into your platform’s capabilities. It also means capitalizing on new data models, data streams, and methods as they develop. Pixentia’s optimization services continue to
                                    support your growth and mastery of your people analytics platform to heighten its value to your organization and its people.
                                </p>
                                <div class="sub_head mt-5">
                                    <h5 class="font-weight-bold">Our services include:
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Optimization/Administrative.png" alt="Administrative and technical support">
                                    </div>
                                    <div>
                                        <p>
                                            Administrative and technical support for your administrators in their day-to-day <span class="d-inline-block">operations and maintenance.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Optimization/Analytics.png" alt="Analytics and technology maintenance">
                                    </div>
                                    <div>
                                        <p>
                                            Analytics and technology maintenance, including updates and upgrades to take advantage of technology innovations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Optimization/Exploration.png" alt="Exploration of new analytical tools">
                                    </div>
                                    <div>
                                        <p>
                                            Exploration of new analytical tools and data relationships and facilitate advanced reporting, dashboards and visualizations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/peopleanalytics/Optimization/Custom Dashboards.png" alt="Custom dashboards">
                                    </div>
                                    <div>
                                        <p>
                                            Custom dashboards and workforce analytics to build a data-driven management team.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>