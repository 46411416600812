import { Component } from '@angular/core';
import { CanonicalService } from './shared/canonical.service';
import { BiopageService } from './shared/biopage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pixentia';
  searchArrayLength: number;
  navbarhide: boolean

  constructor(
    private canonicalService: CanonicalService,

    private bioservice: BiopageService) {
    this.bioservice.navhidden().subscribe(navbarhide =>
      this.navbarhide = navbarhide)
  }

  ngOnInit() {
    this.canonicalService.setCanonicalURL();
  }

  setSearchArrayLength(searchLengthValue: number) {
    this.searchArrayLength = searchLengthValue;
  }
}
