<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/content-resource-center">Content Resource Center</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Align Learning Content to your Workforce Needs
                </h3>
                <p>Finding learning content is easy. Finding training that fits your objectives, your audience, and your culture is a lot harder. Pixentia’s collaborative development framework assures your workforce the best possible learning experience
                    because you are involved in the design from the very first day.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/content-resource/Content Resource Center.png" alt="Content Resource image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Curation vs. Custom Content
                    </h1>
                </div>
                <div>
                    <p class="mb-3">
                        The learning industry creates tons of content every day. Much of it can be useful any for organization in the right context. We advocate curated learning content, especially if it meets your needs and is friendly to your budget
                    </p>
                    <p>
                        Sometimes you need training designed specifically for your people. Our content resource center creates high-quality learning designed precisely for your workforce requirements.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h3 class="font-weight-bold">Four Learning Development Models to Fit Your Requirements and Budget
                    </h3>
                </div>
                <div>
                    <p class="mb-3">
                        We provide four models, or levels of sophistication, based on your needs and budget.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-bottom border-right py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/content-resource/Basic Modules.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Basic Modules
                    </p>
                    <p>
                        An enhanced version of PowerPoint, with interactions
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-bottom py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/content-resource/Mid-Level Modules.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Mid-Level Modules
                    </p>
                    <p>
                        Interactive user interface with assessments and branching logic. It may include embedded assets, video and animation
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-right py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/content-resource/Advanced Modules.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Advanced Modules
                    </p>
                    <p>
                        Advanced user interaction with gamification, simulations, and branching scenarios
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/content-resource/Innovative Modules.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Innovative Modules
                    </p>
                    <p>
                        Virtual Reality and Augmented Reality simulations with realistic real-time scenarios
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_services_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">A Four-Step Collaborative Framework</h4>
                <div class="mt-4">
                    <p>
                        We work with you and your subject matter experts to develop your learning project, with frequent feedback and collaboration. We want to ensure that the design meets your objectives every step of the way. We work in four steps or phases.
                    </p>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-5 justify-content-center">
            <div class="flex_1">
                <div class="box text-center mb-2">
                    <div class="text-center">
                        <img src="../../../../../assets/images/hcm/learning/content-resource/Analyze.png" class="img-fluid" alt="Analyze">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">
                            Analyze
                        </p>
                        <p>
                            We review your learning objectives and subject matter with you. We identify the required design and assets and give you a prototype for your review
                        </p>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="d-none d-md-block arrow_sign">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/Line.png" alt="">
                </div>
                <div class="d-block d-md-none arrow_sign1">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/vertical-line.png" alt="">
                </div>
            </div>
            <div class="flex_1">
                <div class="box text-center">
                    <div class="text-center mb-2">
                        <img src="../../../../../assets/images/hcm/learning/content-resource/Storyboard and Design.png" class="img-fluid" alt="Storyboard and Design">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">Storyboard and Design</p>
                        <p>
                            In an iterative process, we develop and review a storyboard that contains the design elements and learning methods required for the course and deliver it to you for approval
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-5">

            </div>
            <div class="col-md-2 p-md-0">

            </div>
            <div class="col-md-5">
                <div class="arrow_sign1 text-center">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/vertical-line.png" alt="">
                </div>
            </div>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="flex_1">
                <div class="box text-center mb-2">
                    <div class="text-center">
                        <img src="../../../../../assets/images/hcm/learning/content-resource/Assets and Voiceover.png" class="img-fluid" alt="Assets and Voiceover">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">
                            Assets and Voiceover
                        </p>
                        <p>
                            We assemble the design assets and voiceover narrations to prepare for development
                        </p>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="d-none d-md-block arrow_sign">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/Line.png" alt="">
                </div>
                <div class="d-block d-md-none arrow_sign1">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/vertical-line.png" alt="">
                </div>
            </div>
            <div class="flex_1">
                <div class="box text-center">
                    <div class="text-center mb-2">
                        <img src="../../../../../assets/images/hcm/learning/content-resource/Develop and Test.png" class="img-fluid" alt="Develop and Test">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">Develop and Test</p>
                        <p>
                            We develop the course using the authoring tool you select and review it with you. Then we test it and upload it to your LMS for user testing
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn how you can engage your learners with learning content that enhance their performance
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to
                                    Learning Optimization <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>