<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/learning/demosmain">Learning Demos</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">
                    Learning Experiences That Accelerate Performance
                </h3>
                <p>Immersive learning paths to engage and upskill your workforces towards achieving high performance and sustained business results.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/learning/demos/demos-image.png" alt="Demos Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-5 font-weight-bold">
                    DEMOS
                </h1>
            </div>
            <div class="col-md-8 offset-md-2 text-center">
                <p>
                    Step into a world of learning that engages and delights your learners. View our custom-made, gamified interactions to know how we offer learners, the best learning experiences. Are you looking for a sample on Onboarding ? Compliance ? or a sample specific
                    to a particular industry ? We have it for you.
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="demosView">
        <div class="service_section">
            <div class="template_width">
                <div class="row mb-5">
                    <div id="demotabs" class="col-md-6 offset-md-3">
                        <ul class="nav nav-pills nav-justified">
                            <li class="nav-item text-center">
                                <a class="nav-link py-2 active text-cente" data-toggle="pill" href="#all">
                  All
                </a>
                            </li>
                            <li class="ml-2 nav-item">
                                <a class="nav-link py-2" data-toggle="pill" href="#e-learning">
                  E-learning Challenges
                </a>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="isLoggedIn" (click)="logout()" class="col-md-2 col-sm-2 col-xs-2 text-right logout" style="display: block;">
                        <i class="fa fa-sign-out fa-lg" aria-hidden="true"></i>
                        <span class="ml-1">Logout</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="template_width">
            <div class="row">
                <div class="col-md-12">
                    <!-- Tab panes -->
                    <div class="tab-content video_section">
                        <div class="tab-pane container active" id="all">
                            <!-- <div class="row my-5 services_list align-items-center services_list1">
                <div class="col-md-6">
                  <div>
                    <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/mod-1-intro.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="sub_head">
                    <h3 class="font-weight-bold">Email Essentials for Business</h3>
                    <p>
                      Do you want to enhance your email communication skills? Are you looking to build
                      your own Email Policy for your organization? Check out this engaging Course on
                      “Email Essentials for Business” which not only makes your email communication
                      effective but also helps you manage any critical business situation through your
                      emails.
                    </p>
                    <div class="d-flex align-items-center">
                      <div *ngIf="!isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(1)">Sign Up</button>
                        <a class="login_content_styles" (click)="onLoginClick(1)">Already a member? Login</a>
                      </div>
                      <div *ngIf="isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(1)">Launch</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
                            <!-- <div class="row my-5 services_list align-items-center services_list2">
                <div class="col-md-6">
                  <div class="sub_head">
                    <h3 class="font-weight-bold">Interpersonal Communication</h3>
                    <p>
                      Are you an Effective Listener? Are you Assertive and also Approachable? One of the important
                      skills
                      to succeed in a corporate world is Interpersonal Communication Skills. If you want make a lasting
                      impact with your communication, check out this course on Interpersonal Communication Skills that
                      takes you through an immersive path of real learning.
                    </p>
                    <div class="d-flex align-items-center">
                      <div *ngIf="!isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(2)">Sign Up</button>
                        <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(2)">Already a
                          member? Login</a>
                      </div>
                      <div *ngIf="isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(2)">Launch</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/InterPersonal_Communication.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
              </div> -->
                            <div class="row my-5 services_list align-items-center services_list1">
                                <div class="col-md-6">
                                    <div>
                                        <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/Brain Training Video_video.m4v" type="video/mp4">
                    </video>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h3 class="font-weight-bold">Brain Training Games, Puzzles and Exercises in E-learning</h3>
                                        <p>
                                            Gamified learning and learning that involves games engage learners better than courses that just have static content of text and images. This interaction is a language game worked out for the e-learning challenge on, ‘Brain Training Games, Puzzles and
                                            Exercises in E-learning’.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="!isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(3)">Sign Up</button>
                                                <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(3)">Already a
                          member? Login</a>
                                            </div>
                                            <div *ngIf="isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(3)">Launch</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-5 services_list align-items-center services_list2">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h3 class="font-weight-bold">Creating Custom drag and drop interactions</h3>
                                        <p>
                                            Drag and drop is one of the common interactions used in e-learning courses, more often it appears in assessments. But we can also create custom drag and drop to engage learners better. This interaction is a custom drag and drop sample worked out for the
                                            e-learning challenge on, ‘Creating Custom drag and drop interactions’.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="!isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(4)">Sign Up</button>
                                                <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(4)">Already a
                          member? Login</a>
                                            </div>
                                            <div *ngIf="isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(4)">Launch</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/Drag And Drop_video.m4v" type="video/mp4">
                    </video>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-5 services_list align-items-center services_list1">
                                <div class="col-md-6">
                                    <div>
                                        <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/blak and white_video.mp4" type="video/mp4">
                    </video>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h3 class="font-weight-bold">Using only Black and White in course design</h3>
                                        <p>
                                            Picking the right colors for your course design is a crucial part while building your courses. This also has its part creating interest in the learners. But how about using only white and black in your courses? This interaction is a sample worked out
                                            for the e-learning challenge on, ‘Using only Black and White in course design.’
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="!isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(5)">Sign Up</button>
                                                <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(5)">Already a
                          member? Login</a>
                                            </div>
                                            <div *ngIf="isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(5)">Launch</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row my-5 services_list align-items-center services_list2">
                <div class="col-md-6">
                  <div class="sub_head">
                    <h3 class="font-weight-bold">Workplace Ethics</h3>
                    <p>
                      Making choices is many times hard. When it is to make a choice about employees, an HR coordinator
                      needs to be cautious enough to avoid conflicts. Check out this branching scenario which helps you
                      make right choices when you have to deal with an employee, who is excessively tardy.
                    </p>
                    <div class="d-flex align-items-center">
                      <div *ngIf="!isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(6)">Sign Up</button>
                        <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(6)">Already a
                          member? Login</a>
                      </div>
                      <div *ngIf="isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(6)">Launch</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/mod-1-intro.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
              </div>
              <div class="row my-5 services_list align-items-center services_list1">
                <div class="col-md-6">
                  <div>
                    <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/Cisco.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="sub_head">
                    <h3 class="font-weight-bold">Getting to know your CISCO</h3>
                    <p>
                      When you are trained on how to use a device, you get to know how its features work, and what to do
                      when something goes wrong. Check out this interaction which helps you learn some of the features
                      of
                      VoIP phone.
                    </p>
                    <div class="d-flex align-items-center">
                      <div *ngIf="!isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(7)">Sign Up</button>
                        <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(7)">Already a
                          member? Login</a>
                      </div>
                      <div *ngIf="isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(7)">Launch</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-5 services_list align-items-center services_list2">
                <div class="col-md-6">
                  <div class="sub_head">
                    <h3 class="font-weight-bold">Street Racer</h3>
                    <p>
                      Racing is exciting and when you win that’s even more thrilling. Racing games also give you the
                      same
                      kind of excitement of competing in a race. Have you ever been in a race? Check out this gamified
                      interaction that takes you on a race with a big reward.
                    </p>
                    <div class="d-flex align-items-center">
                      <div *ngIf="!isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(8)">Sign Up</button>
                        <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(8)">Already a
                          member? Login</a>
                      </div>
                      <div *ngIf="isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(8)">Launch</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/Racer.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
              </div> -->
                        </div>
                        <div class="tab-pane container fade" id="e-learning">
                            <div class="row my-5 services_list align-items-center services_list1">
                                <div class="col-md-6">
                                    <div>
                                        <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/Brain Training Video_video.m4v" type="video/mp4">
                    </video>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h3 class="font-weight-bold">Brain Training Games, Puzzles and Exercises in E-learning</h3>
                                        <p>
                                            Gamified learning and learning that involves games engage learners better than courses that just have static content of text and images. This interaction is a language game worked out for the e-learning challenge on, ‘Brain Training Games, Puzzles and
                                            Exercises in E-learning’.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="!isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(9)">Sign Up</button>
                                                <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(9)">Already a
                          member? Login</a>
                                            </div>
                                            <div *ngIf="isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(9)">Launch</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-5 services_list align-items-center services_list2">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h3 class="font-weight-bold">Creating Custom drag and drop interactions</h3>
                                        <p>
                                            Drag and drop is one of the common interactions used in e-learning courses, more often it appears in assessments. But we can also create custom drag and drop to engage learners better. This interaction is a custom drag and drop sample worked out for the
                                            e-learning challenge on, ‘Creating Custom drag and drop interactions’.
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="!isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(10)">Sign Up</button>
                                                <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(10)">Already a
                          member? Login</a>
                                            </div>
                                            <div *ngIf="isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(10)">Launch</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/Drag And Drop_video.m4v" type="video/mp4">
                    </video>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-5 services_list align-items-center services_list1">
                                <div class="col-md-6">
                                    <div>
                                        <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/blak and white_video.mp4" type="video/mp4">
                    </video>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h3 class="font-weight-bold">Using only Black and White in course design</h3>
                                        <p>
                                            Picking the right colors for your course design is a crucial part while building your courses. This also has its part creating interest in the learners. But how about using only white and black in your courses? This interaction is a sample worked out
                                            for the e-learning challenge on, ‘Using only Black and White in course design.’
                                        </p>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="!isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(11)">Sign Up</button>
                                                <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(11)">Already a
                          member? Login</a>
                                            </div>
                                            <div *ngIf="isLoggedIn">
                                                <button type="button" class="btn button_styles" (click)="onSignUpClick(11)">Launch</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row my-5 services_list align-items-center services_list2">
                <div class="col-md-6">
                  <div class="sub_head">
                    <h3 class="font-weight-bold">Workplace Ethics</h3>
                    <p>
                      Making choices is many times hard. When it is to make a choice about employees, an HR coordinator
                      needs to be cautious enough to avoid conflicts. Check out this branching scenario which helps you
                      make right choices when you have to deal with an employee, who is excessively tardy.
                    </p>
                    <div class="d-flex align-items-center">
                      <div *ngIf="!isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(12)">Sign Up</button>
                        <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(12)">Already a
                          member? Login</a>
                      </div>
                      <div *ngIf="isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(12)">Launch</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/mod-1-intro.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
              </div>
              <div class="row my-5 services_list align-items-center services_list1">
                <div class="col-md-6">
                  <div>
                    <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/Cisco.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="sub_head">
                    <h3 class="font-weight-bold">Getting to know your CISCO</h3>
                    <p>
                      When you are trained on how to use a device, you get to know how its features work, and what to do
                      when something goes wrong. Check out this interaction which helps you learn some of the features
                      of
                      VoIP phone.
                    </p>
                    <div class="d-flex align-items-center">
                      <div *ngIf="!isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(13)">Sign Up</button>
                        <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(13)">Already a
                          member? Login</a>
                      </div>
                      <div *ngIf="isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(13)">Launch</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-5 services_list align-items-center services_list2">
                <div class="col-md-6">
                  <div class="sub_head">
                    <h3 class="font-weight-bold">Street Racer</h3>
                    <p>
                      Racing is exciting and when you win that’s even more thrilling. Racing games also give you the
                      same
                      kind of excitement of competing in a race. Have you ever been in a race? Check out this gamified
                      interaction that takes you on a race with a big reward.
                    </p>
                    <div class="d-flex align-items-center">
                      <div *ngIf="!isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(14)">Sign Up</button>
                        <a class="login_content_styles" id="Login_all_emailess" (click)="onLoginClick(14)">Already a
                          member? Login</a>
                      </div>
                      <div *ngIf="isLoggedIn">
                        <button type="button" class="btn button_styles" (click)="onSignUpClick(14)">Launch</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <video width="100%" height="340" controls style="border: 7px solid #d1d1d1;">
                      <source src="../../assets/videos/Racer.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
              </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!demosView">
        <app-learning-demo-signup (backClicked)="backClicked()" [eLearningDemo]="eLearningDemo" [isSignUpForm]="isSignUpForm">
        </app-learning-demo-signup>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>