<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps">Chasma Apps</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps/chasma-cible">Chasma Cible</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Cible</h3>
                <p>User Flow and Behavior Analysis for Your Enterprise Applications</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/cible/Cible image.webp" alt="Chasma Cible image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">One Visual Flow Diagram is worth a thousand user surveys</h1>
                <div>
                    <p class="mb-3 px-5">
                        One visual flow diagram is worth a thousand user surveys. Chasma Cible gives you direct insight into user flow and interest, giving you an edge in understanding user behavior.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-right border-bottom text-center">
            <div>
                <img src="../../assets/images/products/cible/Power Up.png" alt="Power Up Your Talent Acquisition Sourcing">
            </div>
            <div class="mt-3">
                <p class="font-weight-bold mb-1">
                    Power Up Your Talent Acquisition Sourcing
                </p>
                <p class="px-5">
                    A "post and pray" scattergun approach to talent sourcing can be a waste of resources. Get visual insights into your <span class="d-inline-block">most valuable channels.</span>
                </p>
            </div>
        </div>
        <div class="col-md-6 pl-md-5 border-bottom text-center">
            <div>
                <img src="../../assets/images/products/cible/Tune up.png" alt="Tune up Your Candidate Journey">
            </div>
            <div class="mt-3">
                <p class="font-weight-bold mb-1">
                    Tune up Your Candidate Journey
                </p>
                <p class="px-5">
                    Candidate reviews have a significant impact on your reputation. See where you are falling short with a candidate journey map of every touchpoint.
                </p>
            </div>
        </div>
        <div class="col-md-6 border-right text-center">
            <div class="mt-md-4">
                <img src="../../assets/images/products/cible/Analyze open.png" alt="Analyze Open Enrollment Behavior">
            </div>
            <div class="mt-3">
                <p class="font-weight-bold mb-1">
                    Analyze Open Enrollment Behavior
                </p>
                <p class="px-5">
                    Analyze your employees' preferences to learn where to target your offerings. Map user behaviors to understand where your people give up on the process and <span class="d-inline-block">pick up the phone.</span>
                </p>
            </div>
        </div>
        <div class="col-md-6 pl-md-5 text-center">
            <div class="mt-md-4">
                <img src="../../assets/images/products/cible/Boost.png" alt="Boost the Onboarding Experience">
            </div>
            <div class="mt-3">
                <p class="font-weight-bold mb-1">
                    Boost the Onboarding Experience
                </p>
                <p class="px-5">
                    Go beyond checklists and surveys to a deeper understanding of how new employees assimilate into the flow of work.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="mb-5 mt-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center mb-4">
                <h4 class="font-weight-bold py-4 mb-0">Deploy Cible in your Current Apps and Platforms
                </h4>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/cible/Analyze.png" alt="Eliminate Labor-Intensive Manual Testing" class="mb-3">
                    <p class="px-4">
                        Analyze a single application or a defined group of apps to optimize the <span class="d-inline-block">user journey.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/cible/View Graphic.png" alt="Automated Integrations Testing" class="mb-3">
                    <p class="px-4">
                        View graphic high-level visitor data at group or application level to show visitor traffic, clicks, and heat maps.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/cible/Control Heat map.png" alt="Enjoy a Vast Library of Test Scenarios" class="mb-3">
                    <p class="px-4">
                        Control heat maps in the master details layout of each app so you can get an accurate picture of user behavior.
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/cible/View a flow.png" alt="Configuration" class="mb-3">
                    <p class="px-4">
                        View a flow diagram of user entry, user flow, and exit for each app to understand why users make decisions <span class="d-inline-block">along the way.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/cible/Map User.png" alt="Real time Status" class="mb-3">
                    <p class="px-4">
                        Map user behaviors to understand where your people give up on the process and <span class="d-inline-block">pick up the phone.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Cible?</h4>
                    <p class="">
                        Chasma Cible shows you detailed diagrams of user behavior or any other online process so you can see exactly where the trouble spots are. Contact us to see how you can gain a new understanding of how your people react to the experience you designed for
                        them.
                    </p>
                    <p>
                        <a href="contact-us" class="video_btn_style text-white">Contact Us <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <img src="../../../assets/images/products/cible/cible-video-img.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/images/products/event/Need more.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-white pl-md-5 mt-5 mt-md-0">
                        <h2 class="font-weight-bold text-uppercase">Need More </h2>
                        <p class="mb-4">Talk to us about your business goals</p>
                        <div class="more_info_btn">
                            <a href="contact-us">Contact Us <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>