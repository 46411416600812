<div id="data" class="shadeforforgotpwd">
  <div class="forgotpswd">
    <div class="col-md-12" style="z-index:9; display: flex; justify-content: flex-end; cursor: pointer;">
      <mat-icon (click)="closeDialog()">close</mat-icon>
    </div>
    <div class="row d-flex col-md-12 text-center">
      <h3 class="p-0 formTitle col-md-12" style="margin-bottom: 10px;">Forgot Password?</h3>
      <span class="col-md-12">Please enter your email to retrieve your password</span>
      <span *ngIf="errorMessage" class="col-md-12 custom_field_height" style="color:#e45454">
        {{errorMessage}}.
      </span>
    </div>
    <div class="mt-2 form-group">
      <div class="col-md-12 col-sm-12">
        <label for="Email" class="control-label padright_null" style="padding-left: 3px;">Email&nbsp;<span
            style="color:red">*</span></label>
        <input type="email" [(ngModel)]="email" class="form-control" id="fpemaild" required="">
        <span id="errfpemail" style="color:#e45454;font-size:13px;">&nbsp;</span>
      </div>
    </div>
    <div class="pb-4 form-group">
      <div class="col-sm-12 padbot4 text-center">
        <button type="button" [disabled]="isSaveButtonVisible" (click)="forgotPasswordDetails()"
          class="col-md-12 col-sm-12 btn btn-default" id="btnproceed"><span>Proceed</span></button>
      </div>
    </div>
  </div>
</div>
