import { loggedInDemoUserModel, loginModel } from './../shared/models/eLearningDemos';
import { CompensationComponent } from './hcm/compensation/compensation.component';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { demoUserApprovalDetails, signUpModel, validateDemoSignUpUserDetails } from '../shared/models/eLearningDemos';
import { APIService } from './api.service';
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class ApicallsService {

  url: string;

  constructor(private apiService: APIService) {
    this.url = environment.apiURL;
  }

  postSignUpDetails(command: signUpModel) {
    return this.apiService.post<string>(`${this.url}/api/Services`, command);
  }

  getValidateUserDetails(userId: string) {
    return this.apiService.get<validateDemoSignUpUserDetails>(`${this.url}/api/Services/${userId}`);
  }

  updateDemoUserValidateDetails(command: demoUserApprovalDetails) {
    return this.apiService.post<string>(`${this.url}/api/Services/UpdateApprovalDetails`, command);
  }

  postForgotPasswordDetails(email: string) {
    return this.apiService.post<any>(`${this.url}/api/Services/${email}`);
  }

  loginDemoUser(command: loginModel) {
    return this.apiService.get<loggedInDemoUserModel>(`${this.url}/api/Services`, command);
  }

  logoutDemoUser() {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('loggedInUserId');
    localStorage.removeItem('isFirstLogin');
    window.location.reload();
  }

}
