import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-staffing-and-augmentation-services',
  templateUrl: './staffing-and-augmentation-services.component.html',
  styleUrls: ['./staffing-and-augmentation-services.component.scss']
})
export class StaffingAndAugmentationServicesComponent implements OnInit {

  title = 'Pixentia Staffing and Augmentation Services';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Whether you need to fill a temporary gap or build up your team, Pixentia has flexible staffing and augmentation services for your learning management. ' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Staffing and Augmentation Services' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Whether you need to fill a temporary gap or build up your team, Pixentia has flexible staffing and augmentation services for your learning management. ' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/hcm/learning/staffing-and-augmentation-services' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Staffing and Augmentation Services' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Whether you need to fill a temporary gap or build up your team, Pixentia has flexible staffing and augmentation services for your learning management. ' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/hcm/learning/staffing-and-augmentation-services' }      
    );
  }
}
