<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Learning Solutions that Deliver High Results</h3>
                <p>Multi-level support, from custom training content, to learning strategy planning, to course delivery across multiple device platforms.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/hcm/learning/HCM-Learning-Image.png" alt="HCM Learning" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-1 font-weight-bold">
                    Create a learning experience that drives customer value
                </h1>
                <p class="text-center">Align your strategies to deliver value to your business. Create an inspiring learning experience to enable your people to deliver value to your customers.
                </p>
            </div>
        </div>
    </div>
    <div class="services_section">
        <div class="template_width">
            <div class="row mb-5">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified">
                        <li class="nav-item text-center">
                            <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                <img src="../../assets/images/hcm/talent/Resource Centers.png" class="d-block mx-auto" alt=""> Learning Content
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#resource">
                                <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Learning Optimization
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <!-- Tab panes -->
        <div class="tab-content mt-5">
            <div class="tab-pane active" id="strategy">
                <div class="col-md-10 offset-md-1">
                    <div class="text-center">
                        <div class="sub_head">
                            <h4 class="font-weight-bold mb-4">
                                A Strategic Approach to Learning Technology Planning
                            </h4>
                        </div>
                        <p>
                            Aligning your technology to business goals and workforce needs drives value for your business. Our planning approach builds a strong foundation for creating more value.
                        </p>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Learning Assessment Services</h4>
                                    <p>
                                        Before we recommend any technology or services, we want to know about your business, learning strategy, and the current state of your learning technology.
                                    </p>
                                    <p>
                                        Only when we have established that baseline can we make sound recommendations to accomplish your goals.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/Strategy/Learning Assessment Services.png" class="img-fluid" alt="Learning Assessment Services">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/Strategy/Business Case Development.png" class="img-fluid" alt="Business Case Development">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Business Case Development</h4>
                                    <p>
                                        Building a strong business case takes more than a simple cost/benefit analysis. We help you to develop an analysis that communicates the business impact of your project.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Learning Technology Roadmap</h4>
                                    <p>
                                        The pace of technology change and the speed of business require a long-term roadmap that can quickly adapt to strategy changes.
                                    </p>
                                    <p>
                                        We assist you in developing a long-term technology plan that grow with you as your needs change. This approach, by design, will help you become more agile in the short term, and more strategic in the long run.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/Strategy/Learning Technology Roadmap.png" class="img-fluid" alt="Learning Technology Roadmap">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/Strategy/Project Governance.png" class="img-fluid" alt="Project Governance">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Project Governance</h4>
                                    <p>
                                        A framework for decision-making is critical to your success. You need accountability and the ability to manage events as they arise.
                                    </p>
                                    <p>
                                        Create a simple framework for communication and decision protocols.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Change Management Support</h4>
                                    <p>
                                        Change management should involve your stakeholders from the start of your project. The best way to gain support is to engage your people in making the change to give them ownership of the new way of working. We provide support services to start a conversation
                                        with your stakeholders, market your solution, and build resource guides.
                                    </p>
                                    <div class="sub_btn_style">
                                        <a href="services/hcm/learning/change-management">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/Strategy/Change Management Support.png" class="img-fluid" alt="Change Management Support">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/Strategy/Learning Analytics Strategy.png" class="img-fluid" alt="Learning Analytics Strategy">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Learning Analytics Strategy</h4>
                                    <p>
                                        Our analytics services provide much more than standard reports. We work with you to develop metrics and measures for your project to assess and report on your progress toward your goals.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="my-5">
                    <div class="template_width">
                        <div class="row align-items-center">
                            <div class="col-md-10 offset-md-1">
                                <div class="row align-items-center">
                                    <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                                        <p class="sub_heading_cta mb-4">Discover the roadmap to your learning technology implementation success.
                                        </p>
                                        <div class="more_info_btn datasheet_btn">
                                            <a href="https://resources.pixentia.com/data-sheets/sumtotal-implementation-preparation-and-deployment" rel="noopener noreferrer" target="_blank"> Get the details here <i class="fa fa-angle-right ml-2"
                                                    aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    <div class="col-md-5 mt-5 mt-md-0">
                                        <div>
                                            <img src="../../../../assets/images/cta_images/HCM_Learning.png" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="deployment">
                <div class="col-md-10 offset-md-1">
                    <div class="text-center">
                        <div class="sub_head">
                            <h4 class="font-weight-bold mb-4">
                                A Structured, Scalable Approach to SumTotal Technology Preparation and Deployment
                            </h4>
                        </div>
                        <p>
                            Preparation, accountability, communication, and control are the foundations of a successful deployment. Our workshops prepare you to become expert managers of your learning platform.
                        </p>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Project Planning</h4>
                                    <p>
                                        We work with you to develop a phased project plan that enables you to manage your timeline, people, and resources.
                                    </p>
                                    <p>
                                        We enable you to establish a project governance framework that assigns responsibilities and supports accountability so you can prepare for managing both known and unknown risks.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/preparation/Project Planning.png" class="img-fluid" alt="Project Planning">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/preparation/Data Preparation.png" class="img-fluid" alt="Data Preparation">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Data Preparation</h4>
                                    <p>
                                        If you require migration of legacy data, we assist you in assessing the quality of your data to help you make the right decision about whether you should migrate.
                                    </p>
                                    <p>
                                        If you do decide to migrate data instead of starting fresh, we provide data cleansing services, map your data to your new platform, and test the migration. You can be assured your data will be consistent and reliable.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Workshops</h4>
                                    <p>
                                        Our workshops prepare you to align your new technology to your organization, business processes, and security requirements.
                                    </p>
                                    <p>
                                        We provide business workshops, training workshops, and configuration workshops to prepare your team to manage your learning platform.
                                    </p>
                                    <div class="sub_btn_style">
                                        <a href="services/hcm/learning/business-workshop">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/preparation/Workshops.png" class="img-fluid" alt="Workshops">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/preparation/Configuration and review.png" class="img-fluid" alt="Configuration and review">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Configuration and review</h4>
                                    <p>
                                        Our team works with your experts either side-by-side or remotely to assist you in configuring and testing your software.
                                    </p>
                                    <p>
                                        We use an iterative approach so you can review business processes as they configure them and make course corrections based on your team’s feedback.
                                    </p>
                                    <div class="sub_btn_style1">
                                        <a href="services/hcm/learning/configuration-workshop">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Integration</h4>
                                    <p>
                                        Standard implementations provide for connecting your learning and talent software to your core HR system of record.
                                    </p>
                                    <p>
                                        We also provide services to integrate your entire learning ecosystem or any part of it. You can make access to learning easy and transparent.
                                    </p>
                                    <div class="sub_btn_style">
                                        <a href="services/hcm/learning/integration">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/preparation/Integration.png" class="img-fluid" alt="Integration">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/preparation/Analytics.png" class="img-fluid" alt="Analytics">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Analytics</h4>
                                    <p>
                                        Learning analytics and reporting are necessary to an effective people development strategy. Our team enables your reporting functions and prepares your team to manage them.
                                    </p>
                                    <p>
                                        We also provide analytics services to build out your report libraries, create and deploy dashboards, and perform advanced analytics to help you make better and faster learning decisions.
                                    </p>
                                    <div class="sub_btn_style1">
                                        <a href="services/hcm/learning/analytics-deployment">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Testing</h4>
                                    <p>
                                        Testing is the final phase before you launch your new platform. We assist you in developing your test strategy and your testing plan.
                                    </p>
                                    <p>
                                        Our approach includes automation that uses scripts to test business processes from start to end. We provide thousands of standardized scripts and help you develop new scripts for your requirements.
                                    </p>
                                    <p>
                                        We also support your user acceptance testing with triage and issue management methodology.
                                    </p>
                                    <div class="sub_btn_style">
                                        <a href="services/hcm/learning/testing">Learn More <i class="fa fa-angle-right"
                                                aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/preparation/Testing.png" class="img-fluid" alt="Testing">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="optimization">
                <div class="col-md-10 offset-md-1">
                    <div class="text-center">
                        <div class="sub_head">
                            <h4 class="font-weight-bold mb-4">
                                For the Way Your People Learn
                            </h4>
                        </div>
                        <p>
                            Finding learning content is easy. Finding learning that fits your objectives, your audience, and your culture is a lot harder.
                        </p>
                        <p>
                            Pixentia’s development framework assures your workforce the best possible learning experience because you are involved in the design from the first day.
                        </p>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Strategy & Planning</h4>
                                    <p>
                                        Our strategy and planning work sessions define your objectives and prepare your plan.
                                    </p>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Explore with.png" class="mr-2" alt="Explore with you how your content strategy">
                                        </p>
                                        <p>
                                            Explore with you how your content strategy fits into your workforce strategy and the current state of your technology and programs.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Learn the needs.png" class="mr-2" alt="Learn the needs of your audience and their current knowledge">
                                        </p>
                                        <p>
                                            Learn the needs of your audience and their current knowledge and skills levels to provide the starting point for your learning initiative.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Effortless.png" class="mr-2" alt="Establish governance and methodologies for working together">
                                        </p>
                                        <p>
                                            Establish governance and methodologies for working together.
                                        </p>
                                    </div>
                                </div>
                                <div class="px-3 row">
                                    <div class="col-md-8 right_box py-4">
                                        <div class="d-flex mb-3 align-items-center">
                                            <div>
                                                <img src="../../../assets/images/digital-transformation/Are you looking for specific apps.png" alt="">
                                            </div>
                                            <div>
                                                <p class="font-weight-bold mb-0">
                                                    Are your struggling with converting your flash content?
                                                </p>
                                            </div>
                                        </div>

                                        <p class="hcm_link">
                                            <a href="contact-us">Contact us to learn about our quick Flash conversion process to save you time and money.</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Strategy & Planning.png" class="img-fluid" alt="Strategy & Planning">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Delivery Modalities.png" class="img-fluid" alt="Delivery Modalities">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Delivery Modalities</h4>
                                    <p>
                                        Explore the learning modalities that support your strategy and the needs of your learners. We conduct a work context analysis that enables you to find the right learning blend.
                                    </p>
                                    <p>
                                        We offer a complete range of learning techniques, from simple gamification to advanced AI-driven technologies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Gamification</h4>
                                    <p>
                                        Increase learning engagement, stimulate creative thinking, and build a sense of achievement with feedback and rewards.
                                    </p>
                                    <p class="orange_color">
                                        See how gamification boosts learning
                                    </p>
                                    <div class="sub_btn_style">
                                        <a href="services/hcm/learning/gamification">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Gamification.png" class="img-fluid" alt="Gamification">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Augmented Reality.png" class="img-fluid" alt="Augmented Reality">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Augmented Reality</h4>
                                    <p>
                                        Overlay the real world or VR with digital information to support rapid decision-making skills and provide real-time feedback.
                                    </p>
                                    <p class="text-white">
                                        Speed up learning with visual feedback
                                    </p>
                                    <div class="sub_btn_style1">
                                        <a href="services/hcm/learning/augmented-reality">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Virtual Reality</h4>
                                    <p>
                                        Create a lifelike, computer-generated work context.
                                    </p>
                                    <p class="orange_color">
                                        Remove the barriers between learning and doing
                                    </p>
                                    <div class="sub_btn_style">
                                        <a href="services/hcm/learning/virtual-reality">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Virtual Reality.png" class="img-fluid" alt="Virtual Reality">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Simulation.png" class="img-fluid" alt="Simulation">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Simulation</h4>
                                    <p>
                                        Enable your people to learn and practice skills in a risk-free environment.
                                    </p>
                                    <p class="text-white">
                                        Learn how simulation builds job skills.
                                    </p>
                                    <div class="sub_btn_style1">
                                        <a href="services/hcm/learning/simulation">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Sourcing</h4>
                                    <p>
                                        Sourcing content can reduce your costs and speed up your implementation. We can integrate content from any source, including remote content servers.
                                    </p>
                                    <p>
                                        We assist you in locating content that fits your business needs and the needs of your audience.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Sourcing.png" class="img-fluid" alt="Sourcing">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/UI and Design.png" class="img-fluid" alt="UI and Design">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">UI and Design</h4>
                                    <p>
                                        Instructional design is the starting point for a useful and engaging learner experience.
                                    </p>
                                    <p>
                                        We work with your SMEs to develop a design that meets your objectives and the needs of your audience. We base our learning design on the principle that its elements should adapt to the way people learn.
                                    </p>
                                    <p class="text-white">
                                        Learn more about Pixentia UI and design services.
                                    </p>
                                    <div class="sub_btn_style1">
                                        <a href="services/hcm/learning/user-experience-and-design">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Learning Development</h4>
                                    <p>
                                        Our experts use the authoring platform most appropriate for your learning needs and your L&D team. We use commonly available tools like Adobe Captivate, Articulate 360, and Adapt HTML5, or you may wish to use the tool in your LMS.
                                    </p>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/We design.png" class="mr-2" alt="We design for the learner experience">
                                        </p>
                                        <p>
                                            We design for the learner experience.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Intuitive.png" class="mr-2" alt="Intuitive, consistent, and transparent navigation">
                                        </p>
                                        <p>
                                            Intuitive, consistent, and transparent navigation.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Simple.png" class="mr-2" alt="Simple, clutter-free layout">
                                        </p>
                                        <p>
                                            Simple, clutter-free layout.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/High-quality Image.png" class="mr-2" alt="High-quality Images, icons, and artwork">
                                        </p>
                                        <p>
                                            High-quality Images, icons, and artwork.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Effortless.png" class="mr-2" alt="Effortless, easy-to-understand reading">
                                        </p>
                                        <p>
                                            Effortless, easy-to-understand reading.
                                        </p>
                                    </div>
                                    <p class="orange_color">
                                        Learn more about Pixentia e-learning development services.
                                    </p>
                                    <div class="sub_btn_style">
                                        <a href="services/hcm/learning/development">Learn More <i
                                                class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Learning Development.png" class="img-fluid" alt="Learning Development">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second_section">
                    <div class="template_width">
                        <div class="row text-white align-items-center my-5">
                            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Deployment and Testing.png" class="img-fluid" alt="Deployment and Testing">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold text-white">Deployment and Testing</h4>
                                    <p>
                                        We help you test your learning design, and recommend you have your end users test it. Their feedback can be invaluable. Our services include:
                                    </p>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Planning your testing.png" class="mr-2" alt="Planning your testing">
                                        </p>
                                        <p>
                                            Planning your testing.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Tracking and reporting tools.png" class="mr-2" alt="Tracking and reporting tools">
                                        </p>
                                        <p>
                                            Tracking and reporting tools.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../assets/images/hcm/learning/learning-content/Assistance with triage.png" class="mr-2" alt="Assistance with triage and issue management">
                                        </p>
                                        <p>
                                            Assistance with triage and issue management.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first_section">
                    <div class="template_width">
                        <div class="row my-5 align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold">Content Integration</h4>
                                    <p>
                                        Our integration experts can connect your learners to any source, including third-party providers or your internal resource repositories.
                                    </p>
                                    <p class="">
                                        We also recommend and help you deploy authentication methods that make the shift to external resources transparent to your learners.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 text-center mt-3 mt-md-0">
                                <div>
                                    <img src="../../assets/images/hcm/learning/learning-content/Content Integration.png" class="img-fluid" alt="Content Integration">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="resource">
                <div class="template_width">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4">
                                    Optimize Your Workforce Learning
                                </h4>
                            </div>
                            <p>
                                With over 22 years of experience, Pixentia’s learning technology and content experts provide end-to-end services to help you realize the value of your learning technology and content – from strategy to the individual learning experience.
                            </p>
                        </div>
                    </div>
                    <div class="row my-5 services_list">
                        <div class="col-md-12 sub_head text-center">
                            <h4 class="font-weight-bold mb-2">
                                Resource Centers
                            </h4>
                            <p>
                                Our resource centers provide direct help to administrators, learning professionals, and learning managers.
                            </p>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4 text-center border-right border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Admin Resource Center.png" alt="Admin Resource Center">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Admin Resource Center</p>
                                            <p>
                                                Assisting administrators in day-to-day operations and maintenance.
                                            </p>
                                            <div class="sub_btn_style mb-3 btn_height">
                                                <a href="services/hcm/learning/admin-resource">Learn More <i
                                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-right border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Analytics Resource Center.png" alt="Analytics Resource Center">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">
                                                Technology Resource Center
                                            </p>
                                            <p>
                                                Technical assistance for administrators for launching new programs, managing updates, scheduling, resource management, <span class="d-inline-block">and reporting.</span>
                                            </p>
                                            <div class="sub_btn_style mb-3">
                                                <a href="services/hcm/learning/technology">Learn More <i
                                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Content Resource Center.png" alt="Technology Resource Center">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Content Resource Center</p>
                                            <p>
                                                Content creation and curation services for all your online learning needs, including gamification, simulation, virtual reality (VR) and augmented reality (AR).
                                            </p>
                                            <div class="sub_btn_style mb-3">
                                                <a href="services/hcm/learning/content-resource-center">Learn More <i
                                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                                <div class="col-md-4 text-center border-right">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Analytics Resource Center.png" alt="Analytics Resource Center">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Analytics Resource Center</p>
                                            <p>
                                                Facilitates advanced reporting, dashboards and visualizations, advanced analytics, and data aggregation and storage.
                                            </p>
                                            <div class="sub_btn_style">
                                                <a href="services/hcm/learning/analytics">Learn More <i
                                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Managed Services Center.png" alt="Managed Services Center">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Managed Services Center</p>
                                            <p>
                                                Dedicated experts manage all or any part of your learning management, operations, and administration.
                                            </p>
                                            <div class="sub_btn_style">
                                                <a href="services/hcm/learning/managed-services">Learn More <i
                                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="promise_section text-white pt-5">
                    <div class="template_width">
                        <div class="row">
                            <div class="col-md-12 text-center pb-4">
                                <h4 class="font-weight-bold mb-2">
                                    Optimization
                                </h4>
                                <p>
                                    Our experts help you achieve the best possible efficiency, effectiveness, and impact of your learning management and administration activities.
                                </p>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/learning/optimization/Administration Optimization.png" alt="Administration Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-0">Administration Optimization</p>
                                        <p>
                                            We provide direct help to your administrators to remove glitches from your admin workflow.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/learning/optimization/Security Optimization.png" alt="Security Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-0">
                                            Security Optimization
                                        </p>
                                        <p>
                                            Security experts help you align your security configuration to achieve the best fit for your organization and the needs of your <span class="d-inline-block">learning audiences.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/learning/optimization/Business Process Optimization.png" alt="Business Process Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-0">Business Process Optimization</p>
                                        <p>
                                            We work with your team to analyze your business processes and workflow to remove bottlenecks and workarounds.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/learning/optimization/Learner Experience Optimization.png" alt="Learner Experience Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-0">Learner Experience Optimization</p>
                                        <p>
                                            Our learning experts work with your team to remove wasted effort and friction in the learner experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/learning/optimization/Analytics Optimization.png" alt="Analytics Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-0">Analytics Optimization</p>
                                        <p>
                                            No matter where you are in your learning analytics journey, our seasoned experts can help you move toward becoming a data-driven learning organization.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                </div>
                <div class="my-5">
                    <div class="template_width">
                        <div class="row align-items-center">
                            <div class="col-md-12 text-center">
                                <h5 class="font-weight-bold mb-3">Staffing and Augmentation Services</h5>
                                <div class="row text-center mb-4">
                                    <div class="col-md-4 offset-md-4">
                                        <img class="img-fluid" src="../../assets/images/hcm/learning/optimization/Staffing and Augmentation Services.png" alt="">
                                    </div>
                                </div>
                                <div>
                                    <p>
                                        Pixentia provides complete staffing services whether you need a little help or a team of experts. We pre-screen and evaluate candidates, assist you with selection, and provide technical onboarding to ensure a good fit and on-the-job performance.
                                    </p>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="py-2">
                                            <div class="mb-2">
                                                <img src="../../assets/images/hcm/learning/optimization/Seasoned experts to fill.png" alt="">
                                            </div>
                                            <div>
                                                <p>Seasoned experts to fill the <span class="d-inline-block">skill gaps
                                                        in your team.</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="py-2">
                                            <div class="mb-2">
                                                <img src="../../assets/images/hcm/learning/optimization/Project teams to.png" alt="Project teams to manage your entire initiative">
                                            </div>
                                            <div>
                                                <p>Project teams to manage your entire initiative.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="py-2">
                                            <div class="mb-2">
                                                <img src="../../assets/images/hcm/learning/optimization/Direct hire and.png" alt="Direct hire and contract-to-hire">
                                            </div>
                                            <div>
                                                <p>Direct hire and contract-to-hire <span class="d-inline-block">recruiting services.</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/staffing-and-augmentation-services">Learn
                                                More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>