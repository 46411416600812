<div class="signup_section">
  <div class="template_width">
    <div *ngIf="!isLoggedIn" class="row pad-null">
      <div *ngIf="isSignUpForm" class="col-md-12 col-sm-12 col-xs-12 text-md-right text-center" id="demoerror_data">
        <h6 class="header_margin_style">* Please fill in the form to access the portfolio</h6>
      </div>
      <div class="row col-md-12 px-0" id="signupcontent">
        <div id="demoimgcarousel" class="col-md-8 col-sm-12 carousel slide p-0" data-ride="carousel">
          <div class="carousel-inner">
            <!-- <div class="item"><img src="/Images/ELearning/Demos/module1/1.jpg" style="width:100%;"></div>
        <div class="item"><img src="/Images/ELearning/Demos/module1/2.jpg" style="width:100%;"></div>
        <div class="item"><img src="/Images/ELearning/Demos/module1/3.jpg" style="width:100%;"></div>
        <div class="item"><img src="/Images/ELearning/Demos/module1/4.jpg" style="width:100%;"></div>
        <div class="item active"><img src="/Images/ELearning/Demos/module1/last-scrren.jpg" style="width:100%;"></div>
      </div>
      <a class="left carousel-control" href="#demoimgcarousel" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#demoimgcarousel" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
      </a> -->
            <div class="mb-2">
              <a (click)="goBack()" class="orangetext" id="btnbacktxt" style="cursor: pointer;">
                <span class="wwacenter"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</span>
              </a>
            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==1)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/module1/1.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/module1/2.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/module1/3.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==2)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/module2/1.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/module2/2.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/module2/3.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/module2/4.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/module2/5.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/module2/6.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==3)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/brain training/1.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/brain training/2.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/brain training/3.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/brain training/4.png" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==4)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/drag and drop/1.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/drag and drop/2.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/drag and drop/3.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/drag and drop/4.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/drag and drop/5.png" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==5)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/black and white/1.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/black and white/2.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/black and white/3.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/black and white/4.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/black and white/5.png" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==6)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/Workplace/Workplace_01.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/Workplace/Workplace_02.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/Workplace/Workplace_03.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/Workplace/Workplace_04.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/Workplace/Workplace_05.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==7)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/Cisco/01.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/Cisco/2.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/Cisco/03.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==8)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/RACER/01.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/RACER/02.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/RACER/03.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/RACER/04.jpg" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==9)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/brain training/1.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/brain training/2.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/brain training/3.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/brain training/4.png" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==10)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/drag and drop/1.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/drag and drop/2.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/drag and drop/3.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/drag and drop/4.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/drag and drop/5.png" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>
            <div id="demo1" class="carousel slide" data-ride="carousel" *ngIf="(demoDetails.demoNumber==11)">

              <!-- The slideshow -->
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../../assets/images/learning/demos/black and white/1.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/black and white/2.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/black and white/3.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/black and white/4.png" class="img-fluid"
                    style="width:100%;">
                </div>
                <div class="carousel-item">
                  <img src="../../../../assets/images/learning/demos/black and white/5.png" class="img-fluid"
                    style="width:100%;">
                </div>
              </div>

              <!-- Left and right controls -->
              <a class="carousel-control-prev" href="#demo1" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a class="carousel-control-next" href="#demo1" data-slide="next">
                <span class="carousel-control-next-icon"></span>
              </a>

            </div>

            <!-- <video controls style="border: 7px solid #d1d1d1; background-repeat: no-repeat;
                             background-size: cover; height: 470px;width: 100%;">
          <source src={{demoDetails.demoVedioSource}} type="video/mp4">
        </video> -->
          </div>
        </div>
        <div *ngIf="isSignUpForm" class="col-md-4 col-sm-12 demofrm">
          <form class="form-horizontal signupdemo col-md-12 col-sm-12" id="formsignup" [formGroup]="signUpForm">
            <div class="col-md-12 col-sm-12 pb-2 text-center padbot2">
              <h3 class="formTitle" style="margin-bottom: 0px;">Access Portfolio</h3>
              <span *ngIf="isSignUpSuccess" class="custom_field_height" style="color:#e45454">
                Thanks for registering. We will soon send a mail with credentials.
              </span>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 row form-group custom_field_height p-0">
              <label class="col-md-3 col-sm-4 col-4 control-label padright_null p-0">First Name&nbsp;
                <span style="color:red">*</span>
              </label>
              <div class="col-md-9 col-sm-8 col-8">
                <mat-form-field class="width-100" appearance="outline">
                  <input matInput formControlName="fName" required placeholder="First Name" />
                  <mat-error style="color:#e45454;font-size:12px;display: flex;" *ngIf="signUpForm.get('fName').hasError('required') &&
                (signUpForm.get('fName').dirty || signUpForm.get('fName').touched)">
                    FirstName is required
                  </mat-error>
                </mat-form-field>

                <!-- <input type="text" class="form-control" id="demofname">
            <span id="errdemofname" style="color:#e45454;font-size:13px;"></span> -->
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 row form-group custom_field_height p-0">
              <label class="col-md-3 col-sm-4 col-4 control-label padright_null p-0">Last Name&nbsp;
                <span style="color:red">*</span>
              </label>
              <div class="col-md-9 col-sm-8 col-8">
                <mat-form-field class="width-100" appearance="outline">
                  <input matInput formControlName="lName" required placeholder="Last Name" />
                  <mat-error style="color:#e45454;font-size:12px;display: flex;" *ngIf="signUpForm.get('lName').hasError('required') &&
                (signUpForm.get('lName').dirty || signUpForm.get('lName').touched)">
                    LastName is required
                  </mat-error>
                </mat-form-field>

                <!-- <input type="text" class="form-control" id="demolname">
            <span id="errdemolname" style="color:#e45454;font-size:13px;"></span> -->
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 row form-group custom_field_height p-0">
              <label class="col-md-3 col-sm-4 col-4 control-label padright_null p-0">Company&nbsp;<span
                  style="color:red">*</span></label>
              <div class="col-md-9 col-sm-8 col-8">
                <mat-form-field class="width-100" appearance="outline">
                  <input matInput formControlName="company" required placeholder="Company" />
                  <mat-error style="color:#e45454;font-size:12px;display: flex;" *ngIf="signUpForm.get('company').hasError('required') &&
                (signUpForm.get('company').dirty || signUpForm.get('company').touched)">
                    Company is required
                  </mat-error>
                </mat-form-field>

                <!-- <input type="text" class="form-control" id="democompany">
            <span id="errdemocompany" style="color:#e45454;font-size:13px;"></span> -->
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 row form-group custom_field_height p-0">
              <label class="col-md-3 col-sm-4 col-4 control-label padright_null p-0">Job Title&nbsp;<span
                  style="color:red">*</span></label>
              <div class="col-md-9 col-sm-8 col-8">
                <mat-form-field class="width-100" appearance="outline">
                  <input matInput formControlName="jobTitle" required placeholder="Job Title" />
                  <mat-error style="color:#e45454;font-size:12px;display: flex;" *ngIf="signUpForm.get('jobTitle').hasError('required') &&
                (signUpForm.get('jobTitle').dirty || signUpForm.get('jobTitle').touched)">
                    Job Title is required
                  </mat-error>
                </mat-form-field>

                <!-- <input type="text" class="form-control" id="demojobtitle">
            <span id="errdemojobtitle" style="color:#e45454;font-size:13px;"></span> -->
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 row form-group custom_field_height p-0">
              <label class="col-md-3 col-sm-4 col-4 control-label padright_null p-0">Email&nbsp;<span
                  style="color:red">*</span></label>
              <div class="col-md-9 col-sm-8 col-8">
                <mat-form-field class="width-100" appearance="outline">
                  <input matInput formControlName="email" required placeholder="Email" />
                  <mat-error style="color:#e45454;font-size:12px;display: flex;" *ngIf="signUpForm.get('email').hasError('required') &&
                (signUpForm.get('email').dirty || signUpForm.get('email').touched)">
                    Email is required
                  </mat-error>
                  <mat-error style="color:#e45454;font-size:12px;display: flex;"
                    *ngIf="signUpForm.get('email').hasError('pattern')">
                    Please provide a valid email
                  </mat-error>
                  <mat-error style="color:#e45454;font-size:12px;display: flex;"
                    *ngIf="signUpForm.get('email').hasError('isValidMailFormat')">
                    Please enter your business email
                  </mat-error>
                </mat-form-field>

                <!-- <input type="email" class="form-control" id="demoemail">
            <span id="errdemoemail" style="color:#e45454;font-size:13px;"></span> -->
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 row form-group custom_field_height p-0">
              <label class="col-md-3 col-sm-4 col-4 control-label padright_null p-0">Contact&nbsp;<span
                  style="color:red">*</span></label>
              <div class="col-md-9 col-sm-8 col-8">
                <mat-form-field class="width-100" appearance="outline">
                  <input matInput formControlName="contact" required placeholder="Contact" />
                  <mat-error style="color:#e45454;font-size:12px;display: flex;" *ngIf="signUpForm.get('contact').hasError('required') &&
                (signUpForm.get('contact').dirty || signUpForm.get('contact').touched)">
                    Contact is required
                  </mat-error>
                </mat-form-field>

                <!-- <input type="text" class="form-control" id="democontact">
            <span id="errdemocontact" style="color:#e45454;font-size:13px;"></span> -->
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12 padbot4 text-center">
                <button type="button" [disabled]="!signUpForm.valid || isSignUpButtonVisible"
                  class="col-md-12 col-sm-12 col-xs-12 btn btn-default" id="btnsignup" (click)="userSignUp(f)">
                  <span>Sign Up</span>
                </button>
                <span>or</span>
                <button type="button" (click)="loginClicked()" class="col-md-12 col-sm-12 col-xs-12 btn btn-default"
                  id="btnlogin">
                  <span>Login</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="!isSignUpForm" class="col-md-4 col-sm-5 col-xs-12 demofrm">
          <form class="form-horizontal signupdemo col-md-12 col-sm-12 col-xs-12" id="formlogin" [formGroup]="loginForm">
            <div class="col-md-12 text-center">
              <h3 class="formTitle">Access Portfolio</h3><br>
              <span *ngIf="errorMessage" class="custom_field_height" id="notifymsg" style="color:#e45454">
                {{errorMessage}}
              </span>
            </div>
            <div class="form-group loginfields_customheight">
              <div class="col-md-12 col-sm-12 col-xs-12 marbot10">
                <label for="Email" class="control-label padright_null"
                  style="padding-left: 3px; justify-content: flex-start;">Email&nbsp;
                  <span style="color:red">*</span>
                </label>
                <div class="col-md-12 col-sm-12 col-xs-12 px-0">
                  <mat-form-field class="width-100" appearance="outline">
                    <input matInput formControlName="email" required placeholder="Email" />
                    <mat-error style="color:#e45454;font-size:12px;display: flex;" *ngIf="loginForm.get('email').hasError('required') &&
                (loginForm.get('email').dirty || loginForm.get('email').touched)">
                      Email is required
                    </mat-error>
                    <mat-error style="color:#e45454;font-size:12px;display: flex;"
                      *ngIf="loginForm.get('email').hasError('pattern')">
                      Please provide a valid email
                    </mat-error>
                    <mat-error style="color:#e45454;font-size:12px;display: flex;"
                      *ngIf="loginForm.get('email').hasError('isValidMailFormat')">
                      Please enter your business email
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- <input type="email" class="form-control" id="demoemaild" required="">
            <span id="erremail" style="color:#e45454;font-size:13px;"></span> -->
              </div>
            </div>
            <div class="form-group loginfields_customheight">
              <div class="col-md-12 col-sm-12 col-xs-12 marbot15">
                <label for="Email" class="control-label padright_null"
                  style="padding-left: 3px; justify-content: flex-start;">Password&nbsp;
                  <span style="color:red">*</span>
                </label>
                <div class="col-md-12 col-sm-12 col-xs-12 px-0">
                  <mat-form-field class="width-100" appearance="outline">
                    <input type="password" matInput formControlName="password" required placeholder="Password" />
                    <mat-error style="color:#e45454;font-size:12px;display: flex;" *ngIf="loginForm.get('password').hasError('required') &&
                (loginForm.get('password').dirty || loginForm.get('password').touched)">
                      Password is required
                    </mat-error>
                    <mat-error style="color:#e45454;font-size:12px;display: flex;"
                      *ngIf="loginForm.get('password').errors?.maxlength">
                      Password should contain 8 characters
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- <input type="password" class="form-control" id="demopasswordd" required="">
            <span id="errpswd" style="color:#e45454;font-size:13px;"></span> -->
              </div>
            </div>
            <div class="form-group">
              <div class="col-md-12 col-sm-12 col-xs-12 padbot4 text-center">
                <button type="button" (click)="userLogin(f)" [disabled]="!loginForm.valid || isLoginButtonVisible"
                  class="col-md-12 col-sm-12 col-xs-12 mb-2 btn btn-default" id="btnlogind">
                  <span>Login</span>
                </button>
                <span><a style="cursor: pointer;" (click)="forgotPassword()">Forgot password ?</a></span><br>
                <span>or</span>
                <button type="button" (click)="signUpClicked()" class="col-md-12 col-sm-12 col-xs-12 btn btn-default"
                  id="btnsignupd"><span>Sign Up</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br>
      <div class="col-md-8 col-sm-12 col-xs-12 text-right pad-null padright3" id="demoback">

      </div>
    </div>
  </div>
  <div *ngIf="isLoggedIn" class="row template_width">
    <div class="mb-1 col-md-12 col-sm-12 col-xs-12 cleard row pad-null" id="demoerror_dataC">
      <div class="col-md-10 col-sm-10 col-xs-10 text-center">
        <h5>Thank you for accessing our E-learning portfolio</h5>
      </div>
      <div (click)="logout()" class="col-md-2 col-sm-2 col-sm-2 logout">
        <i class="fa fa-sign-out fa-lg" aria-hidden="true"></i>
        <span class="ml-1">Logout</span>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 cleard row text-center" id="coursehead">
      <ul class="d-flex col-md-12 list-inline align-items-center justify-content-center"
        style="margin-bottom: 15px; margin-top: 15px;">
        <li class="col-md-3 border_right_none">
          <h5 class="orangetext p-0 m-0" style="font-size: 15px;">GET IN TOUCH WITH US:</h5>
        </li>
        <li class="col-md-3 border_right_none">
          <i class="fa fa-envelope color_black" style="font-size:15px;"></i>
          <a class="ml-1" href="mailto:sales@pixentia.com">
            <span class="avenir_SourceSansPro border_right_header color_black"
              style="font-size: 15px;">sales@pixentia.com
            </span>
          </a>
        </li>
        <li class="col-md-3 border_right_none">
          <i class="fa fa-phone fa-lg color_black" style="font-size: 15px;"></i>
          <span class="ml-1 border_right_header color_black" style="font-size: 15px;">+1 903-306-2430, +1
            855-978-6816</span>
        </li>
        <li class="col-md-3 border_right_none">
          <i class="fa fa-globe fa-lg color_black" style="font-size: 15px;" aria-hidden="true"></i>
          <a class="ml-1" href="http://pixentia.com">
            <span class="avenir_SourceSansPro color_black" style="font-size: 15px;">www.pixentia.com</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 row pad-null" id="coursevideo">
      
    <iframe *ngIf="demoDetails.demoVedioLink" class="col-md-12 pad-null" [src]="demoLink" height="100%" width="100%">
    </iframe>
      <!-- <video class="col-md-12 coursemainvideo pad-null" controls height="100%" width="=&quot;100%&quot;"
        style="border: 7px solid #d1d1d1; background-repeat: no-repeat; background-size: cover;">
        <source src={{demoDetails.demoVedioLink}}>
      </video> -->
    </div>
    <div class="col-md-12 col-xs-12 mb-1 text-right padtop2" id="demoback">
      <a (click)="goBack()" class="orangetext" id="btnbacktxt">
        <span class="backbtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
        <span class="wwacenter" style="cursor: pointer;">Back</span>
      </a>
    </div>
  </div>

  <div class="col-md-12 col-sm-12 col-xs-12 row cleard template_width mt-4" id="videoinnerdesc">
    <div class="col-md-12 pad-null martop">
      <h2 class="text_upper orangetext martop text-left padtop40">{{demoDetails.demoTitle}}</h2>
      <p class="avenir_SourceSansPro fontsize3 line-height3"><span class="text-left pad-null">
          {{demoDetails.demoDescription}}
        </span></p>
    </div>
  </div>

  <div *ngIf="loginDemoNumber && !isFirstLogin">
    <app-demosmain [loginDemoNumber]="loginDemoNumber"></app-demosmain>
  </div>
</div>