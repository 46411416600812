<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/payroll">Payroll</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">People-Centered Payroll Implementation, Optimization, and Support</h1>
                <p>Select and implement the payroll platform and processes that support your talent strategy and culture
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/hcm/payroll/Payroll Image.png" alt="Payroll Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h3 class="text-center mb-5 font-weight-bold">
                    Accurate, Easy to Use Payroll Systems
                </h3>
            </div>
            <div class="col-md-5">
                <img src="../../assets/images/hcm/talent/Boosting recruting.png" class="img-fluid" style="width: 100%;" alt="">
            </div>
            <div class="col-md-7">
                <p>
                    A good payroll system does more than print paychecks and W-2 forms – it should be easy to use, integrate with your organization’s compensation, benefits, and accounting systems, and provide a wide range of customized reports. The consultants at Pixentia’s
                    HCM Center of Excellence can help you make the right choice about a new payroll platform and/or needed upgrades and changes.
                </p>
            </div>
        </div>
    </div>
    <div class="service_section mb-5">
        <div class="template_width">
            <div class="row">
                <div class="col-md-12 text-center sub_head">
                    <h5 class="font-weight-bold py-4 mb-0">OUR SERVICES INCLUDE</h5>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Payroll Platform.png" alt="Payroll Platform Selection" class="mb-3">
                        <p class="px-4">
                            Payroll Platform Selection
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Enterprise.png" alt="Enterprise & HR Systems Integration" class="mb-3">
                        <p class="px-4">
                            Enterprise & HR <span class="d-inline-block">Systems Integration</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Platform.png" alt="Platform Implementation & Training" class="mb-3">
                        <p class="px-4">
                            Platform Implementation <span class="d-inline-block">and Training</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Compliance.png" alt="Compliance Reporting" class="mb-3">
                        <p class="px-4">
                            Compliance Reporting
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Legacy Data.png" alt="Legacy Data & Process Migration" class="mb-3">
                        <p class="px-4">
                            Legacy Data & Process Migration
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Project Justification.png" alt="Project Justification & ROI Valuation" class="mb-3">
                        <p class="px-4">
                            Project Justification <span class="d-inline-block">& ROI Valuation</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Custom Dashboards.png" alt="Custom Dashboards, Reports and Analytics" class="mb-3">
                        <p class="px-4">
                            Custom Dashboards, Reports and Analytics
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Platform application.png" alt="Platform/Application Maintenance" class="mb-3">
                        <p class="px-4">
                            Platform/Application Maintenance
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Discovery and Planning</h4>
                    <p>
                        Pixentia’s discovery and planning process undertakes an in-depth look at your organization’s payroll plans and produces recommendations that are form-fitted to your business needs. Our work includes:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Payroll platform selection.png" alt="Payroll platform selection" class="mb-3">
                        <p class="px-4">
                            Payroll platform selection, encompassing RFP planning, vendor evaluation, and tools to help you chose the right platform <span class="d-inline-block">for your organization.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Comprehensive.png" alt="Comprehensive project planning" class="mb-3">
                        <p class="px-4">
                            Comprehensive project planning that mobilizes the people, resources, and third-party vendors involved in your project.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Business cases.png" alt="Business cases, project justifications" class="mb-3">
                        <p class="px-4">
                            Business cases, project justifications and ROI valuations that demonstrate how your payroll initiative will <span class="d-inline-block">improve operating results.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Data mapping.png" alt="Data mapping and cleansing" class="mb-3">
                        <p class="px-4">
                            Data mapping and cleansing that can inform project plans and assure smooth execution of payroll system <span class="d-inline-block">upgrades and conversions.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="community_section my-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">Project Implementation</h5>
                    <p>
                        The transition between an old and new payroll system demands flawless execution, and Pixentia’s project implementation teams are structured to assure project plans are delivered seamlessly, on-time, and on-budget. Our teams provide:
                    </p>
                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/payroll/Workshops.png" class="mr-2" alt="Workshops">
                        </p>
                        <p>
                            Workshops that build detailed conversion plans, map and align business processes, and establish optimized platform configurations.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/payroll/Handson.png" class="mr-2" alt="Hands-on support">
                        </p>
                        <p>
                            Hands-on support that can integrate new platforms and applications with existing enterprise HR, finance and payroll systems.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/payroll/Developers.png" class="mr-2" alt="legacy systems">
                        </p>
                        <p>
                            Developers who can migrate data from legacy systems, manage third-party service providers, and undertake detailed QA and acceptance testing.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/payroll/Reporting.png" class="mr-2" alt="Reporting applications">
                        </p>
                        <p>
                            Reporting applications to support external compliance requirements.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/payroll/Specialists.png" class="mr-2" alt="data warehouses and dashboards">
                        </p>
                        <p>
                            Specialists who can build data warehouses and dashboards that enable real-time unit and organization reporting as well as comprehensive, analytics-based reports.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/hcm/payroll/Project Implementation.png" alt="Project Implementation" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Operations Support</h4>
                    <p class="mx-md-5">
                        Pixentia’s operations support services can keep your payroll management platform and applications running at maximum efficiency without tying-up in-house IT staff. We undertake:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Daily Operations.png" alt="Daily operations support" class="mb-3">
                        <p class="px-4">
                            Daily operations support, including new report development, platform maintenance, and test/validation of new <span class="d-inline-block">upgrades and releases.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Updates that.png" alt="platform utilization" class="mb-3">
                        <p class="px-4">
                            Updates that optimize platform utilization, improve user experience and support best practices.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Training and communications.png" alt="Training and communications support" class="mb-3">
                        <p class="px-4">
                            Training and communications support to build employee <span class="d-inline-block">utilization and proficiency.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/payroll/Metrics tracking.png" alt="Metrics tracking" class="mb-3">
                        <p class="px-4">
                            Metrics tracking and reporting that enable ongoing evaluation of payroll systems projects and their financial return.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-5">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-10 offset-md-1">
                    <div class="row align-items-center">
                        <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                            <p class="sub_heading_cta mb-4">Learn how you can best choose, implement and optimize your Payroll Technology.
                            </p>
                            <div class="more_info_btn datasheet_btn">
                                <a href="https://resources.pixentia.com/hcm/datasheet/pixentia-payroll-consulting" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="col-md-5 mt-5 mt-md-0">
                            <div>
                                <img src="../../../../assets/images/cta_images/PayrollCTA.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>