<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe">HCM COE</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Human Capital Management Center of Excellence </h3>
                <p>Advance your ability to deliver a people-first digital experience
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/hcm/hcm-coe/hcm-image.png" alt="HCM Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-1 font-weight-bold">
                    Why Is Pixentia Is Your Ideal HR Technology Partner?
                </h1>
                <p class="text-center">It’s our relentless focus on your success.</p>
            </div>
            <div class="col-md-4">
                <img src="../../assets/images/hcm/hcm-coe/Why Is Pixentia Is Your Ideal.png" class="img-fluid" style="width: 100%;" alt="Why Is Pixentia Is Your Ideal">
            </div>
            <div class="col-md-8">
                <p>
                    In today’s rapidly evolving business environment, HR requires a fresh way of thinking about change.
                </p>
                <p>
                    Out-of-the-box Agile software methods will not work well for HR services because workforce change is behavioral change, and that takes time. It also involves a change in practices, policies, and procedures—and those changes require time for people to
                    adapt.
                </p>
                <p>
                    At Pixentia, we believe that the ability to adapt to fast-changing business requires people to become the creators of change. Every project should start and end with people.
                </p>
            </div>
        </div>
    </div>
    <div class="services_section">
        <div class="template_width">
            <div class="row mb-5">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified">
                        <li class="nav-item text-center">
                            <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Optimization
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#resource">
                                <img src="../../assets/images/hcm/talent/Resource Centers.png" class="d-block mx-auto" alt=""> Resource Centers
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <!-- Tab panes -->
                <div class="tab-content my-5">
                    <div class="tab-pane active" id="strategy">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        A Strategic Approach to Human Capital Technology Planning
                                    </h4>
                                </div>
                                <p>
                                    Our Center of Excellence leads you through a step-by-step strategy and planning approach to building your plan for supporting and developing your workforce. We enable you to undertake your improvements with the knowledge and confidence you will succeed.
                                    Among the services we offer are:
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-12 text-center sub_head">
                                <h4 class="font-weight-bold mb-4">
                                    Among the services we offer are:
                                </h4>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Strategy/Structured workshops.png" alt="Structured workshops">
                                    </div>
                                    <div>
                                        <p>
                                            Structured workshops to map your current technology and your vision so you can create a solid foundation for your strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Strategy/Tools that help you.png" alt="Tools that help you identify">
                                    </div>
                                    <div>
                                        <p>
                                            Tools that help you identify and select the right technology execute your strategy, plus RFP support to ensure you get the optimum result.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Strategy/Business case development.png" alt="Business case development tools">
                                    </div>
                                    <div>
                                        <p>
                                            Business case development tools that enable you to engage your stakeholders in supporting your efforts.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Strategy/A flexible.png" alt="A flexible, long-term technology">
                                    </div>
                                    <div>
                                        <p>
                                            A flexible, long-term technology framework that grows with you as your needs change.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Strategy/A governance framework.png" alt="A governance framework consulting">
                                    </div>
                                    <div>
                                        <p>
                                            A governance framework consulting to improve decision-making, both for your project and your enterprise human capital strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Strategy/Change management tools.png" alt="Change management">
                                    </div>
                                    <div>
                                        <p>
                                            Change management tools to encourage and enable people in your organization to become agents of change.
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/change-management">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Strategy/Metrics and analytics.png" alt="Metrics and analytics">
                                    </div>
                                    <div>
                                        <p>
                                            Metrics and analytics to report on progress toward your goals and measure your <span class="d-inline-block">impact on your business.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="mt-4">
                                    <div class="text-center cta_bg mx-md-2 py-3">
                                        <h5 class="font-weight-bold mb-3">A Framework for Cross-Functional Collaboration
                                        </h5>
                                        <p>
                                            Does the multiple challenge of HR governance, analytics, and artificial intelligence see overwhelming?

                                        </p>
                                        <p>
                                            Learn how collaborative governance can enable you to manage your transformation.
                                        </p>
                                        <p class="datasheet_btn mb-0">
                                            <a href="https://resources.pixentia.com/a-framework-for-agile-hcm-governance" rel="noopener noreferrer" target="_blank">Get your ebook</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="deployment">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Preparation and Deployment: A Structured, Scalable Approach
                                    </h4>
                                </div>
                                <p>
                                    Preparation, accountability, communication, and control are the foundations of a successful deployment.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-12 text-center sub_head">
                                <h4 class="font-weight-bold mb-4">
                                    Our teams provide guidance, expertise, and methods to keep your projects on schedule:
                                </h4>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Preparation/User Acceptance.png" alt="Project Planning">
                                    </div>
                                    <div>
                                        <p>
                                            A practical, in-depth roadmap to successful platform or application implementation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Preparation/Data cleansing and.png" alt="Data Cleansing">
                                    </div>
                                    <div>
                                        <p>
                                            Data cleansing and migration to <span class="d-inline-block">ensure reliable data.</span>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Preparation/Agile processes.png" alt="Agile processes">
                                    </div>
                                    <div>
                                        <p>
                                            Agile processes involve you in the design.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Preparation/Structured workshops.png" alt="Structured workshops">
                                    </div>
                                    <div>
                                        <p>
                                            Structured workshops to give process owners the skills to manage structures and workflows.
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/workshops">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe//Preparation/User Acceptance.png" alt="User Acceptance Testing">
                                    </div>
                                    <div>
                                        <p>
                                            User Acceptance Testing support with triage and issue management.
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/testing">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Preparation/Analytics and reporting.png" alt="Analytics and reporting implementation">
                                    </div>
                                    <div>
                                        <p>
                                            Analytics and reporting implementation delivers real-time business intelligence.
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/analytics-deployment">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-left border-right">
                                <div class="mt-5">
                                    <div class="text-center cta_bg mx-md-2 py-3">
                                        <h5 class="font-weight-bold mb-3">Pixentia Managed HCM Services Center
                                        </h5>
                                        <p>
                                            Is managing HR Shared Services negatively affecting your business?
                                        </p>
                                        <p>
                                            Don't focus on your problems--focus on your customers.
                                        </p>
                                        <p>
                                            Let Pixentia manage your HR support services for you.
                                        </p>
                                        <p class="datasheet_btn mb-0">
                                            <a href="contact-us" rel="noopener noreferrer" target="_blank">Ask for a quote</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Preparation/Integrations to connect.png" alt="Integrations to connect core systems">
                                    </div>
                                    <div>
                                        <p>
                                            Integrations to connect core systems to maintain accurate records, with optional integrations for business intelligence, e-commerce, <span class="d-inline-block">and extended enterprise.</span>
                                        </p>
                                    </div>
                                    <div class="text-center">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/integration">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="optimization">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        HR Services Optimization
                                    </h4>
                                </div>
                                <p>
                                    As your needs change, we enable your technology to grow with you, so you can grow with your technology.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Optimization/Direct help for.png" alt="Administration Optimization">
                                    </div>
                                    <div>
                                        <p>
                                            Direct help for your administrators to remove inefficiencies from your admin workflow.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Optimization/Align your security.png" alt="Security Optimization">
                                    </div>
                                    <div>
                                        <p>
                                            Align your security configuration to achieve the best fit for your organization and the <span class="d-inline-block">needs of your organization.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Optimization/Analyze your business.png" alt="Business Process Optimization">
                                    </div>
                                    <div>
                                        <p>
                                            Analyze your business processes and workflow to remove bottlenecks and workarounds.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Optimization/Create a user-centered.png" alt="User Experience Optimization">
                                    </div>
                                    <div>
                                        <p>
                                            Create a user-centered experience that will engage and delight your workforce.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Optimization/Develop insights.png" alt="Analytics Optimization">
                                    </div>
                                    <div>
                                        <p>
                                            Develop insights about people and work to help you become a data-driven people organization.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Optimization/Identify the methodologies.png" alt="Identify the methodologies">
                                    </div>
                                    <div>
                                        <p>
                                            Identify the methodologies, delivery modes, and technology that achieve your learning objectives—within your business and budget constraints.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="resource">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        HR Resource Centers
                                    </h4>
                                </div>
                                <p>
                                    Keeping your platform updated and current with your organization’s needs requires ongoing, hands-on support.
                                </p>
                                <p>
                                    Let Pixentia’s specialists support your administrators, serve as your support backup, or provide comprehensive managed services.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Admin Resource Center.png" alt="Admin Resource Center">
                                    </div>
                                    <div>
                                        <p>
                                            Admin Resource Center assists administrators in day-to-day operations and maintenance.
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/admin-resource">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Analytics Resource Center.png" alt="Analytics Resource Center">
                                    </div>
                                    <div>
                                        <p>
                                            Analytics Resource Center supports your operational reporting, dashboards and visualizations, advanced analytics, and data aggregation and storage.
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/analytics">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Technology Resource Center.png" alt="Technology Resource Center">
                                    </div>
                                    <div>
                                        <p>
                                            Technology Resource Center provides technical help for administrators for managing updates, scheduling, resource management, and reporting.
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/technology">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Content Resource Center.png" alt="Content Resource Center">
                                    </div>
                                    <div>
                                        <p>
                                            Content Resource Center supports new initiatives or refreshing long-standing programs with content creation and curation services.
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/content-resource-center">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Managed Services Center.png" alt="Managed Services Center">
                                    </div>
                                    <div>
                                        <p>
                                            Managed Services Center provides dedicated experts to handle all or any part of <span class="d-inline-block">your administration.</span>
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/managed-services">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Staffing and Augmentation.png" alt="Staffing and Augmentation">
                                    </div>
                                    <div>
                                        <p>
                                            Staffing and Augmentation deliver comprehensive services for direct-hire, contract-to-hire, and staff augmentation from our <span class="d-inline-block">dynamic pool of problem-solvers.</span>
                                        </p>
                                    </div>
                                    <div class="text-center mb-2">
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/hcm-coe/staffing-and-augmentation-services">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>