<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe">HCM COE</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe/analytics">Analytics Resource Center</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Build a Data-Driven Organization
                </h3>
                <p>If you are struggling with mastery of your reporting tools, Pixentia’s Analytics Center can help you achieve your reporting goals and more. We take you beyond operational reporting to advanced predictive and prescriptive analytics that
                    drive faster and better decisions at every level of your organization.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/hcm-coe/analytics-resource/Analytics Resource Center.png" alt="Analytics Resource Center image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Achieve Reporting Mastery</h1>
                </div>
                <div>
                    <p class="mb-3">
                        Your vendor provides tools for building your reporting library, but many organizations find they don’t have the resources to get it done. Our reporting team assists your day-to-day reporting and helps you build a report library that will drive excellence
                        in your organization.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-6 border-bottom border-right py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Resolve user issues related.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Resolve user issues related to accessing reports and dashboards.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-bottom py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Liaison with your vendor support team.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Liaison with your vendor support team on reporting system issues.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 border-right py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Schedule and distribute.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Schedule and distribute reports and visualizations; Create and customize Ad Hoc Views, including custom and calculated fields.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 py-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Manage reports governance.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p class="font-weight mb-2">
                        Manage reports governance, such as naming conventions and catalog structure.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Analytics Consulting and Technical Services
                </h4>
                <div class="mt-4">
                    <p>
                        When you need to go beyond reporting to using your data to solve business problems, our team will provide consulting services to help you achieve your objective.
                    </p>
                </div>
                <div class="row my-5">
                    <div class="col-md-4 border-right py-5">
                        <div class="text-center">
                            <div class="mb-3">
                                <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Identify trends and anomalies in your data.png" alt="Identify trends and anomalies in your data">
                            </div>
                            <div class="px-5">
                                <p class="font-weight mb-2">
                                    Identify trends and anomalies in your data.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 border-right py-5">
                        <div class="text-center">
                            <div class="mb-3">
                                <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Support executive.png" alt="Develop and deliver manager dashboards">
                            </div>
                            <div class="px-5">
                                <p class="font-weight mb-2">
                                    Support executive decisions with intuitive, informative dashboards.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 py-5">
                        <div class="text-center">
                            <div class="mb-3">
                                <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Create learner dashboards to help your people manage their development.png" alt="Create learner dashboards to help your people manage their development">
                            </div>
                            <div class="px-5">
                                <p class="font-weight mb-2">
                                    Create and deliver dashboards to provide actionable visualizations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-4">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-12 text-center sub_head">
                <h4 class="font-weight-bold pt-4 mb-3">Predictive Analytics for Decision Support</h4>
                <p>
                    Our data scientists use predictive modeling to give you new insights into your data to guide your decisions and initiatives.
                </p>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/hcm-coe/analytics-resource/Provide a guided.png" alt="Provide a guided workshop" class="mb-3">
                    <p class="px-4">
                        Provide a guided workshop to zero in on a business need and determine what information you need to assess the issue.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/hcm-coe/analytics-resource/Create comprehensive.png" alt="Create comprehensive data models to predict outcomes" class="mb-3">
                    <p class="px-4">
                        Create comprehensive data models to predict outcomes.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/hcm-coe/analytics-resource/Provide data-driven.png" alt="Provide data-driven decision support" class="mb-3">
                    <p class="px-4">
                        Provide data-driven decision support to help you determine the most viable solution.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section resource_guides_section">
    <div class="template_width">
        <div class="row text-white align-items-center">
            <div class="col-md-6">
                <h4 class="font-weight-bold pt-4 mb-3">Optimize Your Workforce Strategy
                </h4>
                <p>
                    Developing a workforce strategy requires that you analyze the value of multiple options, working within your staffing and funding constraints. Our data scientists will help you assess the cost and impact of various options using advanced predictive models.
                </p>
            </div>
            <div class="col-md-6">
                <img src="../../../../../assets/images/hcm/hcm-coe/analytics-resource/Optimize Your Learning Strategy.png" class="img-fluid" alt="">
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-12 text-center">
            <div class="sub_head pb-4">
                <h4 class="font-weight-bold">Data Aggregation and Storage</h4>
                <p>We provide complete services for storing and accessing your data.</p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Sourcing and integratin.png" alt="Sourcing and integrating data sources">
                </div>
                <div class="px-5">
                    <p>
                        Sourcing and integrating data sources.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Warehousing of both.png" alt="Warehousing of both structured and unstructured data">
                </div>
                <div class="px-5">
                    <p>
                        Warehousing of both structured and unstructured data.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/analytics-resource/Data management.png" alt="Data management and governance">
                </div>
                <div class="px-5">
                    <p>
                        Data management and governance.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn more about how Pixentia SumTotal Analytics Center can help you achieve your reporting goals
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/hcm-coe"> Back to Resource Center <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>