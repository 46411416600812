<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/learning/learning-coe">Learning COE</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Learning Solutions that Deliver High Results</h3>
                <p>Multi-level support, from custom training content, to learning strategy planning, to course delivery across multiple device platforms.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/learning/learning-coe/learning-coe-image.png" alt="Learning COE Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.kubota.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/kuboto.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="http://www.intelsecurity.com" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/mcafee_logo.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.pheaa.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/pheaa.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.linde.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/linde.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.tmhcc.com/en/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/tokia-marine.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-4 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-5 font-weight-bold">
                    Superior E-Learning Solutions that Will Energize Your Workforce
                </h1>
            </div>
            <div class="col-md-10 offset-md-1 text-center">
                <p class="mb-0">
                    Pixentia’s deep understanding of your organization and its learning goals enables us to create superior, cost-effective solutions that measurably increase workforce knowledge and productivity.
                </p>
            </div>
        </div>
    </div>
    <div class="service_section mb-5">
        <div class="template_width">
            <div class="row">
                <div class="col-md-12 text-center sub_head">
                    <h5 class="font-weight-bold py-4 mb-3">OUR SERVICES INCLUDE</h5>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Product training.png" alt="Product Training" class="mb-3">
                        <p class="px-4">
                            Product Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Compliance traning.png" alt="Compliance Training" class="mb-3">
                        <p class="px-4">
                            Compliance Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Onboarding.png" alt="Onboarding" class="mb-3">
                        <p class="px-4">
                            Onboarding
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Leadership.png" alt="Leadership Development Process" class="mb-3">
                        <p class="px-4">
                            Leadership Development Process & Skills Training
                        </p>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Sales Training.png" alt="Sales Training" class="mb-3">
                        <p class="px-4">
                            Sales Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/College Level Instruction.png" alt="College Level Instruction" class="mb-3">
                        <p class="px-4">
                            College Level Instruction
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Software Training.png" alt="Software Training" class="mb-3">
                        <p class="px-4">
                            Software Training
                        </p>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Learning Strategy Planning</h4>
                    <p>
                        Pixentia makes the extra effort to understand how your organization works and what approaches can best achieve your learning goals. Our discovery and planning processes include:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Learning Strategy Workshops.png" alt="Learning Strategy Workshops" class="mb-3">
                        <p class="font-weight-bold mb-1">Learning Strategy Workshops</p>
                        <p class="px-4">
                            Where organization objectives are linked to learning requirements, end-user personas are defined, and plans developed that will deliver quantifiable improvements in workforce knowledge and performance.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Learning Style Selection.png" alt="Learning Style Selection" class="mb-3">
                        <p class="font-weight-bold mb-1">Learning Style Selection</p>
                        <p class="px-4">
                            Choosing the learning methods (e.g. interactive video, simulation, gamification, etc.), that can best achieve high rates of <span class="d-inline-block">participation and proficiency.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Program and Project Planning.png" alt="Program and Project Planning" class="mb-3">
                        <p class="font-weight-bold mb-1">Program and Project Planning</p>
                        <p class="px-4">
                            Creating in-depth roadmaps, schedules and individual project plans by which your learning program will be implemented to <span class="d-inline-block">your budget and timeline.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Learning Programs Case Development.png" alt="Learning Programs Case Development" class="mb-3">
                        <p class="font-weight-bold mb-1">Learning Programs Case Development</p>
                        <p class="px-4">
                            Strengthen executive buy-in for organization learning initiatives with business cases that present realistic costs, monetized benefits, and high return-on-investment.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="community_section my-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">Learning Project Implementation</h5>
                    <p>
                        Pixentia’s Learning Center of Excellence implements learning projects with a proven team of instructional and technology consultants. Our support portfolio extends to:
                    </p>
                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/learning-coe/Learning content.png" class="mr-2" alt="Learning Content Creation">
                        </p>
                        <p>
                            <strong class="d-block">Learning Content Creation</strong> Content, instructional approach, and data flows designed to engage employees and deliver higher retention and more job-specific skills.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/learning-coe/Mobile device.png" class="mr-2" alt="Mobile Device and Data Capture Development">
                        </p>
                        <p>
                            <strong class="d-block">Mobile Device and Data Capture Development</strong> Reach employees anywhere, anytime with high-quality content and data exchange developed for multiple devices and mobile platforms.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/learning-coe/User Acceptance.png" class="mr-2" alt="User Acceptance Testing">
                        </p>
                        <p>
                            <strong class="d-block">User Acceptance Testing
                            </strong> Structured UAT planning and execution assuring reliable, multi-platform content delivery and data exchange.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/learning-coe/Transformation Planning.png" class="mr-2" alt="Transformation Planning">
                        </p>
                        <p>
                            <strong class="d-block">Transformation Planning</strong> Designing outreach and messaging that creates high employee <span class="d-inline-block">awareness and engagement.</span>
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/learning-coe/Implementation.png" class="mr-2" alt="Implementation and Deployment Resources">
                        </p>
                        <p>
                            <strong class="d-block">Implementation and Deployment Resources</strong> Experienced IT professionals who can augment your staff and accelerate the successful launch of new training delivery methods and content.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/learning-coe/Learning Project Evaluation.png" class="mr-2" alt="Learning Project Evaluation">
                        </p>
                        <p>
                            <strong class="d-block">Learning Project Evaluation</strong> Planning and tools that use employee feedback and learning assessments to measure results and suggest opportunities for content and <span class="d-inline-block">delivery improvements.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/learning/learning-coe/Learning Project Implementation.png" alt="Learning Project Implementation" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Operations Support</h4>
                    <p>
                        Turn to Pixentia’s IT professionals for operations support and free your internal staff to focus on higher priorities. We undertake:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Content Deployment.png" alt="Content Deployment" class="mb-3">
                        <p class="font-weight-bold mb-1">Content Deployment</p>
                        <p class="px-4">
                            Launching new content and verifying successful delivery across multiple devices and platforms.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/LMS Maintenance.png" alt="LMS Maintenance" class="mb-3">
                        <p class="font-weight-bold mb-1">LMS Maintenance</p>
                        <p class="px-4">
                            Assuring high <a href="services/learning/learningmanagementsystems" class="text-white">learning management system</a> uptime and reliability by managing LMS maintenance, including test and validation of upgrades and uptime
                            monitoring/response.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Content Updating.png" alt="Content Updating" class="mb-3">
                        <p class="font-weight-bold mb-1">Content Updating</p>
                        <p class="px-4">
                            Keeping content current with changes in plans, products, processes, and regulatory compliance.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Learning Metrics and Analytics.png" alt="Learning Metrics and Analytics" class="mb-3">
                        <p class="font-weight-bold mb-1">Learning Metrics and Analytics</p>
                        <p class="px-4">
                            Tracking progress against learning goals with personal, supervisory, and organization-wide reporting; supporting successful people analytics initiatives with data mapping, curation and warehousing.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-5 more_info_section">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-10 offset-md-1">
                    <div class="row align-items-center">
                        <div class="col-md-7 pl-md-5 mt-5 mt-md-0 sub_head">
                            <h2 class="font-weight-bold">Build a High-Performance Learning Organization</h2>
                            <p class="sub_heading_cta mb-4">
                                Learn how you can align your learning to business and deliver learning that maximizes learner engagement.

                            </p>
                            <div class="more_info_btn datasheet_btn">
                                <a href="https://resources.pixentia.com/elearn/datasheet/learning-center-of-excellence" rel="noopener noreferrer" target="_blank"> Get the details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="col-md-5 mt-5 mt-md-0">
                            <div>
                                <img src="../../../../assets/images/cta_images/learning-coe.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>