<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/analytics-deployment">Analytics Deployment</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">LMS Analytics Deployment
                </h3>
                <p>Reporting and analytics implementation packages that enable you to gain real business value from the data in your learning operations.</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Analytics Image.png" alt="Analytics image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Analytics Deployment Services
                    </h1>
                </div>
                <div>
                    <p class="mb-3">
                        Most vendors provide a library of reports and tools to create your custom library. During your deployment, your Pixentia team will set up reporting and help you get started.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Analytics Deployment Services
                </h4>
                <div class="mt-4 mb-4">
                    <p>
                        Pixentia provides consulting services defined by your vendor in a standard Statement of Work.
                    </p>
                    <p>
                        L&D organizations often find that basic level of service does not meet their needs, and many do not have the time or expert skills to build out their reporting and analytics ability.
                    </p>
                    <p>
                        Pixentia provides add-on reporting and analytics services that enable users to gain valuable insights from your data.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Learning analytics.png" alt="Learning analytics" class="mb-3">
                        <p class="px-4">
                            Learning analytics find trends and trouble points so you can improve service delivery
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Advanced analytics.png" alt="Advanced analytics" class="mb-3">
                        <p class="px-4">
                            Advanced analytics evaluate the impact of learning on business performance
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Predictive and prescriptive.png" alt="Predictive and prescriptive" class="mb-3">
                        <p class="px-4">
                            Predictive and prescriptive analytics help you make better business decisions
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Dashboard development.png" alt="Dashboard development" class="mb-3">
                        <p class="px-4">
                            Dashboard development tools give your people real-time access to the data they need
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn about how you can take your L&D's reporting and analytics to the next level.
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to
                                    Preparation and Planning <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>