<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/analytics">Advanced Analytics</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Predict Future Outcomes and Trends</h3>
                <p>
                    Analyze current data and historical facts in order to better understand customers, products and partners.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/Advanced-Analytics/Advanced-Analytics-Image.png" alt="Advanced Analytics Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-5 font-weight-bold">
                    PREDICTIVE ANALYTICS
                </h1>
            </div>
            <div class="col-md-7">
                <p>
                    Analytics is the approach of using statistical computational methods to discover useful patterns in data and report them. We thereby gain insight and can objectively workout decisions and approaches for a line of business.
                </p>
                <p>
                    Predictive Analytics is a data-driven algorithmic approach to derive key attributes of the model from the data itself, instead of an analyst making assumptions given a background in a particular domain.
                </p>
                <p>
                    Using a combination of domain knowledge, predictive modelling skills and a investigative mindset is what makes our approach novel. We iteratively work between 'observed' and 'noticed' patterns in the data to refine the model to deliver a successful predictive
                    analytics based solution.
                </p>
                <p>
                    Our current offerings are focussed on the infrastructure software domain, at scale deployment of containers and feedback based systems.
                </p>
            </div>
            <div class="col-md-5">
                <img src="../../assets/images/Advanced-Analytics/predictive-analysis.png" class="img-fluid" style="width: 100%;" alt="Predivtive Analysis">
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>