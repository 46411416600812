<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Company</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="about">About Us</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Relentless Focus On Clients Needs And Business Results</h1>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/about/header-img.webp" alt="" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.kubota.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/kuboto.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="http://www.intelsecurity.com" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/mcafee_logo.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.pheaa.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/pheaa.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.linde.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/linde.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.tmhcc.com/en/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/tokia-marine.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h4 class="font-weight-bold mb-4">WHO WE ARE</h4>
                <div>
                    <p class="mb-3">
                        Pixentia is a technology solutions and support company dedicated to meeting the unique business needs of each of our clients.
                    </p>
                    <p class="mb-3">
                        We strive first to understand your business, your culture, and your unique challenges.
                    </p>
                    <p class="mb-3">
                        Then, we deliver innovative solutions that merge seamlessly into your operations and culture to create measurable business value.
                    </p>
                    <p class="mb-5">
                        We believe technology solutions should solve problems, not create them.
                    </p>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="row mx-0">
    <div class="col-md-12 story_section pb-5">
        <div class="template_width">
            <div class="text-center">
                <h4 class="font-weight-bold text-uppercase mb-4 pt-5">The Pixentia Story</h4>
                <div class="row align-items-center mt-5 justify-content-center">
                    <div class="flex_1">
                        <div class="box">
                            <div>
                                <img src="../../assets/images/about/01.png" class="img-fluid" alt="">
                            </div>
                            <div>
                                <p>
                                    Pixentia started as a conversation among a group of like-minded technology consultants. Something was fundamentally wrong with the way many IT service providers approached their customers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="d-none d-md-block arrow_sign">
                            <img src="../../assets/images/about/Arrow.png" alt="">
                        </div>
                        <div class="d-block d-md-none arrow_sign1">
                            <img src="../../assets/images/about/Arrow_Vertical.png" alt="">
                        </div>
                    </div>
                    <div class="flex_1">
                        <div class="box">
                            <div>
                                <img src="../../assets/images/about/02.png" class="img-fluid" alt="">
                            </div>
                            <div>
                                <p>
                                    Instead of understanding their client’s needs, these firms set out to sell a one-size-fits-all package that offered the least customer resistance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-5">

                    </div>
                    <div class="col-md-2 p-md-0">

                    </div>
                    <div class="col-md-5">
                        <div class="arrow_sign1">
                            <img src="../../assets/images/about/Arrow_Vertical.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center">
                    <div class="flex_1">
                        <div class="box">
                            <div>
                                <img src="../../assets/images/about/03.png" class="img-fluid" alt="">
                            </div>
                            <div>
                                <p>
                                    There had to be a better way. Over time, this tiny pixel of an idea grew into a picture bright, bold and clear – Pixentia, an IT solutions and support company that puts client needs first.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="d-none d-md-block arrow_sign">
                            <img src="../../assets/images/about/Arrow_Left.png" alt="">
                        </div>
                        <div class="d-block d-md-none arrow_sign1">
                            <img src="../../assets/images/about/Arrow_Vertical.png" alt="">
                        </div>
                    </div>
                    <div class="flex_1">
                        <div class="box">
                            <div>
                                <img src="../../assets/images/about/04.png" class="img-fluid" alt="">
                            </div>
                            <div>
                                <p>
                                    Implementation almost always created additional problems and, more importantly, failed to address clients’ real needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="arrow_sign1">
                            <img src="../../assets/images/about/Arrow_Vertical.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-2 p-md-0">

                    </div>
                    <div class="col-md-5">

                    </div>
                </div>
                <div class="row align-items-center justify-content-center">
                    <div class="flex_1">
                        <div class="box">
                            <div>
                                <img src="../../assets/images/about/05.png" class="img-fluid" alt="">
                            </div>
                            <div>
                                <p>
                                    Pixentia’s clients are global organizations and Fortune 1000-class companies that want to improve customer satisfaction, grow revenue, streamline operations, and build better, more capable workforces.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="d-none d-md-block arrow_sign">
                            <img src="../../assets/images/about/Arrow.png" alt="">
                        </div>
                        <div class="d-block d-md-none arrow_sign1">
                            <img src="../../assets/images/about/Arrow_Vertical.png" alt="">
                        </div>
                    </div>
                    <div class="flex_1">
                        <div class="box">
                            <div>
                                <img src="../../assets/images/about/06.png" class="img-fluid" alt="">
                            </div>
                            <div>
                                <p>
                                    Founded in 2011, Pixentia is headquartered in Irving, Texas and employs two hundred global workforce. Its client support staff includes teams in Irving as well as at client locations globally.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">THE PIXENTIA PROMISE</h4>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/about/Fully understand.png" alt="" class="mb-3">
                    <p class="px-4">
                        Fully understand your business, challenges, strategy, and culture before making recommendations.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/about/Develop solutions.png" alt="" class="mb-3">
                    <p class="px-4">
                        Develop solutions that directly address your business needs.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/about/RIght size.png" alt="" class="mb-3">
                    <p class="px-4">
                        Right-size our recommendations to meet your economic requirements.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/about/Map out.png" alt="" class="mb-3">
                    <p class="px-4">
                        Map-out implementation plans that will be embraced by your stakeholders.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/about/Measure.png" alt="" class="mb-3">
                    <p class="px-4">
                        Measure our success by the improvements we bring to <span class="d-inline-block">your business.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/about/Never forget.png" alt="" class="mb-3">
                    <p class="px-4">
                        Never forget that technology is a tool, <span class="d-inline-block">not the answer.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width mt-4 mb-4">
    <app-get-started></app-get-started>
</div>