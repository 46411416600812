<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="privacy-policy">Privacy Policy</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Privacy Policy</h1>
                <p class="mb-0">We believe in the power of creativity and innovation.</p>
                <p>We take a creative approach to address the needs of better tomorrow and our motive is to formulate creative solutions.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/policy/Privacy Image.png" alt="Privacy Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width policy_page">
    <div class="row">
        <div class="col-md-6">
            <div>
                <h5>Privacy Policy</h5>
                <p>
                    Pixentia respects your privacy. We do not sell, rent or loan any identifiable information regarding our customers to any third party. Any information you give us is held with the utmost care and security.
                </p>
                <p>
                    This privacy policy sets out our privacy practices. Please take time to review this privacy policy as it tells you how your personal information will be treated by us.
                </p>
            </div>
            <div>
                <h5>Use of Information</h5>
                <p>
                    Pixentia will not collect any personal information about individuals except when specifically and knowingly provided by such individuals. Examples of such information are: name, company, postal address, email address, phone number and product commentary.
                </p>
                <h5>We will use the personal information provided to us in order to:</h5>
                <ul>
                    <li>Process orders submitted by you</li>
                    <li>Customize the service we provide to you</li>
                    <li>Administrate or otherwise carry out our obligations in relation to any agreement you have with us</li>
                    <li>Anticipate and resolve any problems with any goods or services supplied to you</li>
                    <li>Carry out market research and surveys</li>
                    <li>Send you our newsletter, where this is requested by you</li>
                    <li>To send you follow-up communications relating to software downloaded from our site</li>
                    <li>To send you information about our other products and services</li>
                </ul>
                <p>
                    We may collect any personal information provided by you to us through this website or via one of our telephone operators, or we may collect this information through your email or other communications with us. Although we do not make it compulsory to give
                    us every item of information we ask for, the more information you volunteer, the better we can tailor our services for you.
                </p>
                <p>
                    We also collect information from you if you choose to take part in any of our on-site surveys or promotions. We will use this information to administer the promotion and help us to plan other promotions.
                </p>
                <p>
                    Certain services, such as payment processing, are offered in conjunction with our business partners. In such cases, we may need to share your personal information with them in order to provide these services. Please note that certain services may be unavailable
                    if you do not want to disclose the personal information you are asked for.
                </p>
            </div>
            <div>
                <h5>Non personal Information</h5>
                <p>
                    We may automatically collect non-personal information about you, such as the type of internet browsers you use, the site from which you linked to our site, and may also track your movement within the site. You cannot be identified from this information
                    and it is only used to assist us in providing an effective service on our site.
                </p>
            </div>
            <div>
                <h5>Third Party Sites</h5>
                <p>
                    We cannot be responsible for the privacy policies and practices of other sites even if you access those using links from our site and recommend that you check the policy of each site you visit.
                </p>
                <p>
                    In addition, if you linked to our site from a third party site, we cannot be responsible for the privacy policies and practices of the owners or operators of that third party site and recommend that you check the policy of that third party site and contact
                    its owner or operator if you have any concerns or questions.
                </p>
            </div>
        </div>
        <div class="col-md-6">
            <div>
                <h5>Rights available to the Data Subjects</h5>
                <p class="mb-0">Data subjects will have the following rights over the data shared-</p>
                <p class="mb-0">To be informed on the usage of their personal data</p>
                <p class="mb-0">To access, correct, modify the data and review whether the data is lawfully processed</p>
                <p class="mb-0">To request erasure of the data in certain situations</p>
                <p class="mb-0">To object processing of personal data if we:</p>
                <p class="mb-0">Process the data based on legitimate interests or the performance of a task in the</p>
                <p class="mb-0">public interest as an exercise of official authority (including profiling);</p>
                <p class="mb-0">Use the data for direct marketing (including profiling); or</p>
                <p>Process the data for purposes of scientific/historical research and statistics.</p>
            </div>
            <div>
                <h5>Cookies</h5>
                <p>
                    Cookies are very small text files saved to your computer used to track user navigation around a website. At Pixentia session cookies are used when users log-in to the site. We use such cookies to identify unique users to our sites. They are not cross-referenced
                    with any other information such that an individual person could be identified.
                </p>
                <p>
                    Pixentia may use a third party to serve advertisements on our sites. The advertisers may use cookies to track the number of users responding to the campaign. We do not have access to or control of cookies placed by third parties.
                </p>
            </div>
            <div>
                <h5>Disclosures</h5>
                <p>
                    Other than the disclosures referred to in this policy, we will not disclose any personal information without your permission unless we are legally obliged or entitled to do so (for example, if required to do so by Court order or for the purposes of fraud
                    or other crime). We will only disclose your personal information to a third party having ensured that your privacy rights continue to be protected.
                </p>
            </div>
            <div>
                <h5>Keeping our Records Accurate</h5>
                <p>
                    We aim to keep our information about you as accurate as possible. If you would like to review or change the details you have supplied us with, please contact us through mail or phone.
                </p>
            </div>
            <div>
                <h5>Security</h5>
                <p>
                    We have implemented technology and policies to help safeguard your privacy from unauthorized access and improper use. For example, transactions conducted through the site are encrypted and user log-in is subject to password protection. We will continue
                    to monitor and update security measures as new technology becomes available as appropriate to the site.
                </p>
            </div>
            <div>
                <h5>Your Consent</h5>
                <p>
                    By using our website, or giving us your details at an exhibition, you consent to the collection and use of this information by Pixentia. If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of
                    what information we collect, how we use it, and under what circumstances we would disclose it.
                </p>
            </div>
            <div>
                <h5>Contacting Us</h5>
                <p>
                    If at any time you would like to contact us with your views about our privacy practices, or with any enquiry relating to your personal information, you can do so by sending an email to us at sales@pixentia.com or call us at +1 903-306-2430.
                </p>
            </div>
            <div>
                <h5>Log files/IP Addresses</h5>
                <p>
                    When you visit our site, we automatically log your IP address which is recognized by our web server. We use IP addresses to help us administer the site and to collect broad demographic information for aggregate use.
                </p>
            </div>
        </div>
    </div>
</div>