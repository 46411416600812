import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-learningstylesandgamification',
  templateUrl: './learningstylesandgamification.component.html',
  styleUrls: ['./learningstylesandgamification.component.scss']
})
export class LearningstylesandgamificationComponent implements OnInit {

  title = 'Pixentia Learning Strategies';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: "Drive Learning with strategies that reinforce your culture and optimize information retention, proficiency and productivity."}
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Learning Strategies' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: "Drive Learning with strategies that reinforce your culture and optimize information retention, proficiency and productivity." }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/learning/learningstylesandgamification' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Learning Strategies' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: "Drive Learning with strategies that reinforce your culture and optimize information retention, proficiency and productivity." }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/learning/learningstylesandgamification' }      
    );
  }

}
