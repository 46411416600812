<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/managed-talent-development-services"> Managed Talent Development Services </a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-8">
            <div>
                <h3 class="font-weight-bold">Unlock the Power of Talent Development with Pixentia AMS </h3>
                <p>Align your talent, technology, and processes to your business strategy and financial goals with application management services. 
                </p>
                <p>Let Pixentia manage your talent ecosystem so you can focus on your people.</p>
                <a href="https://resources.pixentia.com/power-up-your-talent-development-with-application-management-services" rel="noreferrer noopener" target="_blank" class="button_style bg-light mb-4"> <span style="color: #f26f21;">Download eBook</span> <i class="fa fa-angle-right" style="color: #f26f21;" aria-hidden="true"></i></a>
            </div>
        </div> 
        <div class="col-md-4 text-md-right text-center">
            
                <img src="../../../assets/images/managed-talent/managed-talent-development-service.png" alt="Digital Transformation Image" class="img-fluid px-0">
           
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img
                            src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img
                            src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img
                            src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img
                            src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img
                            src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
            
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-3 align-items-center justify-content-center info_section">
            <div class="col-md-9 sub_head">
                <h1 class="text-center mb-3 font-weight-bold">
                    Are you unlocking the value potential of your technology?
                </h1>
                <p class="text-center font-italic font-weight-bold">
                    Your talent systems don't deliver value when you "go live." The benefits begin when you create opportunities for growth and innovation that drive value to your customers, suppliers, partners, and stakeholders.
                </p>
            </div>
        </div>
    </div>
    </div>




    <div class="digital_section mb-4">
        <div class="template_width">
            <div class="row justify-content-center align-items-center digital_section text-white py-5"> 
                <div class="col-12 ">
                <p class="font-weight-bold mb-5">
                    Our <a class="font-italic link-opacity-100 text-warning"  target="_blank" rel="noreferrer noopener"
                        href="https://www.forbes.com/advisor/business/what-is-ams/"> <u>
                        application management service</u> </a> deliver value beyond cost savings.
                </p>
            </div>
            
            
            
            <div class="col-xl-7 col-lg-6 col-md-12">
                <div class="row mx-auto">
                    <div class="d-flex align-items-center mb-3 col-12 ">
                        <p>
                            <img src="../../../assets/images/managed-talent/bug-fix.png" class="mr-2"
                                alt="A review of your business and workforce">
                        </p>
                        <p>
                         You get 100% immediate attention on glitches and bug fixes without shifting priorities or waiting for IT or support services to become available.
                        </p>
                    </div>
                    <div class="d-flex align-items-center mb-3 col-12">
                        <p>
                            <img src="../../../assets/images/managed-talent/stability.png" class="mr-2"
                                alt="A review of your business and workforce">
                        </p>
                        <p>
                            You don't have to worry about staffing an administrative team. We handle monitoring, managing, optimizing your technology, and stabilizing your processes.
                        </p>
                    </div>
                    <div class="d-flex align-items-center mb-3 col-12">
                        <p>
                            <img src="../../../assets/images/managed-talent/team-leader.png" class="mr-2"
                                alt="A review of your business and workforce">
                        </p>
                        <p>
                        When you need skilled expertise for an update, technology change, integration, or learning content, there's no need to scour the market for the right skills. Rely on our skilled experts.
                        </p>
                    </div>
                    <div class="d-flex align-items-center mb-3  col-12">
                        <p>
                            <img src="../../../assets/images/managed-talent/content.png" class="mr-2"
                                alt="A review of your business and workforce">
                        </p>
                        <p>
                        When you need new content, our learning consultant will source or create learning that meets your needs. 
                        </p>
                    </div>

                    <div class="d-flex align-items-center mb-3  col-12">
                        <p>
                            <img src="../../../assets/images/managed-talent/performance.png" class="mr-2"
                                alt="A review of your business and workforce">
                        </p>
                        <p>
                        We meet with you regularly on a schedule you define to evaluate our performance and adjust your services. 
                        </p>
                    </div>
                    <div class="d-flex align-items-center mb-3  col-12">
                        <p>
                            <img src="../../../assets/images/managed-talent/plan.png" class="mr-2"
                                alt="A review of your business and workforce">
                        </p>
                        <p>
                        When it's time for you to visit your firm's strategic plan, our consultants help you align your talent with your business goals and financial objectives.  
                        </p>
                    </div>

                    <div class="d-flex align-items-center  col-md-12 mt-5">
                        <a href="demo" class="button_style bg-orng">Schedule a Free Consultation <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                    
                    
                </div>
            </div>
            
            <div class="col-xl-5 col-lg-6  col-md-12 text-center text-md-right">
                <div>
                    <img src="../../../assets/images/managed-talent/talent-in-ams.png" class="img-fluid" alt="Managed Talent in AMS">
                </div>
            </div>
            </div>
        </div>
    </div>

    <div class=" mb-4">
        <div class="template_width">
            <div class="row justify-content-center align-items-center ">

                <div class="col-md-4">
                    <div>
                        <img src="../../../assets/images/managed-talent/why-pixentia.png" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="col-md-8 sub_head">
                    <h3 class="mb-3 font-weight-bold">
                        Why Choose Pixentia? 
                    </h3>

                    <p>At Pixentia, we provide consulting, technology, and support services to help you deliver the talent you need to succeed. We are committed to helping you achieve your goals by ensuring your tech stack is in sync and operating at peak efficiency.</p>   

                    <p>Don't let your talent go to waste. Contact us today to discuss how you can align your talent with business value.</p>

                    <p>Book a meeting at your convenience to get your questions answered, no matter how tough! </p>
                    <a href="https://meetings.hubspot.com/natasha-ramdial-roopnarine?uuid=cc2a7f0f-11cc-4ef7-8356-d35bf8b9a763" rel="noreferrer noopener" target="_blank" class="button_style bg-orng mb-4">Lets talk now <i class="fa fa-angle-right" aria-hidden="true"></i></a>
               
            </div>


            </div>
        </div>
    </div>

    <app-vendors></app-vendors>
    <div class="template_width my-4">
        
        <app-get-started></app-get-started>
    </div>