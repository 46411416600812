<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Cloud Transformation</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/cloud-transformation/workday">Salesforce</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">The One Place to Go for Your Salesforce Service Needs </h3>
                <p>Our Salesforce Managed Services Team handles all your administration and maintenance so you can focus on your customers.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/cloud-transformations/salesforce/Salesforce-image.png" alt="Salesforce Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-12 text-center">
                <h1 class="text-center mb-5 font-weight-bold">
                    Offload your administration, maintenance, and development <span class="d-inline-block">so that you can focus on your customer
                    pipeline</span>
                </h1>
                <p>
                    Over time, Salesforce has matured and grown so much that you need a dedicated team to manage its many functions. Pixentia enables you to keep pace with your growing needs to improve your customer service delivery continuously.
                </p>
            </div>
        </div>
    </div>
    <div class="community_section my-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">Why would you need Pixentia managed support services?</h5>

                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/salesforce/Your organization.png" class="mr-2" alt="On-the-Job Mobile Learning Content">
                        </p>
                        <p>
                            Your organization hasn’t developed the skills and expertise you need to be competitive.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/salesforce/You find your organization.png" class="mr-2" alt="Spontaneous Learning Programs">
                        </p>
                        <p>
                            You find your organization unable to respond quickly to changing business priorities and customer demands.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/salesforce/your initial edge.png" class="mr-2" alt="Legacy Content Conversion">
                        </p>
                        <p>
                            You’ve lost your initial edge. Over time, your administrators have trained other administrators, who taught others, and now, critical tasks go undone.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/salesforce/You need a dedicated.png" class="mr-2" alt="Learning Deployment">
                        </p>
                        <p>
                            You need a dedicated team that will anticipate your changing needs and works with you to maintain the mix of technologies that best support your business needs.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/cloud-transformations/salesforce/why would.png" alt="Learning Project Implementation" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Why Pixentia? </h4>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/salesforce/A dedicated team.png" alt="A dedicated team of administrators" class="mb-3">
                        <p class="px-4">
                            A dedicated team of administrators, developers, and a Salesforce Architect.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/salesforce/Regularly scheduled.png" alt="Regularly scheduled assessments" class="mb-3">
                        <p class="px-4">
                            Regularly scheduled assessments to ensure that you have the right mix of solutions for your business.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/salesforce/Timely updates.png" alt="end-to-end testing" class="mb-3">
                        <p class="px-4">
                            Timely updates with complete end-to-end testing of Salesforce and connected apps to eliminate potential disruption.
                        </p>
                    </div>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/salesforce/Time-sensitive.png" alt="Time-sensitive handling" class="mb-3">
                        <p class="px-4">
                            Time-sensitive handling of critical technical tasks based on your SLA.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/salesforce/Seasoned experts.png" alt="Seasoned experts" class="mb-3">
                        <p class="px-4">
                            Seasoned experts handle your daily administration so you can focus <span class="d-inline-block">on your customers.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </div>
    <div class="my-5">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-10 offset-md-1">
                    <div class="row align-items-center">
                        <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                            <p class="sub_heading_cta mb-4">
                                Learn how you can make the most of your Servicenow platform and maximize your people experience.
                            </p>
                            <div class="more_info_btn datasheet_btn">
                                <a href="">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="col-md-5 mt5 mt-md-0">
                            <div>
                                <img src="../../../../assets/images/cta_images/CTA Contact.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>