export class Search {
    public static searchingfor = [
        {
            name: "Chasma Apps",
            description: "Avoid the expense of ripping and replacing your HR platform with apps that adapt your technology to the way people work.",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors , Transform Your Employee Experience",
            url: "/products/chasma-apps"
        },
        {
            name: "Chasma Editor",
            description: "Create engaging user portals for your core HR, talent and learning applications without programming",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors , Create a personalized, branded homepage in your HR applications without programming",
            url: "/products/chasma-apps/chasma-editor"
        },
        {
            name: "Chasma Event",
            description: "Agile administration for gatherings of any size",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors , Let Chasma handle your ILT administration",
            url: "/products/chasma-apps/chasma-event"
        },
        {
            name: "Chasma Lexy",
            description: "AI-Enabled Virtual Assistant for Human Capital Management Applications",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors , Ease Communication across your enterprise technology landscape",
            url: "/products/chasma-apps/chasma-lexy"
        },
        {
            name: "Chasma Quale",
            description: "Automated Cloud Validation Platform",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, Automated testing for HR applications",
            url: "/products/chasma-apps/chasma-quale"
        },
        {
            name: "Chasma Natio Desk",
            description: "Support portal management for customers and internal users in one application",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, NEED MORE",
            url: "/products/chasma-apps/chasma-natiodesk"
        },
        {
            name: "Chasma Bot Builder",
            description: "Chatbot Management for Non-technical Users",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, Create Your Customer Service Chatbot",
            url: "/products/chasma-apps/chasma-botbuilder"
        },
        {
            name: "Chasma Cible",
            description: "User Flow and Behavior Analysis for Your Enterprise Applications",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, NEED MORE",
            url: "/products/chasma-apps/chasma-cible"
        },
        {
            name: "Chasma Analytics",
            description: "Data-Driven Visual Insight",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, Get hooked up with updates on Chasma Analytics to know more on our latest solutions and offerings.",
            url: "/products/chasma-analytics"
        },
        {
            name: "Chasma Analytics For SumTotal",
            description: "130+ Dashboards and Visualizations for SumTotal Advanced Reporting",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, Pre-Configured Dashboards for SumTotal Advanced Reporting",
            url: "/products/chasma-analytics/sumtotal-analytics"
        },
        {
            name: "Chasma HRBox",
            description: "Dashboard Design for All your HR systems — without coding",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, HR Dashboard Design for Everyone",
            url: "/products/chasma-analytics/chasma-hrbox"
        },
        {
            name: "Chasma Analytics For Cornerstone®",
            description: "Preconfigured dashboards ready to deploy now",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, NEED MORE",
            url: "/products/chasma-analytics/cornerstone-analytics"
        },
        {
            name: "Chasma Analytics For Power BI",
            description: "Dashboards and visualizations ready to deploy now",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, ACHIEVE REPORTING EXCELLENCE IN DAYS, NOT WEEKS.",
            url: "/products/chasma-analytics/people-analytics-power-bi"
        },
        {
            name: "Chasma Analytics For Workday®",
            description: "Rapid Deployment of Human Capital Dashboards and Visualization",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, NEED MORE",
            url: "/products/chasma-analytics/workday-analytics"
        },
        {
            name: "Chasma Connect",
            description: "Integrate your HR Applications data",
            content: "Chasma Apps , Chasma Analytics , Chasma Connectors, Frustrated by disconnected HR applications?",
            url: "/products/chasma-connect"
        },
        {
            name: "HCM COE",
            description: "Human Capital Management Center of Excellence. Advance your ability to deliver a people- first digital experience",
            content: "",
            url: "/services/hcm/hcm-coe"
        },
        {
            name: "Recruiting",
            description: "Talent Acquisition Technology. Implementation, Support, and Optimization",
            content: "",
            url: "/services/hcm/recruiting"
        },
        {
            name: "Learning",
            description: "Learning Solutions that Deliver High Results. Multi- level support, from custom training content, to learning strategy planning, to course delivery across multiple device platforms.",
            content: "Learning Assessment Services , Business Case Development , Learning Technology Roadmap , Project Governance , Change Management Support , Learning Analytics Strategy",
            url: "/services/hcm/learning"
        },
        {
            name: "Talent Management",
            description: "Talent Management Implementation, Optimization, and Support. Select and implement the talent management platform and processes that support your strategy and culture.",
            content: "",
            url: "/services/hcm/talentmanagement"
        },
        {
            name: "Payroll",
            description: "People-Centered Payroll Implementation, Optimization, and Support. Select and implement the payroll platform and processes that support your talent strategy and culture",
            content: "Learn how you can best choose, implement and optimize your Payroll Technology.",
            url: "/services/hcm/payroll"
        },
        {
            name: "Compensation",
            description: "People-Centered Compensation Implementation, Optimization, and Support. Select and implement the compensation platform and processes that support your talent strategy and culture",
            content: "",
            url: "/services/hcm/compensation"
        },
        {
            name: "Enterprise Integrations",
            description: "Enterprise Application Integration Services. All your public and private data integrations on one secure platform",
            content: "Data Integration Is the Lifeblood of Your Modern Enterprise , Strategy and Governance , Integration Standards , Optimization , Support and Services ",
            url: "/services/hcm/enterprise-integrations"
        },
        {
            name: "People Analytics",
            description: "People-Centered People analytics Implementation, Optimization, and Support. Select and implement the people analytics platform and processes that support your talent strategy and culture",
            content: "",
            url: "/services/hcm/peopleanalytics"
        },
        {
            name: "Employee Portals",
            description: "Unleash Human Ingenuity with an Employee Portal. Designed for Your Business",
            content: "Create a Whole-Experience Employee Portal, The Impact of Technology on Wellbeing , Create the Employee Portal that’s Right for Your Business",
            url: "/services/hcm/employee-portals"
        },
        {
            name: "Value Calculator",
            description: "HCM Technology Value Calculator. Estimate the potential value you could realize with Pixentia technology",
            content: "",
            url: "/services/hcm/hcm-value-calculator"
        },
        {
            name: "HCM Resource Center - AMS",
            description: "Keeping your platform updated and current with your organization’s needs requires ongoing, hands-on support.",
            content: "",
            url: "/services/hcm/hcm-resource-center"
        },
        {
            name: "Learning COE",
            description: "Learning Solutions that Deliver High Results. Multi- level support, from custom training content, to learning strategy planning, to course delivery across multiple device platforms.",
            content: "",
            url: "/services/learning/learning-coe"
        },
        {
            name: "Learning Management Systems",
            description: "Build Your Ideal Workforce With Right LMS. Make informed LMS decisions, whether if you are choosing a new LMS, or looking for ways to get better results from an existing system.",
            content: "",
            url: "/services/learning/learningmanagementsystems"
        },
        {
            name: "Learning Portals",
            description: "Learning Portals That Motivate Self-Development. Drive self- directed learning in every part of your organization and your extended enterprise",
            content: "",
            url: "/services/learning/learning-portals"
        },
        {
            name: "Custom Learning",
            description: "Learning Crafted to Your Workforce Needs. Custom learning solutions that engage and provide the exact skills and proficiencies your people need to achieve your organizational goals.",
            content: "",
            url: "/services/learning/customlearning"
        },
        {
            name: "Mobile Learning",
            description: "Custom Mobile Learning Solutions. Spontaneous learning opportunities? On-demand support for roaming employees? Turn to Pixentia for solutions that inform, teach and improve on-the-job performance.",
            content: "",
            url: "/services/learning/mobilelearning"
        },
        {
            name: "Learning Styles and Gamification",
            description: "Learning Styles and Gamification. Drive Learning with strategies that reinforce your culture and optimize information retention, proficiency and productivity.",
            content: "Learning Strategy Planning , Learning Content Implementation",
            url: "/services/learning/learningstylesandgamification"
        },
        {
            name: "Learning Demos",
            description: "Learning Experiences That Accelerate Performance. Immersive learning paths to engage and upskill your workforces towards achieving high performance and sustained business results.",
            content: "",
            url: "/services/learning/demosmain"
        },
        {
            name: "Learn Resource Center - AMS",
            description: "Our resource centers provide direct help to administrators, learning professionals, and learning managers.",
            content: "",
            url: "/services/learning/learning-resource-center"
        },
        {
            name: "Workday",
            description: "Delivering Superior Workday® Functionality and Results. Select and implement the payroll platform and processes that support your talent strategy and culture.",
            content: "Discovery and Planning , Workday® Project Services , Workday® Post Production",
            url: "/services/cloud-transformation/workday"
        },
        {
            name: "Sumtotal",
            description: "Innovative Implementation, Content, and Optimization for SumTotal Learn.",
            content: "Pixentia Technology Strategy and Planning Services , Assessment Services , Business Case Development , Technology Roadmap , Project Governance , Change Management Support , Learning and Performance Analytics Strategy",
            url: "/services/cloud-transformation/sumtotal"
        },
        {
            name: "Cornerstone",
            description: "Consulting and Advisory Services for Cornerstone® Human Capital Management. Boost your technology’s potential for workforce development",
            content: "Technology Assessment Services , Platform Recommendations , Business Case Development , Learning Technology Roadmap , Project Governance , Change Management , People Analytics Strategy",
            url: "/services/cloud-transformation/cornerstone"
        },
        {
            name: "Ceridian",
            description: "Consulting, Advisory, and Implementation Services for Ceridian Dayforce® Human Capital Management. A Relentless Focus on Your Objectives",
            content: "Technology Assessment Services , Platform Recommendations , Business Case Development , Learning Technology Roadmap , Project Governance , Change Management , People Analytics Strategy",
            url: "/services/cloud-transformation/ceridian"
        },
        {
            name: "Servicenow",
            description: "Your One Place to Go for ServiceNow Support and Optimization. If your ServiceNow administration and maintenance are an unwelcome burden, let Pixentia carry it for you.Offload your administration so you can focus on your people.",
            content: "Administration , Technology , Reporting and Analytics",
            url: "/services/cloud-transformation/servicenow"
        },
        {
            name: "Salesforce",
            description: "The One Place to Go for Your Salesforce Service Needs. Our Salesforce Managed Services Team handles all your administration and maintenance so you can focus on your customers.",
            content: "",
            url: "/services/cloud-transformation/salesforce"
        },
        {
            name: "Digital Transformation",
            description: "Your Path to Digital HR Transformation. Start with strategy",
            content: "Start With Strategy in Your HR Digital Transformation , A Strategic Approach to Digitization , Strategy Review , Technology Assessment , Governance , Change Management",
            url: "/services/digital-transformation"
        },
        {
            name: "Mobile Applications",
            description: "Custom Mobile Apps for all your business needs. Extend your enterprise with mobile apps for your partners, supply chain, and distribution channels.",
            content: "CROSS PLATFORM MOBILE APPLICATIONS",
            url: "/services/mobile"
        },
        {
            name: "Advanced Analytics",
            description: "Predict Future Outcomes and Trends. Analyze current data and historical facts in order to better understand customers, products and partners.",
            content: "PREDICTIVE ANALYTICS",
            url: "/services/analytics"
        },
        {
            name: "About Us",
            description: "Relentless Focus On Clients Needs And Business Results",
            content: "",
            url: "/about"
        },
        {
            name: "Careers",
            description: "Become a Pixentian and Grow With Us. Create adventures with technology in a creative, challenging and vibrant environment.",
            content: "",
            url: "https://careers.pixentia.com/careers"
        },
        {
            name: "Customers",
            description: "You’re in good company. Join with other enterprises on Pixentia.",
            content: "",
            url: "/customers"
        },
        {
            name: "Partners",
            description: "We're A Certified SumTotal Services Partner!",
            content: "",
            url: "/partners"
        },
        {
            name: "Blog",
            description: "",
            content: "",
            url: "https://blog.pixentia.com/"
        },
        {
            name: "Resources",
            description: "Explore our growing collection of resources. LEARN. SHARE. GROW",
            content: "",
            url: "https://resources.pixentia.com/"
        },
        {
            name: "Events",
            description: "All Live Events",
            content: "",
            url: "https://resources.pixentia.com/liveevents"
        },
        {
            name: "Datasheets",
            description: "All Data Sheets",
            content: "",
            url: "https://resources.pixentia.com/data-sheets"
        },
        {
            name: "Guides & Whitepapers",
            description: "All Guides & Whitepapers",
            content: "",
            url: "https://resources.pixentia.com/guides"
        },
        {
            name: "Case Studies",
            description: "Latest Case Studies",
            content: "",
            url: "https://resources.pixentia.com/case-studies"
        },
        {
            name: "Contact Us",
            description: "Contact Us. Let's talk. We'd love to hear from you first.",
            content: "Sales Enquiries , Business Enquiries , Locations",
            url: "/contact-us"
        },
    ]
}