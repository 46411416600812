<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-analytics">Chasma Analytics</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-analytics/workday-analytics">Chasma Analytics For Workday<sup>&reg;</sup></a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Analytics For Workday<sup>&reg;</sup></h3>
                <p>Rapid Deployment of Human Capital Dashboards and Visualization</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/workday/workday-image.png" alt="Chasma apps image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">What's keeping you from building your Workday reporting libraries?
                </h1>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <p class="mb-3">
                            For many of our Chasma customers, building a comprehensive Workday reporting library always seems to get shoved to the back of the line. Some tell us that they just don't have the time or the skills to get it done. Still others say they can't decide what
                            to measure.
                        </p>
                        <p class="mb-3">
                            At Chasma, We are on a mission to address HR technology needs in shorter and faster cycles to maximize your potential, so you can get it done now, without waiting for priorities to align themselves.
                        </p>
                        <p class="mb-3">
                            Your technology should not call the tune for you to dance; it should dance to the music in
                            <span class="d-inline-block">your critical processes.</span>
                        </p>
                        <p class="mb-3">
                            That's why we maintain a library of 130+ dashboards and hundreds of data visualizations, ready for you to deploy in Workday. If you don't see what you need, let us know and we'll create it for you.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">

    <div class="mb-5 mt-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">Workday Analytics Advantage
                </h4>
                <p>
                    Enjoy the benefits of rapid deployment and low-cost, pre-configured visualizations and dashboards built for Workday.
                </p>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/workday/Realtime.png" alt="Real-time visualizations" class="mb-3">
                    <p class="px-4">
                        Real-time visualizations of critical operational metrics like employee cost versus performance, analysis on your data, and more.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/workday/Data visulization.png" alt="Data visualizations" class="mb-3">
                    <p class="px-4">
                        Data visualizations that help your executives make decisions at the speed of business.
                    </p>
                </div>
            </div>
            <div class="col-md-4 ">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/workday/Experts.png" alt="business strategy" class="mb-3">
                    <p class="px-4">
                        Experts who can lead you through the framework for aligning your metrics to business strategy.
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/workday/Threepricng.png" alt="pricing levels to fit your budget" class="mb-3">
                    <p class="px-4">
                        Three pricing levels to fit your budget and business needs.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/workday/KPI.png" alt="KPIs and metrics" class="mb-3">
                    <p class="px-4">
                        KPIs and metrics that matter most for your talent organization.
                    </p>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Analytics for Workday<sup>&reg;</sup>?</h4>
                    <p class="">
                        Pre-configured dashboards ready to deploy right now in your Workday® platform, at a fraction of custom development cost. Watch the video to see how you can help your executives make the decisions at the speed of business with Chasma Analytics for Workday<sup>&reg;</sup>.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/231573767?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/images/products/event/Need more.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-white pl-md-5 mt-5 mt-md-0">
                        <h2 class="font-weight-bold text-uppercase">Need More </h2>
                        <p class="mb-4">Talk to us about your business goals</p>
                        <div class="more_info_btn">
                            <a href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                    aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>