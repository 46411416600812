import { Domains } from './domains';
import { AbstractControl, FormControl } from '@angular/forms';

export function removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
        control.setValue('');
    }
    return null;
}

export class EmailValidator {

    public static isValidMailFormat() {
        return (control: FormControl): { [s: string]: boolean } => {
            var EMAIL_REGEXP = new RegExp("^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$");
            if (control.value && EMAIL_REGEXP.test(control.value)) {
                var mdomain = control.value.split('@');
                var mailvalidate = ExceptedMail(mdomain[1]);

                if (mailvalidate) {
                    return { "isValidMailFormat": true };
                }
            }

            return null;
        }
    }

}

function ExceptedMail(mailid: string) {
    if (Domains.domains.filter(x => x.domainName.toLocaleLowerCase().includes(mailid.toLocaleLowerCase())).length > 0) {
        return true;
    }
    return false;
}
