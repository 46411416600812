import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customlearning',
  templateUrl: './customlearning.component.html',
  styleUrls: ['./customlearning.component.scss']
})
export class CustomlearningComponent implements OnInit {

  title = 'Pixentia Custom eLearning Services and Support';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: "Pixentia’s Custom eLearning involves you directly in the development process so you can be assured of achieving the right learning for your workforce."}
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Custom eLearning Services and Support' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: "Pixentia’s Custom eLearning involves you directly in the development process so you can be assured of achieving the right learning for your workforce." }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/learning/customlearning' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Custom eLearning Services and Support' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: "Pixentia’s Custom eLearning involves you directly in the development process so you can be assured of achieving the right learning for your workforce." }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/learning/customlearning' }      
    );
  }
}
