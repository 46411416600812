<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-resource-center">HCM Resource Center</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">HCM Resource Center (AMS)</h3>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/hcm/hcm-resource-center/hcm-resource-image.png" alt="Hcm Resource Center Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-10 offset-md-1">
            <div class="text-center">
                <div class="sub_head">
                    <h4 class="font-weight-bold mb-4">
                        HR Resource Centers
                    </h4>
                </div>
                <p>
                    Keeping your platform updated and current with your organization’s needs requires ongoing, hands-on support.
                </p>
                <p>
                    Let Pixentia’s specialists support your administrators, serve as your support backup, or provide comprehensive managed services.
                </p>
            </div>
        </div>
    </div>
    <div class="row my-5 services_list">
        <div class="col-md-4 text-center border-right border-bottom">
            <div class="py-2">
                <div class="mb-3 mt-4">
                    <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Admin Resource Center.png" alt="Admin Resource Center">
                </div>
                <div>
                    <p>
                        Admin Resource Center assists administrators in day-to-day operations and maintenance.
                    </p>
                </div>
                <div class="text-center mb-2">
                    <div class="sub_btn_style">
                        <a href="services/hcm/hcm-coe/admin-resource">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 text-center border-right border-bottom">
            <div class="py-2">
                <div class="mb-3 mt-4">
                    <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Analytics Resource Center.png" alt="Analytics Resource Center">
                </div>
                <div>
                    <p>
                        Analytics Resource Center supports your operational reporting, dashboards and visualizations, advanced analytics, and data aggregation and storage.
                    </p>
                </div>
                <div class="text-center mb-2">
                    <div class="sub_btn_style">
                        <a href="services/hcm/hcm-coe/analytics">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 text-center border-bottom">
            <div class="py-2">
                <div class="mb-3 mt-4">
                    <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Technology Resource Center.png" alt="Technology Resource Center">
                </div>
                <div>
                    <p>
                        Technology Resource Center provides technical help for administrators for managing updates, scheduling, resource management, and reporting.
                    </p>
                </div>
                <div class="text-center mb-2">
                    <div class="sub_btn_style">
                        <a href="services/hcm/hcm-coe/technology">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 text-center border-right">
            <div class="py-2">
                <div class="mb-3 mt-4">
                    <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Content Resource Center.png" alt="Content Resource Center">
                </div>
                <div>
                    <p>
                        Content Resource Center supports new initiatives or refreshing long-standing programs with content creation and curation services.
                    </p>
                </div>
                <div class="text-center mb-2">
                    <div class="sub_btn_style">
                        <a href="services/hcm/hcm-coe/content-resource-center">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 text-center border-right">
            <div class="py-2">
                <div class="mb-3 mt-4">
                    <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Managed Services Center.png" alt="Managed Services Center">
                </div>
                <div>
                    <p>
                        Managed Services Center provides dedicated experts to handle all or any part of your administration.
                    </p>
                </div>
                <div class="text-center mb-2">
                    <div class="sub_btn_style">
                        <a href="services/hcm/hcm-coe/managed-services">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <div class="py-2">
                <div class="mb-3 mt-4">
                    <img src="../../assets/images/hcm/hcm-coe/Resource Centers/Staffing and Augmentation.png" alt="Staffing and Augmentation">
                </div>
                <div>
                    <p>
                        Staffing and Augmentation deliver comprehensive services for direct-hire, contract-to-hire, and staff augmentation from our dynamic <span class="d-inline-block">pool of problem-solvers.</span>
                    </p>
                </div>
                <div class="text-center mb-2">
                    <div class="sub_btn_style">
                        <a href="services/hcm/hcm-coe/staffing-and-augmentation-services">Learn More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>