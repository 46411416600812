<div class="row mb-5 vendors">
    <div class="col-md-6">
        <div>
            <h3>Ready to get started?</h3>
            <p class="mt-3">
                Explore Pixentia Services and get started to elevate your employee experience. 
                We'd love to support your employee centered vision, do contact us.
            </p>
        </div>
        <div class="mt-5">
            <a href="demo" class="button_style bg-orng">Schedule a Free Consultation <i class="fa fa-angle-right" aria-hidden="true"></i></a>
            <a href="contact-us" class="btn_style">Contact Us <i class="fa fa-angle-right" aria-hidden="true"></i></a>
        </div>
    </div>
    <div class="col-md-6">
        <div>
            <h3></h3>
            <p class="mt-5 font-weight-bold">
                Pixentia support is just a call away.
            </p>
            <p class="mb-0">
                Reach us if you have any queries or need any help.
            </p>
            <div class="mt-4">                    
                <a href="mailto:info@pixentia.com" class="btn_style">Contact Support <i class="fa fa-angle-right" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>
</div>