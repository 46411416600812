import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hcm-learning',
  templateUrl: './hcm-learning.component.html',
  styleUrls: ['./hcm-learning.component.scss']
})
export class HcmLearningComponent implements OnInit {

  title = 'Pixentia Workforce Learning Services';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Complete services for  workforce learning: strategy and planning, deployment, optimization, and content that enables you to build a learning organization.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Workforce Learning Services' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Complete services for  workforce learning: strategy and planning, deployment, optimization, and content that enables you to build a learning organization.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/hcm/learning' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Workforce Learning Services' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Complete services for  workforce learning: strategy and planning, deployment, optimization, and content that enables you to build a learning organization.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/hcm/learning' }      
    );
  }

}
