<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps">Chasma Apps</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps/chasma-botbuilder">Chasma Bot Builder</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Bot Builder</h3>
                <p>Chatbot Management for Non-technical Users</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/botbuilder/Botbuilder-image.webp" alt="Chasma Bot Builder image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">
                    Boost Customer Service with Intelligent Chatbots
                </h1>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <p class="mb-3">
                            A lot of people today are happy to interact with chatbots. You can deploy a smart, friendly chatbot to do most of the routine tasks that humans do now, and you can do it in such a way that your <span class="d-inline-block">customers will be delighted.</span>
                        </p>
                        <p class="mb-3">
                            Chasma's Bot Builder gives you all the tools you need to offload the tedious, repetitious work to a smart machine so your agents can focus on service that makes a difference.
                        </p>
                        <p>
                            For example, your Bot can capture account numbers, emails, order numbers, and issue description, then answer questions or determine that a live agent is needed—then make the transition automatically.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="mb-5 mt-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center mb-4">
                <h4 class="font-weight-bold py-4 mb-0">
                    Build Your Own Customer Service Chatbot
                </h4>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/botbuilder/Create.png" alt="Eliminate Labor-Intensive Manual Testing" class="mb-3">
                    <p class="px-4">
                        Create a bot that can naturally interact with users with AI cognitive services.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/botbuilder/Smooth.png" alt="Automated Integrations Testing" class="mb-3">
                    <p class="px-4">
                        Smooth transition to live chat <span class="d-inline-block">with an agent.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/botbuilder/Deploy.png" alt="Enjoy a Vast Library of Test Scenarios" class="mb-3">
                    <p class="px-4">
                        Deploy your Bot to popular channels, wherever your customers interact.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/botbuilder/Your assistant.png" alt="Configuration" class="mb-3">
                    <p class="px-4">
                        Your assistant, your brand, with our <span class="d-inline-block">tools and support.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/botbuilder/No need.png" alt="Real time Status" class="mb-3">
                    <p class="px-4">
                        No need for computer-speak. Use natural language in an open, <span class="d-inline-block">extensible network.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/botbuilder/Create conversational.png" alt="Automated Reporting" class="mb-3">
                    <p class="px-4">
                        Create a conversational experience while maintaining control of your data <span class="d-inline-block">and customer flow.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Bot Builder?</h4>
                    <p class="">
                        Contact us to see how Chasma's Bot Builder, a fully customizable, easy-to-use tool can help you create a helpful robot that will listen to a customer’s questions or concerns and automatically provide routine information <span class="d-inline-block">you make available.</span>
                    </p>
                    <p>
                        <a href="contact-us" class="video_btn_style text-white">Contact Us <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <img src="../../../assets/images/products/botbuilder/botbuilder-video-img.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/images/products/event/Need more.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-white pl-md-5 mt-5 mt-md-0">
                        <h2 class="font-weight-bold">Create Your Customer Service Chatbot
                        </h2>
                        <p class="mb-4">You don't have to be a tech wizard to create your customer service chatbot. Chasma Bot Builder gives you bot management anyone can do. </p>
                        <div class="more_info_btn">
                            <a href="demo">Explore Bot Builder Now <i class="fa fa-angle-right ml-2"
                                    aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>