import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-workday-analytics',
  templateUrl: './workday-analytics.component.html',
  styleUrls: ['./workday-analytics.component.scss']
})
export class WorkdayAnalyticsComponent implements OnInit {

  title = 'Chasma Analytics for Workday®';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Chasma Analytics provides tools in Workday to measure efficiency, create strategic alignment, and drive profitable growth.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Chasma Analytics for Workday®' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Chasma Analytics provides tools in Workday to measure efficiency, create strategic alignment, and drive profitable growth.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/products/chasma-analytics/workday-analytics' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Chasma Analytics for Workday®' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Chasma Analytics provides tools in Workday to measure efficiency, create strategic alignment, and drive profitable growth.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/products/chasma-analytics/workday-analytics' }      
    );
  }

}
