<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/enterprise-integrations">Enterprise Integrations</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Enterprise Application Integration Services</h3>
                <p>All your public and private data integrations on one secure platform
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/hcm/enterprise/Enterprise Integrations.png" alt="Enterprise Integrations Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h3 class="font-weight-bold">Data Integration Is the Lifeblood of Your Modern Enterprise</h3>
                </div>
                <div>
                    <p class="mb-3">
                        Keep it flowing
                    </p>
                    <p class="mb-0">
                        Integrating your data flow is vital to your business, whether your applications are in the cloud, on-premises, or a hybrid model.
                    </p>
                    <p>
                        Pixentia delivers state-of-the-art integrations, from a single application to integrating your entire enterprise.
                    </p>
                    <p>
                        Pixentia enterprise integration services keep you connected with complete end-to-end solutions, from dataflow planning to optimization and 24/7 monitoring.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="template_width my-4">
    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="sub_head">
                <h4 class="font-weight-bold">Strategy and Governance</h4>
                <p>
                    We bring your public and private data integrations together on one, secure platform—one place to go for business intelligence and analytics.
                </p>
                <p>
                    Take advantage of our governance tools and unified platform to reduce your cost.
                </p>
                <p>
                    Planned data flow and aggregation enable you to make better and faster strategic decisions.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right">
            <div>
                <img src="../../../../assets/images/hcm/enterprise/Strategy and Governance.png" alt="Strategy and Governance" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="enterprise_section">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <img src="../../../../assets/images/hcm/enterprise/Integration Standards.png" alt="Integration Standards" class="img-fluid">
            </div>
            <div class="col-md-6 text-white">
                <div class="sub_head">
                    <h4 class="font-weight-bold text-white">Integration Standards</h4>
                    <p>
                        We use the most up-to-date web APIs using JSON and REST standards.
                    </p>
                    <p>
                        Connect your SSIS and EDI data sources or upgrade to web services.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width my-4">
    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="sub_head">
                <h4 class="font-weight-bold">Optimization</h4>
                <p>
                    Plan and develop a single source of truth for every data set so you can eliminate rogue duplications.
                </p>
                <p>
                    We help you optimize for efficient data flow to deliver business intelligence when and where it’s needed.
                </p>
                <p>
                    Eliminate workflow bottlenecks that slow down business processes.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right">
            <div>
                <img src="../../../../assets/images/hcm/enterprise/Optimization.png" alt="Optimization" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="enterprise_section">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <img src="../../../../assets/images/hcm/enterprise/Support and Services.png" alt="Support and Services" class="img-fluid">
            </div>
            <div class="col-md-6 text-white">
                <div class="sub_head">
                    <h4 class="font-weight-bold text-white">Support and Services</h4>
                    <p>
                        We offer 24/7 monitoring and support, so you know when you have an integration failure right away.
                    </p>
                    <p>
                        Our managed services can take the worry out of data management.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn more about how Pixentia Admin services can offer comprehensive services for managing your enterprise learning
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/hcm-coe"> Back to
                                    Resource Center <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>