<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/talentmanagement">Talent Management</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Talent Management Implementation, Optimization, and Support</h1>
                <p>Select and implement the talent management platform and processes that support your strategy and culture. </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/hcm/talent/talentmanagement.png" alt="" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h3 class="text-center mb-5 font-weight-bold">
                    Boost Recruiting, Engagement, Performance, and Retention <span class="d-inline-block">with
                        Integrated Talent Management</span>
                </h3>
            </div>
            <div class="col-md-5">
                <img src="../../assets/images/hcm/talent/Boosting recruting.png" class="img-fluid" style="width: 100%;" alt="">
            </div>
            <div class="col-md-7">
                <p>
                    Well-designed talent management does more than manage jobs and people. It is a complete, integrated set of tools that enables you to create a superior employee experience throughout the entire employment life cycle. To achieve that, Pixentia’s HCM Center
                    of Excellence guides you through making the right decisions in talent management technology implementation, maintenance, and optimization.
                </p>
            </div>
        </div>
    </div>
    <div class="services_section">
        <div class="template_width">
            <div class="row mb-5">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified">
                        <li class="nav-item text-center">
                            <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Optimization
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <!-- Tab panes -->
                <div class="tab-content my-5">
                    <div class="tab-pane active" id="strategy">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <p>
                                    Dozens of well-respected and successful talent management platforms vie for a place in your technology stack. Most of them offer a “unified platform” in a single application, a platform of micro-applications, or an integrated suite.
                                </p>
                                <p>
                                    Each of them has its core strengths but may or may not have superior strength in the function that matters most to your company. The strategic imperative is to design a technology stack that supports your strategy, your culture, and your workforce objectives.
                                </p>
                                <p>
                                    Our strategy and culture discussions form the foundation for technology planning, which is a six- or seven-step process that creates teamwork and collaboration across your implementation team.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Straztegy and Planning/Technology Assessment.png" alt="">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Technology Assessment</p>
                                        <p>
                                            We assess the current state of your talent management technology and the tools necessary to achieve your business objectives.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Straztegy and Planning/Technology Selection.png" alt="">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">
                                            Technology Selection
                                        </p>
                                        <p>
                                            We provide tools for assessing and choosing the right technology for your business, <span class="d-inline-block">including RFP support.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Straztegy and Planning/Business Case Development.png" alt="">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Business Case Development</p>
                                        <p>
                                            We assist you in developing your business case so you can gain the support of your stakeholders and maximize the impact of your investment, and we provide tools for selecting the right technology package,
                                            <span
                                                class="d-inline-block">including RFP support.
                                                </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Straztegy and Planning/Technology Roadmap.png" alt="">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Technology Roadmap</p>
                                        <p>
                                            We plot your technology roadmap to determine where your current project fits into your long-term technology strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Straztegy and Planning/Project Governance.png" alt="">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Project Governance</p>
                                        <p>
                                            We work with you to establish a framework for project governance, so you can assign accountability and decrease your risks.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Straztegy and Planning/Change Management Support.png" alt="">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Change Management Support</p>
                                        <p>
                                            We support your change management and program marketing to foster user adoption.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Straztegy and Planning/Analytics Strategy.png" alt="">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Analytics Strategy</p>
                                        <p>
                                            We assist you in developing your measurement strategy to assess organizational performance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="mt-4">
                                    <div class="text-center cta_bg mx-md-2 py-3">
                                        <h5 class="font-weight-bold mb-3">Are your people initiatives being turned down?
                                        </h5>
                                        <p>
                                            Start a conversation about people and performance in the language your CFO understands.
                                        </p>
                                        <p>
                                            Download our free e-book: Building the Business Case for talent management
                                        </p>
                                        <p>
                                            Learn how to build alliances to change how people think about people programs.
                                        </p>
                                        <p class="datasheet_btn mb-0">
                                            <a href="">Get E book</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="deployment">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <p>
                                    Preparation, accountability, communication, and control are the foundations of a successful deployment. Our teams provide guidance, expertise, and methods to keep your project on schedule. Our workshops prepare you to become expert managers of your talent
                                    management platform so you can achieve your objectives.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Preparation and deployment/Project Planning.png" alt="Project Planning">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Project Planning</p>
                                        <p>
                                            We provide resource planning tools to identify the people, skills, and assets you will need to complete your project. Those tools include risk assessment and mitigation planning so you can <span class="d-inline-block">anticipate contingencies.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Preparation and deployment/Data Cleansing.png" alt="Data Cleansing">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">
                                            Data Cleansing
                                        </p>
                                        <p>
                                            We guide you through evaluating the condition of your data, assisting you in the decisions on what data to migrate. If you do migrate data, we provide cleansing tools to get your data in shape.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Preparation and deployment/Data Migration.png" alt="Data Migration">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Data Migration</p>
                                        <p>
                                            We support you as you prepare your legacy data, map your data transformations, and test the quality and usability of migrated data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Preparation and deployment/Workshops.png" alt="Workshops">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Workshops</p>
                                        <p>
                                            We provide facilitator-led workshops to evaluate your processes and workflows so you can prepare your team to configure and manage your talent management platform.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Preparation and deployment/Configuration and Review.png" alt="Configuration and Review">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Configuration and Review</p>
                                        <p>
                                            During the configuration phase of your project, we use an integrative process so you can review progress for each function as you go. Then, we assist you in reviewing business processes end to end to ensure that the entire workflow
                                            <span
                                                class="d-inline-block">produces accurate results.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Preparation and deployment/Integration.png" alt="Integration">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Integration</p>
                                        <p>
                                            We support integration with your time and attendance platform, your core systems of record, and third-party platforms.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-left border-right">
                                <div class="mt-4">
                                    <div class="text-center cta_bg mx-md-2 py-3">
                                        <h5 class="font-weight-bold mb-3">Are your people initiatives being turned down?
                                        </h5>
                                        <p>
                                            Start a conversation about people and performance in the language your CFO understands.
                                        </p>
                                        <p>
                                            Download our free e-book: Building the Business Case for talent management
                                        </p>
                                        <p>
                                            Learn how to build alliances to change how people think about people programs.
                                        </p>
                                        <p class="datasheet_btn mb-0">
                                            <a href="">Get E book</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Preparation and deployment/Analytics.png" alt="Analytics">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Analytics</p>
                                        <p>
                                            We support you as you implement your talent management reporting, auditing,
                                            <span class="d-inline-block">and analytics.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="optimization">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <p>
                                    Your platform is not fully implemented until you adopt the tools and practices that optimize your talent management delivery. And, as your needs change, we help you grow with your technology and your tech to grow with you.
                                </p>
                                <div class="sub_head mt-5">
                                    <h5 class="font-weight-bold">Optimization Services
                                    </h5>
                                    <p>
                                        Pixentia provides ongoing services to support your organizational growth and development by expanding your use and mastery of your talent management platform to enhance its function and value.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/Administration Optimization.png" alt="Administration Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Administration Optimization</p>
                                        <p>
                                            Optimize your administrative workflow to remove bottlenecks and pain points.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/Security Optimization.png" alt="Security Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">
                                            Security Optimization
                                        </p>
                                        <p>
                                            We help you audit your security configuration and, if needed, propose changes to protect your data and maintain <span class="d-inline-block">talent management compliance.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/Business Process Optimization.png" alt="Business Process Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Business Process Optimization
                                        </p>
                                        <p>
                                            We tune up your business processes and eliminate workarounds with process analysis.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/User Experience Optimization.png" alt="User Experience Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">User Experience Optimization</p>
                                        <p>
                                            We help you analyze user behavior and feedback so you can maintain positive user experience, accuracy, and efficiency.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/Analytics.png" alt="Analytics Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Analytics Optimization</p>
                                        <p>
                                            Our experts provide complete optimization services for all your report needs—from basic reporting to advanced predictive analytics
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                        </div>
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head mt-5">
                                    <h5 class="font-weight-bold mb-1">Resource Centers
                                    </h5>
                                    <p>
                                        Keeping your talent management platform updated and current with your organization’s needs requires ongoing, hands-on support. Let Pixentia’s specialists support your administrators, serve as your support backup, or provide comprehensive managed services.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/Admin Resource Center.png" alt="Admin Resource Center">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Admin Resource Center</p>
                                        <p>
                                            Let our team support your administrators in day-to-day operations and maintenance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/Analytics Resource Center.png" alt="Analytics Resource Center">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">
                                            Analytics Resource Center
                                        </p>
                                        <p>
                                            We provide support for reporting, workforce analytics, and data aggregation and storage.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/Technology Resource Center.png" alt="Technology Resource Center">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Technology Resource Center
                                        </p>
                                        <p>
                                            We offer support for technical tasks beyond day-to-day administration, including updates and regulatory changes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/talent/Optimization/Staffing and Augmentation.png" alt="Staffing and Augmentation">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Staffing and Augmentation</p>
                                        <p>
                                            Fill temporary gaps in specialized skills or take advantage of our flexible staffing levels to control your budget.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>