export class Domains {
    public static domains = [
        { domainName: "gmail.com" },
        { domainName: "tcs.com" },
        { domainName: "wipro.com" },
        { domainName: "infosys.com" },
        { domainName: "capgemini.com" },
        { domainName: "gpworldwide.com" },
        { domainName: "gpstrategies.com" },
        { domainName: "effective-people.com" },
        { domainName: "effective-learning.com" },
        { domainName: "tcs.org" },
        { domainName: "tcs.net" },
        { domainName: "alumnimail.utdallas.edu" },
        { domainName: "hexa.com" },
        { domainName: "free-server.bid" },
        { domainName: "gff.events" },
        { domainName: "outlook.com" },
        { domainName: "hailsa.gov.sa" },
        { domainName: "texaras.com" },
        { domainName: "osvhr.com" },
        { domainName: "teammegamedia@gmail.com" },
        { domainName: "nauvera.com" },
        { domainName: "brigadebig.com" },
        { domainName: "exasoft.com" },
        { domainName: "cognizant.com" },
        { domainName: "outlook.de" },
        { domainName: "accenture.com" },
        { domainName: "deloitte.com" },
        { domainName: "abeam.com" },
        { domainName: "aon.com" },
        { domainName: "appirio.com" },
        { domainName: "ataraxis-services.com" },
        { domainName: "bnetbuilders.com" },
        { domainName: "cloudator.com" },
        { domainName: "collaborativesolutions.com" },
        { domainName: "cpsgpartners.com" },
        { domainName: "csc.com" },
        { domainName: "daynine.com" },
        { domainName: "everbe.com" },
        { domainName: "hpe.com" },
        { domainName: "highpointsolutions.com" },
        { domainName: "hrmsconsulting.com" },
        { domainName: "huronconsultinggroup.com" },
        { domainName: "intecrowd.com" },
        { domainName: "kainos.com" },
        { domainName: "aon.com" },
        { domainName: "kpmg.com" },
        { domainName: "lake-stream.com" },
        { domainName: "mercer.com" },
        { domainName: "navmp.com" },
        { domainName: "ngahr.com" },
        { domainName: "oakton.com.au" },
        { domainName: "onesourcevirtual.com" },
        { domainName: "pwc.com" },
        { domainName: "realright.de" },
        { domainName: "sierra-cedar.com" },
        { domainName: "theoryofmind.com.au" },
        { domainName: "tieto.com" },
        { domainName: "kanjoya.com" },
        { domainName: "hexaware.com" },
        { domainName: "hcl.com" },
        { domainName: "cts.com" },
        { domainName: "xo.com" },
        { domainName: "vspincorp.com" },
        { domainName: "ironmountain.com" },
        { domainName: "sap.com" },
        { domainName: "mailinator.com" },
        { domainName: "capgemini.co.uk" },
        { domainName: "ibm.com" },
        { domainName: "capgemini.com" },
        { domainName: "symphonytalent.com" },
        { domainName: "gettyimages.com" },
        { domainName: "aonhewitt.com" },
        { domainName: "zensar.com" },
        { domainName: "miraclesoft.com" },
        { domainName: "gap.com" },
        { domainName: "enterpriseits.com" },
        { domainName: "hravatar.com" },
        { domainName: "absorblms.com" },
        { domainName: "progress.com" },
        { domainName: "dimensiondata.com" },
        { domainName: "cloudera.com" },
        { domainName: "elliemae.com" },
        { domainName: "fiserv.com" },
        { domainName: "dimensiondata.com" },
        { domainName: "path4change.com" },
        { domainName: "xactlycorp.com" },
        { domainName: "cubic.com" },
        { domainName: "jda.com" },
        { domainName: "ecova.com" },
        { domainName: "rackspace.com" },
        { domainName: "progress.com" },
        { domainName: "neustar.biz" },
        { domainName: "datastax.com" },
        { domainName: "softcomputer.com" },
        { domainName: "netlearningteam.org" },
        { domainName: "epicor.com" },
        { domainName: "sinergia-group.co.il" },
        { domainName: "trimeritus.com" },
        { domainName: "zoho.com" },
        { domainName: "oracle.com" },
        { domainName: "getbusinessfunded.com" },
        { domainName: "mintemail.com" },
        { domainName: "hotmail.it" },
        { domainName: "sharklasers.com" },
        { domainName: "ptc.com" },
        { domainName: "yandex.com" },
        { domainName: "mavro.cf" },
        { domainName: "rambler.ru" },
        { domainName: "findfastbusinessfunds.com" },
        { domainName: "rng.marvsz.com" },
        { domainName: "dnsrco.com" },
        { domainName: "cdc-consult.com" },
        { domainName: "bluewaterlearning.com" },
        { domainName: "smartfinancialfunding.com" },
        { domainName: "hcltech.com" },
        { domainName: "cognizant.com" },
        { domainName: "hclinfosystems.in" },
        { domainName: "techmahindra.com" },
        { domainName: "iflex.com" },
        { domainName: "niit.com" },
        { domainName: "polaris.co.in" },
        { domainName: "perotsystems.com" },
        { domainName: "sap.com" },
        { domainName: "ca.com" },
        { domainName: "hexaware.com" },
        { domainName: "lntinfotech.com" },
        { domainName: "mastek.com" },
        { domainName: "mphasis.com" },
        { domainName: "syntelinc.com" },
        { domainName: "in.capgemini.com" },
        { domainName: "rolta.com" },
        { domainName: "zensar.com" },
        { domainName: "tulip.net" },
        { domainName: "sonata-software.com" },
        { domainName: "3i-infotech.com" },
        { domainName: "csc.com" },
        { domainName: "infotech-enterprises.com" },
        { domainName: "kpitcummins.com" },
        { domainName: "itcinfotech.com" },
        { domainName: "mindtree.com" },
        { domainName: "pcstech.com" },
        { domainName: "geometricglobal.com" },
        { domainName: "cranessoftware.com" },
        { domainName: "persistentsys.com" },
        { domainName: "aztecsoftware.com" },
        { domainName: "birlasoft.com" },
        { domainName: "zenith-india.com" },
        { domainName: "hotmail.co.in" },
        { domainName: "afqtip.com" },
        { domainName: "nl.pwc.com" },
        { domainName: "highpoint-solutions.com" },
        { domainName: "iscconsulting.com" },
        { domainName: "prolifepowerup.com" },
        { domainName: "getbusinessfunded.com" },
        { domainName: "msn.com" },
        { domainName: "ciber.com" },
        { domainName: "buyanessaycheape.top" },
        { domainName: "iphonemsk.com" },
        { domainName: "iacg.in" },
        { domainName: "saama.com" },
        { domainName: "amberoon.com" },
        { domainName: "successinfusion.com" },
        { domainName: "FindFastBusinessFunds.com" },
        { domainName: "kinofan-online.ru" },
        { domainName: "mantestosterone.com" },
        { domainName: "vempyimpex.com" },
        { domainName: "maildrop.cc" },
        { domainName: "bigstring.com" },
        { domainName: "tt.com" },
        { domainName: "glassoflearning.com" },
        { domainName: "ezyiweb.com" },
        { domainName: "goohle.co.ua" },
        { domainName: "tournament-challenge.com" },
        { domainName: "dallasmail.com" },
        { domainName: "zaednoschools.org" },
        { domainName: "qip.ru" },
        { domainName: "onlinewcm.com" },
        { domainName: "dolinux.de" },
        { domainName: "taos.com" },
        { domainName: "glassoflearning.com" },
        { domainName: "getsynapse.com" },
        { domainName: "bcb-gmbh.ch" },
        { domainName: "happiestminds.com" },
        { domainName: "live.com" },
        { domainName: "workday.com" },
        { domainName: "me.com" },
        { domainName: "tbrfky.com" },
        { domainName: "bizco.biz" },
        { domainName: "areteconsultingservices.com" },
        { domainName: "l2r.dk" },
        { domainName: "yandex.kz" },
        { domainName: "penisenlargerpillsusa.com" },
        { domainName: "enhancemalepotency.com" },
        { domainName: "mail.ua" },
        { domainName: "tmdrive.com" },
        { domainName: "testosteroneforman.com" },
        { domainName: "moscowmail.ru" },
        { domainName: "battle-force.com" },
        { domainName: "csdinterpretingonline.com" },
        { domainName: "chemodanymos.com" },
        { domainName: "getabusinessloan365.com" },
        { domainName: "snailmail.men" },
        { domainName: "b2bmail.download" },
        { domainName: "gmail.ru" },
        { domainName: "criteriaconsulting.co.uk" },
        { domainName: "northgatearinso.com" },
        { domainName: "absa.co.za" },
        { domainName: "textonglobal.com" },
        { domainName: "yandex.ua" },
        { domainName: "peepconsulting.com" },
        { domainName: "peopletechgroup.com" },
        { domainName: "g6hospitality.com" },
        { domainName: "dimension-six.com" },
    ];

}