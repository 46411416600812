import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chasma-editor',
  templateUrl: './chasma-editor.component.html',
  styleUrls: ['./chasma-editor.component.scss']
})
export class ChasmaEditorComponent implements OnInit {

  title = 'Chasma Editor | Portals and Dashboards';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Chasma Editor is a drag-and-drop template for landing pages and portals. Choose from our hundreds of templates, or build your own.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Chasma Editor | Portals and Dashboards' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Chasma Editor is a drag-and-drop template for landing pages and portals. Choose from our hundreds of templates, or build your own.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/products/chasma-apps/chasma-editor' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Chasma Editor | Portals and Dashboards' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Chasma Editor is a drag-and-drop template for landing pages and portals. Choose from our hundreds of templates, or build your own.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/products/chasma-apps/chasma-editor' }      
    );
  }

}
