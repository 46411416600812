import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  title = 'Pixentia | Contact Us';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: "Contact us for HCM technology consulting, deployment, service, and support. Let's chat about your business goals." }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia | Contact Us' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: "Contact us for HCM technology consulting, deployment, service, and support. Let's chat about your business goals." }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/contact-us' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia | Contact Us' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: "Contact us for HCM technology consulting, deployment, service, and support. Let's chat about your business goals." }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/contact-us' }      
    );
  }

}
