<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Cloud Transformation</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/cloud-transformation/ceridian">Ceridian</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Consulting, Advisory, and Implementation Services for Ceridian Dayforce® Human Capital Management</h1>
                <p>A Relentless Focus on Your Objectives
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Ceridian Image.png" alt="Ceridian Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-4 align-items-center info_section">
            <div class="col-md-12 sub_head">
                <h3 class="text-center mb-3 font-weight-bold main-color">
                    Maximize the value and impact of your
                    <span class="d-md-block">Ceridian Dayforce® HCM technology</span>
                </h3>
                <div class="col-md-8 offset-md-2">
                    <p class="text-center">
                        Ceridian’s Dayforce® platform helps you manage the entire employee lifecycle, from recruiting and onboarding, to paying people and developing their careers.
                    </p>
                </div>
                <div class="col-md-10 offset-md-1 my-4 ceridian">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <ul class="nav nav-pills flex-column" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active border-b0" id="home-tab" data-toggle="tab" href="#hr" role="tab" aria-controls="home" aria-selected="true"><img src="../../../../assets/images/cloud-transformations/ceridian/strategy/hr.png" alt="" class="mr-2">HR</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link border-b0" id="profile-tab" data-toggle="tab" href="#payroll" role="tab" aria-controls="profile" aria-selected="false"><img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Payroll.png" alt="" class="mr-2">Payroll</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link border-b0" id="contact-tab" data-toggle="tab" href="#benefit" role="tab" aria-controls="contact" aria-selected="false"><img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Benefits.png" alt="" class="mr-2">Benefits</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link border-b0" id="profile-tab" data-toggle="tab" href="#talent" role="tab" aria-controls="profile" aria-selected="false"><img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Talent Management23.png" alt="" class="mr-2">Talent Management</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#workforce" role="tab" aria-controls="contact" aria-selected="false"><img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Workforce Management.png" alt="" class="mr-2">Workforce Management</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-8">
                            <div class="tab-content ceridian_list_content" id="myTabContent">
                                <div class="tab-pane fade show active" id="hr" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/A single employee.png" class="mr-2" alt="Intuitive, consistent, and transparent navigation">
                                            </p>
                                            <p>
                                                A single employee record across all HR functions.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Gain deep insights.png" class="mr-2" alt="Simple, clutter-free layout">
                                            </p>
                                            <p>
                                                Gain deep insights into your workforce.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Help employees take.png" class="mr-2" alt="High-quality Images, icons, and artwork">
                                            </p>
                                            <p>
                                                Help employees take charge of their own work life.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="payroll" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="">
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Payroll and time.png" class="mr-2" alt="Payroll and time">
                                            </p>
                                            <p>
                                                Payroll and time in a single system, with continuous calculation throughout the entire pay period.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Reduce rework.png" class="mr-2" alt="Simple, clutter-free layout">
                                            </p>
                                            <p>
                                                Reduce rework and increase accuracy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="benefit" role="tabpanel" aria-labelledby="contact-tab">
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Benefits specifically.png" class="mr-2" alt="Benefits specifically tailored">
                                            </p>
                                            <p>
                                                Benefits specifically tailored to each employee’s profile.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Support tools to.png" class="mr-2" alt="Support tools">
                                            </p>
                                            <p>
                                                Support tools to help employees evaluate multiple plan options.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Improve process.png" class="mr-2" alt="Improve process efficiency">
                                            </p>
                                            <p>
                                                Improve process efficiency.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="talent" role="tabpanel" aria-labelledby="contact-tab">
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Attract and onboard.png" class="mr-2" alt="Attract and onboard top talent">
                                            </p>
                                            <p>
                                                Attract and onboard top talent.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Align and develop your.png" class="mr-2" alt="Align and develop your employees">
                                            </p>
                                            <p>
                                                Align and develop your employees.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Reward and retain.png" class="mr-2" alt="Reward and retain your talent">
                                            </p>
                                            <p>
                                                Reward and retain your talent.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="workforce" role="tabpanel" aria-labelledby="contact-tab">
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Improve operational efficiency.png" class="mr-2" alt="Improve operational efficiency">
                                            </p>
                                            <p>
                                                Improve operational efficiency.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Decrease labor costs.png" class="mr-2" alt="Decrease labor costs">
                                            </p>
                                            <p>
                                                Decrease labor costs.
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Reduce compliance issue.png" class="mr-2" alt="Reduce compliance issues">
                                            </p>
                                            <p>
                                                Reduce compliance issues.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="text-center mb-3 font-weight-bold main-color">
                    Why Is Pixentia Your Ideal Technology Partner?
                </h3>
                <p class="font-weight-bold text-center">It’s our focus on your objectives</p>
                <div class="col-md-8 offset-md-2">
                    <p class="text-center">
                        We learn your business and your people strategy before we make recommendations on how your technology can meet your organization’s workforce needs. Leverage our expert knowledge of configuration and tools to develop intuitive workflows that cut costs,
                        increase proficiency, and improve performance.
                    </p>
                    <p class="text-center">
                        Let us help you exploit your technology to maximize its impact on workforce performance and growth.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <h3 class="text-center mb-3 font-weight-bold main-color">
                    Ceridian Dayforce® Center of Excellence
                </h3>
            </div>
        </div>
    </div>
    <div class="community_section mb-3">
        <div class="services_section">
            <div class="template_width">
                <div class="row mb-5">
                    <div class="col-md-12">
                        <ul class="nav nav-pills nav-justified">
                            <li class="nav-item text-center">
                                <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                    <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                    <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                    <img src="../../assets/images/hcm/talent/Resource Centers.png" class="d-block mx-auto" alt=""> Learning Content
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#resource">
                                    <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Optimization
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <!-- Tab panes -->
            <div class="tab-content mt-5">
                <div class="tab-pane active" id="strategy">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4 main-color">
                                    Strategy and Planning Services for Ceridian Dayforce®
                                </h4>
                            </div>
                            <p>
                                Whether your organization is evaluating Dayforce® or already using it, Pixentia starts by developing a deep understanding of your business and workforce goals  so we can make informed recommendations.
                            </p>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Technology Assessment Services
                                        </h4>
                                        <p>
                                            A structured workshop to map your current situation and your vision so you can create a solid foundation for your strategy. Our lead consultant explores your technology strategy with you.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Your current technology.png" class="mr-2" alt="Your current technology, plans">
                                        </p>
                                        <p>
                                            Your current technology, plans, and where your project fits in your workforce strategy.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Maximizing the.png" class="mr-2" alt="Maximizing the impact of your investment">
                                        </p>
                                        <p>
                                            Maximizing the impact of your investment.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p>
                                            <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/An integrated.png" class="mr-2" alt="An integrated approach to human capital management">
                                        </p>
                                        <p>
                                            An integrated approach to human capital management.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Technology Assessment Services.png" alt="Technology Assessment Services" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Platform Recommendations.png" alt="Platform Recommendations" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Platform Recommendations</h4>
                                        <p>
                                            Tools and support for evaluating potential solutions execute your HCM strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Business Case Development
                                        </h4>
                                        <p>
                                            We provide tools and support for building your business case and identifying the benefits of your implementation.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Working with operational.png" class="mr-2" alt="Working with operational managers">
                                            </p>
                                            <p>
                                                Working with operational managers to get realistic estimates of the impact of your project.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Methods and.png" class="mr-2" alt="Methods and tools for calculating ROI">
                                            </p>
                                            <p>
                                                Methods and tools for calculating ROI and breakeven analysis.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Guidance and tools.png" class="mr-2" alt="Guidance and tools for analyzing risks">
                                            </p>
                                            <p>
                                                Guidance and tools for analyzing risks and developing mitigation plans.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Business Case Development.png" alt="Business Case Development" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Learning Technology Roadmap.png" class="img-fluid" alt="Learning Technology Roadmap">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Learning Technology Roadmap</h4>
                                        <p>
                                            Develop a long-term technology plan that grows with you as your needs change.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Potential new initiatives.png" class="mr-2" alt="Potential new initiatives based on technology">
                                            </p>
                                            <p>
                                                Potential new initiatives based on technology and market trends.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Estimated costs.png" class="mr-2" alt="Estimated costs">
                                            </p>
                                            <p>
                                                Estimated costs based on current information.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Evaluation of.png" class="mr-2" alt="Evaluation of technology life cycles">
                                            </p>
                                            <p>
                                                Evaluation of technology life cycles.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Project Governance</h4>
                                        <p>
                                            A framework for decision-making and risk management.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Establishing stakeholder.png" class="mr-2" alt="Establishing stakeholder relationships">
                                            </p>
                                            <p>
                                                Establishing stakeholder relationships.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Establishing responsibilities.png" class="mr-2" alt="Establishing responsibilities and accountability abilities">
                                            </p>
                                            <p>
                                                Establishing responsibilities and accountability abilities for each phase and activity of your project.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Creating a framework.png" class="mr-2" alt="Creating a framework for project communication">
                                            </p>
                                            <p>
                                                Creating a framework for project communication.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Project Governance.png" alt="Project Governance" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Change Management.png" alt="Change Management" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Change Management</h4>
                                        <p>
                                            Increase employee adoption of Dayforce® applications and provide leadership with timely data on utilization and proficiency.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Recommendations for two.png" class="mr-2" alt="Recommendations for two-way communication">
                                            </p>
                                            <p>
                                                Recommendations for two-way communication channels and project feedback to create a free flow of ideas.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Program marketing.png" class="mr-2" alt="Program marketing techniques">
                                            </p>
                                            <p>
                                                Program marketing techniques using gamification techniques to build enthusiasm.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Resource guides for.png" class="mr-2" alt="Resource guides for learners">
                                            </p>
                                            <p>
                                                Resource guides for learners, administrators, and managers to give them a jumpstart on managing their responsibilities.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/change-management">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">People Analytics Strategy
                                        </h4>
                                        <p>
                                            Assess and report on progress toward your goals and measure the impact on performance.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Develop metrics.png" class="mr-2" alt="Develop metrics and measures">
                                            </p>
                                            <p>
                                                Develop metrics and measures to report on project progress.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Map metrics to.png" class="mr-2" alt="Map metrics to key performance indicators">
                                            </p>
                                            <p>
                                                Map metrics to key performance indicators.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/Evaluate the impact.png" class="mr-2" alt="Evaluate the impact on performance">
                                            </p>
                                            <p>
                                                Evaluate the impact on performance and productivity.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/strategy/People Analytics Strategy.png" alt="People Analytics Strategy" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="deployment">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4 main-color">
                                    A Structured, Scalable Approach to Technology Deployment
                                </h4>
                            </div>
                            <p>
                                Preparation, accountability, communication, and control are the foundations of a successful deployment. Our teams provide guidance, expertise, and methods to keep your project on schedule. Our workshops prepare you to become expert managers of your technology
                                platform so you can achieve your objectives.
                            </p>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold mb-3">Project Planning</h4>
                                        <p>
                                            Tools and expertise for managing your timeline and resources, plus in-depth risk assessment and mitigation planning.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/A phased project plan.png" class="mr-2" alt="Phased project plan helps you manage timelines">
                                            </p>
                                            <p>
                                                A phased project plan to establish timelines and assign accountability.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Resource planning.png" class="mr-2" alt="Resource planning helps you control your budget">
                                            </p>
                                            <p>
                                                Resource planning to identify the people, skills, and assets.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Project governance.png" class="mr-2" alt="Project governance establishes communication">
                                            </p>
                                            <p>
                                                Project governance to establish communication channels with stakeholders.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Project Planning.png" alt="Project Planning" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Data Cleansing.png" alt="Data Cleansing" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white mb-3">Data Cleansing</h4>
                                        <p>
                                            Ensuring consistent and reliable legacy data before you migrate to a new platform.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Guiding you.png" class="mr-2" alt="Guiding you through evaluating the condition of your data">
                                            </p>
                                            <p>
                                                Guiding you through evaluating the condition of your data.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Assisting you in.png" class="mr-2" alt="Assisting you in the decision on whether and what to migrate">
                                            </p>
                                            <p>
                                                Assisting you in the decision on whether and what to migrate.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Providing data.png" class="mr-2" alt="Providing data cleansing tools and expertise to get your data in shape">
                                            </p>
                                            <p>
                                                Providing data cleansing tools and expertise to get your data in shape.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold mb-3">Data Migration</h4>
                                        <p>
                                            Preparing and testing your legacy data. Our support includes:
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Mapping and.png" class="mr-2" alt="Mapping and importing data to the new platform">
                                            </p>
                                            <p>
                                                Mapping and importing data to the new platform.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Eliminating outdated.png" class="mr-2" alt="Eliminating outdated field names and data structures">
                                            </p>
                                            <p>
                                                Eliminating outdated field names and data structures.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Testing the quality.png" class="mr-2" alt="Testing the quality and usability of the migrated data">
                                            </p>
                                            <p>
                                                Testing the quality and usability of the migrated data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Data Migration.png" alt="Data Migration" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Workshops.png" alt="Workshops" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head text-white">
                                        <h4 class="font-weight-bold text-white mb-3">Workshops</h4>
                                        <p>
                                            Structured to give platform owners the skills to master Dayforce® capabilities.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Our Business Workshop.png" class="mr-2" alt="Our Business Workshop maps your existing processes">
                                            </p>
                                            <p>
                                                Our Business Workshop maps your existing processes, examines pain points and inefficiencies, and develops improved workflows.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/The Training Workshop.png" class="mr-2" alt="Training Workshop prepares you to manage processes and workflow">
                                            </p>
                                            <p>
                                                The Training Workshop prepares you to manage your processes and workflows in Dayforce® HCM.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Our Configuration.png" class="mr-2" alt="Configuration workshop aligns technology to your business requirements">
                                            </p>
                                            <p>
                                                Our Configuration Workshop shows you how to align your new technology to your business.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/business-workshop">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row align-items-center my-5">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Configuration and review</h4>
                                        <p>
                                            We involve you in the design so you can evaluate and approve each configuration phase.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Iterative process.png" class="mr-2" alt="Iterative process to review progress of each module and function">
                                            </p>
                                            <p>
                                                Iterative process to review progress of each module and function.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/You conduct a final.png" class="mr-2" alt="You conduct a final review to ensure that the configuration meets your requirements">
                                            </p>
                                            <p>
                                                You conduct a final review to ensure that the configuration meets your requirements.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/You sign off on the.png" class="mr-2" alt="You sign off on the configuration and you are ready to begin testing">
                                            </p>
                                            <p>
                                                You sign off on the configuration and you are ready to begin testing.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/configuration-workshop">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center text-md-right mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Configuration and review.png" alt="Configuration and review" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-md-left text-center mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Integration.png" alt="Integration" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6 text-white">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Integration</h4>
                                        <p>
                                            Connect core systems, remote systems, and third-party platforms to maintain accurate records and analytical data. We enable you to:
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Identify the single.png" class="mr-2" alt="Identify the single source of truth for each dataset">
                                            </p>
                                            <p>
                                                Identify the single source of truth for each dataset.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Connect your new.png" class="mr-2" alt="Connect your new platform to core systems to maintain accurate user records">
                                            </p>
                                            <p>
                                                Connect your new platform to core systems to maintain accurate user records.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Perform optional.png" class="mr-2" alt="Perform optional integrations with any of your business applications and your third-party providers">
                                            </p>
                                            <p>
                                                Perform optional integrations with any of your business applications and your third-party providers.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/integration">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row align-items-center my-5">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Analytics</h4>
                                        <p>
                                            With your unified Dayforce® platform, you can implement reporting and analytics across all your HR functions to gain a holistic view of your workforce. Our services include:
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Enabling and supporting.png" class="mr-2" alt="Enabling and supporting your reporting">
                                            </p>
                                            <p>
                                                Enabling and supporting your reporting to enable you to gain business intelligence from your Dayforce® platform.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Supporting your.png" class="mr-2" alt="Supporting your report developers">
                                            </p>
                                            <p>
                                                Supporting your report developers and administrators in creating and distributing reports.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Support your.png" class="mr-2" alt="Support your analytics team">
                                            </p>
                                            <p>
                                                Support your analytics team so you can leverage predictive analytics make better and faster business decisions.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/analytics-deployment">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center text-md-right mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Analytics.png" alt="Analytics" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Testing.png" alt="Testing" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6 text-white">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Testing</h4>
                                        <p>
                                            Support for User Acceptance Testing with triage and issue management. We also offer automated testing to reduce manual errors and manual testing costs.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Script building.png" class="mr-2" alt="Script building and tracking for your User Acceptance Testing">
                                            </p>
                                            <p>
                                                Script building and tracking for your User Acceptance Testing.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Best practices.png" class="mr-2" alt="Best practices for managing testing issue triage">
                                            </p>
                                            <p>
                                                Best practices for managing testing issue triage.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/preparation/Focusing on evaluating.png" class="mr-2" alt="Focusing on evaluating the user experience">
                                            </p>
                                            <p>
                                                Focusing on evaluating the user experience.
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/testing">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="optimization">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4 main-color">
                                    For the Way Your People Learn
                                </h4>
                            </div>
                            <p>
                                Finding learning content is easy. Finding learning that fits your objectives, your audience, and your culture is a lot harder. Pixentia’s development framework assures your workforce the best possible learning experience because you are involved in the
                                design from the first day.
                            </p>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Strategy & Planning</h4>
                                        <p>
                                            Our strategy and planning work sessions define your objectives and prepare your plan.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Explore with you how.png" class="mr-2" alt="Explore with you how your content strategy">
                                            </p>
                                            <p>
                                                Explore with you how your content strategy fits into your workforce strategy and the current state of your technology and programs.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Learn the needs.png" class="mr-2" alt="Learn the needs of your audience and their current knowledge">
                                            </p>
                                            <p>
                                                Learn the needs of your audience and their current knowledge and skills levels to provide the starting point for your learning initiative.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Establish governanc.png" class="mr-2" alt="Establish governance and methodologies for working together">
                                            </p>
                                            <p>
                                                Establish governance and methodologies for working together.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Strategy  Planning.png" class="img-fluid" alt="Strategy & Planning">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Delivery Modalities.png" class="img-fluid" alt="Delivery Modalities">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Delivery Modalities</h4>
                                        <p>
                                            Our experts help you explore the learning modalities that support your strategy and objectives and the needs of your learners. We conduct a detailed work context analysis that enables you to determine the optimum learning blend.
                                        </p>
                                        <p>
                                            We offer a complete range of learning techniques, from simple gamification to advanced AI-driven technologies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Gamification</h4>
                                        <p>
                                            Increases learning engagement, stimulates creative thinking, and builds a sense of achievement with feedback and rewards.
                                        </p>
                                        <p class="orange_color">
                                            See how gamification boosts learning
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/gamification">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Gamification.png" class="img-fluid" alt="Gamification">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Augmented Reality.png" class="img-fluid" alt="Augmented Reality">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Augmented Reality</h4>
                                        <p>
                                            Overlays the real world or VR with digital information to support rapid decision-making skills and provides real-time feedback.
                                        </p>
                                        <p class="text-white">
                                            Speed up learning with visual feedback
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/augmented-reality">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Virtual Reality</h4>
                                        <p>
                                            Create a lifelike, computer-generated work context.
                                        </p>
                                        <p class="orange_color">
                                            Remove the barriers between learning and doing
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/virtual-reality">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Virtual Reality.png" class="img-fluid" alt="Virtual Reality">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Simulation.png" class="img-fluid" alt="Simulation">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Simulation</h4>
                                        <p>
                                            Enables learners to learn and practice skills in a risk-free environment.
                                        </p>
                                        <p class="text-white">
                                            Learn how simulation builds job skills.
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/simulation">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Sourcing</h4>
                                        <p>
                                            Sourcing available content can reduce your costs and speed up your implementation. We can integrate content from any source, including remote content servers. We assist you in locating content that fits your business needs and the needs of your audience.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Sourcing.png" class="img-fluid" alt="Sourcing">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/UI and Design.png" class="img-fluid" alt="UI and Design">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">UI and Design</h4>
                                        <p>
                                            Instructional design is the starting point for a useful and engaging learner experience.
                                        </p>
                                        <p>
                                            We work with your SMEs to develop a design that meets your objectives and the needs of your audience. We base our learning design on the principle that its elements should adapt to the way people learn.
                                        </p>
                                        <p class="text-white">
                                            Learn more about Pixentia UI and design services.
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/user-experience-and-design">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Learning Development</h4>
                                        <p>
                                            Our experts use the authoring platform most appropriate for your learning needs and your L&D team. We use tools like Articulate 360, Adobe Captivate and adapt HTML5. We focus on
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Intuitive.png" class="mr-2" alt="Intuitive, consistent, and transparent navigation">
                                            </p>
                                            <p>
                                                Intuitive, consistent, and transparent navigation.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Simple.png" class="mr-2" alt="Simple, clutter-free layout">
                                            </p>
                                            <p>
                                                Simple, clutter-free layout.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/High-quality Image.png" class="mr-2" alt="High-quality Images, icons, and artwork">
                                            </p>
                                            <p>
                                                High-quality Images, icons, and artwork.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Effortless.png" class="mr-2" alt="Effortless, easy-to-understand reading">
                                            </p>
                                            <p>
                                                Effortless, easy-to-understand reading.
                                            </p>
                                        </div>
                                        <p class="orange_color">
                                            Learn more about Pixentia e-learning development services.
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/development">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Learning Development.png" class="img-fluid" alt="Learning Development">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Deployment and Testing.png" class="img-fluid" alt="Deployment and Testing">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Deployment and Testing</h4>
                                        <p>
                                            We help you test your learning design, and recommend you have your users test it. Their feedback can be invaluable. Our services include:
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Planning your testing.png" class="mr-2" alt="Planning your testing">
                                            </p>
                                            <p>
                                                Planning your testing.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Tracking and reporting tools.png" class="mr-2" alt="Tracking and reporting tools">
                                            </p>
                                            <p>
                                                Tracking and reporting tools.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Assistance with triage.png" class="mr-2" alt="Assistance with triage and issue management">
                                            </p>
                                            <p>
                                                Assistance with triage and issue management.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Content Integration</h4>
                                        <p>
                                            Our integration experts can connect your learners to any source, including third-party providers or your internal resource repositories.
                                        </p>
                                        <p class="">
                                            We also recommend and help you deploy authentication methods that make the shift to external resources transparent to your learners.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/learning-content/Content Integration.png" class="img-fluid" alt="Content Integration">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="resource">
                    <div class="row mx-0">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4 main-color">
                                        Banish Bottlenecks, Workarounds, And Inefficient Processes
                                    </h4>
                                </div>
                                <p>
                                    Your platform is not fully implemented until you adopt the tools and practices you need to optimize your service delivery. As your needs change, we help your technology grow with you.
                                </p>
                            </div>
                            <div class="text-center">
                                <p class="font-weight-bold mb-0">
                                    Pixentia provides ongoing services to support your organizational growth and development:
                                </p>
                                <p>
                                    Expanding your use and mastery of the Dayforce® platform to enhance its function and value. Application and content development to grow your ability to deliver efficiency and excellence. Supporting your digital transformation to a data-driven organization.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Administration Optimization</h4>
                                        <p>
                                            Optimize your administrative workflow to remove bottlenecks and pain points.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Map administrative.png" class="mr-2" alt="Map administrative workflow to develop a visual representation of task alignment">
                                            </p>
                                            <p>
                                                Map administrative workflow to develop a visual representation of task alignment.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Identify pain points.png" class="mr-2" alt="Identify pain points and bottlenecks">
                                            </p>
                                            <p>
                                                Identify pain points and bottlenecks.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Automate offline.png" class="mr-2" alt="Automate offline processes and workarounds">
                                            </p>
                                            <p>
                                                Automate offline processes and workarounds.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Administration Optimization.png" class="img-fluid" alt="Administration Optimization">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Security Optimization.png" class="img-fluid" alt="Security Optimization">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Security Optimization</h4>
                                        <p>
                                            Align your security configuration to achieve the best fit for the needs of your organization.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Apply governance.png" class="mr-2" alt="Apply governance principles to security role assignments">
                                            </p>
                                            <p>
                                                Apply governance principles to security role assignments.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Remove duplication.png" class="mr-2" alt="Remove duplication in security roles assignments">
                                            </p>
                                            <p>
                                                Remove duplication in security roles assignments.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Review constraints.png" class="mr-2" alt="Review constraints to ensure data security">
                                            </p>
                                            <p>
                                                Review constraints to ensure data security.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Business Process Optimization</h4>
                                        <p>
                                            Analysis of your business processes and workflow to remove chokepoints and workarounds.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Analyze and map.png" class="mr-2" alt="Analyze and map business processes to identify workarounds and offline sub-processes">
                                            </p>
                                            <p>
                                                Analyze and map business processes to identify workarounds and offline sub-processes.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Review decision.png" class="mr-2" alt="Review decision points and options to align them with standard configurations">
                                            </p>
                                            <p>
                                                Review decision points and options to align them with standard configurations.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Automate offline-1.png" class="mr-2" alt="Automate offline processes that create bottlenecks">
                                            </p>
                                            <p>
                                                Automate offline processes that create bottlenecks.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Business Process Optimization.png" class="img-fluid" alt="Business Process Optimization">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/User Experience Optimization.png" class="img-fluid" alt="User Experience Optimization">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">User Experience Optimization</h4>
                                        <p>
                                            Analyze user behavior and feedback to create an engaging, useful experience.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Analyze workflows.png" class="mr-2" alt="Analyze workflows from the user perspective">
                                            </p>
                                            <p>
                                                Analyze workflows from the user perspective.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Gather user feedback.png" class="mr-2" alt="Gather user feedback and analyze behavior tracking to identify user pain points">
                                            </p>
                                            <p>
                                                Gather user feedback and analyze behavior tracking to identify user pain points.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Include end users.png" class="mr-2" alt="Include end users in experience analysis and recommendations">
                                            </p>
                                            <p>
                                                Include end users in experience analysis and recommendations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Analytics Optimization </h4>
                                        <p>
                                            Develop insights about people and work to help you move toward becoming a data-driven learning organization.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Apply statistical.png" class="mr-2" alt="Apply statistical analysis in Dayforce® Analytics so you can identify trends and anomalies in your workforce">
                                            </p>
                                            <p>
                                                Apply statistical analysis in Dayforce® Analytics so you can identify trends and anomalies in your workforce.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Determine what.png" class="mr-2" alt="Determine what statistical trends and anomalies require further study">
                                            </p>
                                            <p>
                                                Determine what statistical trends and anomalies require further study.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Develop analytical.png" class="mr-2" alt="Develop analytical models to determine root causes so you can understand the why of your data">
                                            </p>
                                            <p>
                                                Develop analytical models to determine root causes so you can understand the why of your data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Analytics Optimization.png" class="img-fluid" alt="Analytics Optimization">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Content Optimization.png" class="img-fluid" alt="Content Optimization">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Content Optimization</h4>
                                        <p>
                                            Identify the learning methodologies, delivery modes, and technology that achieve your learning objectives — within your business and budget constraints.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Understand your.png" class="mr-2" alt="Understand your learning needs and business priorities">
                                            </p>
                                            <p>
                                                Understand your learning needs and business priorities.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Analyze current.png" class="mr-2" alt="Analyze current methodologies, delivery modes, and technology to identify inefficient delivery">
                                            </p>
                                            <p>
                                                Analyze current methodologies, delivery modes, and technology to identify inefficient delivery.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Analyze recommendations.png" class="mr-2" alt="Analyze recommendations to help you develop a long-term workforce development strategy">
                                            </p>
                                            <p>
                                                Analyze recommendations to help you develop a long-term workforce development strategy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="template_width">
                        <div class="row">
                            <div class="col-md-12 text-center my-5">
                                <h4 class="font-weight-bold mb-4">
                                    Pixentia Dayforce® Resource Centers
                                </h4>
                                <p>
                                    Keeping your Dayforce® platform updated and current with your organization’s needs requires ongoing, hands-on support. Let Pixentia’s specialists support your administrators, service as your support backup, or provide comprehensive managed services.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Admin Resource Center</h4>
                                        <p>
                                            Assisting administrators in day-to-day operations and maintenance.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Support organization.png" class="mr-2" alt="Support organization changes, group and role assignments, and security">
                                            </p>
                                            <p>
                                                Support organization changes, group and role assignments, and security.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Assist with user.png" class="mr-2" alt="Assist with user management to keep your data in shape and better manage your licensing costs">
                                            </p>
                                            <p>
                                                Assist with user management to keep your data in shape and better manage your licensing costs.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Assist administrators.png" class="mr-2" alt="Assist administrators in service delivery and user support">
                                            </p>
                                            <p>
                                                Assist administrators in service delivery and user support.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Admin Resource Center.png" class="img-fluid" alt="Admin Resource Center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Analytics Resource Center.png" class="img-fluid" alt="Analytics Resource Center">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Analytics Resource Center</h4>
                                        <p>
                                            We provide support for reporting, dashboards and visualizations, advanced analytics, and data aggregation and storage.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Scheduling and distributing.png" class="mr-2" alt="Scheduling and distributing reports so your users have the information they need">
                                            </p>
                                            <p>
                                                Scheduling and distributing reports so your users have the information they need.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Creating and customizing reports.png" class="mr-2" alt="Creating and customizing reports, including custom and calculated fields">
                                            </p>
                                            <p>
                                                Creating and customizing reports, including custom and calculated fields.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Referral to our Analytics.png" class="mr-2" alt="Referral to our Analytics">
                                            </p>
                                            <p>
                                                Referral to our Analytics team when you need advanced analytics, predictive models, and prescriptive analytics to tackle your most pressing challenges.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Technology Resource Center</h4>
                                        <p>
                                            We offer support for technical tasks beyond day-to-day administration.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Evaluation of.png" class="mr-2" alt="Evaluation of Dayforce® updates to assess potential benefits to your business processes">
                                            </p>
                                            <p>
                                                Evaluation of Dayforce® updates to assess potential benefits to your business processes.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Validating updates.png" class="mr-2" alt="Validating updates and upgrades to catch regression errors before they happen">
                                            </p>
                                            <p>
                                                Validating updates and upgrades to catch regression errors before they happen.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Integration support.png" class="mr-2" alt="Integration support, including third-party connections, and nightly data feed issues">
                                            </p>
                                            <p>
                                                Integration support, including third-party connections, and nightly data feed issues.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Technology Resource Center.png" class="img-fluid" alt="Technology Resource Center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Content Resource Center.png" class="img-fluid" alt="Content Resource Center">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Content Resource Center</h4>
                                        <p>
                                            We support your administrators and learning professionals with technical support in program management and deploying your learning programs.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/New program.png" class="mr-2" alt="New program launch and administration">
                                            </p>
                                            <p>
                                                New program launch and administration.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Sourcing and.png" class="mr-2" alt="Sourcing and third-party integrations">
                                            </p>
                                            <p>
                                                Sourcing and third-party integrations.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Back-end data.png" class="mr-2" alt="Back-end data administration and reporting">
                                            </p>
                                            <p>
                                                Back-end data administration and reporting.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Managed Services Center </h4>
                                        <p>
                                            Dedicated experts to handle all or any part of your HCM management, operations, and administration.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/You choose what.png" class="mr-2" alt="You choose what services you need and your level of support">
                                            </p>
                                            <p>
                                                You choose what services you need and your level of support.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Includes monitoring.png" class="mr-2" alt="Includes monitoring and technical support for integrations and data feeds">
                                            </p>
                                            <p>
                                                Includes monitoring and technical support for integrations and data feeds.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Services include.png" class="mr-2" alt="Services include periodic review of services to fine-tune them to your growing needs">
                                            </p>
                                            <p>
                                                Services include periodic review of services to fine-tune them to your growing needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Managed Services Center.png" class="img-fluid" alt="Managed Services Center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Staffing and Augmentation.png" class="img-fluid" alt="Staffing and Augmentation ">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Staffing and Augmentation </h4>
                                        <p>
                                            Comprehensive staffing services for the specialized roles in your HCM administration and maintenance.  Our services include:
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Staff augmentation.png" class="mr-2" alt="Staff augmentation when you need specialized skills or to feel a temporary critical gap on your team">
                                            </p>
                                            <p>
                                                Staff augmentation when you need specialized skills or to feel a temporary critical gap on your team.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Direct hire when yo.png" class="mr-2" alt="Direct hire when you need a full-time replacement">
                                            </p>
                                            <p>
                                                Direct hire when you need a full-time replacement.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/ceridian/optimization/Contract-to-hire.png" class="mr-2" alt="Contract-to-hire, which can be the perfect solution in times of uncertainty">
                                            </p>
                                            <p>
                                                Contract-to-hire, which can be the perfect solution in times of uncertainty.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-5">
            <div class="template_width">
                <div class="row align-items-center">
                    <div class="col-md-10 offset-md-1">
                        <div class="row align-items-center">
                            <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                                <p class="sub_heading_cta mb-4">Let's talk about how Pixentia can help you better understand your users.
                                </p>
                                <div class="more_info_btn datasheet_btn">
                                    <a href="contact-us" rel="noopener noreferrer" target="_blank"> Contact Us <i class="fa fa-angle-right ml-2"
                                            aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <div class="col-md-5 mt-5 mt-md-0">
                                <div>
                                    <img src="../../../../assets/images/cta_images/CTA Contact.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-vendors></app-vendors>
    </div>
    <div class="template_width">
        <app-get-started></app-get-started>
    </div>
</div>