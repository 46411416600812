<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="disclaimer">Disclaimer</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Disclaimer</h1>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/policy/Privacy Image.png" alt="Disclaimer Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width policy_page">
    <div class="row">
        <div class="col-md-12 mb-5 sub_head">
            <div>
                <p>
                    The information contained on <a href="https://www.pixentia.com/">https://pixentia.com</a> website (the "Service") is for general information purposes only. Pixentia assumes no responsibility for errors or omissions in the contents
                    on the Service. In no event shall Pixentia be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other sort, arising out of or in
                    connection with the use of the Service or the contents of the Service. Pixentia reserves the right to make additions, deletions, or modification to the contents on the Service at any time without prior notice. Pixentia does not warrant
                    that the website is free of viruses or other harmful components.
                </p>
                <h4 class="font-weight-bold mt-5">
                    Trademarks
                </h4>
                <p>
                    Pixentia is an independent technology solution provider and integrator. All trademarks, representations and/or content found on Pixentia’s website are owned by the designated intellectual property holders and governed by all applicable state and federal
                    laws. Unless stated otherwise, Pixentia has no joint venture, partnership, association, sponsorship, endorsement or other affiliation with the designated intellectual property holders except as a solutions provider supporting customers
                    in the respective domain.
                </p>
                <h4 class="font-weight-bold mt-5">
                    External links
                </h4>
                <p>
                    <a href="https://www.pixentia.com/">https://pixentia.com</a> website may contain links to external websites that are not provided or maintained by or in any way affiliated with Pixentia. Please note that Pixentia does not guarantee
                    the accuracy, relevance, timeliness, or completeness of any information on these external websites.
                </p>
            </div>
        </div>
    </div>
</div>