<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps">Chasma Apps</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps/chasma-editor">Chasma Editor</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Editor</h3>
                <p>Create engaging user portals for your core HR, talent and learning applications without programming
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/editor/Editor image.webp" alt="Chasma editor image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4 px-md-5">Pre-designed dashboards to help create engaging, user-friendly landing pages</h1>
                <div class="col-md-8 offset-md-2">
                    <p class="mb-3">
                        Most HR technology provides a way to brand your application home pages or landing pages. Many also provide the tools to link to resources or other applications.
                    </p>
                    <p class="mb-3">
                        But the scope of what you can do is often limited by design or by the skills required to make those home pages a reality. And too often, organizations find they don’t have the resources or design experience to make it happen.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/editor/Make your user.png" alt="Make your user">
                </div>
                <div class="px-5">
                    <p>
                        Make your user portal or landing page a hub of collaborative activity.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/editor/Improve the user.png" alt="Improve the user experience">
                </div>
                <div class="px-5">
                    <p>
                        Improve the user experience by eliminating friction.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/editor/Achieve greater.png" alt="user adoption">
                </div>
                <div class="px-5">
                    <p>
                        Achieve greater user adoption in HR management applications.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center mb-4">
                <h4 class="font-weight-bold py-4 mb-0">How Chasma Editor Speeds Up Your Development</h4>
                <p>Chasma Editor is an open canvas with drag and drop components you can use to build an experience unique to your enterprise and users. Each embedded Chasma template has user-friendly WYSIWYG design tools that do not require programming
                    skills.</p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/editor/Embed Components.png" alt="Embed Components" class="mb-3">
                    <p class="font-weight-bold mb-0">Embed Components</p>
                    <p class="px-4">
                        Embed components, widgets, leaderboards and <span class="d-inline-block">other motivational
                            tools.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/editor/Drag-and-Drop Interface.png" alt="Drag-and-Drop Interface" class="mb-3">
                    <p class="font-weight-bold mb-0">Drag-and-Drop Interface</p>
                    <p class="px-4">
                        Add elements to your canvas using a simple drag-and-drop interface.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/editor/Plug and Play Integrations.png" alt="Plug and Play Integrations" class="mb-3">
                    <p class="font-weight-bold mb-0">Plug and Play Integrations</p>
                    <p class="px-4">
                        Plug and play integrations with your application data sets.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/editor/Collaboration Tools.png" alt="Collaboration Tools" class="mb-3">
                    <p class="font-weight-bold mb-0">Collaboration Tools</p>
                    <p class="px-4">
                        Collaboration tools embedded directly into your dashboards or landing pages.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/editor/No Complex Programming.png" alt="No Complex Programming" class="mb-3">
                    <p class="font-weight-bold mb-0">No Complex Programming</p>
                    <p class="px-4">
                        Provide easy access to targeted information without <span class="d-inline-block">complex
                            programming.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/editor/Gamification.png" alt="Gamification" class="mb-3">
                    <p class="font-weight-bold mb-0">Gamification</p>
                    <p class="px-4">
                        Leaderboards and a gamified experience to motivate employees.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Editor?</h4>
                    <p class="">
                        Improve the user experience without expensive upgrades and customization. Give your users instant access to the information and knowledge they need to stay on task. Watch the video to know how Chasma Editor helps you create a user centric experience across
                        your enterprise technology applications.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/230429413?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/images/products/event/Need more.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-white pl-md-5 mt-5 mt-md-0">
                        <h2 class="font-weight-bold">Create a personalized, branded homepage in your HR applications without programming </h2>
                        <p class="mb-4">
                            See how Chasma Editor's drag and drop page designer can help you create a user experience that will keep your people coming back more.
                        </p>
                        <div class="more_info_btn">
                            <a href="http://resources.chasma.io/chasma-editor" rel="noopener noreferrer" target="_blank">Learn More <i
                                    class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>