<div [ngClass]="resultsLength > 0 ? 'bg-light' : 'noresult-bg-color'" class="container pt-3">
  <div *ngIf="resultsLength == 0" class="d-flex justify-content-center">
    <h4>No results found.</h4>
  </div>
  <div *ngIf="resultsLength > 0" class="d-flex justify-content-center">
    <h4>{{resultsLength}} results found.</h4>
  </div>
  <div class="pt-3" *ngFor="let result of results">
    <div class="search_content_style">
      <h4>{{result.name}}</h4>
      <a href="{{url}}{{result.url}}">{{url}}{{result.url}}</a>
      <p class="m-0">{{result.description}}</p>
      <p>{{result.content}}</p>
    </div>
  </div>
</div>
