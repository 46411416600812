<div class="bg-pixcolor">
    <div class="container-md py-1">
        <div class="d-block pt-2 pb-2 ">
            <div class=" d-flex justify-content-center"><img src="../../assets/images/socialmedia/Pixentia_Brand.jpg"
                    class="img-thumbnail rounded-circle p-3" alt="Pixentia Brand" height="200" width="200">
            </div>
            <h2 class="text-center fw-bold ">
                Pixentia
            </h2>
            <p class="text-center ">HR Technology Consulting and Support</p>
            <div class="d-flex justify-content-center mt-2">
                <div class="d-flex"> <a href="" rel="noopener noreferrer" target="_blank" class="px-5"><img
                            src="../../assets/images/socialmedia/global.png" width="25" alt=" Pixentia"></a>
                    <a href="mailto:info@pixentia.com " rel="noopener noreferrer" target="_blank" class="px-5"><img
                            src="../../assets/images/socialmedia/email.png" width="25" alt="Email"></a>
                    <a href="tel:+1%20855-978-6816" rel="noopener noreferrer" target="_blank" class="px-5"><img
                            src="../../assets/images/socialmedia/phone-call.png" width="25" alt="Phone"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="container-md pb-3 rounded">
        <div class=" m-3">
            <a href="https://facebook.com/pixentia" rel="noopener noreferrer" target="_blank"
                class="social-link p-3 custom-btn btn-16">
                <div class="social-handle">
                    <div class="social-item ">
                        <img src="../../assets/images/socialmedia/facebook.png" height="25" width="25"
                            alt="Pixentia Facebook">
                    </div>
                    <div class="social-item">
                        <P class="text-center">Facebook</P>
                    </div>

                </div>
            </a>
        </div>

        <div class=" m-3">
            <a href="https://twitter.com/pixentia" rel="noopener noreferrer" target="_blank" type="button"
                class="social-link p-3 custom-btn btn-16">
                <div class="social-handle">
                    <div class="social-item ">
                        <img src="../../assets/images/socialmedia/twitter.png" height="25" width="25"
                            alt="Pixentia Twitter">
                    </div>
                    <div class="social-item">
                        <P class="text-center">Twitter</P>
                    </div>

                </div>
            </a>
        </div>

        <div class=" m-3">
            <a href="https://www.linkedin.com/company/pixentia" rel="noopener noreferrer" target="_blank" type="button"
                class="social-link p-3 custom-btn btn-16">
                <div class="social-handle">
                    <div class="social-item ">
                        <img src="../../assets/images/socialmedia/linkedin.png" height="25" width="25"
                            alt="Pixentia Linkedin" />
                    </div>
                    <div class="social-item">
                        <P class="text-center">LinkedIn</P>
                    </div>

                </div>
            </a>
        </div>

        <div class=" m-3">
            <a href="https://www.youtube.com/c/Pixentia" rel="noopener noreferrer" target="_blank" type="button"
                class="social-link p-3 custom-btn btn-16">
                <div class="social-handle">
                    <div class="social-item ">
                        <img src="../../assets/images/socialmedia/youtube.png" height="25" width="25"
                            alt="Pixentia Youtube">
                    </div>
                    <div class="social-item">
                        <P class="text-center">YouTube</P>
                    </div>
                </div>
            </a>
        </div>

        <div class=" m-3">
            <a href="https://www.instagram.com/pixentiacorporation/" rel="noopener noreferrer" target="_blank"
                type="button" class="social-link p-3 custom-btn btn-16">
                <div class="social-handle">
                    <div class="social-item ">
                        <img src="../../assets/images/socialmedia/instagram.png" height="25" width="25"
                            alt="Pixentia Instagram">
                    </div>
                    <div class="social-item">
                        <P class="text-center">Instagram</P>
                    </div>

                </div>
            </a>
        </div>
    </div>
</div>