<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe">HCM COE</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe/workshops">Workshops</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Hcm Deployment Workshops </h1>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Workshops.png" alt="HCM Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="services_section">
        <div class="template_width">
            <div class="row mb-5">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified">
                        <li class="nav-item text-center">
                            <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#business">
                                <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Business Workshop
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#training">
                                <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Training Workshop
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#config">
                                <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Configuration Workshop
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="workshop_section">
        <div class="row mx-0">
            <!-- Tab panes -->
            <div class="tab-content my-5">
                <div class="tab-pane active" id="business">
                    <div class="template_width">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Align Cornerstone® HCM Technology to Your Business
                                    </h4>
                                </div>
                                <p>
                                    Our business workshops support your technology deployment with a detailed analysis of your business practices and learning processes. Our workshops have three primary objectives:
                                </p>
                            </div>
                        </div>
                        <div class="row mt-5 services_list">
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Establish the learning.png" alt="Establish the learning priorities">
                                    </div>
                                    <div>
                                        <p>
                                            Establish the learning priorities critical to your business.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Define your current.png" alt="Define your current learning">
                                    </div>
                                    <div>
                                        <p>
                                            Define your current learning practices and processes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Optimize your practices.png" alt="Optimize your practices and processes">
                                    </div>
                                    <div>
                                        <p>
                                            Optimize your practices and processes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-center my-5">
                                <p>
                                    We will understand your business before we propose a solution. We target our recommendations to your specific objectives and challenges.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="promise_section">
                        <div class="template_width">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div>
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Business Workshop Side.png" alt="" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6 text-white">
                                    <div>
                                        <h3 class="font-weight-bold">Business Workshop
                                        </h3>
                                        <p>
                                            A thorough understanding of your business practices and processes is essential to adapting Cornerstone® to the way your people work. We work with you to understand the internal and external forces that drive your workforce strategy, so we can see how
                                            to best leverage your technology.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="template_width">
                        <div class="row mt-5 services_list">
                            <div class="col-md-10 offset-md-1">
                                <div class="text-center">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold mb-4">
                                            Process Workshop
                                        </h4>
                                    </div>
                                    <p>
                                        Process work begins with mapping your current workflows to analyze and improve them.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Map your processes.png" alt="Map your processes">
                                    </div>
                                    <div>
                                        <p>
                                            Map your processes as they exist, including offline processes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Identify functionality gaps.png" alt="Identify functionality">
                                    </div>
                                    <div>
                                        <p>
                                            Identify functionality gaps, bottlenecks, pain points, and workarounds in your existing workflows.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Recommend how.png" alt="improve processes">
                                    </div>
                                    <div>
                                        <p>
                                            Recommend how you can improve processes using your workflow tools.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Use your process map.png" alt="Use your process map">
                                    </div>
                                    <div>
                                        <p>
                                            Use your process map to analyze current workflows.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/business-workshop/Map the desired future.png" alt="Map the desired future state">
                                    </div>
                                    <div>
                                        <p>
                                            Map the desired future state.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-12 text-center mt-5">
                                <p>
                                    This work serves as the foundation for your configuration workshop where you will apply your business rules to your technology platform.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="training">
                    <div class="template_width">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Learn How to Manage Cornerstone for the Way You Do Business
                                    </h4>
                                </div>
                                <p>
                                    Your configuration workshop and administrator training will show you what you can do to manage your platform. However, what you can do differs from what you should do to improve business impact. Pixentia’s training workshop shows you how to use those
                                    tools to your best advantage.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-12">
                                <div class="sub_head text-center">
                                    <h4 class="font-weight-bold mb-4">
                                        Pixentia Technology Implementation Training Workshop
                                    </h4>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/Simplify Administration.png" alt="Simplify Administration">
                                    </div>
                                    <div>
                                        <p>
                                            Simplify Administration
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/Staffing and Augmentation.png" alt="Deliver a User-Centric Experience">
                                    </div>
                                    <div>
                                        <p>
                                            Deliver a User-Centric Experience
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/Identifying communication.png" alt="Increase User Engagement">
                                    </div>
                                    <div>
                                        <p>
                                            Increase User Engagement
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="promise_section training_section text-white">
                        <div class="template_width">
                            <div class="row align-items-center">
                                <div class="col-md-12 pt-4">
                                    <div class="sub_head  text-center">
                                        <h4 class="font-weight-bold mb-4 text-white">
                                            Making Organizational Structures Simple
                                        </h4>
                                        <p>
                                            <a href="services/learning/learningmanagementsystems" class="text-white">Enterprise learning management systems</a> have tools to define your organizational structure, learner groups, and content. Those tools
                                            require knowledge of what happens behind the user interface.
                                        </p>
                                        <p>
                                            Pixentia’s training team shows you how to plan your structures to make them both useful and flexible, so you can handle organizational changes as they happen. That includes:
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-right">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/How to gather requirements.png" alt="How to gather requirements from your user groups">
                                        </div>
                                        <div>
                                            <p>
                                                How to gather requirements from your user groups.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-right">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/Setting up your structures.png" alt="Setting up your structures">
                                        </div>
                                        <div>
                                            <p>
                                                Setting up your structures.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/Managing structural changes.png" alt="Managing structural changes">
                                        </div>
                                        <div>
                                            <p>
                                                Managing structural changes.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="template_width">
                        <div class="row mt-5 services_list">
                            <div class="col-md-12">
                                <div class="sub_head text-center">
                                    <h4 class="font-weight-bold mb-4">
                                        Delivering a Useful, Engaging Experience
                                    </h4>
                                    <p>
                                        What is a useful, engaging experience?
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/As users.png" alt="As users">
                                    </div>
                                    <div>
                                        <p>
                                            As users, we want quick access to the resources we need when we need them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/As managers.png" alt="As managers">
                                    </div>
                                    <div>
                                        <p>
                                            As managers, we would say give us a holistic picture of each team member’s potential, development, and progress.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/As administrators.png" alt="As administrators">
                                    </div>
                                    <div>
                                        <p>
                                            As administrators, we want to manage the structures and resources with an intuitive interface that doesn’t require an IT degree to operate.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-5 text-center">
                                <p>
                                    Pixentia’s core purpose is to deliver on that promise. We do that by keeping the user’s needs first and enabling you to give them that useful, engaging experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="promise_section training_section text-white mt-4">
                        <div class="template_width">
                            <div class="row align-items-center">
                                <div class="col-md-12 pt-4">
                                    <div class="sub_head  text-center">
                                        <h4 class="font-weight-bold mb-4 text-white">
                                            Increasing User Engagement
                                        </h4>
                                        <p>
                                            Every user notification, input form, news page, and message is an opportunity to communicate with your people about the value of their contribution to your organization. They can also be used for them to interact with you.
                                        </p>
                                        <p>
                                            To facilitate that conversation, Pixentia’s experts will show you how to:
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                                <div class="col-md-4 text-center border-right">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/Structure notification.png" alt="Structure notifications">
                                        </div>
                                        <div>
                                            <p>
                                                Structure notifications to benefit from each communication opportunity to reinforce your company culture.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../../assets/images/hcm/hcm-coe/workshops/training-workshop/Deliver personalized.png" alt="Deliver personalized">
                                        </div>
                                        <div>
                                            <p>
                                                Deliver personalized and targeted interactive news pages.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="config">
                    <div class="template_width">
                        <div class="col-md-10 offset-md-1 mb-4">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Align Your Structure and Processes to your Business
                                    </h4>
                                </div>
                                <p>
                                    <a href="services/cloud-transformation/cornerstone">Cornerstone</a> enables customers to adapt software platforms to their unique requirements by building in configuration options. Pixentia’s training shows you how
                                    to leverage those options to adapt your software to the way you do business.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Identifying Your Configuration Training Audience
                                    </h4>
                                </div>
                                <p>
                                    The first task for your team is to ensure that the right people receive configuration training. Many of the decisions you make will be essential for the success of your operations. Inviting the right people into your workshop will enable you to get valuable
                                    input from the people who do the work.
                                </p>
                                <p>
                                    At a minimum, the following roles should receive training, but your operations may require others to attend.
                                </p>
                            </div>
                        </div>
                        <div class="row mt-5 services_list">
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/configuration-workshop/Your project sponsor.png" alt="Your project sponsor">
                                    </div>
                                    <div>
                                        <p>
                                            Your project sponsor, business managers, and project managers, who will make decisions about which functions to enable and how to use them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/configuration-workshop/System administrators.png" alt="System administrators">
                                    </div>
                                    <div>
                                        <p>
                                            System administrators, who will need to learn how to manage day-to-day operations and system updates.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/configuration-workshop/HR functional staff.png" alt="HR functional staff">
                                    </div>
                                    <div>
                                        <p>
                                            HR functional staff who will do much of their daily work in the software platform.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="promise_section">
                        <div class="template_width">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div>
                                        <img src="../../../../../assets/images/hcm/hcm-coe/workshops/configuration-workshop/Configuration Scope.png" alt="Configuration Scope" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6 text-white">
                                    <div>
                                        <h3 class="font-weight-bold">Configuration Scope
                                        </h3>
                                        <p>
                                            In the workshop, we review the configuration options with you, answer your questions, and make recommendations based on your business requirements.
                                        </p>
                                        <p>
                                            Your implementation agreement and Statement of Work determine the services we can provide in your implementation agreement. Your Pixentia team will work with you to ensure that the scope of work is right for your business needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="template_width">
                        <div class="row mt-5 services_list align-items-center">
                            <div class="col-md-6">
                                <div class="sub_head">
                                    <h3 class="font-weight-bold">Configuration Process
                                    </h3>
                                    <p>
                                        During your workshop, we provide a workbook for you to document your configuration decisions. That workbook becomes the guiding document for setting the configuration options in your software platform.
                                    </p>
                                    <p>
                                        We use a series of small, iterative steps to make sure each business sub-process works as it should before we move on to the next set of configuration activities.
                                    </p>
                                    <p>
                                        At the end of the configuration process, we test your business processes end-to-end to validate the configuration.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-center">
                                    <img src="../../../../../assets/images/hcm/hcm-coe/workshops/configuration-workshop/Configuration Process.png" class="img-fluid" alt="Configuration Process">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>