import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hcm-coe',
  templateUrl: './hcm-coe.component.html',
  styleUrls: ['./hcm-coe.component.scss']
})
export class HcmCoeComponent implements OnInit {

  title = 'Pixentia HCM Center of Excellence';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: "Pixentia's HCM Center of Excellence delivers planning, deployment, support, and optimization services for your human capital management technology. " }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia HCM Center of Excellence' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: "Pixentia's HCM Center of Excellence delivers planning, deployment, support, and optimization services for your human capital management technology. " }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/hcm/hcm-coe' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia HCM Center of Excellence' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: "Pixentia's HCM Center of Excellence delivers planning, deployment, support, and optimization services for your human capital management technology. " }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/hcm/hcm-coe' }      
    );
  }

}
