<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/learning/mobilelearning">Mobile Learning</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Custom Mobile Learning Solutions</h3>
                <p>Spontaneous learning opportunities? On-demand support for roaming employees? Turn to Pixentia for solutions that inform, teach and improve on-the-job performance.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/learning/mobile-learning/Mobilelearning Image.png" alt="Mobile Learning Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-4 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-5 font-weight-bold">
                    Superior E-Learning Solutions that Will Energize Your Workforce
                </h1>
            </div>
            <div class="col-md-10 offset-md-1 text-center">
                <p class="mb-0">
                    Pixentia’s deep understanding of your organization and its learning goals enables us to create superior, cost-effective solutions that measurably increase workforce knowledge and productivity.
                </p>
            </div>
        </div>
    </div>
    <div class="service_section mb-5">
        <div class="template_width">
            <div class="row">
                <div class="col-md-12 text-center sub_head">
                    <h5 class="font-weight-bold py-4 mb-3">OUR SERVICES INCLUDE</h5>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Product training.png" alt="Product Training" class="mb-3">
                        <p class="px-4">
                            Product Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Compliance traning.png" alt="Compliance Training" class="mb-3">
                        <p class="px-4">
                            Compliance Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Onboarding.png" alt="Onboarding" class="mb-3">
                        <p class="px-4">
                            Onboarding
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Leadership.png" alt="Leadership Development Process" class="mb-3">
                        <p class="px-4">
                            Leadership Development Process & Skills Training
                        </p>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Sales Training.png" alt="Sales Training" class="mb-3">
                        <p class="px-4">
                            Sales Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/College Level Instruction.png" alt="College Level Instruction" class="mb-3">
                        <p class="px-4">
                            College Level Instruction
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Software Training.png" alt="Software Training" class="mb-3">
                        <p class="px-4">
                            Software Training
                        </p>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Mobile Learning Planning</h4>
                    <p>
                        Mobile learning differs from other learning delivery platforms, and Pixentia can show you how it can better engage your employees with just-in-time information and micro learning experiences that lead to better decisions and higher productivity. Our discovery
                        and planning processes include:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/mobile-learning/Mobile Learning Course Development Workshops.png" alt="Mobile Learning Course Development Workshops" class="mb-3">
                        <p class="font-weight-bold mb-1">Mobile Learning Course Development Workshops</p>
                        <p class="px-4">
                            Specifying the use scenarios, information requirements, collaboration methods, and individual modules that will improve knowledge retention and on-the-job efficiency.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/mobile-learning/Learning Style Planning.png" alt="Learning Style Planning" class="mb-3">
                        <p class="font-weight-bold mb-1">Learning Style Planning</p>
                        <p class="px-4">
                            Course design that matches training goals with the learning styles (e.g. interactive video, gamification, etc.), and assures the most effective delivery of high value content to mobile employees.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/mobile-learning/Module Project Planning.png" alt="Module Project Planning" class="mb-3">
                        <p class="font-weight-bold mb-1">Module Project Planning</p>
                        <p class="px-4">
                            Hands-on support from experienced professionals who create credible, cost-effective plans showing how your mobile learning will be scripted, visualized, designed, prototyped, <span class="d-inline-block">tested and launched.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/mobile-learning/Business Case Support.png" alt="Business Case Development" class="mb-3">
                        <p class="font-weight-bold mb-1">Business Case Support</p>
                        <p class="px-4">
                            Creating organization-wide support for mobile learning projects with guidance on how to build clear, compelling business cases.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="community_section my-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">Mobile Learning Implementation</h5>
                    <p>
                        Pixentia’s learning specialists understand the special requirements of mobile learning and bring practical experience to every aspect of your project, from rough concepts to finished, deployed training modules. Our services include:
                    </p>
                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Multi-platform Course Development.png" class="mr-2" alt="On-the-Job Mobile Learning Content">
                        </p>
                        <p>
                            <strong class="d-block">On-the-Job Mobile Learning Content</strong> Developing custom, highly-effective content that provides your employees with a knowledge and performance advantage in mobile job situations such as field
                            safety, business processes, equipment procedures, medical services delivery, and direct sales.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Content Creation.png" class="mr-2" alt="Spontaneous Learning Programs">
                        </p>
                        <p>
                            <strong class="d-block">Spontaneous Learning Programs</strong> Enabling employees to choose when and where to learn with special purpose course design and content that improves retention and skills.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Legacy Content Conversion.png" class="mr-2" alt="Legacy Content Conversion">
                        </p>
                        <p>
                            <strong class="d-block">Legacy Content Mobile Learning Conversion
                            </strong> Increasing the ROI from legacy content built on Flash by repurposing it for mobile learning through content revision and HTML5 conversion.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Learning Deployment.png" class="mr-2" alt="Learning Deployment">
                        </p>
                        <p>
                            <strong class="d-block">Mobile Learning Applications Deployment</strong> Supporting seamless deployment of mobile learning applications and courses, covering all tablet and smartphone platforms and supporting both online and
                            offline use.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Course and Module Marketing.png" class="mr-2" alt="Course and Module Marketing">
                        </p>
                        <p>
                            <strong class="d-block">Mobile Learning Marketing
                            </strong> Creating employee communications and campaigns that create high levels of mobile application awareness and utilization.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Evaluation and Continuous Improvement.png" class="mr-2" alt="Evaluation and Continuous Improvement">
                        </p>
                        <p>
                            <strong class="d-block">Evaluation and Continuous Improvement</strong> Providing tools, feedback mechanisms, and reports that track mobile learning use, measure personal and organizational performance, and indicate opportunities
                            for ongoing content improvement.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/learning/mobile-learning/Learning Project Implementation.png" alt="Learning Project Implementation" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Operations Support</h4>
                    <p>
                        Mobile learning applications demand continuous support to assure uninterrupted employee access and mobile platform compatibility. Pixentia’s IT professionals can supplement your internal staff to provide:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/mobile-learning/New Content Deployment.png" alt="New Content Deployment" class="mb-3">
                        <p class="font-weight-bold mb-1">New Content Deployment</p>
                        <p class="px-4">
                            Assuring new mobile learning modules are reliably launched and fully available across multiple devices and platforms.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/mobile-learning/LMS Maintenance.png" alt="LMS Maintenance" class="mb-3">
                        <p class="font-weight-bold mb-1">LMS Maintenance</p>
                        <p class="px-4">
                            Maintaining high rates of learning management system uptime and seamless integration with other applications through upgrade validation and test, 7 X 24 system monitoring, <span class="d-inline-block">and help-desk support.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/mobile-learning/Content Updating.png" alt="Mobile Content Updating" class="mb-3">
                        <p class="font-weight-bold mb-1">Mobile Content Updating</p>
                        <p class="px-4">
                            Responding to updated plans, business processes, operating instructions and regulatory compliance with fast, trouble-free revisions and extensions to mobile learning applications.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/mobile-learning/Learning Metrics and Analytics.png" alt="Mobile Learning Reporting and Analytics" class="mb-3">
                        <p class="font-weight-bold mb-1">Mobile Learning Reporting and Analytics</p>
                        <p class="px-4">
                            Real-time reports that follow personal and group mobile learning utilization and scoring; support for people analytics programs with integrated data curation and warehousing services.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-5">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-10 offset-md-1">
                    <div class="row align-items-center">
                        <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                            <p class="sub_heading_cta mb-4">
                                Discover a comprehensive, strategic approach to your mobile learning design, implementation, and support.
                            </p>
                            <div class="more_info_btn datasheet_btn">
                                <a href="https://resources.pixentia.com/elearn/datasheet/mobile-learning-01-1" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="col-md-5 mt-md-0 mt-5">
                            <div>
                                <img src="../../../../assets/images/cta_images/Mobile_Learning.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>