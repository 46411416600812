import { SearchComponent } from './search/search.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SiteMapComponent } from './site-map/site-map.component';
import { HcmCoeComponent } from './services/hcm/hcm-coe/hcm-coe.component';
import { HcmLearningComponent } from './services/hcm/hcm-learning/hcm-learning.component';
import { RecrutingComponent } from './services/hcm/recruting/recruting.component';
import { TalentmanagementComponent } from './services/hcm/talentmanagement/talentmanagement.component';
import { PeopleanalyticsComponent } from './services/hcm/peopleanalytics/peopleanalytics.component';
import { CompensationComponent } from './services/hcm/compensation/compensation.component';
import { PayrollComponent } from './services/hcm/payroll/payroll.component';
import { EnterpriseIntegrationsComponent } from './services/hcm/enterprise-integrations/enterprise-integrations.component';
import { EmployeePortalComponent } from './services/hcm/employee-portal/employee-portal.component';
import { HcmValueCalculatorComponent } from './services/hcm/hcm-value-calculator/hcm-value-calculator.component';
import { HcmResourceCenterComponent } from './services/hcm/hcm-resource-center/hcm-resource-center.component';
import { CustomlearningComponent } from './services/learning/customlearning/customlearning.component';
import { MobilelearningComponent } from './services/learning/mobilelearning/mobilelearning.component';
import { LearningmanagementsystemsComponent } from './services/learning/learningmanagementsystems/learningmanagementsystems.component';
import { LearningstylesandgamificationComponent } from './services/learning/learningstylesandgamification/learningstylesandgamification.component';
import { DemosmainComponent } from './services/learning/demosmain/demosmain.component';
import { MobileComponent } from './services/mobile/mobile.component';
import { AnalyticsComponent } from './services/analytics/analytics.component';
import { DigitalTransformationComponent } from './services/digital-transformation/digital-transformation.component';
import { CustomersComponent } from './customers/customers.component';
import { ChasmaAppsComponent } from './products/chasma-apps/chasma-apps.component';
import { ChasmaEventComponent } from './products/chasma-event/chasma-event.component';
import { ChasmaLexyComponent } from './products/chasma-lexy/chasma-lexy.component';
import { ChasmaQualeComponent } from './products/chasma-quale/chasma-quale.component';
import { ChasmaEditorComponent } from './products/chasma-editor/chasma-editor.component';
import { ChasmaAnalyticsComponent } from './products/chasma-analytics/chasma-analytics.component';
import { ChasmaHrboxComponent } from './products/chasma-hrbox/chasma-hrbox.component';
import { ChasmaConnectComponent } from './products/chasma-connect/chasma-connect.component';
import { LearningPortalsComponent } from './services/learning/learning-portals/learning-portals.component';
import { LearningResourceCenterComponent } from './services/learning/learning-resource-center/learning-resource-center.component';
import { LearningCoeComponent } from './services/learning/learning-coe/learning-coe.component';
import { WorkdayComponent } from './services/cloud-transformation/workday/workday.component';
import { SumtotalComponent } from './services/cloud-transformation/sumtotal/sumtotal.component';
import { CeridianComponent } from './services/cloud-transformation/ceridian/ceridian.component';
import { SalesforceComponent } from './services/cloud-transformation/salesforce/salesforce.component';
import { ServicenowComponent } from './services/cloud-transformation/servicenow/servicenow.component';
import { CornerstoneComponent } from './services/cloud-transformation/cornerstone/cornerstone.component';
import { SumtotalAnalyticsComponent } from './products/sumtotal-analytics/sumtotal-analytics.component';
import { CornerstoneAnalyticsComponent } from './products/cornerstone-analytics/cornerstone-analytics.component';
import { WorkdayAnalyticsComponent } from './products/workday-analytics/workday-analytics.component';
import { PeopleAnalyticsPowerBIComponent } from './products/people-analytics-power-bi/people-analytics-power-bi.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { ChasmaNatioDeskComponent } from './products/chasma-natio-desk/chasma-natio-desk.component';
import { ChasmaBotBuilderComponent } from './products/chasma-bot-builder/chasma-bot-builder.component';
import { ChasmaCibileComponent } from './products/chasma-cibile/chasma-cibile.component';
import { VendorsComponent } from './vendors/vendors.component';
import { ChangeManagementComponent } from './services/hcm/hcm-learning/change-management/change-management.component';
import { BusinessWorkshopComponent } from './services/hcm/hcm-learning/business-workshop/business-workshop.component';
import { ConfigurationWorkshopComponent } from './services/hcm/hcm-learning/configuration-workshop/configuration-workshop.component';
import { IntegrationComponent } from './services/hcm/hcm-learning/integration/integration.component';
import { AnalyticsDeploymentComponent } from './services/hcm/hcm-learning/analytics-deployment/analytics-deployment.component';
import { TestingComponent } from './services/hcm/hcm-learning/testing/testing.component';
import { GamificationComponent } from './services/hcm/hcm-learning/gamification/gamification.component';
import { AugmentedRealityComponent } from './services/hcm/hcm-learning/augmented-reality/augmented-reality.component';
import { VirtualRealityComponent } from './services/hcm/hcm-learning/virtual-reality/virtual-reality.component';
import { SimulationComponent } from './services/hcm/hcm-learning/simulation/simulation.component';
import { UserExperienceAndDesignComponent } from './services/hcm/hcm-learning/user-experience-and-design/user-experience-and-design.component';
import { DevelopmentComponent } from './services/hcm/hcm-learning/development/development.component';
import { AdminResourceComponent } from './services/hcm/hcm-learning/admin-resource/admin-resource.component';
import { TechnologyComponent } from './services/hcm/hcm-learning/technology/technology.component';
import { ContentResourceCenterComponent } from './services/hcm/hcm-learning/content-resource-center/content-resource-center.component';
import { ManagedServicesComponent } from './services/hcm/hcm-learning/managed-services/managed-services.component';
import { AnalyticsResourceComponent } from './services/hcm/hcm-learning/analytics-resource/analytics-resource.component';
import { StaffingAndAugmentationServicesComponent } from './services/hcm/hcm-learning/staffing-and-augmentation-services/staffing-and-augmentation-services.component';
import { HcmChangeManagementComponent } from './services/hcm/hcm-coe/hcm-change-management/hcm-change-management.component';
import { HcmWorkshopsComponent } from './services/hcm/hcm-coe/hcm-workshops/hcm-workshops.component';
import { HcmIntegrationComponent } from './services/hcm/hcm-coe/hcm-integration/hcm-integration.component';
import { HcmAnalyticsDeploymentComponent } from './services/hcm/hcm-coe/hcm-analytics-deployment/hcm-analytics-deployment.component';
import { HcmTestingComponent } from './services/hcm/hcm-coe/hcm-testing/hcm-testing.component';
import { HcmAdminResourceComponent } from './services/hcm/hcm-coe/hcm-admin-resource/hcm-admin-resource.component';
import { HcmTechnologyComponent } from './services/hcm/hcm-coe/hcm-technology/hcm-technology.component';
import { HcmContentResourceCenterComponent } from './services/hcm/hcm-coe/hcm-content-resource-center/hcm-content-resource-center.component';
import { HcmAnalyticsComponent } from './services/hcm/hcm-coe/hcm-analytics/hcm-analytics.component';
import { HcmManagedServicesComponent } from './services/hcm/hcm-coe/hcm-managed-services/hcm-managed-services.component';
import { HcmStaffingAndAugmentationServicesComponent } from './services/hcm/hcm-coe/hcm-staffing-and-augmentation-services/hcm-staffing-and-augmentation-services.component';
import { PartnersComponent } from './partners/partners.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { LearningDemoSignupComponent } from './services/learning/learning-demo-signup/learning-demo-signup.component';
import { ForgotPasswordDialogComponent } from './services/learning/forgot-password-dialog/forgot-password-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DemouserapprovalComponent } from './services/learning/demouserapproval/demouserapproval.component';
import { ApprovalConfirmationDialogComponent } from './services/learning/approval-confirmation-dialog/approval-confirmation-dialog.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { DemoComponent } from './demo/demo.component';
import { HrDigitalTransformationComponent } from './services/hr-digital-transformation/hr-digital-transformation.component';
import { PixentiabioComponent } from './pixentiabio/pixentiabio.component';
import { AmsComponent } from './services/ams/ams.component';
import { ManagedtalentdevelopmentservicesComponent } from './services/managedtalentdevelopmentservices/managedtalentdevelopmentservices.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    DisclaimerComponent,
    SiteMapComponent,
    HcmCoeComponent,
    HcmLearningComponent,
    RecrutingComponent,
    TalentmanagementComponent,
    PeopleanalyticsComponent,
    CompensationComponent,
    PayrollComponent,
    EnterpriseIntegrationsComponent,
    EmployeePortalComponent,
    HcmValueCalculatorComponent,
    HcmResourceCenterComponent,
    CustomlearningComponent,
    MobilelearningComponent,
    LearningmanagementsystemsComponent,
    LearningstylesandgamificationComponent,
    DemosmainComponent,
    MobileComponent,
    AnalyticsComponent,
    DigitalTransformationComponent,
    CustomersComponent,
    ChasmaAppsComponent,
    ChasmaEventComponent,
    ChasmaLexyComponent,
    ChasmaQualeComponent,
    ChasmaEditorComponent,
    ChasmaAnalyticsComponent,
    ChasmaHrboxComponent,
    ChasmaConnectComponent,
    LearningPortalsComponent,
    LearningResourceCenterComponent,
    LearningCoeComponent,
    WorkdayComponent,
    SumtotalComponent,
    CeridianComponent,
    SalesforceComponent,
    ServicenowComponent,
    CornerstoneComponent,
    SumtotalAnalyticsComponent,
    CornerstoneAnalyticsComponent,
    WorkdayAnalyticsComponent,
    PeopleAnalyticsPowerBIComponent,
    GetStartedComponent,
    ChasmaNatioDeskComponent,
    ChasmaBotBuilderComponent,
    ChasmaCibileComponent,
    VendorsComponent,
    ChangeManagementComponent,
    BusinessWorkshopComponent,
    ConfigurationWorkshopComponent,
    IntegrationComponent,
    AnalyticsDeploymentComponent,
    TestingComponent,
    GamificationComponent,
    AugmentedRealityComponent,
    VirtualRealityComponent,
    SimulationComponent,
    UserExperienceAndDesignComponent,
    DevelopmentComponent,
    AdminResourceComponent,
    TechnologyComponent,
    ContentResourceCenterComponent,
    ManagedServicesComponent,
    AnalyticsResourceComponent,
    StaffingAndAugmentationServicesComponent,
    HcmChangeManagementComponent,
    HcmWorkshopsComponent,
    HcmIntegrationComponent,
    HcmAnalyticsDeploymentComponent,
    HcmTestingComponent,
    HcmAdminResourceComponent,
    HcmTechnologyComponent,
    HcmContentResourceCenterComponent,
    HcmAnalyticsComponent,
    HcmManagedServicesComponent,
    HcmStaffingAndAugmentationServicesComponent,
    PartnersComponent,
    NotfoundComponent,
    LearningDemoSignupComponent,
    ForgotPasswordDialogComponent,
    DemouserapprovalComponent,
    ApprovalConfirmationDialogComponent,
    DemoComponent,
    SearchComponent,
    HrDigitalTransformationComponent,
    PixentiabioComponent,
    AmsComponent,
    ManagedtalentdevelopmentservicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  entryComponents: [
    ForgotPasswordDialogComponent,
    ApprovalConfirmationDialogComponent,
  ],
  exports: [
    // FormsModule,
    // ReactiveFormsModule,
  ],
  providers: [HttpClientModule, BnNgIdleService],
  bootstrap: [AppComponent],
})
export class AppModule { }
