import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class APIService {

  constructor(private httpClient: HttpClient) { }

  public get<TRes>(url: string, params?: any, headers?: HttpHeaders) {

    if (!params) {
      params = {};
    }

    const observable = this.getOptions(headers).pipe(switchMap(options => {
      const request = this.httpClient.get<TRes>(url, {
        ...options,
        params
      });
      return request;
    }))

    return observable;
  }

  public post<TRes>(url: string, req?: any, params?: any, headers?: HttpHeaders) {

    if (!params) {
      params = {};
    }

    const observable = this.getOptions(headers).pipe(switchMap(options => {
      const request = this.httpClient.post<TRes>(url, req, {
        ...options,
        params
      });
      return request;
    }))

    return observable;
  }

  public put<TRes>(url: string, req?: any, params?: any, headers?: HttpHeaders) {

    if (!params) {
      params = {};
    }

    const observable = this.getOptions(headers).pipe(switchMap(options => {
      const request = this.httpClient.put<TRes>(url, req, {
        ...options,
        params
      });
      return request;
    }))

    return observable;
  }

  public delete<TRes>(url: string, params?: any, headers?: HttpHeaders) {

    if (!params) {
      params = {};
    }

    const observable = this.getOptions(headers).pipe(switchMap(options => {
      const request = this.httpClient.delete<TRes>(url, {
        ...options,
        params
      });
      return request;
    }));

    return observable;
  }

  // private getOptions() {
  //   const options = {};
  //   const promise = this.authService.getUser().then(user => {
  //     if (user) {
  //       options["headers"] = new HttpHeaders()
  //         .append("Authorization", `Bearer ${user.access_token}`);
  //     }

  //     return options;
  //   });

  //   return from(promise);
  // }

  private getOptions(headers?: HttpHeaders) {
    const options = {
      headers: headers || new HttpHeaders({
        'Accept': 'application/json'
      })
    };

    options.headers = options.headers
      .append("Authorization", `Bearer ${localStorage.getItem('apitoken')}`);

    options.headers = options.headers
      .append("OrgId", `${localStorage.getItem('orgid')}`);

    return of(options);

  }

}
