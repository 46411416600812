import { Component, OnInit } from '@angular/core';

import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ams',
  templateUrl: './ams.component.html',
  styleUrls: ['./ams.component.scss']
})
export class AmsComponent implements OnInit {
  title = 'Application Management Services';
  constructor(private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Learn how outsourcing application management can boost your efficiency, reduce stress on your HR team, and increase the business value you deliver. ' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Application Management Services' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Learn how outsourcing application management can boost your efficiency, reduce stress on your HR team, and increase the business value you deliver. ' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/application-management-services' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Application Management Services' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Learn how outsourcing application management can boost your efficiency, reduce stress on your HR team, and increase the business value you deliver. ' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/application-management-services' }      
    );
  }

}


