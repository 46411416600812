import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BiopageService {
  private isNavhidden = new BehaviorSubject<boolean>(false)
  constructor() {

  }
  getnavbarhidden(status: boolean) {
    this.isNavhidden.next(status)
  }



  navhidden(): Observable<boolean> {
    return this.isNavhidden.asObservable().pipe(distinctUntilChanged())
  }
}
