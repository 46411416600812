import { Search } from './../shared/models/search';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public searchTerm: string;
  searchResults = [];
  searchArrayLength: number;
  hasValue: boolean = false;
  userSearchUpdate = new Subject<string>();
  @Output() setSearchArrayLength = new EventEmitter<any>();

  constructor() {
    this.userSearchUpdate.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.searchResults = Search.searchingfor.filter(x => x.description.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || x.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || x.url.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || x.content.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
          this.searchTerm = value;
          this.searchArrayLength = this.searchResults.length;
        }
        else {
          this.searchArrayLength = 0;
        }
        this.setSearchLength(this.searchArrayLength);
      });
  }

  ngOnInit() {
  }

  setSearchLength(searchLengthValue) {
    this.setSearchArrayLength.emit(searchLengthValue);
  }

}
