import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hcm-value-calculator',
  templateUrl: './hcm-value-calculator.component.html',
  styleUrls: ['./hcm-value-calculator.component.scss']
})

export class HcmValueCalculatorComponent implements OnInit {

  title = 'Pixentia HCM Value Calculator';
  roiScripts = ['Overlay.min.js', 'html2pdf.bundle.min', 'stepper.js', 'custom.js', 'jquery.toaster.js'];
  isValid: boolean = true;
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router: Router) {

  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Estimate the value of your HR initiatives. Enter your data in our value calculator to see how you can improve top-line and bottom-line results.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia HCM Value Calculator' }
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Estimate the value of your HR initiatives. Enter your data in our value calculator to see how you can improve top-line and bottom-line results.' }
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/hcm/hcm-value-calculator' }
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia HCM Value Calculator' }
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Estimate the value of your HR initiatives. Enter your data in our value calculator to see how you can improve top-line and bottom-line results.' }
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/hcm/hcm-value-calculator' }
    );
    this.loadScript();
  }

  public loadScript() {
    let body = <HTMLDivElement>document.body;
    for (var i = 0; i <= this.roiScripts.length; i++) {
      let script = document.createElement('script');
      script.src = this.roiScripts[i];
      body.appendChild(script);
    }
  }

}
