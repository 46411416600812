import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AnalyticsComponent } from './services/analytics/analytics.component';
import { CeridianComponent } from './services/cloud-transformation/ceridian/ceridian.component';
import { ChasmaAnalyticsComponent } from './products/chasma-analytics/chasma-analytics.component';
import { ChasmaAppsComponent } from './products/chasma-apps/chasma-apps.component';
import { ChasmaBotBuilderComponent } from './products/chasma-bot-builder/chasma-bot-builder.component';
import { ChasmaCibileComponent } from './products/chasma-cibile/chasma-cibile.component';
import { ChasmaConnectComponent } from './products/chasma-connect/chasma-connect.component';
import { ChasmaEditorComponent } from './products/chasma-editor/chasma-editor.component';
import { ChasmaEventComponent } from './products/chasma-event/chasma-event.component';
import { ChasmaHrboxComponent } from './products/chasma-hrbox/chasma-hrbox.component';
import { ChasmaLexyComponent } from './products/chasma-lexy/chasma-lexy.component';
import { ChasmaNatioDeskComponent } from './products/chasma-natio-desk/chasma-natio-desk.component';
import { ChasmaQualeComponent } from './products/chasma-quale/chasma-quale.component';
import { CompensationComponent } from './services/hcm/compensation/compensation.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { CornerstoneAnalyticsComponent } from './products/cornerstone-analytics/cornerstone-analytics.component';
import { CornerstoneComponent } from './services/cloud-transformation/cornerstone/cornerstone.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomlearningComponent } from './services/learning/customlearning/customlearning.component';
import { DemosmainComponent } from './services/learning/demosmain/demosmain.component';
import { DigitalTransformationComponent } from './services/digital-transformation/digital-transformation.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { EmployeePortalComponent } from './services/hcm/employee-portal/employee-portal.component';
import { EnterpriseIntegrationsComponent } from './services/hcm/enterprise-integrations/enterprise-integrations.component';
import { HcmCoeComponent } from './services/hcm/hcm-coe/hcm-coe.component';
import { HcmLearningComponent } from './services/hcm/hcm-learning/hcm-learning.component';
import { HcmResourceCenterComponent } from './services/hcm/hcm-resource-center/hcm-resource-center.component';
import { HcmValueCalculatorComponent } from './services/hcm/hcm-value-calculator/hcm-value-calculator.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LearningCoeComponent } from './services/learning/learning-coe/learning-coe.component';
import { LearningPortalsComponent } from './services/learning/learning-portals/learning-portals.component';
import { LearningResourceCenterComponent } from './services/learning/learning-resource-center/learning-resource-center.component';
import { LearningmanagementsystemsComponent } from './services/learning/learningmanagementsystems/learningmanagementsystems.component';
import { LearningstylesandgamificationComponent } from './services/learning/learningstylesandgamification/learningstylesandgamification.component';
import { MobileComponent } from './services/mobile/mobile.component';
import { MobilelearningComponent } from './services/learning/mobilelearning/mobilelearning.component';
import { PayrollComponent } from './services/hcm/payroll/payroll.component';
import { PeopleAnalyticsPowerBIComponent } from './products/people-analytics-power-bi/people-analytics-power-bi.component';
import { PeopleanalyticsComponent } from './services/hcm/peopleanalytics/peopleanalytics.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RecrutingComponent } from './services/hcm/recruting/recruting.component';
import { SalesforceComponent } from './services/cloud-transformation/salesforce/salesforce.component';
import { ServicenowComponent } from './services/cloud-transformation/servicenow/servicenow.component';
import { SiteMapComponent } from './site-map/site-map.component';
import { SumtotalAnalyticsComponent } from './products/sumtotal-analytics/sumtotal-analytics.component';
import { SumtotalComponent } from './services/cloud-transformation/sumtotal/sumtotal.component';
import { TalentmanagementComponent } from './services/hcm/talentmanagement/talentmanagement.component';
import { WorkdayAnalyticsComponent } from './products/workday-analytics/workday-analytics.component';
import { WorkdayComponent } from './services/cloud-transformation/workday/workday.component';
import { ChangeManagementComponent } from './services/hcm/hcm-learning/change-management/change-management.component';
import { BusinessWorkshopComponent } from './services/hcm/hcm-learning/business-workshop/business-workshop.component';
import { ConfigurationWorkshopComponent } from './services/hcm/hcm-learning/configuration-workshop/configuration-workshop.component';
import { IntegrationComponent } from './services/hcm/hcm-learning/integration/integration.component';
import { AnalyticsDeploymentComponent } from './services/hcm/hcm-learning/analytics-deployment/analytics-deployment.component';
import { TestingComponent } from './services/hcm/hcm-learning/testing/testing.component';
import { GamificationComponent } from './services/hcm/hcm-learning/gamification/gamification.component';
import { AugmentedRealityComponent } from './services/hcm/hcm-learning/augmented-reality/augmented-reality.component';
import { VirtualRealityComponent } from './services/hcm/hcm-learning/virtual-reality/virtual-reality.component';
import { SimulationComponent } from './services/hcm/hcm-learning/simulation/simulation.component';
import { UserExperienceAndDesignComponent } from './services/hcm/hcm-learning/user-experience-and-design/user-experience-and-design.component';
import { DevelopmentComponent } from './services/hcm/hcm-learning/development/development.component';
import { AdminResourceComponent } from './services/hcm/hcm-learning/admin-resource/admin-resource.component';
import { TechnologyComponent } from './services/hcm/hcm-learning/technology/technology.component';
import { ContentResourceCenterComponent } from './services/hcm/hcm-learning/content-resource-center/content-resource-center.component';
import { ManagedServicesComponent } from './services/hcm/hcm-learning/managed-services/managed-services.component';
import { AnalyticsResourceComponent } from './services/hcm/hcm-learning/analytics-resource/analytics-resource.component';
import { StaffingAndAugmentationServicesComponent } from './services/hcm/hcm-learning/staffing-and-augmentation-services/staffing-and-augmentation-services.component';
import { HcmChangeManagementComponent } from './services/hcm/hcm-coe/hcm-change-management/hcm-change-management.component';
import { HcmWorkshopsComponent } from './services/hcm/hcm-coe/hcm-workshops/hcm-workshops.component';
import { HcmIntegrationComponent } from './services/hcm/hcm-coe/hcm-integration/hcm-integration.component';
import { HcmAnalyticsDeploymentComponent } from './services/hcm/hcm-coe/hcm-analytics-deployment/hcm-analytics-deployment.component';
import { HcmTestingComponent } from './services/hcm/hcm-coe/hcm-testing/hcm-testing.component';
import { HcmAdminResourceComponent } from './services/hcm/hcm-coe/hcm-admin-resource/hcm-admin-resource.component';
import { HcmTechnologyComponent } from './services/hcm/hcm-coe/hcm-technology/hcm-technology.component';
import { HcmContentResourceCenterComponent } from './services/hcm/hcm-coe/hcm-content-resource-center/hcm-content-resource-center.component';
import { HcmAnalyticsComponent } from './services/hcm/hcm-coe/hcm-analytics/hcm-analytics.component';
import { HcmManagedServicesComponent } from './services/hcm/hcm-coe/hcm-managed-services/hcm-managed-services.component';
import { HcmStaffingAndAugmentationServicesComponent } from './services/hcm/hcm-coe/hcm-staffing-and-augmentation-services/hcm-staffing-and-augmentation-services.component';
import { PartnersComponent } from './partners/partners.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { DemouserapprovalComponent } from './services/learning/demouserapproval/demouserapproval.component';
import { DemoComponent } from './demo/demo.component';
import { HrDigitalTransformationComponent } from './services/hr-digital-transformation/hr-digital-transformation.component';
import { PixentiabioComponent } from './pixentiabio/pixentiabio.component';
import { AmsComponent } from './services/ams/ams.component';
import { ManagedtalentdevelopmentservicesComponent } from './services/managedtalentdevelopmentservices/managedtalentdevelopmentservices.component';

const routes: Routes = [

  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: 'acelerate_learning_transfer_with_augmented_reality',
    redirectTo: ''
  },
  {
    path: 'Admin/ForgotPassword',
    redirectTo: ''
  },
  {
    path: 'home',
    redirectTo: ''
  },

  {
    path: 'products/signup',
    redirectTo: ''
  },
  {
    path: 'Blocked_mails.xml',
    redirectTo: ''
  },
  {
    path: 'Home/AddContactWebinarQuestionsToHubSpot_Integrating_SumTotalLMS_with_other_systems',
    redirectTo: ''
  },
  {
    path: 'Home/AddContactWebinarQuestionsToHubSpotbuildingemployeeandintranetportals',
    redirectTo: ''
  },
  {
    path: 'Home/AddContactWebinarQuestionsToHubSpotdataBuildingHRdashboards',
    redirectTo: ''
  },
  {
    path: 'Home/AddContactWebinarQuestionsToHubSpotdataDrivenHR',
    redirectTo: ''
  },
  {
    path: 'Home/AddContactWebinarQuestionsToHubSpotImplementingSumtotalTestingStrategy',
    redirectTo: ''
  },
  {
    path: 'Home/AddContactWebinarQuestionsToHubStop',
    redirectTo: ''
  },
  {
    path: 'Home/AddContactWebinarQuestionsToHubStopAdvancedReporting',
    redirectTo: ''
  },
  {
    path: 'Home/Homemanagedsolutions',
    redirectTo: ''
  },
  {
    path: 'home/index',
    redirectTo: ''
  },
  {
    path: 'Home/ROI',
    redirectTo: ''
  },
  {
    path: 'home/search',
    redirectTo: ''
  },
  {
    path: 'search',
    redirectTo: ''
  },
  {
    path: 'services',
    redirectTo: ''
  },
  {
    path: 'services/docker',
    redirectTo: ''
  },
  {
    path: 'it_business.html',
    redirectTo: ''
  },
  {
    path: 'jsapi/min/minified/v3.js?v',
    redirectTo: ''
  },
  {
    path: 'mobile/tpl-performance-issue',
    redirectTo: ''
  },
  {
    path: 'prepare.html',
    redirectTo: ''
  },
  {
    path: 'services/staffing',
    redirectTo: ''
  },
  {
    path: 'services/staffing/creativeservicesdesign',
    redirectTo: ''
  },
  {
    path: 'services/enterprise-applications',
    redirectTo: ''
  },
  {
    path: 'services/enterprise-applications/windows-desktop-development',
    redirectTo: ''
  },
  {
    path: 'Services/WebSolutions',
    redirectTo: ''
  },
  {
    path: 'windows-applications',
    redirectTo: ''
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'bio',
    component: PixentiabioComponent
  },
  {
    path: 'company/about',
    redirectTo: 'about'
  },
  {
    path: 'home/About',
    redirectTo: 'about'
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'services/requestquote',
    redirectTo: 'contact-us'
  },
  {
    path: 'queries',
    redirectTo: 'contact-us'
  },
  {
    path: 'queries-building-landing-pages',
    redirectTo: 'contact-us'
  },
  {
    path: 'request-quote',
    redirectTo: 'contact-us'
  },
  {
    path: 'careers',
    pathMatch: 'full',
    redirectTo: 'https://careers.pixentia.com/careers'
  },
  {
    path: 'partners',
    component: PartnersComponent
  },
  {
    path: 'services/hcm/hcm-coe',
    component: HcmCoeComponent
  },
  {
    path: 'services/hcm-coe/augmented-reality',
    redirectTo: 'services/hcm/hcm-coe'
  },
  {
    path: 'services/hcm-coe/development',
    redirectTo: 'services/hcm/hcm-coe'
  },
  {
    path: 'services/hcm-coe/gamification',
    redirectTo: 'services/hcm/hcm-coe'
  },
  {
    path: 'services/hcm-coe/user-experience-and-design',
    redirectTo: 'services/hcm/hcm-coe'
  },
  {
    path: 'services/staffing/projectandendusertechsupport',
    redirectTo: 'services/hcm/hcm-coe'
  },
  {
    path: 'services/hcm-coe/virtual-reality',
    redirectTo: 'services/hcm/hcm-coe'
  },
  {
    path: 'services/human-capital-management',
    redirectTo: 'services/hcm/hcm-coe'
  },
  {
    path: 'services/human-capital-management/resource-center',
    redirectTo: 'services/hcm/hcm-coe'
  },
  {
    path: 'services/hcm/recruiting',
    component: RecrutingComponent
  },
  {
    path: 'services/hcm/learning',
    component: HcmLearningComponent
  },
  {
    path: 'services/e-learning/multi-device-elearning',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/virtual-reality',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/testing',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/technology',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/staffing-and-augmentation-services',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/simulation',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/managed-services',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/Integration',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/gamification',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/development',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/content-resource-center',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/change-management',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/changemanagement',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/augmented-reality',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/analytics-deployment',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/analytics',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/admin-resource',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian/AdminResource',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning/ceridian',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/learning',
    redirectTo: 'services/hcm/learning'
  },
  {
    path: 'services/hcm/talentmanagement',
    component: TalentmanagementComponent
  },
  {
    path: 'talent_performance.html',
    redirectTo: 'services/hcm/talentmanagement'
  },
  {
    path: 'services/hcm/payroll',
    component: PayrollComponent
  },
  {
    path: 'services/hcm/compensation',
    component: CompensationComponent
  },
  {
    path: 'services/hcm/enterprise-integrations',
    component: EnterpriseIntegrationsComponent
  },
  {
    path: 'services/hcm/peopleanalytics',
    component: PeopleanalyticsComponent
  },
  {
    path: 'services/hcm/employee-portals',
    component: EmployeePortalComponent
  },
  {
    path: 'services/hcm/hcm-value-calculator',
    component: HcmValueCalculatorComponent
  },
  {
    path: 'services/hcm/hcm-resource-center',
    component: HcmResourceCenterComponent
  },
  {
    path: 'services/hcm/workday',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/talentx',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/sumtotal',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/success-factors',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/successfactors',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/roi',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/moodle',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/humancapitalmanagement',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/hcm-roi-value-calculator',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/HCM/DevelopmentCenter',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm/benefits',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/hcm',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/catalog-integration',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/hr-tech-conference',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/hr-tech-congress-2015',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/is-cover-letter-a-compulsion',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/let-your-resume-give-you-a-job',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/sharepoint-portals',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/strategic-onboarding',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/sumtotal-advanced-reporting',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'hcm/sumtotal-web-services',
    redirectTo: 'services/hcm/hcm-resource-center'
  },
  {
    path: 'services/learning/customlearning',
    component: CustomlearningComponent
  },
  {
    path: 'services/e-learning/customlearning',
    redirectTo: 'services/learning/customlearning'
  },
  {
    path: 'services/e-learning/custom-learning',
    redirectTo: 'services/learning/customlearning'
  },
  {
    path: 'services/learning/mobilelearning',
    component: MobilelearningComponent
  },
  {
    path: 'researchreports/mobile-learning',
    redirectTo: 'services/learning/mobilelearning'
  },
  {
    path: 'services/learning/learningstylesandgamification',
    component: LearningstylesandgamificationComponent
  },
  {
    path: 'services/learning/learningmanagementsystems',
    component: LearningmanagementsystemsComponent
  },
  {
    path: 'services/e-learning/LearningManagementSystems',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/virtual-reality',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/userexperienceanddesign',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/testing',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/technology',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/staffing-and-augmentation-services',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/simulation',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/managed-services',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/integration',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/gamification',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/development',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/content-resource-center',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/configuration-workshop',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/change-management',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/analytics-deployment',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/analytics',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal/admin-resource',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/sumtotal',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/virtual-reality',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/testing',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/technology',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/staffing-and-augmentation-services',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/staffingAndAugmentationServices',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/simulation',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/managed-services',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/integration',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/gamification',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/development',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/content-resource-center',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/change-management',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/augmented-reality',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone/analytics-deployment',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/learning/cornerstone',
    redirectTo: 'services/learning/learningmanagementsystems'
  },
  {
    path: 'services/e-learning/learningstylesandgamification',
    redirectTo: 'services/learning/learningstylesandgamification'
  },
  {
    path: 'services/learning/demosmain',
    component: DemosmainComponent
  },
  {
    path: 'services/e-learning/demosmain',
    redirectTo: 'services/learning/demosmain'
  },
  {
    path: 'services/learning/mobilelearning',
    component: MobilelearningComponent
  },
  {
    path: 'services/e-learning/mobilelearning',
    redirectTo: 'services/learning/mobilelearning'
  },
  {
    path: 'services/analytics',
    component: AnalyticsComponent
  },
  {
    path: 'services/digital-transformation',
    component: DigitalTransformationComponent
  },
  {
    path: 'services/mobile',
    component: MobileComponent
  },
  {
    path: 'services/application-management-services',
    component: AmsComponent
  },
  {
    path: 'services/managed-talent-development-services',
    component: ManagedtalentdevelopmentservicesComponent
  },
  {
    path: 'Services/MobileSolutionsDetails',
    redirectTo: 'services/mobile'
  },
  {
    path: 'Services/MobileSolutions',
    redirectTo: 'services/mobile'
  },
  {
    path: 'Services/MobileOffshore',
    redirectTo: 'services/mobile'
  },
  {
    path: 'Services/Mobilemanagesol',
    redirectTo: 'services/mobile'
  },
  {
    path: 'services/mobile/mobileapplications',
    redirectTo: 'services/mobile'
  },
  {
    path: 'Services/ApplicationDevelopment',
    redirectTo: 'services/mobile'
  },
  {
    path: 'services/advancedanalytics',
    component: AnalyticsComponent
  },
  {
    path: 'customers',
    component: CustomersComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent
  },
  {
    path: 'CookiePolicy',
    redirectTo: 'cookie-policy'
  },
  {
    path: 'disclaimer',
    component: DisclaimerComponent
  },
  {
    path: 'products/chasma-apps',
    component: ChasmaAppsComponent
  },
  {
    path: 'products/chasma_apps',
    redirectTo: 'products/chasma-apps'
  },
  {
    path: 'products/chasma_apps/chasma_licensing',
    redirectTo: 'products/chasma-apps'
  },
  {
    path: 'products/chasma_apps/chasma_trek',
    redirectTo: 'products/chasma-apps'
  },
  {
    path: 'products/chasma-analytics',
    component: ChasmaAnalyticsComponent
  },
  {
    path: 'products/chasma_analytics',
    redirectTo: 'products/chasma-analytics'
  },
  {
    path: 'products/chasma-analytics/sumtotal-analytics',
    component: SumtotalAnalyticsComponent
  },
  {
    path: 'products/chasma-analytics/cornerstone-analytics',
    component: CornerstoneAnalyticsComponent
  },
  {
    path: 'products/chasma-analytics/workday-analytics',
    component: WorkdayAnalyticsComponent
  },
  {
    path: 'products/chasma-analytics/people-analytics-power-bi',
    component: PeopleAnalyticsPowerBIComponent
  },
  {
    path: 'products/chasma-analytics/chasma-hrbox',
    component: ChasmaHrboxComponent
  },
  {
    path: 'products/chasma-connect',
    component: ChasmaConnectComponent
  },
  {
    path: 'products/chasma_connect',
    redirectTo: 'products/chasma-connect'
  },
  {
    path: 'products/chasma-apps/chasma-editor',
    component: ChasmaEditorComponent
  },
  {
    path: 'products/chasma_analytics/chasma_hrbox',
    redirectTo: 'products/chasma-apps/chasma-editor'
  },
  {
    path: 'products/chasma_editor',
    redirectTo: 'products/chasma-apps/chasma-editor'
  },
  {
    path: 'products/chasma-editor',
    redirectTo: 'products/chasma-apps/chasma-editor'
  },
  {
    path: 'products/chasma_apps/chasma_editor',
    redirectTo: 'products/chasma-apps/chasma-editor'
  },
  {
    path: 'products/chasma_apps/chasma_event',
    redirectTo: 'products/chasma-apps/chasma-event'
  },
  {
    path: 'products/chasma-apps/chasma-event',
    component: ChasmaEventComponent
  },
  {
    path: 'products/chasma-apps/chasma-quale',
    component: ChasmaQualeComponent
  },
  {
    path: 'products/chasma_apps/chasma_quale',
    redirectTo: 'products/chasma-apps/chasma-quale'
  },
  {
    path: 'products/chasma-apps/chasma-natiodesk',
    component: ChasmaNatioDeskComponent
  },
  {
    path: 'products/chasma-apps/chasma-botbuilder',
    component: ChasmaBotBuilderComponent
  },
  {
    path: 'products/chasma-apps/chasma-cible',
    component: ChasmaCibileComponent
  },
  {
    path: 'products/chasma-apps/chasma-lexy',
    component: ChasmaLexyComponent
  },
  {
    path: 'products/chasma_apps/chasma_lexy',
    redirectTo: 'products/chasma-apps/chasma-lexy'
  },
  {
    path: 'services/learning/learning-portals',
    component: LearningPortalsComponent
  },
  {
    path: 'services/learning/learning-coe',
    component: LearningCoeComponent
  },
  {
    path: 'services/cloud-transformation/workday',
    component: WorkdayComponent
  },
  {
    path: 'services/workday',
    redirectTo: 'services/cloud-transformation/workday'
  },
  {
    path: 'services/cloud-transformation/sumtotal',
    component: SumtotalComponent
  },
  {
    path: 'ResearchReports/SumTotal-audience',
    redirectTo: 'services/cloud-transformation/sumtotal'
  },
  {
    path: 'testing_your_sumtotal_integrations',
    redirectTo: 'services/cloud-transformation/sumtotal'
  },
  {
    path: 'researchreports/sumtotal-catalog',
    redirectTo: 'services/cloud-transformation/sumtotal'
  },
  {
    path: 'services/cloud-transformation/cornerstone',
    component: CornerstoneComponent
  },
  {
    path: 'services/e-learning/cornerstone',
    redirectTo: 'services/cloud-transformation/cornerstone'
  },
  {
    path: 'services/cloud-transformation/ceridian',
    component: CeridianComponent
  },
  {
    path: 'services/cloud-transformation/servicenow',
    component: ServicenowComponent
  },
  {
    path: 'services/cloud-transformation/salesforce',
    component: SalesforceComponent
  },
  {
    path: 'services/learning/learning-resource-center',
    component: LearningResourceCenterComponent
  },
  {
    path: 'services/hcm/learning/change-management',
    component: ChangeManagementComponent
  },
  {
    path: 'services/hcm/learning/business-workshop',
    component: BusinessWorkshopComponent
  },
  {
    path: 'services/hcm/learning/configuration-workshop',
    component: ConfigurationWorkshopComponent
  },
  {
    path: 'services/hcm/learning/integration',
    component: IntegrationComponent
  },
  {
    path: 'services/hcm/learning/analytics-deployment',
    component: AnalyticsDeploymentComponent
  },
  {
    path: 'services/hcm/learning/testing',
    component: TestingComponent
  },
  {
    path: 'services/hcm/learning/gamification',
    component: GamificationComponent
  },
  {
    path: 'services/hcm/learning/augmented-reality',
    component: AugmentedRealityComponent
  },
  {
    path: 'services/hcm/learning/virtual-reality',
    component: VirtualRealityComponent
  },
  {
    path: 'services/hcm/learning/simulation',
    component: SimulationComponent
  },
  {
    path: 'services/hcm/learning/user-experience-and-design',
    component: UserExperienceAndDesignComponent
  },
  {
    path: 'services/hcm/learning/development',
    component: DevelopmentComponent
  },
  {
    path: 'services/hcm/learning/admin-resource',
    component: AdminResourceComponent
  },
  {
    path: 'services/hcm/learning/technology',
    component: TechnologyComponent
  },
  {
    path: 'services/hcm/learning/content-resource-center',
    component: ContentResourceCenterComponent
  },
  {
    path: 'services/hcm/learning/analytics',
    component: AnalyticsResourceComponent
  },
  {
    path: 'services/hcm/learning/managed-services',
    component: ManagedServicesComponent
  },
  {
    path: 'services/hcm/learning/staffing-and-augmentation-services',
    component: StaffingAndAugmentationServicesComponent
  },
  {
    path: 'services/hcm/hcm-coe/change-management',
    component: HcmChangeManagementComponent
  },
  {
    path: 'services/hcm-coe/change-management',
    redirectTo: 'services/hcm/hcm-coe/change-management'
  },
  {
    path: 'services/hcm/hcm-coe/workshops',
    component: HcmWorkshopsComponent
  },
  {
    path: 'services/hcm/hcm-coe/integration',
    component: HcmIntegrationComponent
  },
  {
    path: 'services/hcm-coe/integration',
    redirectTo: 'services/hcm/hcm-coe/integration'
  },
  {
    path: 'services/hcm/hcm-coe/analytics-deployment',
    component: HcmAnalyticsDeploymentComponent
  },
  {
    path: 'services/hcm-coe/analytics-deployment',
    redirectTo: 'services/hcm/hcm-coe/analytics-deployment'
  },
  {
    path: 'services/hcm/hcm-coe/testing',
    component: HcmTestingComponent
  },
  {
    path: 'services/hcm-coe/testing',
    redirectTo: 'services/hcm/hcm-coe/testing'
  },
  {
    path: 'services/hcm/hcm-coe/admin-resource',
    component: HcmAdminResourceComponent
  },
  {
    path: 'services/hcm-coe/admin-resource',
    redirectTo: 'services/hcm/hcm-coe/admin-resource'
  },
  {
    path: 'services/hcm/hcm-coe/technology',
    component: HcmTechnologyComponent
  },
  {
    path: 'hcm/debugging-integrations-in-work',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/avoid-software-project-failure',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'SERVICES/TechnologySolutions',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'services/hcm-coe/technology',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/web-api-services',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/tpl-performance-issue',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/thinking-out-of-the-box',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/telnet-ssh-connections',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/sharepoint-callout',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/qualities-of-it-recruiter',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/product-success',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/embedded-iron-python-in-wpf',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/dynamic-windows-creation-wpf',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'technology/diffrent-format-iframe',
    redirectTo: 'services/hcm/hcm-coe/technology'
  },
  {
    path: 'services/hcm/hcm-coe/content-resource-center',
    component: HcmContentResourceCenterComponent
  },
  {
    path: 'services/hcm-coe/content-resource-center',
    redirectTo: 'services/hcm/hcm-coe/content-resource-center'
  },
  {
    path: 'services/hcm/hcm-coe/analytics',
    component: HcmAnalyticsComponent
  },
  {
    path: 'services/hcm-coe/analytics',
    redirectTo: 'services/hcm/hcm-coe/analytics'
  },
  {
    path: 'services/hcm/hcm-coe/managed-services',
    component: HcmManagedServicesComponent
  },
  {
    path: 'services/hcm-coe/managed-services',
    redirectTo: 'services/hcm/hcm-coe/managed-services'
  },
  {
    path: 'Services/Techmanagedsol',
    redirectTo: 'services/hcm/hcm-coe/managed-services'
  },
  {
    path: 'services/hcm/hcm-coe/staffing-and-augmentation-services',
    component: HcmStaffingAndAugmentationServicesComponent
  },
  {
    path: 'services/hcm-coe/staffing-and-augmentation-services',
    redirectTo: 'services/hcm/hcm-coe/staffing-and-augmentation-services'
  },
  {
    path: 'site-map',
    component: SiteMapComponent
  },
  {
    path: 'services/e-learning/demouserapproval/:id/:reviewingBy',
    component: DemouserapprovalComponent
  },
  {
    path: 'demo',
    component: DemoComponent
  },
  {
    path: 'products/demo',
    redirectTo: 'demo'
  },
  {
    path: 'products/demo_login',
    redirectTo: 'demo'
  },
  {
    path: 'products',
    component: ChasmaAppsComponent
  },
  {
    path: 'notfound',
    component: NotfoundComponent
  },
  {
    path: 'services/hr-digital-transformation',
    redirectTo: 'services/digital-transformation'
  },
  { path: '**', redirectTo: '/notfound' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
