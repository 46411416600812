<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/compensation">Compensation</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">People-Centered Compensation Implementation, Optimization, and Support</h3>
                <p>Select and implement the compensation platform and processes that support your talent strategy and culture
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/hcm/compensation/compensation-image.png" alt="compensation image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-5 font-weight-bold">
                    Compensation Systems that Incentivize High Performance People and Teams
                </h1>
            </div>
            <div class="col-md-5">
                <img src="../../assets/images/hcm/talent/Boosting recruting.png" class="img-fluid" style="width: 100%;" alt="">
            </div>
            <div class="col-md-7">
                <p>
                    Matching your organization’s goals with your pay-for-performance strategy requires a systems solution tailored to your needs. The professionals of Pixentia’s HCM Center of Excellence know that more than software, you need user-friendly options that integrate
                    with existing HR systems and execute your strategy without compromise.
                </p>
            </div>
        </div>
    </div>
    <div class="service_section">
        <div class="template_width">
            <div class="row">
                <div class="col-md-12 text-center sub_head">
                    <h5 class="font-weight-bold py-4 mb-0">OUR SERVICES INCLUDE</h5>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Compensation platform.png" alt="Compensation Platform Selection" class="mb-3">
                        <p class="px-4">
                            Compensation <span class="d-inline-block">Platform Selection</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/HR Payroll.png" alt="HR & Payroll Systems Integration" class="mb-3">
                        <p class="px-4">
                            HR & Payroll Systems Integration
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Application Dev.png" alt="Application Development & Training" class="mb-3">
                        <p class="px-4">
                            Application <span class="d-inline-block">Development & Training</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Legacy.png" alt="Legacy Process & Data Migration" class="mb-3">
                        <p class="px-4">
                            Legacy Process & Data Migration
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Custom Dashboards.png" alt="Custom Dashboards, Reports & Portals" class="mb-3">
                        <p class="px-4">
                            Custom Dashboards, <span class="d-inline-block">Reports & Portals</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Compansation Benchmarking.png" alt="Compensation Benchmarking Guidance" class="mb-3">
                        <p class="px-4">
                            Compensation <span class="d-inline-block">Benchmarking Guidance</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/position grade mapping.png" alt="Position Grade Mapping & Development" class="mb-3">
                        <p class="px-4">
                            Position Grade Mapping & Development
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/project justification.png" alt="Project Justification & ROI Valuation" class="mb-3">
                        <p class="px-4">
                            Project Justification <span class="d-inline-block">& ROI Valuation</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/compensation analytics.png" alt="Compensation Analytics" class="mb-3">
                        <p class="px-4">
                            Compensation Analytics
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Platform application main.png" alt="Platform/Application Maintenance" class="mb-3">
                        <p class="px-4">
                            Platform/Application Maintenance
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section mt-5">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Discovery and Planning</h4>
                    <p>
                        Your organization’s compensation strategy is unique, and Pixentia’s discovery and planning process develops custom solutions based on your compensation objectives, performance incentive plans, and existing applications infrastructure. Our work includes:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Compensation managment.png" alt="Compensation management platform" class="mb-3">
                        <p class="px-4">
                            Compensation management platform selection, encompassing RFP planning, vendor evaluation, and tools to help you chose the right platform for your organization.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Project Planning.png" alt="Project planning" class="mb-3">
                        <p class="px-4">
                            Project planning that captures the fine detail needed to successfully execute your initiatives on-time and on-budget.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Business Cases.png" alt="Business cases, project justifications" class="mb-3">
                        <p class="px-4">
                            Business cases, project justifications and ROI valuations that demonstrate how your compensation project will help achieve your business objectives and strengthen your bottom line.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Data mapping.png" alt="Data mapping and cleansing" class="mb-3">
                        <p class="px-4">
                            Data mapping and cleansing that can inform project plans and assure smooth execution of compensation <span class="d-inline-block">management initiatives.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="community_section my-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">Project Implementation</h5>
                    <p>
                        Pixentia’s project implementation teams understand that compensation systems projects demand seamless execution. Our consultants leverage their experience and knowledge to provide:
                    </p>
                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/compensation/Workshops to create.png" class="mr-2" alt="Workshops">
                        </p>
                        <p>
                            Workshops to create compensation transformation plans, map and align business processes, and set platform configurations.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/compensation/Guidance on.png" class="mr-2" alt="Guidance on benchmarking">
                        </p>
                        <p>
                            Guidance on benchmarking, grade structure, and employee compensation roadmaps.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/compensation/Compensation portals.png" class="mr-2" alt="Compensation portals">
                        </p>
                        <p>
                            Compensation portals; mobile applications that reach remote employees; and integrated SharePoint portals.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/compensation/Hands on.png" class="mr-2" alt="Hands-on support">
                        </p>
                        <p>
                            Hands-on support that can integrate new platforms and applications with existing enterprise HR, finance and payroll systems.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/compensation/Developers who.png" class="mr-2" alt="QA and acceptance testing">
                        </p>
                        <p>
                            Developers who can migrate data from legacy systems and undertake detailed QA and acceptance testing.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/hcm/compensation/Workforce analytics.png" class="mr-2" alt="Workforce analytics">
                        </p>
                        <p>
                            Workforce analytics specialists who can build data warehouses, reports and dashboards that enable deep unit and organization reporting as well as new analytic insights.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/hcm/compensation/Project Implementation.png" alt="Project Implementation" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section mt-5">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Operations Support</h4>
                    <p>
                        Pixentia’s operations support services can keep your compensation management platform and applications running at maximum efficiency without tying-up in-house IT staff. We undertake:
                    </p>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Daily Operations.png" alt="Daily operations support" class="mb-3">
                        <p class="px-4">
                            Daily operations support, including new report development, platform maintenance, and test/validation of new upgrades and releases.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Support for.png" alt="Support for periodic benchmarking" class="mb-3">
                        <p class="px-4">
                            Support for periodic benchmarking and compensation structure revisions and annual compensation updates.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Training and communications.png" alt="Training and communications support" class="mb-3">
                        <p class="px-4">
                            Training and communications support to build employee and supervisory <span class="d-inline-block">utilization and proficiency.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/New Applications.png" alt="New applications development" class="mb-3">
                        <p class="px-4">
                            New applications development that optimizes platform utilization and implements compensation management best practices.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/hcm/compensation/Metrics tracking.png" alt="Metrics tracking" class="mb-3">
                        <p class="px-4">
                            Metrics tracking and reporting that enable ongoing evaluation of compensation management projects and <span class="d-inline-block">their financial return.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </div>
    <div class="my-5">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-10 offset-md-1">
                    <div class="row align-items-center">
                        <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                            <p class="sub_heading_cta mb-4">Learn how you can effectively choose, implement and optimize your Compensation Technology.
                            </p>
                            <div class="more_info_btn datasheet_btn">
                                <a href="https://resources.pixentia.com/hcm/datasheet/pixentia-compensation-management-consulting" rel="noopener noreferrer" target="_blank">Get the detals here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="col-md-5 mt-5 mt-md-0">
                            <div>
                                <img src="../../../../assets/images/cta_images/Compensation.png" class="img-fluid" alt="CTA">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>