<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/learning/learning-portals">Learning Portals</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Learning Portals That Motivate Self-Development</h3>
                <p>Drive self-directed learning in every part of your organization and your extended enterprise
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/learning/learning-portals/Banner Image.png" alt="Learning Portals" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.kubota.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/kuboto.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="http://www.intelsecurity.com" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/mcafee_logo.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.pheaa.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/pheaa.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.linde.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/linde.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.tmhcc.com/en/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/tokia-marine.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">How You Can Create Portals That Motivate Self-Development</h1>
                </div>
                <div class="mx-5">
                    <p class="mb-3">
                        Most organizations find it difficult to engage their people in learning, but their people are not the problem. Instead, the barriers in their way are time, access, and a path to follow.
                    </p>
                </div>
                <div class="mt-4">
                    <h5 class="font-weight-bold">Pixentia’s mission is to remove those barriers.
                    </h5>
                </div>
                <div>
                    <p class="mb-3">
                        One way we do that is to create custom learning portals that engage your people in self-development.
                    </p>
                </div>
                <div class="sub_head">
                    <h3 class="font-weight-bold">Support a Culture of Learning</h3>
                    <p>
                        Your learning portal can support a culture that values learning and self-development, but creating that culture is up to you.
                    </p>
                    <p>
                        By far, not having time to learn is the most significant frustration among workers. Yet, oddly enough, many companies find that when you give people time for learning, they become more productive, not less.
                    </p>
                    <p>
                        You can create a learning culture by:
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="employee_section mb-4">
    <div class="template_width">
        <div class="row">
            <div class="col-md-6 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Finding the information.png" alt="Finding the information" class="mb-3">
                    <p class="px-4">
                        Making learning an essential work activity.
                    </p>
                </div>
            </div>
            <div class="col-md-6 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Accomplishing those.png" alt="Accomplishing those tasks" class="mb-3">
                    <p class="px-4">
                        Holding managers accountable for developing learning goals for their people—from the top of your organization to the bottom.
                    </p>
                </div>
            </div>
            <div class="col-md-6 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Interacting with colleagues.png" alt="Interacting with colleagues" class="mb-3">
                    <p class="px-4">
                        Providing a recommended learning path for every person <span class="d-inline-block">in your organization.</span>

                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="box text-center my-4">
                    <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Learning how to succeed.png" alt="Learning how to succeed in their current job" class="mb-3">
                    <p class="px-4">
                        Recognizing and rewarding learning achievements by giving people a chance to use their new knowledge and skills on the job.
                    </p>
                </div>
            </div>
            <div class="col-md-12 text-center mt-4">
                <p>To support that effort, you can design your learning portal to:</p>
            </div>
        </div>
    </div>
</div>
<div class="support_section">
    <div class="template_width">
        <div class="row">
            <div class="col-md-6">
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/learning/learning-portals/Provide job.png" class="mr-2" alt="Provide job aids and skills training in the flow of work">
                    </p>
                    <p>
                        Provide job aids and skills training in the flow of work.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/learning/learning-portals/Put achievement.png" class="mr-2" alt="Put achievement and progress">
                    </p>
                    <p>
                        Put achievement and progress front and center on the portal.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/learning/learning-portals/Suggest and provide.png" class="mr-2" alt="Suggest and provide relevant content on-demand">
                    </p>
                    <p>
                        Suggest and provide relevant content on-demand.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/learning/learning-portals/Make new content.png" class="mr-2" alt="Make new content easy to find, perhaps with a chatbot">
                    </p>
                    <p>
                        Make new content easy to find, perhaps with a chatbot.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/learning/learning-portals/Integrate social.png" class="mr-2" alt="Integrate social learning tools">
                    </p>
                    <p>
                        Integrate social learning tools into the portal.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/learning/learning-portals/Display achievement.png" class="mr-2" alt="Display achievement badges and certifications">
                    </p>
                    <p>
                        Display achievement badges and certifications on each person’s profile.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/learning/learning-portals/Integrate an.png" class="mr-2" alt="Integrate an internal talent marketplace into the portal">
                    </p>
                    <p>
                        Integrate an internal talent marketplace into the portal.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <img src="../../../../assets/images/learning/learning-portals/To support that effor.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section mt-5">
    <div class="services_section">
        <div class="template_width">
            <div class="row mb-4">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified">
                        <li class="nav-item text-center">
                            <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Optimization
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <!-- Tab panes -->
                <div class="tab-content mb-5 mt-3">
                    <div class="tab-pane active" id="strategy">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Strategy and Planning
                                    </h4>
                                </div>
                                <p>
                                    We provide a structured approach to supporting your workforce. Our services include:
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Strategy Workshops.png" alt="Strategy Workshops">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Strategy Workshops</p>
                                        <p>
                                            Work sessions to develop an understanding of your business challenges and objectives.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Governance.png" alt="Governance">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Governance</p>
                                        <p>
                                            Establishing a framework for data and information governance, so you can deliver accurate <span class="d-inline-block">and timely information.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Change Management.png" alt="Change Management">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Change Management</p>
                                        <p>
                                            Change management tools help you create a wave of change across your organization.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Technology Roadmap.png" alt="Technology Roadmap">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Technology Roadmap</p>
                                        <p>
                                            No matter where you are in your journey, we help you develop a road map to providing an engaging learning experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portal-strategy/Technology Selection.png" alt="Technology Selection">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Technology Selection</p>
                                        <p>
                                            We provide tools that assist you in selecting the right solution for your workforce.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="deployment">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Preparation and Deployment
                                    </h4>
                                </div>
                                <p>
                                    We prepare you to create and maintain your technology with a collaborative approach to project management.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-preparation/Project Planning.png" alt="Project Planning">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Project Planning</p>
                                        <p>
                                            We provide tools for managing your resources, people, and time to ensure <span class="d-inline-block">a successful on-time launch.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-preparation/Data Cleansing.png" alt="Data Cleansing and Migration">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Data Cleansing and Migration</p>
                                        <p>
                                            We provide tools to ensure consistent and reliable data before you migrate it to your portal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-preparation/Testing.png" alt="Testing">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Testing</p>
                                        <p>
                                            We provide training and tools to manage your testing to ensure a successful launch.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="optimization">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Optimization
                                    </h4>
                                </div>
                                <div class="row">
                                    <div class="col-md-8 offset-md-2">
                                        <p>
                                            After you launch your new portal, your deployment continues as you adopt new tools and practices to optimize your employee experience.
                                        </p>
                                        <p>
                                            In addition, we provide ongoing support and services to enable you to grow with your technology as your technology grows with you.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-optimization/Business Process Optimization.png" alt="Business Process Optimization">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">Business Process Optimization</p>
                                        <p>
                                            Direct help for your administrators to ensure timely information delivery and workflow <span class="d-inline-block">as your needs change.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../../../assets/images/hcm/employee-portals/portals-optimization/User Experience Optimization.png" alt="User Experience Optimization">
                                    </div>
                                    <div>
                                        <p class="mb-1 font-weight-bold">User Experience Optimization</p>
                                        <p>
                                            We assist you in monitoring and fine-tuning the user experience to make it the best it can be.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>