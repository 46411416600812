import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chasma-cibile',
  templateUrl: './chasma-cibile.component.html',
  styleUrls: ['./chasma-cibile.component.scss']
})
export class ChasmaCibileComponent implements OnInit {

  title = 'Chasma Cible | User Flow Analytics';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Expose hidden insights in customer and employee behavior with visual analytics. Capture online user flow in your applications and web pages.. ' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Chasma Cible | User Flow Analytics' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Expose hidden insights in customer and employee behavior with visual analytics. Capture online user flow in your applications and web pages.. ' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/products/chasma-apps/chasma-cible' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Chasma Cible | User Flow Analytics' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Expose hidden insights in customer and employee behavior with visual analytics. Capture online user flow in your applications and web pages.. ' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/products/chasma-apps/chasma-cible' }      
    );
  }
}
