<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/user-experience-and-design">UI and Design</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Remove the Barriers to Learning
                </h3>
                <p>At Pixentia, we work on the principle that e-learning design must adapt to the way people learn. Our experts bring the elements of instructional design and the user experience together to create a useful and rewarding experience.</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/ui/user-experience-image.png" alt="Change management image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Design a Learner-Centered Experience</h1>
                </div>
                <div>
                    <p class="mb-3">
                        Developing effective e-learning depends much more on preparation and instructional design than on the authoring tools. We work with your SMEs to create a design that meets your objectives and the needs of your audience.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-4">
    <div class="template_width">
        <div class="row">
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Develop a storyline.png" alt="Develop a storyline in the context of the work" class="mb-3">
                    <p class="px-5">
                        Develop a storyline in the context of the work
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Embed learning.png" alt="Embed learning retention strategies in the design" class="mb-3">
                    <p class="px-4">
                        Embed learning retention strategies in the design
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Create multimodal.png" alt="Create multimodal design elements that encompass user preferences" class="mb-3">
                    <p class="px-4">
                        Create multimodal design elements that encompass user preferences
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Develop and finalize.png" alt="Develop and finalize the voiceover script" class="mb-3">
                    <p class="px-4">
                        Develop and finalize the voiceover script
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Develop interaction.png" alt="Develop interaction models that enable exploration and self-awareness with a logical flow" class="mb-3">
                    <p class="px-4">
                        Develop interaction models that enable exploration and self-awareness with a logical flow
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Choose the authoring.png" alt="Choose the authoring tool and technology that works best for your requirements" class="mb-3">
                    <p class="px-4">
                        Choose the authoring tool and technology that works best for your requirements
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section text-white">
    <div class="template_width">
        <div class="row text-white align-items-center">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 mb-3">Focus on Quality
                </h4>
                <p>
                    Once we complete the design phase, we turn our focus to the quality of the learner experience (LX). We strive to create an immersive, focused experience. Our standards include:
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Navigation must be.png" alt="Develop a storyline in the context of the work" class="mb-3">
                    <p class="px-5">
                        Navigation must be intuitive, consistent, and transparent. Learners must always know what is expected of them
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Color schemes are consistent.png" alt="Embed learning retention strategies in the design" class="mb-3">
                    <p class="px-4">
                        Color schemes are consistent with the current research on the effect of colors on the human mind
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Simple layout.png" alt="Create multimodal design elements that encompass user preferences" class="mb-3">
                    <p class="px-4">
                        Simple layout, with plenty of space between elements, so learners can focus on what is essential
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Images.png" alt="Develop and finalize the voiceover script" class="mb-3">
                    <p class="px-4">
                        Images, icons, and artwork are of high quality that reflects attention to detail
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Reading is easy.png" alt="Develop interaction models that enable exploration and self-awareness with a logical flow" class="mb-3">
                    <p class="px-4">
                        Reading is easy to understand and appropriate for the learner’s skill level
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/ui/Fonts are large enough.png" alt="Fonts are large enough for easy reading" class="mb-3">
                    <p class="px-4">
                        Fonts are large enough for easy reading
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn more about how you can maximize learning engagement with Pixentia’s UI and Design services.
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to Preparation and Planning  <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>