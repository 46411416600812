import { ApprovalConfirmationDialogComponent } from './../approval-confirmation-dialog/approval-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ApicallsService } from './../../apicalls.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { validateDemoSignUpUserDetails } from 'src/app/shared/models/eLearningDemos';

@Component({
  selector: 'app-demouserapproval',
  templateUrl: './demouserapproval.component.html',
  styleUrls: ['./demouserapproval.component.scss']
})
export class DemouserapprovalComponent implements OnInit {

  userId: string;
  approvalStatus: string;
  approvedById: string;
  loginValidateDetails: validateDemoSignUpUserDetails;
  displayMessage: string;

  constructor(private activeRoute: ActivatedRoute,
    private apicallsService: ApicallsService,
    private matDialog: MatDialog) {

  }

  ngOnInit() {
    this.userId = this.activeRoute.snapshot.paramMap.get("id");
    this.approvedById = this.activeRoute.snapshot.paramMap.get("reviewingBy");
    this.getDemoUserDetails();
  }

  getDemoUserDetails() {
    this.apicallsService.getValidateUserDetails(this.userId).subscribe(validateDetails => {
      this.loginValidateDetails = validateDetails;
      this.displayMessage = validateDetails.approvalMessage;
    })
  }

  confirmDemoUserStatus(status: string) {
    const dialogRef = this.matDialog.open(ApprovalConfirmationDialogComponent, {
      // panelClass: 'app-confirmation-dialog',
      width: '500px',
      height: 'fit-content',
      disableClose: true,
      // backdropClass: 'backdropBackground',
      data: { message: status, approvedBy: this.approvedById, userDetails: this.loginValidateDetails },
    });
    // dialogRef.updatePosition({ top: '2%', left: '50%' });
    dialogRef.afterClosed().subscribe(displayMessage => {
      this.displayMessage = displayMessage;
    })
  }

}
