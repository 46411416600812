<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="demo">Demo</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="">
                <h1 class="font-weight-bold">Schedule a Live Demo</h1>
                <p>Learn how you can elevate your employee experience and actualize your employee centered vision.</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/demos/Demo image.png" alt="" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>

<div class="contact_bg">
    <div class="template_width">
        <div class="row my-5">
            <div class="col-md-6 offset-md-3">
                <div class="right_column_data">
                    <h4 class="font-weight-bold text-center mb-3">Yes, I want a Demo.</h4>
                    <div id="form-target" data-hs-forms-root="true">
                        <div class="engage-hub-form-embed" id="" data-id="5557467346894848">
                        </div>
                        <form class="form form-style-form1 default position-relative" onsubmit="window.parent.EhForm.submit_form(event,this)" style="background-color:#ffffff;background-position:0% 0%
                " data-id="5557467346894848">
                            <fieldset>
                                <!-- Form Name -->
                                <div class="form-group" style="">
                                    <label class="control-label" for="name">
                                        <p class="mb-2"><span style="font-size: 16px; line-height: 9px;"
                                                data-mce-style="font-size: 16px; line-height: 9px;">First Name <span
                                                    class="text-danger">*</span></span></p>
                                    </label>
                                    <div class="controls">
                                        <input data-ebay_field="name" data-ebay_add_as="" id="name" title="" name="name" type="text" style="background-color:#fff;" placeholder="" class="form-control" required="true">
                                    </div>
                                </div>
                                <div class="form-group" style="">
                                    <label class="control-label" for="last_name">
                                        <p class="mb-2"><span style="font-size: 16px;"
                                                data-mce-style="font-size: 16px;">Last Name <span
                                                    class="text-danger">*</span></span></p>

                                    </label>
                                    <div class="controls">
                                        <input data-ebay_field="last_name" data-ebay_add_as="" id="last_name" title="" name="last_name" type="text" style="background-color:#fff;" placeholder="" class="form-control" required="true">
                                    </div>
                                </div>
                                <div class="form-group" style="">
                                    <label class="control-label" for="email">
                                        <p class="mb-2"><span style="font-size: 16px;"
                                                data-mce-style="font-size: 16px;">Business Email <span
                                                    class="text-danger">*</span></span>
                                        </p>
                                    </label>
                                    <div class="controls">
                                        <input data-ebay_field="email" data-ebay_add_as="" id="email" title="" name="email" type="email" style="background-color:#fff;" placeholder="" class="form-control" required="true">
                                    </div>
                                </div>
                                <div class="form-group" style="">
                                    <label class="control-label" for="phone_number">
                                        <p class="mb-2"><span style="font-size: 16px;"
                                                data-mce-style="font-size: 16px;">Phone Number <span
                                                    class="text-danger">*</span></span></p>

                                    </label>
                                    <div class="controls">
                                        <input data-ebay_field="phone" data-ebay_add_as="" id="phone_number" title="Please enter valid phone number." name="phone_number" type="phone" style="background-color:#fff;" placeholder="" class="form-control" required="true">
                                    </div>
                                </div>
                                <div class="form-group" style="">
                                    <label class="control-label" for="Primary_HR_System_that_you_use?">
                                        <p class="mb-2"><span style="font-size: 16px;"
                                                data-mce-style="font-size: 16px;">Primary HR System that
                                                you use? <span class="text-danger">*</span></span></p>
                                    </label>
                                    <div class="controls">
                                        <select data-ebay_field="Primary HR System that you use?" data-ebay-add="" data-ebay_add_as="" type="select" id="Primary_HR_System_that_you_use?" name="Primary_HR_System_that_you_use?" style="background-color:#fff;" placeholder="Select your HR System?"
                                            class="form-control" required="true">
                                            <option value="">Select your HR System?</option>
                                            <option value="Workday">Workday</option>
                                            <option value="SumTotal">SumTotal</option>
                                            <option value="Cornerstone">Cornerstone</option>
                                            <option value="Success Factors">Success Factors</option>
                                            <option value="Taleo">Taleo</option>
                                            <option value="Ultimate">Ultimate</option>
                                            <option value="ADP">ADP</option>
                                            <option value="Kronos">Kronos</option>
                                            <option value="Ceridian">Ceridian</option>
                                            <option value="Saba">Saba</option>
                                            <option value="Others">Others</option>
                                            <option value="None">None</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group" style="">
                                    <label class="control-label" for="Comments">
                                        <p>Comments</p>
                                    </label>
                                    <div class="controls">
                                        <textarea rows="2" data-ebay_field="Comments" data-ebay_add_as="" id="Comments" name="Comments" type="textarea" style="background-color:#fff;" placeholder="" class="form-control"></textarea>
                                    </div>
                                </div>
                                <div class="form-group text-center">
                                    <div>
                                        <button data-ebay_field="Comments" data-ebay_add_as="" type="submit" class="submit-btn btn_style" style="color:#fff;" onclick="EhGrabbers.executeGrabberOnDemand('4543173725519872-5557467346894848')">
                                            <p class="mb-0 px-2"><span style="font-size: 15px;"
                                                    data-mce-style="font-size: 15px;">Submit</span></p>
                                        </button>
                                        <br>
                                        <span id="error-msg"></span>
                                    </div>
                                </div>
                            </fieldset>
                            <div class="error-success-container" style="display: block;
                            position: absolute;
                            top: 5%;
                            transform: translateY(-45%);
                            left: 0;
                            right: 0;
                            margin: 0;
                            text-align: center;
                            font-size: 22px;"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>