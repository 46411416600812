<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Cloud Transformation</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/cloud-transformation/workday">Workday<sup>&reg;</sup></a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Delivering Superior Workday<sup>&reg;</sup> Functionality and Results</h1>
                <p>Select and implement the payroll platform and processes that support your talent strategy and culture
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/cloud-transformations/workday/Workday Image.png" alt="Workday platform users and those considering a Workday investment can both benefit from our discovery and planning process." class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.kubota.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/kuboto.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="http://www.intelsecurity.com" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/mcafee_logo.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.pheaa.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/pheaa.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.linde.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/linde.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.tmhcc.com/en/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/tokia-marine.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center info_section">
            <div class="col-md-12 text-center">
                <h3 class="text-center mb-3 font-weight-bold">
                    How can Pixentia help you get the most out of your Workday<sup>&reg;</sup> platform?
                </h3>
                <div class="row workday_section">
                    <div class="col-md-4 text-center border-right">
                        <div class="py-2 px-5">
                            <div class="mb-3 mt-4">
                                <img src="../../../../assets/images/cloud-transformations/workday/Align Workday.png" alt="Align Workday®">
                            </div>
                            <div>
                                <p class="mb-1 font-weight-bold workday-color">Align Workday® to Your Most Important HCM Goals and Processes
                                </p>
                                <p>
                                    Pixentia’s experienced consultants start by understanding your business and goals, and then show you how to focus Workday®’s <a href="services/hcm/hcm-coe">HCM</a> applications on the priorities critical to your
                                    <span
                                        class="d-inline-block">organization’s success.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center border-right">
                        <div class="py-2 px-5">
                            <div class="mb-3 mt-4">
                                <img src="../../../../assets/images/cloud-transformations/workday/Improve Workday.png" alt="Improve Workday®">
                            </div>
                            <div>
                                <p class="mb-1 font-weight-bold workday-color">Improve Workday® Integration with Existing Applications and Workflows</p>
                                <p>
                                    Workday® is a large, multi-purpose platform – turn to Pixentia for ways to optimize your Workday® configuration, integrate it with your existing platforms and applications, <span class="d-inline-block">and streamline workflows.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="py-2 px-5">
                            <div class="mb-3 mt-4">
                                <img src="../../../../assets/images/cloud-transformations/workday/Exploit the Full.png" alt="Exploit the Full Capabilities">
                            </div>
                            <div>
                                <p class="mb-1 font-weight-bold workday-color">Exploit the Full Capabilities of the Workday® Platform</p>
                                <p>
                                    Get the most out of your Workday® investment with practical recommendations on how to add functionality, deploy mobile apps to reach remote workforces, and cut platform administration costs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row mb-5 align-items-center info_section">
                <div class="col-md-12 text-center text-white">
                    <h3 class="text-center mb-3 font-weight-bold">
                        Discovery and Planning
                    </h3>
                    <p>
                        Workday® platform users and those considering a Workday<sup>&reg;</sup> investment can both benefit from Pixentia’s discovery and planning process. Our consultants start by learning about the drivers that define your organization’s
                        success. From that conversation can rise:
                    </p>
                    <div class="row">
                        <div class="col-md-4 text-center border-right">
                            <div class="py-2 px-5">
                                <div class="mb-3 mt-4">
                                    <img src="../../../../assets/images/cloud-transformations/workday/Platform Recommendations.png" alt="Platform Recommendations">
                                </div>
                                <div>
                                    <p class="mb-1 font-weight-bold">Platform Recommendations
                                    </p>
                                    <p>
                                        Which can help you decide whether to invest in Workday<sup>&reg;</sup> or how best to use an existing Workday® installation.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center border-right">
                            <div class="py-2 px-5">
                                <div class="mb-3 mt-4">
                                    <img src="../../../../assets/images/cloud-transformations/workday/Business Workshops.png" alt="Business Workshops">
                                </div>
                                <div>
                                    <p class="mb-1 font-weight-bold">Business Workshops</p>
                                    <p>
                                        That step-by-step evaluate your organization’s needs, rank your priorities, develop options for Workday® configuration, and estimate how the resulting organization improvements will deliver a real return on investment.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="py-2 px-5">
                                <div class="mb-3 mt-4">
                                    <img src="../../../../assets/images/cloud-transformations/workday/Project Planning.png" alt="Project Planning">
                                </div>
                                <div>
                                    <p class="mb-1 font-weight-bold">Project Planning</p>
                                    <p>
                                        Encompassing every aspect of Workday<sup>&reg;</sup> configuration, legacy data transfer, acceptance testing, employee and IT staff training, <span class="d-inline-block">and follow-up reporting.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="sub_head text-center my-4">
                    <h3 class="font-weight-bold">Workday<sup>&reg;</sup> Project Services</h3>
                    <p class="mb-5">
                        Pixentia’s Workday<sup>&reg;</sup> project services can support activities as simple as installation of an upgrade and as complex as a full platform installation.
                    </p>
                </div>
            </div>
            <div class="col-md-6 workday_section">
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/cloud-transformations/workday/Configuration and Report Design.png" class="mr-2" alt="Configuration and Report Design">
                    </p>
                    <p>
                        <strong class="d-block">Configuration and Report Design</strong> Using Pixentia’s subject matter expertise to configure Workday<sup>&reg;</sup> for optimized business processes, ease of use, and high impact personal, supervisory,
                        and executive reports.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/cloud-transformations/workday/Implementation.png" class="mr-2" alt="Align and develop your employees">
                    </p>
                    <p>
                        <strong class="d-block">Implementation, Integration and Testing Resources</strong> Who can connect Workday<sup>&reg;</sup> to your <a href="services/hcm/enterprise-integrations">enterprise</a> and payroll systems, migrate legacy
                        data, undertake detailed QA and acceptance testing, and provide overall project management.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/cloud-transformations/workday/Change Management and Adoption Programs.png" class="mr-2" alt="Reward and retain your talent">
                    </p>
                    <p>
                        <strong class="d-block">Change Management and Adoption Programs</strong> Which will engage employees, accelerate employee adoption of Workday<sup>&reg;</sup> applications and provide comprehensive reports on utilization and proficiency.
                    </p>
                </div>
                <div class="d-flex">
                    <p>
                        <img src="../../../../assets/images/cloud-transformations/workday/People Analytics.png" class="mr-2" alt="People Analytics">
                    </p>
                    <p>
                        <strong class="d-block">People Analytics</strong> That can use Workday<sup>&reg;</sup> data to generate new insights about recruiting, hiring, retention, proficiency, and performance.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="my-4">
                    <img src="../../../../assets/images/cloud-transformations/workday/Project Services.png" class="img-fluid" alt="Our Workday project services can support activities as simple as installation of an upgrade and as complex as a full platform installation.">
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row mb-3 mt-3 align-items-center info_section">
                <div class="col-md-12 text-center text-white">
                    <h3 class="text-center mb-3 font-weight-bold">
                        Workday<sup>&reg;</sup> Post Production
                    </h3>
                    <p>
                        Turn to Pixentia to help maintain your Workday<sup>&reg;</sup> platform and keep it current with your organization’s evolving requirements. We can provide:
                    </p>
                    <div class="row">
                        <div class="col-md-4 text-center border-right">
                            <div class="py-2 px-5">
                                <div class="mb-3 mt-4">
                                    <img src="../../../../assets/images/cloud-transformations/workday/Operations Support.png" alt="Operations Support">
                                </div>
                                <div>
                                    <p class="mb-1 font-weight-bold">Operations Support
                                    </p>
                                    <p>
                                        Including platform maintenance, system reporting, test, and validation of Workday®’s periodic update cycles.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center border-right">
                            <div class="py-2 px-5">
                                <div class="mb-3 mt-4">
                                    <img src="../../../../assets/images/cloud-transformations/workday/Platform Enhancements.png" alt="Platform Enhancements">
                                </div>
                                <div>
                                    <p class="mb-1 font-weight-bold">Platform Enhancements</p>
                                    <p>
                                        Which integrate new functions, add new business units, and/or support updated and revised business processes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="py-2 px-5">
                                <div class="mb-3 mt-4">
                                    <img src="../../../../assets/images/cloud-transformations/workday/Updated Reports and Talent Cards.png" alt="Updated Reports and Talent Cards">
                                </div>
                                <div>
                                    <p class="mb-1 font-weight-bold">Updated Reports and Talent Cards</p>
                                    <p>
                                        That provide supervision with new insights and employees with well-defined paths to improved performance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width my-4">
        <app-get-started></app-get-started>
    </div>