import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-analytics-resource',
  templateUrl: './analytics-resource.component.html',
  styleUrls: ['./analytics-resource.component.scss']
})
export class AnalyticsResourceComponent implements OnInit {

  title = 'Pixentia Learning Analytics Center';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Pixentia’s analytics services help you achieve your reporting goals and more. We take you beyond operational reporting to advanced predictive and prescriptive analytics that drive faster and better decisions at every level of your organization.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Learning Analytics Center' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Pixentia’s analytics services help you achieve your reporting goals and more. We take you beyond operational reporting to advanced predictive and prescriptive analytics that drive faster and better decisions at every level of your organization.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://www.pixentia.com/services/hcm/learning/analytics' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Learning Analytics Center' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Pixentia’s analytics services help you achieve your reporting goals and more. We take you beyond operational reporting to advanced predictive and prescriptive analytics that drive faster and better decisions at every level of your organization.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://www.pixentia.com/services/hcm/learning/analytics' }      
    );
  }
}