<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Cloud Transformation</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/cloud-transformation/sumtotal">SumTotal</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Innovative Implementation, Content, and Optimization for SumTotal Learn
                </h1>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/sumtotal-image.png" alt="sumtotal Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center info_section">
            <div class="col-md-12 text-center sub_head">
                <h3 class="text-center mb-3 font-weight-bold">
                    Why Is Pixentia Your Ideal SumTotal Learning Partner?
                </h3>
                <h6 class="font-weight-bold">It’s our focus on your objectives:</h6>
                <div class="row">
                    <div class="col-md-4 text-center border-right">
                        <div class="py-2 px-5">
                            <div class="mb-3 mt-4">
                                <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Adapting SumTotal.png" alt="SumTotal’s learning capabilities">
                            </div>
                            <div>
                                <p class="mb-1 font-weight-bold">Adapting SumTotal’s learning capabilities to your business goals
                                </p>
                                <p>
                                    Using Pixentia’s extensive SumTotal experience to make smart recommendations on how SumTotal can meet your organization’s specific needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center border-right">
                        <div class="py-2 px-5">
                            <div class="mb-3 mt-4">
                                <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Improving organizational.png" alt="Improving organizational processes">
                            </div>
                            <div>
                                <p class="mb-1 font-weight-bold">Improving organizational processes and outcomes</p>
                                <p>
                                    Leverage our expert knowledge of the SumTotal user interface to create simplified processes and intuitive workflows that cut costs, increase proficiency, and improve performance.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="py-2 px-5">
                            <div class="mb-3 mt-4">
                                <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Increasing the return.png" alt="SumTotal investment">
                            </div>
                            <div>
                                <p class="mb-1 font-weight-bold">Increasing the return on your SumTotal investment</p>
                                <p>
                                    Using our human capital management expertise to exploit SumTotal’s features to maximize its impact on your workforce performance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="community_section mb-3">
        <div class="services_section">
            <div class="template_width">
                <div class="row mb-5">
                    <div class="col-md-12">
                        <ul class="nav nav-pills nav-justified">
                            <li class="nav-item text-center">
                                <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                    <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                    <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                    <img src="../../assets/images/hcm/talent/Resource Centers.png" class="d-block mx-auto" alt=""> Learning Content
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link font-weight-bold" data-toggle="pill" href="#resource">
                                    <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Learning Optimization
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <!-- Tab panes -->
            <div class="tab-content mt-5">
                <div class="tab-pane active" id="strategy">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4">
                                    A Strategic Approach to Technology Planning
                                </h4>
                            </div>
                            <p>
                                Pixentia’s Center of Excellence leads you through a step-by-step strategy and planning approach to build a solid foundation for your technology initiatives. We enable you to undertake your improvements with the knowledge and confidence you will succeed.
                            </p>
                            <div>
                                <h5 class="font-weight-bold mt-4">Pixentia Technology Strategy and Planning Services
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Assessment Services</h4>
                                        <p>
                                            Analyzing your current business and technology, we take you through a structured workshop to map your current situation and your vision so you can create a solid foundation for your strategy.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Assessment Services.png" alt="Assessment Services" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Business Case Development.png" alt="Business Case Development" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Business Case Development</h4>
                                        <p>
                                            Enabling you to develop realistic estimates of the impact your initiative will have on your business, we support you in every step from building alliances, working with operational managers, capturing costs and benefits to presenting your case in the
                                            language your CXOs understand.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Technology Roadmap
                                        </h4>
                                        <p>
                                            We assist you in developing a long-term technology plan that you grow with you as your needs change. This approach, by design, will help you become more agile in the short-term, and more strategic in the long run.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Technology Roadmap.png" alt="Learning Technology Roadmap" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Project Governance.png" class="img-fluid" alt="Project Governance">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Project Governance</h4>
                                        <p>
                                            Establishing relationships between all internal and external stakeholders, we identify a single point of accountability for each project, phase, and activity. Build risk management into project planning and execution with established communication protocols
                                            for contingencies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Change Management Support</h4>
                                        <p>
                                            Engaging your people in making the change, we conduct surveys that start two-way communication about the change and its impact, program marketing techniques to build enthusiasm, resource guides to manage responsibilities.
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/change-management">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Change Management Support.png" alt="Change Management Support" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/strategy/Learning and Performance Analytics Strategy.png" alt="Learning Analytics Strategy" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Learning and Performance Analytics Strategy
                                        </h4>
                                        <p>
                                            Developing metrics and measures for your project to assess and report on your progress toward your goals. We also help you develop measures linked to your critical business metrics, so you can determine the impact on performance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="deployment">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4">
                                    A Structured, Scalable Approach to SumTotal Preparation and Deployment
                                </h4>
                            </div>
                            <p>
                                Preparation, accountability, communication, and control are the foundations of a successful deployment.
                            </p>
                            <p>
                                Pixentia’s implementation teams provide guidance, expertise, and methods to keep your project on schedule.
                            </p>
                            <div class="mt-4">
                                <h5 class="font-weight-bold">Pixentia Planning and Deployment Services</h5>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold mb-3">Project Planning</h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Phased project.png" class="mr-2" alt="Phased project plan helps you manage timelines">
                                            </p>
                                            <p>
                                                Phased project plan helps you manage timelines
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Resource planning.png" class="mr-2" alt="Resource planning helps you control your budget">
                                            </p>
                                            <p>
                                                Resource planning helps you control your budget
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Project governance establishes.png" class="mr-2" alt="Project governance establishes communication">
                                            </p>
                                            <p>
                                                Project governance establishes communication and decision protocols
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Risk management.png" class="mr-2" alt="Risk management mitigates the impact of contingencies">
                                            </p>
                                            <p>
                                                Risk management mitigates the impact of contingencies
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Project Planning.png" alt="Project Planning" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Data Cleansing.png" alt="Data Cleansing" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white mb-3">Data Cleansing</h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/We ensure.png" class="mr-2" alt="We ensure consistent and reliable data before you migrate">
                                            </p>
                                            <p>
                                                We ensure consistent and reliable data before you migrate
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Prepare data.png" class="mr-2" alt="Prepare data sources for both migration and integration">
                                            </p>
                                            <p>
                                                Prepare data sources for both migration and integration
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold mb-3">Data Migration</h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Analyze legacy.png" class="mr-2" alt="Analyze legacy data to ensure smooth conversion">
                                            </p>
                                            <p>
                                                Analyze legacy data to ensure smooth conversion
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Map and import.png" class="mr-2" alt="Map and import data to new platform">
                                            </p>
                                            <p>
                                                Map and import data to new platform
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Test quality.png" class="mr-2" alt="Test quality of migrated data">
                                            </p>
                                            <p>
                                                Test quality of migrated data
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Data Migration.png" alt="Data Migration" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Workshops.png" alt="Workshops" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head text-white">
                                        <h4 class="font-weight-bold text-white mb-3">Workshops</h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Business Workshop.png" class="mr-2" alt="Business Workshop establishes priorities and objectives">
                                            </p>
                                            <p>
                                                Business Workshop establishes priorities and objectives
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Training Workshop.png" class="mr-2" alt="Training Workshop prepares you to manage processes and workflow">
                                            </p>
                                            <p>
                                                Training Workshop prepares you to manage processes and workflow
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Configuration worksho.png" class="mr-2" alt="Configuration workshop aligns technology to your business requirements">
                                            </p>
                                            <p>
                                                Configuration workshop aligns technology to your business requirements
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/business-workshop">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row align-items-center my-5">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Configuration and review</h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Iterative process.png" class="mr-2" alt="Iterative process involves you in the design">
                                            </p>
                                            <p>
                                                Iterative process involves you in the design
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/We demo so you.png" class="mr-2" alt="We demo so you can review and evaluate each configuration phase">
                                            </p>
                                            <p>
                                                We demo so you can review and evaluate each configuration phase
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Configuration signoff.png" class="mr-2" alt="Configuration signoff when it meets your requirements">
                                            </p>
                                            <p>
                                                Configuration signoff when it meets your requirements
                                            </p>
                                        </div>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/configuration-workshop">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center text-md-right mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Configuration and review.png" alt="Configuration and review" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-md-left text-center mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Integration.png" alt="Integration" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6 text-white">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Integration</h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Connect core.png" class="mr-2" alt="Connect core systems to maintain accurate records">
                                            </p>
                                            <p>
                                                Connect core systems to maintain accurate records
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Optional integrations.png" class="mr-2" alt="Optional integrations for business intelligence, e-commerce, and extended enterprise">
                                            </p>
                                            <p>
                                                Optional integrations for business intelligence, e-commerce, and extended enterprise
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/integration">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row align-items-center my-5">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Analytics</h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Set up Advanced.png" class="mr-2" alt="Set up Advanced Reporting to enable business intelligence">
                                            </p>
                                            <p>
                                                Set up Advanced Reporting to enable business intelligence
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Optional support.png" class="mr-2" alt="Optional support for dashboards and visualizations Optional advanced analytics support">
                                            </p>
                                            <p>
                                                Optional support for dashboards and visualizations Optional advanced analytics support
                                            </p>
                                        </div>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/analytics-deployment">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center text-md-right mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Analytics.png" alt="Analytics" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Testing.png" alt="Testing" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-6 text-white">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Testing</h4>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Automated test.png" class="mr-2" alt="Automated test scripts reduce cost and effort of manual testing">
                                            </p>
                                            <p>
                                                Automated test scripts reduce cost and effort of manual testing
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Reduces errors.png" class="mr-2" alt="Reduces errors inherent in manual testing">
                                            </p>
                                            <p>
                                                Reduces errors inherent in manual testing
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Support User Acceptance.png" class="mr-2" alt="Support User Acceptance Testing with triage and issue management">
                                            </p>
                                            <p>
                                                Support User Acceptance Testing with triage and issue management
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/preparation/Focus on user.png" class="mr-2" alt="Focus on user experience">
                                            </p>
                                            <p>
                                                Focus on user experience
                                            </p>
                                        </div>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/testing">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="optimization">
                    <div class="col-md-10 offset-md-1">
                        <div class="text-center">
                            <div class="sub_head">
                                <h4 class="font-weight-bold mb-4">
                                    For the Way Your People Learn
                                </h4>
                            </div>
                            <p>
                                Finding learning content is easy. Finding learning that fits your objectives, your audience, and your culture is a lot harder. Pixentia’s development framework assures your workforce the best possible learning experience because you are involved in the
                                design from the first day.
                            </p>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Strategy & Planning</h4>
                                        <p>
                                            Our strategy and planning work sessions define your objectives <span class="d-inline-block">and prepare
                                            your plan.</span>
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Explore with.png" class="mr-2" alt="Explore with you how your content strategy">
                                            </p>
                                            <p>
                                                Explore with you how your content strategy fits into your workforce strategy and the current state of your technology and programs.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Learn the needs.png" class="mr-2" alt="Learn the needs of your audience and their current knowledge">
                                            </p>
                                            <p>
                                                Learn the needs of your audience and their current knowledge and skills levels to provide the starting point for your learning initiative.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Establish governanc.png" class="mr-2" alt="Establish governance and methodologies for working together">
                                            </p>
                                            <p>
                                                Establish governance and methodologies for working together.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Strategy & Planning.png" class="img-fluid" alt="Strategy & Planning">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Delivery Modalities.png" class="img-fluid" alt="Delivery Modalities">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Delivery Modalities</h4>
                                        <p>
                                            Our experts help you explore the learning modalities that support your strategy and objectives and the needs of your learners. We conduct a detailed work context analysis that enables you to determine the optimum learning blend.
                                        </p>
                                        <p>
                                            We offer a complete range of learning techniques, from simple gamification to advanced AI-driven technologies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Gamification</h4>
                                        <p>
                                            Increases learning engagement, stimulates creative thinking, and builds a sense of achievement with feedback and rewards.
                                        </p>
                                        <p class="orange_color">
                                            See how gamification boosts learning
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/gamification">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Gamification.png" class="img-fluid" alt="Gamification">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Augmented Reality.png" class="img-fluid" alt="Augmented Reality">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Augmented Reality</h4>
                                        <p>
                                            Overlays the real world or VR with digital information to support rapid decision-making skills and provides real-time feedback.
                                        </p>
                                        <p class="text-white">
                                            Speed up learning with visual feedback
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/augmented-reality">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Virtual Reality</h4>
                                        <p>
                                            Create a lifelike, computer-generated work context.
                                        </p>
                                        <p class="orange_color">
                                            Remove the barriers between learning and doing
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/virtual-reality">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Virtual Reality.png" class="img-fluid" alt="Virtual Reality">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Simulation.png" class="img-fluid" alt="Simulation">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Simulation</h4>
                                        <p>
                                            Enables learners to learn and practice skills in a risk-free environment.
                                        </p>
                                        <p class="text-white">
                                            Learn how simulation builds job skills.
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/simulation">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Sourcing</h4>
                                        <p>
                                            Sourcing available content can reduce your costs and speed up your implementation. We can integrate content from any source, including remote content servers. We assist you in locating content that fits your business needs and the needs of your audience.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Sourcing.png" class="img-fluid" alt="Sourcing">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/UI and Design.png" class="img-fluid" alt="UI and Design">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">UI and Design</h4>
                                        <p>
                                            Instructional design is the starting point for a useful and engaging <span class="d-inline-block">learner
                                            experience.</span>
                                        </p>
                                        <p>
                                            We work with your SMEs to develop a design that meets your objectives and the needs of your audience. We base our learning design on the principle that its elements should adapt to the way people learn.
                                        </p>
                                        <p class="text-white">
                                            Learn more about Pixentia UI and design services.
                                        </p>
                                        <div class="sub_btn_style1">
                                            <a href="services/hcm/learning/user-experience-and-design">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Learning Development</h4>
                                        <p>
                                            Our experts use the authoring platform most appropriate for your learning needs and your L&D team. We use commonly available tools like Adobe Captivate, Articulate 360, and Adapt HTML5, or you may wish to use the tool in your LMS.
                                        </p>
                                        <p>
                                            The designs we create focus on the learner experience.
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Intuitive.png" class="mr-2" alt="Intuitive, consistent, and transparent navigation">
                                            </p>
                                            <p>
                                                Intuitive, consistent, and transparent navigation.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Simple.png" class="mr-2" alt="Simple, clutter-free layout">
                                            </p>
                                            <p>
                                                Simple, clutter-free layout.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/High-quality Image.png" class="mr-2" alt="High-quality Images, icons, and artwork">
                                            </p>
                                            <p>
                                                High-quality Images, icons, and artwork.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../../../assets/images/cloud-transformations/sumtotal/learning/Effortless.png" class="mr-2" alt="Effortless, easy-to-understand reading">
                                            </p>
                                            <p>
                                                Effortless, easy-to-understand reading.
                                            </p>
                                        </div>
                                        <p class="orange_color">
                                            Learn more about Pixentia e-learning development services.
                                        </p>
                                        <div class="sub_btn_style">
                                            <a href="services/hcm/learning/development">Learn More <i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../assets/images/hcm/learning/learning-content/Learning Development.png" class="img-fluid" alt="Learning Development">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="second_section">
                        <div class="template_width">
                            <div class="row text-white align-items-center my-5">
                                <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                    <div>
                                        <img src="../../assets/images/hcm/learning/learning-content/Deployment and Testing.png" class="img-fluid" alt="Deployment and Testing">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold text-white">Deployment and Testing</h4>
                                        <p>
                                            We help you test your learning design, and recommend you have your end users test it. Their feedback can be invaluable. Our services include:
                                        </p>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../assets/images/hcm/learning/learning-content/Planning your testing.png" class="mr-2" alt="Planning your testing">
                                            </p>
                                            <p>
                                                Planning your testing.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../assets/images/hcm/learning/learning-content/Tracking and reporting tools.png" class="mr-2" alt="Tracking and reporting tools">
                                            </p>
                                            <p>
                                                Tracking and reporting tools.
                                            </p>
                                        </div>
                                        <div class="d-flex">
                                            <p>
                                                <img src="../../assets/images/hcm/learning/learning-content/Assistance with triage.png" class="mr-2" alt="Assistance with triage and issue management">
                                            </p>
                                            <p>
                                                Assistance with triage and issue management.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="first_section">
                        <div class="template_width">
                            <div class="row my-5 align-items-center">
                                <div class="col-md-6">
                                    <div class="sub_head">
                                        <h4 class="font-weight-bold">Content Integration</h4>
                                        <p>
                                            Our integration experts can connect your learners to any source, including third-party providers or your internal resource repositories.
                                        </p>
                                        <p class="">
                                            We also recommend and help you deploy authentication methods that make the shift to external resources transparent to your learners.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center mt-3 mt-md-0">
                                    <div>
                                        <img src="../../assets/images/hcm/learning/learning-content/Content Integration.png" class="img-fluid" alt="Content Integration">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="resource">
                    <div class="template_width">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-4">
                                        Optimize Your Workforce Learning
                                    </h4>
                                </div>
                                <p>
                                    With over 22 years of SumTotal experience, Pixentia’s learning technology and content experts provide end-to-end services to help you realize the value of your SumTotal technology and your learning content – from strategy to the individual learning experience.
                                </p>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-12 sub_head text-center">
                                <h4 class="font-weight-bold mb-2">
                                    Resource Centers
                                </h4>
                                <p>
                                    Our resource centers provide direct help to administrators, learning professionals, and learning managers.
                                </p>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4 text-center border-right border-bottom">
                                        <div class="py-2">
                                            <div class="mb-3 mt-4">
                                                <img src="../../assets/images/hcm/learning/optimization/Admin Resource Center.png" alt="Admin Resource Center">
                                            </div>
                                            <div>
                                                <p class="font-weight-bold mb-0">Admin Resource Center</p>
                                                <p>
                                                    Assisting administrators in day-to-day operations and maintenance.
                                                </p>
                                                <div class="sub_btn_style mb-3 btn_height">
                                                    <a href="services/hcm/learning/admin-resource">Learn More <i
                                                            class="fa fa-angle-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 text-center border-right border-bottom">
                                        <div class="py-2">
                                            <div class="mb-3 mt-4">
                                                <img src="../../assets/images/hcm/learning/optimization/Analytics Resource Center.png" alt="Analytics Resource Center">
                                            </div>
                                            <div>
                                                <p class="font-weight-bold mb-0">
                                                    Technology Resource Center
                                                </p>
                                                <p>
                                                    Technical assistance for administrators for launching new programs, managing updates, scheduling, resource management, <span class="d-inline-block">and reporting.</span>
                                                </p>
                                                <div class="sub_btn_style mb-3">
                                                    <a href="services/hcm/learning/technology">Learn More <i
                                                            class="fa fa-angle-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 text-center border-bottom">
                                        <div class="py-2">
                                            <div class="mb-3 mt-4">
                                                <img src="../../assets/images/hcm/learning/optimization/Content Resource Center.png" alt="Technology Resource Center">
                                            </div>
                                            <div>
                                                <p class="font-weight-bold mb-0">Content Resource Center</p>
                                                <p>
                                                    Content creation and curation services for all your online learning needs, including gamification, simulation, virtual reality (VR) <span class="d-inline-block">and
                                                    augmented reality (AR).</span>
                                                </p>
                                                <div class="sub_btn_style mb-3">
                                                    <a href="services/hcm/learning/content-resource-center">Learn More
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4 text-center border-right">
                                        <div class="py-2">
                                            <div class="mb-3 mt-4">
                                                <img src="../../assets/images/hcm/learning/optimization/Analytics Resource Center.png" alt="Analytics Resource Center">
                                            </div>
                                            <div>
                                                <p class="font-weight-bold mb-0">Analytics Resource Center</p>
                                                <p>
                                                    Facilitates advanced reporting, dashboards and visualizations, advanced analytics, and data aggregation and storage.
                                                </p>
                                                <div class="sub_btn_style">
                                                    <a href="services/hcm/learning/analytics">Learn More <i
                                                            class="fa fa-angle-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 text-center">
                                        <div class="py-2">
                                            <div class="mb-3 mt-4">
                                                <img src="../../assets/images/hcm/learning/optimization/Managed Services Center.png" alt="Managed Services Center">
                                            </div>
                                            <div>
                                                <p class="font-weight-bold mb-0">Managed Services Center</p>
                                                <p>
                                                    Dedicated experts manage all or any part of your learning management, operations, <span class="d-inline-block">and administration.</span>
                                                </p>
                                                <div class="sub_btn_style">
                                                    <a href="services/hcm/learning/managed-services">Learn More <i
                                                            class="fa fa-angle-right" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="promise_section text-white pt-5">
                        <div class="template_width">
                            <div class="row">
                                <div class="col-md-12 text-center pb-4">
                                    <h4 class="font-weight-bold mb-2">
                                        Optimization
                                    </h4>
                                    <p>
                                        Our SumTotal experts help you achieve the best possible efficiency, effectiveness, and impact of your learning management and administration activities.
                                    </p>
                                </div>
                                <div class="col-md-4 text-center border-right border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Administration Optimization.png" alt="Administration Optimization">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Administration Optimization</p>
                                            <p>
                                                Our SumTotal experts provide direct help to your administrators to remove inefficiencies from your admin workflow.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-right border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Security Optimization.png" alt="Security Optimization">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">
                                                Security Optimization
                                            </p>
                                            <p>
                                                Security experts help you align your security configuration to achieve the best fit for your organization and the needs of your learning audiences.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-bottom">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Business Process Optimization.png" alt="Business Process Optimization">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Business Process Optimization</p>
                                            <p>
                                                We work with your team to analyze your business processes and workflow to remove bottlenecks and workarounds.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 text-center border-right">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Learner Experience Optimization.png" alt="Learner Experience Optimization">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Learner Experience Optimization</p>
                                            <p>
                                                Our learning experts work with your team to remove wasted effort and friction in the learner experience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center border-right">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../../../assets/images/cloud-transformations/sumtotal/optimization/Content Optimization.png" alt="Learner Experience Optimization">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Content Optimization</p>
                                            <p>
                                                Our content experts work with you to identify the learning methodologies, delivery modes, and technology that achieve your learning objectives — within your business and budget constraints.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center">
                                    <div class="py-2">
                                        <div class="mb-3 mt-4">
                                            <img src="../../assets/images/hcm/learning/optimization/Analytics Optimization.png" alt="Analytics Optimization">
                                        </div>
                                        <div>
                                            <p class="font-weight-bold mb-0">Analytics Optimization</p>
                                            <p>
                                                No matter where you are in your learning analytics journey, our seasoned experts can help you define your digital development path and move toward becoming a data-driven learning organization.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="my-5">
                        <div class="template_width">
                            <div class="row align-items-center">
                                <div class="col-md-12 text-center">
                                    <h5 class="font-weight-bold mb-3">Staffing and Augmentation Services</h5>
                                    <div class="row text-center mb-4">
                                        <div class="col-md-4 offset-md-4">
                                            <img class="img-fluid" src="../../assets/images/hcm/learning/optimization/Staffing and Augmentation Services.png" alt="">
                                        </div>
                                    </div>
                                    <div>
                                        <p>
                                            Pixentia provides complete staffing services whether you need a little help or a team of experts. We pre-screen and evaluate candidates, assist you with selection, and provide technical onboarding to ensure a good fit and on-the-job performance.
                                        </p>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="py-2">
                                                <div class="mb-2">
                                                    <img src="../../assets/images/hcm/learning/optimization/Seasoned experts to fill.png" alt="">
                                                </div>
                                                <div>
                                                    <p>Seasoned experts to fill the <span class="d-inline-block">skill
                                                            gaps
                                                            in your team.</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="py-2">
                                                <div class="mb-2">
                                                    <img src="../../assets/images/hcm/learning/optimization/Project teams to.png" alt="Project teams to manage your entire initiative">
                                                </div>
                                                <div>
                                                    <p>Project teams to manage your entire initiative.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="py-2">
                                                <div class="mb-2">
                                                    <img src="../../assets/images/hcm/learning/optimization/Direct hire and.png" alt="Direct hire and contract-to-hire">
                                                </div>
                                                <div>
                                                    <p>Direct hire and contract-to-hire <span class="d-inline-block">recruiting services.</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-center">
                                            <div class="sub_btn_style">
                                                <a href="services/hcm/learning/staffing-and-augmentation-services">Learn
                                                    More <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-5">
            <div class="template_width">
                <div class="row align-items-center">
                    <div class="col-md-10 offset-md-1">
                        <div class="row align-items-center">
                            <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                                <p class="sub_heading_cta mb-4">Let's talk about how Pixentia can help you better understand your users.
                                </p>
                                <div class="more_info_btn datasheet_btn">
                                    <a href="contact-us" rel="noopener noreferrer" target="_blank"> Contact Us <i class="fa fa-angle-right ml-2"
                                            aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <div class="col-md-5 mt-5 mt-md-0">
                                <div>
                                    <img src="../../../../assets/images/cta_images/CTA Contact.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-vendors></app-vendors>
    </div>
    <div class="template_width">
        <app-get-started></app-get-started>
    </div>
</div>