<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="site-map">Site Map</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Site Map</h3>
                <p>We believe in the power of creativity and innovation.
                </p>
                <p>We take a creative approach to address the needs of better tomorrow and our motive is to formulate
                    creative solutions.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/Sitemap Image.png" alt="" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5 sitemap">
        <div class="col-md-12">
            <div>
                <ul>
                    <li class="orange-color"><a class="orange-color" href="/">Home</a></li>
                    <li class="orange-color">Products<ul>
                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <li><a href="products/chasma-apps">Chasma Apps</a></li>
                                    <ul>
                                        <li><a href="products/chasma-editor">Chasma Editor</a></li>
                                        <li><a href="products/chasma-event">Chasma Event</a></li>
                                        <li><a href="products/chasma-lexy">Chasma Lexy</a></li>
                                        <li><a href="products/chasma-quale">Chasma Quale</a></li>
                                        <li><a href="products/chasma-natiodesk">Chasma Natio Desk</a></li>
                                        <li><a href="products/chasma-botbuilder">Chasma Bot Builder</a></li>
                                        <li><a href="products/chasma-cible">Chasma Cible</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <li><a href="products/chasma-analytics">Chasma Analytics</a></li>
                                    <ul>
                                        <li><a href="products/chasma-analytics/sumtotal-analytics">Sumtotal
                                                Analytics</a></li>
                                        <li><a href="products/chasma-analytics/chasma-hrbox">People Analytics with
                                                HRBox</a></li>
                                        <li><a href="products/chasma-analytics/cornerstone-analytics">Cornerstone
                                                Analytics</a></li>
                                        <li><a href="products/chasma-analytics/people-analytics-power-bi">People
                                                Analytics with Power BI</a></li>
                                        <li><a href="products/chasma-analytics/workday-analytics">Workday Analytics</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <li><a href="products/chasma-connect">Chasma Connect</a></li>
                                </div>
                            </div>
                        </ul>
                    </li>
                    <li class="orange-color">Services<ul>
                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <li class="orange-color">Human Capital</li>
                                    <ul>
                                        <li><a href="services/hcm/hcm-coe">HCM COE</a></li>
                                        <ul>
                                            <li><a href="services/hcm/hcm-coe/change-management">Change Management</a>
                                            </li>
                                            <li><a href="services/hcm/hcm-coe/workshops">Hcm Deployment Workshops</a>
                                            </li>
                                            <li><a href="services/hcm/hcm-coe/testing">Testing</a></li>
                                            <li><a href="services/hcm/hcm-coe/analytics-deployment">Analytics
                                                    Deployment</a></li>
                                            <li><a href="services/hcm/hcm-coe/integration">Integrations</a></li>
                                            <li><a href="services/hcm/hcm-coe/admin-resource">Admin Resource Center</a>
                                            </li>
                                            <li><a href="services/hcm/hcm-coe/analytics">Analytics Resource Center</a>
                                            </li>
                                            <li><a href="services/hcm/hcm-coe/technology">Technology Resource Center</a>
                                            </li>
                                            <li><a href="services/hcm/hcm-coe/content-resource-center">Content Resource
                                                    Center</a></li>
                                            <li><a href="services/hcm/hcm-coe/managed-services">Managed Services
                                                    Center</a>
                                            <li><a href="services/hcm/hcm-coe/staffing-and-augmentation-services">Staffing
                                                    and Augmentation</a>
                                            </li>
                                        </ul>
                                        <li><a href="services/hcm/recruiting">Recruiting</a></li>
                                        <li><a href="services/hcm/learning">Learning</a></li>
                                        <ul>
                                            <li><a href="services/hcm/learning/change-management">Change Management</a>
                                            </li>
                                            <li><a href="services/hcm/learning/business-workshop">Business Workshop</a>
                                            </li>
                                            <li><a href="services/hcm/learning/configuration-workshop">Configuration
                                                    Workshop</a></li>
                                            <li><a href="services/hcm/learning/integration">Integrations</a></li>
                                            <li><a href="services/hcm/learning/testing">Testing</a></li>
                                            <li><a href="services/hcm/learning/analytics-deployment">Analytics
                                                    Deployment</a></li>
                                            <li><a href="services/hcm/learning/gamification">Gamification</a></li>
                                            <li><a href="services/hcm/learning/augmented-reality">Augmented Reality</a>
                                            </li>
                                            <li><a href="services/hcm/learning/virtual-reality">Virtual Reality</a></li>
                                            <li><a href="services/hcm/learning/simulation">Simulation</a></li>
                                            <li><a href="services/hcm/learning/user-experience-and-design">UI and
                                                    Design</a>
                                            <li><a href="services/hcm/learning/development">Learning Development</a>
                                            <li><a href="services/hcm/learning/admin-resource">Admin Resource Center</a>
                                            </li>
                                            <li><a href="services/hcm/learning/analytics">Analytics Resource Center</a>
                                            </li>
                                            <li><a href="services/hcm/learning/technology">Technology Resource
                                                    Center</a></li>
                                            <li><a href="services/hcm/learning/content-resource-center">Content Resource
                                                    Center</a></li>
                                            <li><a href="services/hcm/learning/managed-services">Managed Services
                                                    Center</a>
                                            </li>
                                        </ul>
                                        <li><a href="services/hcm/talentmanagement">Talent Management</a></li>
                                        <li><a href="services/hcm/payroll">Payroll</a></li>
                                        <li><a href="services/hcm/compensation">Compensation</a></li>
                                        <li><a href="services/hcm/enterprise-integrations">Enterprise Integrations</a>
                                        </li>
                                        <li><a href="services/hcm/peopleanalytics">People Analytics</a></li>
                                        <li><a href="services/hcm/employee-portals">Employee Portals</a></li>
                                        <li><a href="services/hcm/hcm-value-calculator">Value Calculator</a></li>
                                        <li><a href="services/hcm/hcm-resource-center">HCM Resource Center (AMS)</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <li class="orange-color">Learning</li>
                                    <ul>
                                        <li><a href="services/learning/learning-coe">Learning COE</a></li>
                                        <li><a href="services/learning/learningmanagementsystems">Learning Management
                                                Systems</a></li>
                                        <li><a href="services/learning/learning-portals">Learning Portals</a></li>
                                        <li><a href="services/learning/customlearning">Custom Learning</a></li>
                                        <li><a href="services/learning/mobilelearning">Mobile Learning</a></li>
                                        <li><a href="services/learning/learningstylesandgamification">Learning Styles
                                                and Gamification</a></li>
                                        <li><a href="services/learning/demosmain">Learning Demos</a></li>
                                        <li><a href="services/learning/learning-resource-center">Learn Resource Center
                                                (AMS)</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <li class="orange-color">Cloud Transformation</li>
                                    <ul>
                                        <li><a href="services/cloud-transformation/workday">Workday</a></li>
                                        <li><a href="services/cloud-transformation/sumtotal">Sumtotal</a></li>
                                        <li><a href="services/cloud-transformation/cornerstone">Cornerstone</a></li>
                                        <li><a href="services/cloud-transformation/ceridian">Ceridian</a></li>
                                        <li><a href="services/cloud-transformation/salesforce">Salesforce</a></li>
                                        <li><a href="services/cloud-transformation/servicenow">Servicenow</a></li>
                                    </ul>
                                    
                                    <li><a class="orange-color" routerLink="/services/application-management-services">Application Management Services</a></li>
                                    <li><a class="orange-color" routerLink="/services/managed-talent-development-services">Managed Talent Development Services </a></li>
                                    <li class="mt-4"><a class="orange-color"
                                            href="services/digital-transformation">Digital Transformation</a></li>
                                    <li><a class="orange-color" href="services/mobile">Mobile Applications</a></li>
                                    <li><a class="orange-color" href="services/analytics">Advanced Analytics</a></li>
                                </div>
                            </div>
                        </ul>
                    </li>
                    <li class="orange-color">Company<ul class="mt-2">
                            <li><a href="about">About Us</a></li>
                            <li><a href="customers">Customers</a></li>
                            <li><a href="partners">Partners</a></li>
                            <li><a href="">Careers</a></li>
                        </ul>
                    </li>
                    <li class="orange-color"><a class="orange-color" href="">Contact Us</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>