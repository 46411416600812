<section class="cleard demo_mainContent">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-4 col-sm-6 col-xs-12 col-sm-offset-2 col-md-offset-4">
        <div class="col-md-12 col-sm-12 col-xs-12" style="text-align:center;">
          <h5>{{displayMessage}}</h5>
        </div>
        <form class="form-horizontal col-md-12 col-xs-12" id="formapprove" novalidate="novalidate">
          <div class="pt-3">
            <div class="col-md-12 col-sm-12 p-0 mx-0 row form-group">
              <label class="col-md-4 col-sm-5 control-label padright_null" style="text-align:left;">
                First Name
              </label>
              <label class="col-md-8 col-sm-7 control-label padright_null" style="text-align:left;">
                <span>:</span>&nbsp;{{loginValidateDetails.fName}}
              </label>
            </div>
            <div class="col-md-12 col-sm-12 p-0 mx-0 row form-group">
              <label class="col-md-4 col-sm-5 control-label padright_null" style="text-align:left;">
                Last Name
              </label>
              <label class="col-md-8 col-sm-7 control-label padright_null" style="text-align:left;">
                <span>:</span>&nbsp;{{loginValidateDetails.lName}}
              </label>
            </div>
            <div class="col-md-12 col-sm-12 p-0 mx-0 row form-group">
              <label class="col-md-4 col-sm-5 control-label padright_null" style="text-align:left;">Company
              </label>
              <label class="col-md-8 col-sm-7 control-label padright_null" style="text-align:left;">
                <span>:</span>&nbsp;{{loginValidateDetails.company}}
              </label>
            </div>
            <div class="col-md-12 col-sm-12 p-0 mx-0 row form-group">
              <label class="col-md-4 col-sm-5 control-label padright_null" style="text-align:left;">
                Job Title
              </label>
              <label class="col-md-8 col-sm-7 control-label padright_null" style="text-align:left;">
                <span>:</span>&nbsp;{{loginValidateDetails.job}}
              </label>
            </div>
            <div class="col-md-12 col-sm-12 p-0 mx-0 row form-group">
              <label class="col-md-4 col-sm-5 control-label padright_null" style="text-align:left;">Email
              </label>
              <label class="col-md-8 col-sm-7 control-label padright_null" style="text-align:left;">
                <span>:</span>&nbsp;{{loginValidateDetails.email}}
              </label>
            </div>
            <div class="col-md-12 col-sm-12 p-0 mx-0 row form-group">
              <label class="col-md-4 col-sm-5 control-label padright_null" style="text-align:left;">Contact
              </label>
              <label class="col-md-8 col-sm-7 control-label padright_null" style="text-align:left;">
                <span>:</span>&nbsp;{{loginValidateDetails.contact}}
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="col-md-12 col-sm-12 col-xs-12 martop2" style="padding-left: 40px;">
              <div class="row mb-2 pad-null">
                <button [disabled]="displayMessage" type="button" class="col-md-5 col-sm-5 col-xs-5 btn btn-default"
                  id="userapprove" (click)="confirmDemoUserStatus('Approved')" data-toggle="modal">
                  <span>Approve</span>
                </button>
                <button [disabled]="displayMessage" type="button" class="col-md-5 col-sm-5 col-xs-5 btn btn-default"
                  id="userhold" (click)="confirmDemoUserStatus('Onhold')" style="margin-left: 30px;"
                  data-toggle="modal">
                  <span>OnHold</span>
                </button>
              </div>
              <div class="row martop2 pad-null">
                <button [disabled]="displayMessage" type="button" class="col-md-5 col-sm-5 col-xs-5 btn btn-default"
                  id="userescalate" (click)="confirmDemoUserStatus('Escalated')" data-toggle="modal">
                  <span>Escalate</span>
                </button>
                <button [disabled]="displayMessage" type="button" class="col-md-5 col-sm-5 col-xs-5 btn btn-default"
                  id="userreject" (click)="confirmDemoUserStatus('Rejected')" style="margin-left: 30px;"
                  data-toggle="modal">
                  <span>Reject</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
