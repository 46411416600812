<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs testingbreadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/testing">Testing</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold black-color">LMS Update and Upgrade Testing
                </h3>
                <p class="black-color">Get control of your LMS Testing
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/testing/Testing IMage.png" alt="Testingt image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Get Control of SumTotal Testing
                    </h1>
                </div>
                <div>
                    <p class="mb-3">
                        Testing your cloud learning software before deployment is essential to effective learning delivery. The stakes can be high — among the risks are dissatisfied users, failed audits, and damage to your organization’s reputation.
                    </p>
                    <p>
                        Pixentia employs a disciplined approach that ensures accurate, comprehensive evaluation and remedies for your business processes and user experience.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Testing Strategy
                </h4>
                <div class="mt-4 mb-4">
                    <p>
                        The first step in testing your learning software platform is to build your strategy.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Learning analytics.png" alt="Learning analytics" class="mb-3">
                        <p class="px-4">
                            How you will test. Automated testing can save a lot of time and expense, but some manual testing is necessary
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Advanced analytics.png" alt="Advanced analytics" class="mb-3">
                        <p class="px-4">
                            What resources you need, including the testing venue
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Predictive and prescriptive.png" alt="Predictive and prescriptive" class="mb-3">
                        <p class="px-4">
                            Your testing schedules
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../../../../assets/images/hcm/learning/analytics-deployment/Dashboard development.png" alt="Dashboard development" class="mb-3">
                        <p class="px-4">
                            Measures and metrics to assess your progress and results.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-8 offset-md-2 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h3 class="font-weight-bold">Test Development
                    </h3>
                </div>
                <div>
                    <p class="mb-3">
                        The second part of the planning phase is to develop your testing plans: scheduling the resources and creating your scripts and scenarios. We provide:
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/change-management/Analyzing the internal.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p>
                        Standard scripts for automation, offer tools for you to record and create your own
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/change-management/Assessing organizational.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p>
                        Testing scenarios for manual testing and user acceptance testing (UAT); and
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/change-management/Identifying communication.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p>
                        Building sample data sets for testingBuilding sample data sets for testing
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section my-4">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/testing/Test Execution.png" alt="Test Execution" class="mb-2">
                    <h4 class="font-weight-bold mb-1">Test Execution</h4>
                    <p class="px-4">
                        When you execute your plan, you will run each script and scenario, grouped by function, then evaluate and report on each. We support you during your testing and assist you in tracking and reporting your results.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/testing/Issue Management.png" alt="Issue Management" class="mb-2">
                    <h4 class="font-weight-bold mb-1">Issue Management</h4>
                    <p class="px-4">
                        Managing the results of your testing requires communication and decision protocols so project managers can achieve the correct response to issues. Our experts work with you to assess the scope of each finding so you can estimate the impact and we can
                        schedule the resources to address it.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/testing/User Acceptance Testing.png" alt="User Acceptance Testing" class="mb-2">
                    <h4 class="font-weight-bold mb-1">User Acceptance Testing (UAT)
                    </h4>
                    <p class="px-4">
                        Our testing team works with you to provide user checklists for your scenarios, so your testers can document both the behavior of the configuration and their reaction to it.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5 align-items-center">
        <div class="col-md-6">
            <div>
                <h4 class="font-weight-bold mb-1">Testing Updates and Upgrades</h4>
                <p>
                    It will help if you test your LMS whenever you make significant changes, such as adding new functions like e-commerce or extending it to new business.
                </p>
                <p>
                    That includes updates and upgrades. Your vendor tests every change in their software—but they don’t check your configuration, your processes, your security, or your integrations. It’s up to you.
                </p>
                <p>
                    The good news is that once you have your scripts configured, you can reuse them.The good news is that once you have your scripts configured, you can reuse them.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/testing/Testing Updates and Upgrades.png" alt="Testing Updates and Upgrades" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Contact us if you need help to manage your updates, upgrades, and organizational changes.
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to Preparation and Planning  <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>