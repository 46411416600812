<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/gamification">Gamification</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Boost Learning
                </h3>
                <p>Pixentia’s approach to gamified learning places people first. We begin with the business problem you need to solve, then we design learning from the perspective of humans in the workplace.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/gamification/Gamification image.png" alt="Change management image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Demystifying Gamification
                    </h1>
                </div>
                <div>
                    <p class="mb-3">
                        Gamification is the practice of using game mechanics, game aesthetics, and game thinking in instructional design. It may make learning fun, but that is not the purpose. Gamification engages learners by using the same mechanics that make computer games
                        fascinating to us.
                    </p>
                    <p>
                        Gamification is not a technology or a software product. More than progress indicators, leaderboards, and badges, it is an entire world of design where you can introduce:
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/gamification/A story and context.png" alt=" A story and context">
                </div>
                <div class="px-5 mb-md-5">
                    <p>
                        A story and context to simulate real situations
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/gamification/Mystery or uncertainty.png" alt="Mystery or uncertainty">
                </div>
                <div class="px-5 mb-md-5">
                    <p>
                        Mystery or uncertainty to trigger the desire to explore
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/gamification/Risks.png" alt="Risks, challenges, and rewards">
                </div>
                <div class="px-5 mb-md-5">
                    <p>
                        Risks, challenges, and rewards to motivate toward achievement
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/gamification/Techniques like.png" alt="Techniques like personalized avatars">
                </div>
                <div class="px-5">
                    <p>
                        Techniques like personalized avatars, creating an emotional connection to amplify learning retention
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/gamification/Frequent feedback.png" alt="Frequent feedback">
                </div>
                <div class="px-5">
                    <p>
                        Frequent feedback, providing a supportive learning experience
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-12 text-center mt-4">
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white align-items-center">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Tap into the Natural Desire to Learn</h4>
            </div>
            <div class="col-md-6">
                <div>
                    <p>
                        Gamification can build both skill and confidence by giving learners positive feedback as they achieve learning milestones. They will want to repeat the experience.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <img src="../../../../../assets/images/hcm/learning/gamification/Tap into the Natural Desire to Learn.png" alt="" style="width: 100%;">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">
                            Learn how a structured, strategic approach to gamification will get you results you will be proud of.
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="https://resources.pixentia.com/guides/improve_learning_and_performance_with_gamification" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to
                                    Preparation and Planning <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>