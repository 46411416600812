<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/learning/learning-resource-center">Learn Resource Center</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Learn Resource Center (AMS)</h3>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../assets/images/learning/learn-resource-center/Learn Resource Image.png" alt="Learn Resource Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row mb-5 mt-3 services_list">
        <div class="col-md-12 sub_head text-center">
            <h4 class="font-weight-bold mb-2">
                Resource Centers
            </h4>
            <p>
                Our resource centers provide direct help to administrators, learning professionals, and learning managers.
            </p>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 text-center border-right border-bottom">
                    <div class="py-2">
                        <div class="mb-3 mt-4">
                            <img src="../../assets/images/hcm/learning/optimization/Admin Resource Center.png" alt="Admin Resource Center">
                        </div>
                        <div>
                            <p class="font-weight-bold mb-0">Admin Resource Center</p>
                            <p>
                                Assisting administrators in day-to-day operations and maintenance.
                            </p>
                            <div class="sub_btn_style mb-3 btn_height">
                                <a href="services/hcm/learning/admin-resource">Learn More <i
                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center border-right border-bottom">
                    <div class="py-2">
                        <div class="mb-3 mt-4">
                            <img src="../../assets/images/hcm/learning/optimization/Analytics Resource Center.png" alt="Analytics Resource Center">
                        </div>
                        <div>
                            <p class="font-weight-bold mb-0">
                                Technology Resource Center
                            </p>
                            <p>
                                Technical assistance for administrators for launching new programs, managing updates, scheduling, resource management, <span class="d-inline-block">and reporting.</span>
                            </p>
                            <div class="sub_btn_style mb-3">
                                <a href="services/hcm/learning/technology">Learn More <i
                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center border-bottom">
                    <div class="py-2">
                        <div class="mb-3 mt-4">
                            <img src="../../assets/images/hcm/learning/optimization/Content Resource Center.png" alt="Technology Resource Center">
                        </div>
                        <div>
                            <p class="font-weight-bold mb-0">Content Resource Center</p>
                            <p>
                                Content creation and curation services for all your online learning needs, including gamification, simulation, virtual reality (VR) and
                                <span class="d-inline-block">augmented reality (AR).</span>
                            </p>
                            <div class="sub_btn_style mb-3">
                                <a href="services/hcm/learning/content-resource-center">Learn More <i
                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-4 text-center border-right">
                    <div class="py-2">
                        <div class="mb-3 mt-4">
                            <img src="../../assets/images/hcm/learning/optimization/Analytics Resource Center.png" alt="Analytics Resource Center">
                        </div>
                        <div>
                            <p class="font-weight-bold mb-0">Analytics Resource Center</p>
                            <p>
                                Facilitates advanced reporting, dashboards and visualizations, advanced analytics, and data aggregation and storage.
                            </p>
                            <div class="sub_btn_style">
                                <a href="services/hcm/learning/analytics">Learn More <i
                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center">
                    <div class="py-2">
                        <div class="mb-3 mt-4">
                            <img src="../../assets/images/hcm/learning/optimization/Managed Services Center.png" alt="Managed Services Center">
                        </div>
                        <div>
                            <p class="font-weight-bold mb-0">Managed Services Center</p>
                            <p>
                                Dedicated experts manage all or any part of your learning management, operations, <span class="d-inline-block">and administration.</span>
                            </p>
                            <div class="sub_btn_style">
                                <a href="services/hcm/learning/managed-services">Learn More <i
                                        class="fa fa-angle-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>