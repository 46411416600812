<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/configuration-workshop">Configuration Workshop</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Configuration Workshop
                </h3>
                <p>Align your LMS to your business requirements</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/configuration-workshop/Configuration Workshop.png" alt="Configuration Workshop" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Align Learning Structure and Processes to your Business</h1>
                </div>
                <div>
                    <p class="mb-3">
                        LMS vendors enable their customers to adapt software to their unique requirements by building in configuration options. Pixentia’s training shows you how those options work together, so you can make decisions that support the way you do business.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h3 class="font-weight-bold">Identifying Your Training Audience
                    </h3>
                </div>
                <div class="px-md-5">
                    <p class="mb-3">
                        The first task for your team is to ensure that the right people receive configuration training. Many of the decisions you make will be essential for the success of your learning operations. Inviting the right people into your workshop will enable you
                        to get valuable input from the people who do the work.
                    </p>
                    <p>
                        At a minimum, the following roles should receive training, but your operations may require others to attend.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/configuration-workshop/Your project sponsor.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p>
                        Your project sponsor, business managers, and project manager will make decisions about which functions to enable and how to use them
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/configuration-workshop/Learning system administrators.png" alt="Learning system administrators">
                </div>
                <div class="px-5">
                    <p>
                        Learning system administrators and HR administrators will need to learn how to manage day-to-day operations and system updates
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/configuration-workshop/Training managers.png" alt="Training managers">
                </div>
                <div class="px-5">
                    <p>
                        Training managers, training administrators, and coordinators, who will do much of their daily work in the learning platform
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white text-center">
            <div class="col-md-6 border-right">
                <h4 class="font-weight-bold pt-4 mb-2">Configuration Scope</h4>
                <p>
                    Most vendors will have a standard configuration scope for their implementation. We review the configuration options with you, answer your questions, and make recommendations based on your business requirements.
                </p>
            </div>
            <div class="col-md-6">
                <h4 class="font-weight-bold pt-4 mb-2">Add-on Packages
                </h4>
                <p>
                    Standard implementation agreements limit the services we can provide to you. We can provide additional services that enable you to deliver learning to any audience, no matter how complex your organization.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn how our configuration workshops can help you align your learning ecosystem to your growing business needs.

                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to Preparation and Planning  <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>