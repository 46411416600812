<div>
  <div style="background-color:#fff;">
    <div *ngIf="!isReject && !isEscalateTo">
      <div class="col-md-12 col-sm-12 col-xs-12 row d-flex justify-content-center align-items-center">
        <h5>{{confirmDialogMessage}}</h5>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-default democonfirm mr-2" (click)="confirmStatus('')">
          <span>Yes</span>
        </button>
        <button type="button" class="btn btn-default democlose" (click)="closeDialog('')">
          <span>No</span>
        </button>
      </div>
    </div>
    <div *ngIf="isReject">
      <div class="col-md-12 col-sm-12 col-xs-12 row">
        <label id="rejectcomments" class="col-md-4 control-label padright_null text-center martop1">Comments&nbsp;
          <span>: </span>
        </label>
        <div class="col-md-8 ">
          <textarea [(ngModel)]="comments" style="width: 100%;" class="col-md-12 col-xs-12 form-control"
            id="democomments"></textarea>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <button [disabled]="!comments" type="button" class="btn btn-default democonfirm mr-2"
          (click)="confirmStatus('comments')" id="modalapprove" data-dismiss="modal">
          <span>Yes</span>
        </button>
        <button type="button" class="btn btn-default democlose" (click)="closeDialog()">
          <span>No</span>
        </button>
      </div>
    </div>
    <div *ngIf="isEscalateTo">
      <div class="col-md-12 col-sm-12 col-xs-12 row">
        <label class="col-md-3 col-xs-3 control-label padright_null text-center martop1">Email&nbsp;
          <span>: </span>
        </label>
        <div class="col-md-7">
          <input type="text" [(ngModel)]="escalatedto" class="col-md-12 col-xs-12 form-control" id="escalatedemail">
          <span id="errescalate" style="color:#e45454;font-size:13px;"></span>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <button type="button" class="btn btn-default democonfirm mr-2" (click)="confirmStatus('escalatedto')"
          id="modalapprove">
          <span>Yes</span>
        </button>
        <button type="button" class="btn btn-default democlose" (click)="closeDialog()">
          <span>No</span>
        </button>
      </div>
    </div>
  </div>
</div>
