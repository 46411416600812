<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Cloud Transformation</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/cloud-transformation/servicenow">Servicenow</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Your One Place to Go for ServiceNow Support and Optimization </h3>
                <p>If your ServiceNow administration and maintenance are an unwelcome burden, let Pixentia carry it for you. Offload your administration so you can focus on your people.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/cloud-transformations/servicenow/Servicenow-Image.png" alt="Service Now Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-12 text-center">
                <h1 class="text-center mb-3 font-weight-bold">
                    Offload your ServiceNow administration so you can focus on your people
                </h1>
                <p class="mb-0 mx-md-5">
                    Pixentia enables you to make the most of your ServiceNow platform to transform the way your people work, so you can put their resources and critical tasks at their fingertips.
                </p>
            </div>
        </div>
    </div>
    <div class="community_section mb-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">Why would you need managed services? </h5>

                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/salesforce/Your organization.png" class="mr-2" alt="On-the-Job Mobile Learning Content">
                        </p>
                        <p>
                            You have only one administrator and no ready backup.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/servicenow/Your administrators.png" class="mr-2" alt="Spontaneous Learning Programs">
                        </p>
                        <p>
                            Your administrators have other mission-critical roles that may impact their capacity to manage learning.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/servicenow/Some administration.png" class="mr-2" alt="Legacy Content Conversion">
                        </p>
                        <p>
                            Some administration and maintenance tasks are too much of a burden.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/servicenow/your initial edge.png" class="mr-2" alt="Learning Deployment">
                        </p>
                        <p>
                            You’ve lost your initial edge. Over time, your administrators have trained other administrators, who taught others, and now, critical tasks go undone.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/servicenow/advanced training.png" class="mr-2" alt="Learning Deployment">
                        </p>
                        <p>
                            Your administrators can’t seem to find the time for advanced training.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/cloud-transformations/servicenow/Why would you need managed service.png" alt="Learning Project Implementation" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-12 mt-5 text-center">
                    <p>
                        Enjoy the peace of mind knowing that seasoned experts are managing your ServiceNow administration.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Administration</h4>
                    <p>
                        With Pixentia managed services, you can enjoy the peace of mind of knowing seasoned experts are handling your daily administration.
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/servicenow/Maintaining your organizational structure.png" alt="Maintaining your organizational structure" class="mb-3">
                        <p class="px-4">
                            Maintaining your organizational structure.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/servicenow/Maintaining user access.png" alt="Maintaining user access, roles, and security." class="mb-3">
                        <p class="px-4">
                            Maintaining user access, roles, and security.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/servicenow/Managing resources.png" alt="Managing resources." class="mb-3">
                        <p class="px-4">
                            Managing resources.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/servicenow/Troubleshooting and working.png" alt="Troubleshooting and working with ServiceNow support" class="mb-3">
                        <p class="px-4">
                            Troubleshooting and working with ServiceNow support.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="community_section my-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">Technology</h5>
                    <p>
                        Some technical issues can be difficult and time-consuming, especially if they happen so infrequently that you don’t develop the experience to manage them. Let Pixentia’s experts handle your critical technical tasks.
                    </p>

                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/servicenow/Technical issues.png" class="mr-2" alt="On-the-Job Mobile Learning Content">
                        </p>
                        <p>
                            Technical issues and support for end-users.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/servicenow/Managing updates.png" class="mr-2" alt="Managing updates and update validation">
                        </p>
                        <p>
                            Managing updates and update validation.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/servicenow/Liaison with the.png" class="mr-2" alt="Liaison with the ServiceNow Support">
                        </p>
                        <p>
                            Liaison with the ServiceNow Support Team on ServiceNow-related issues.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/cloud-transformations/servicenow/Integrations and data.png" class="mr-2" alt="Integrations and data feeds">
                        </p>
                        <p>
                            Integrations and data feeds, including monitoring and resolution of data issues.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/cloud-transformations/servicenow/Technology.png" alt="Technology" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Reporting and Analytics</h4>
                    <p>
                        <a href="services/hcm/peopleanalytics">People analytics</a> has become an essential competency for HR, but some organizations have not yet developed it. Let Pixentia fill the gap while you build your team, or let us handle all
                        your reporting administration.
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/servicenow/Generating.png" alt="Generating, scheduling, and distributing reports" class="mb-3">
                        <p class="px-4">
                            Generating, scheduling, and distributing reports.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/servicenow/Maintaining and troubleshooting.png" alt="Maintaining and troubleshooting" class="mb-3">
                        <p class="px-4">
                            Maintaining and troubleshooting reports and data tables.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/servicenow/Maintaining dashboards and visualizations.png" alt="Maintaining dashboards and visualization" class="mb-3">
                        <p class="px-4">
                            Maintaining dashboards and visualizations.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/cloud-transformations/servicenow/Support from Pixentia.png" alt="Support from Pixentia’s advanced analytics team" class="mb-3">
                        <p class="px-4">
                            Support from Pixentia’s advanced analytics team.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-5">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-10 offset-md-1">
                    <div class="row align-items-center">
                        <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                            <p class="sub_heading_cta mb-4">
                                Contact us to talk about a service package dedicated to your business needs.
                            </p>
                            <div class="more_info_btn datasheet_btn">
                                <a href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="col-md-5 mt-5 mt-md-0">
                            <div>
                                <img src="../../../../assets/images/cta_images/CTA Contact.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>