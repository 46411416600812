<div class="vendors_section mb-5">
    <div class="template_width">
        <div class="row py-5">
            <div class="col-md-8 offset-md-2">
                <div class="text-center">
                    <p class="vender_text">
                        Pixentia’s experienced team of professionals brings hands-on, proven experience across multiple human capital management and cloud platforms.
                    </p>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-2">
                    <div class="py-2 px-3 border-radius text-center">
                        <a href="services/cloud-transformation/ceridian"> <img src="../../assets/images/vendors/ceridian.png" alt="" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="py-2 px-3 border-radius text-center">
                        <a href="services/cloud-transformation/cornerstone"><img src="../../assets/images/vendors/cornerstone.png" alt="" class="img-fluid"></a> 
                    </div>
                </div>                
                <div class="col-md-2">
                    <div class="py-2 px-3 border-radius text-center">
                        <a href="services/cloud-transformation/salesforce"> <img src="../../assets/images/vendors/salesforce.png" alt="" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="py-2 px-3 border-radius text-center">
                        <a href="services/cloud-transformation/servicenow"> <img src="../../assets/images/vendors/servicenow.png" alt="" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="py-2 px-3 border-radius text-center">
                        <a href="services/cloud-transformation/sumtotal"><img src="../../assets/images/vendors/sumtotal.png" alt="" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="py-2 px-3 border-radius text-center">
                        <a href="services/cloud-transformation/workday"> <img src="../../assets/images/vendors/workday.png" alt="" class="img-fluid"></a>
                    </div>
                </div> 
            </div>
            
        </div>
    </div>
</div>