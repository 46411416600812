<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/learning/customlearning">Custom Learning</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Learning Crafted to Your Workforce Needs</h3>
                <p>Custom learning solutions that engage and provide the exact skills and proficiencies your people need to achieve your organizational goals.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/learning/custom-learning/CustomLearning_Image.png" alt="Custom Learning Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-4 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-5 font-weight-bold">
                    Superior E-Learning Solutions that Will Energize Your Workforce
                </h1>
            </div>
            <div class="col-md-10 offset-md-1 text-center">
                <p class="mb-0">
                    Pixentia’s deep understanding of your organization and its learning goals enables us to create superior, cost-effective solutions that measurably increase workforce knowledge and productivity.
                </p>
            </div>
        </div>
    </div>
    <div class="service_section mb-5">
        <div class="template_width">
            <div class="row">
                <div class="col-md-12 text-center sub_head">
                    <h5 class="font-weight-bold py-4 mb-3">OUR SERVICES INCLUDE</h5>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Product training.png" alt="Product Training" class="mb-3">
                        <p class="px-4">
                            Product Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Compliance traning.png" alt="Compliance Training" class="mb-3">
                        <p class="px-4">
                            Compliance Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Onboarding.png" alt="Onboarding" class="mb-3">
                        <p class="px-4">
                            Onboarding
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Leadership.png" alt="Leadership Development Process" class="mb-3">
                        <p class="px-4">
                            Leadership Development Process & Skills Training
                        </p>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Sales Training.png" alt="Sales Training" class="mb-3">
                        <p class="px-4">
                            Sales Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/College Level Instruction.png" alt="College Level Instruction" class="mb-3">
                        <p class="px-4">
                            College Level Instruction
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Software Training.png" alt="Software Training" class="mb-3">
                        <p class="px-4">
                            Software Training
                        </p>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">E-learning Course and Content</h4>
                    <p>
                        Pixentia’s experienced learning consultants go in-depth to understand your workforce, learning goals and the role they play in your organization’s success. Our discovery and planning processes include:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Course Development Workshops.png" alt="Course Development Workshops" class="mb-3">
                        <p class="font-weight-bold mb-1">Course Development Workshops</p>
                        <p class="px-4">
                            Identifying end user personas, learning goals, individual courses and micro-learning modules that will enable your employees to cost-effectively acquire targeted knowledge and skills.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/E-learning Course Planning.png" alt="E-learning Course Planning" class="mb-3">
                        <p class="font-weight-bold mb-1">E-learning Course Planning</p>
                        <p class="px-4">
                            Determining the learning styles (e.g. interactive video, simulation, gamification, etc.), and delivery modes (desktop, mobile device), that deliver the highest levels of <span class="d-inline-block">knowledge retention and proficiency.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Course Project Planning.png" alt="Course Project Planning" class="mb-3">
                        <p class="font-weight-bold mb-1">Course Project Planning</p>
                        <p class="px-4">
                            Credible, cost-effective plans showing how your training courses will be scripted, visualized, designed, prototyped, <span class="d-inline-block">tested and launched.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Business Case Support.png" alt="Business Case Support" class="mb-3">
                        <p class="font-weight-bold mb-1">Business Case Support</p>
                        <p class="px-4">
                            Building support for custom learning programs with comprehensive cost and benefit analyses combined with clear metrics for tracking post-implementation results.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="community_section my-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">E-learning Implementation</h5>
                    <p>
                        Our team of learning strategists, instructional content specialists and media developers provide end-to-end project support, from rough concepts to finished, deployed courses and modules. Pixentia provides:
                    </p>
                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Multi-platform Course Development.png" class="mr-2" alt="Multi-platform Course Development">
                        </p>
                        <p>
                            <strong class="d-block">Multi-platform Course Development</strong> With experience on such tools as Articulate Storyline, Adobe Captivate and Flash, Adapt HTML5, and a variety of mobile learning platforms<span class="d-none">, Pixentia can choose the authoring platform best able to present your content and achieve your learning goals</span>.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Content Creation.png" class="mr-2" alt="Content Creation">
                        </p>
                        <p>
                            <strong class="d-block">Content Creation</strong> Including instructional approach, outlines, data exchange, storyboards, content, animation, motion graphics, narration scripts and production<span class="d-none"> – all custom-designed to enable your employees to learn the right information more quickly and efficiently</span>.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Legacy Content Conversion.png" class="mr-2" alt="Legacy Content Conversion">
                        </p>
                        <p>
                            <strong class="d-block">Legacy Content Conversion
                            </strong> Extending the life of legacy content built on Flash by converting it to HTML5 in order to support tablet and mobile access.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Learning Deployment.png" class="mr-2" alt="Learning Deployment">
                        </p>
                        <p>
                            <strong class="d-block">Learning Deployment</strong> Facilitating trouble-free, multi-platform deployment of mobile learning apps, micro learning modules, game-based courses, and simulations.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Course and Module Marketing.png" class="mr-2" alt="Course and Module Marketing">
                        </p>
                        <p>
                            <strong class="d-block">Course and Module Marketing
                            </strong> Employee communications that promote learning initiatives, build awareness and strengthen employee engagement.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Evaluation and Continuous Improvement.png" class="mr-2" alt="Evaluation and Continuous Improvement">
                        </p>
                        <p>
                            <strong class="d-block">Evaluation and Continuous Improvement</strong> Built-in features and reports that capture individual performance, collect employee feedback, and help guide course revisions and improvements.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/learning/custom-learning/Learning Project Implementation.png" alt="Learning Project Implementation" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Operations Support</h4>
                    <p>
                        Ongoing maintenance of content and technology is an important part of any learning strategy. Pixentia’s IT professionals can supplement your internal staff to provide:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Content Deployment.png" alt="Content Deployment" class="mb-3">
                        <p class="font-weight-bold mb-1">Content Deployment</p>
                        <p class="px-4">
                            Launching new content and verifying successful delivery across multiple devices and platforms.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/LMS Maintenance.png" alt="LMS Maintenance" class="mb-3">
                        <p class="font-weight-bold mb-1">LMS Maintenance</p>
                        <p class="px-4">
                            Maintaining high rates of <a href="services/learning/learningmanagementsystems" class="text-white">learning management system</a> uptime and reliable integration with other applications through test and validation of upgrades,
                            ongoing system monitoring <span class="d-inline-block">and help-desk support.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning//custom-learning/Content Updating.png" alt="Content Updating" class="mb-3">
                        <p class="font-weight-bold mb-1">Content Updating</p>
                        <p class="px-4">
                            Incorporating changes in plans, products, business processes, and regulatory compliance with timely, cost-effective <span class="d-inline-block">course revisions and extensions.</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Learning Metrics and Analytics.png" alt="Learning Metrics and Analytics" class="mb-3">
                        <p class="font-weight-bold mb-1">Learning Metrics and Analytics</p>
                        <p class="px-4">
                            Tracking employee and organization progress with custom, real-time reporting; incorporating learning into your <a href="services/hcm/peopleanalytics" class="text-white">people analytics</a> initiatives with ongoing data curation
                            and warehousing services.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-5">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-10 offset-md-1">
                    <div class="row align-items-center">
                        <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                            <p class="sub_heading_cta mb-4">Learn how we support your entire learning life cycle, from concept to design, from delivery to measuring impact on your organization.
                            </p>
                            <div class="more_info_btn datasheet_btn">
                                <a href="https://resources.pixentia.com/data-sheets/custom-learning-01" rel="noopener noreferrer" target="_blank"> Get the details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="col-md-5 mt-5 mt-md-0">
                            <div>
                                <img src="../../../../assets/images/cta_images/Customlearning.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>