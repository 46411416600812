<div class="nav_menu fixed-top bg-light">
    <div class="text-md-right text-center pt-2">
        <div class="d-flex justify-content-end align-items-center top_section">
            <div class="mr-md-4 mt-md-0">
                <p class="mb-0 phone">

                    <img src="../../assets/images/Top phonbe.png" class="mr-1" width="15" height="15" alt=""><a
                        href="tel:+1 855-978-6816" class="black-color">+1 855-978-6816</a>
                </p>
            </div>
            <div class="mr-md-4 mt-md-0 mr-3 ml-3 ml-md-0">
                <p class="mb-0 support">
                    <img src="../../assets/images/SUpport.png" class="mr-1" alt="" width="15" height="15"><a
                        href="mailto:info@pixentia.com" class="black-color">Support</a>
                </p>
            </div>
            <div class="social_media">
                <a href="https://www.facebook.com/pixentia/" rel="noopener noreferrer" target="_blank"><img
                        src="../../assets/images/socialmedia/facebook.png" class="ml-0 ml-md-0" width="15" height="auto"
                        alt=" Pixentia facebook"></a>
                <a href="https://twitter.com/pixentia" rel="noopener noreferrer" target="_blank"><img
                        src="../../assets/images/socialmedia/twitter.png" class="ml-2" width="15" height="auto"
                        alt="Pixentia Twitter"></a>
                <a href="https://www.linkedin.com/company/pixentia" rel="noopener noreferrer" target="_blank"><img
                        src="../../assets/images/socialmedia/linkedin.png" width="15" height="auto" class="ml-2"
                        alt=" Pixentia Linkedin"></a>
                <a href="https://www.youtube.com/c/Pixentia" rel="noopener noreferrer" target="_blank"><img
                        src="../../assets/images/socialmedia/youtube.png" width="15" height="auto" class="ml-2"
                        alt="Pixentia Youtube"></a>
                <a href="https://www.instagram.com/pixentiacorporation/" rel="noopener noreferrer" target="_blank"><img
                        src="../../assets/images/socialmedia/instagram.png" width="15" height="auto" class="ml-2"
                        alt="Pixentia Instagram"></a>
            </div>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg  navbar-dark custom-toggler homepage_header px-0 py-0">
        <a class="navbar-brand" routerLink="/"><img src="../assets/images/anniversary10.png" height="60"
                alt="Pixentia"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justity-content-end nav_links" id="collapsibleNavbar">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle cursor-pointer" id="dropdown01" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Products</a>
                    <div class="dropdown-menu dropdown-menu" aria-labelledby="dropdown01">
                        <div class="d-md-flex">
                            <div>
                                <a class="dropdown-item primary-color" routerLink="/products/chasma-apps"> Chasma
                                    Apps</a>
                                <a class="dropdown-item" routerLink="/products/chasma-apps/chasma-editor">Chasma
                                    Editor</a>
                                <a class="dropdown-item" routerLink="/products/chasma-apps/chasma-event">Chasma
                                    Event</a>
                                <a class="dropdown-item" routerLink="/products/chasma-apps/chasma-lexy"> Chasma Lexy</a>
                                <a class="dropdown-item" routerLink="/products/chasma-apps/chasma-quale">Chasma
                                    Quale</a>
                                <a class="dropdown-item" routerLink="/products/chasma-apps/chasma-natiodesk">Chasma
                                    Natio Desk</a>
                                <a class="dropdown-item" routerLink="/products/chasma-apps/chasma-botbuilder">Chasma Bot
                                    Builder</a>
                                <a class="dropdown-item" routerLink="/products/chasma-apps/chasma-cible">Chasma
                                    Cible</a>
                            </div>
                            <div>
                                <a class="dropdown-item primary-color" routerLink="/products/chasma-analytics"> Chasma
                                    Analytics</a>
                                <a class="dropdown-item"
                                    routerLink="/products/chasma-analytics/sumtotal-analytics">Sumtotal
                                    Analytics</a>
                                <a class="dropdown-item" routerLink="/products/chasma-analytics/chasma-hrbox">People
                                    Analytics With
                                    HRBox</a>
                                <a class="dropdown-item"
                                    routerLink="/products/chasma-analytics/cornerstone-analytics">Cornerstone
                                    Analytics</a>
                                <a class="dropdown-item"
                                    routerLink="/products/chasma-analytics/people-analytics-power-bi">People
                                    Analytics With PowerBI</a>
                                <a class="dropdown-item"
                                    routerLink="/products/chasma-analytics/workday-analytics">Workday Analytics</a>
                            </div>
                            <div>
                                <a class="dropdown-item primary-color" routerLink="/products/chasma-connect"> Chasma
                                    Connect</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle cursor-pointer" id="dropdown01" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Services</a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01">
                        <div class="d-md-flex">
                            <div>
                                <a class="dropdown-item primary-color"> Human Capital</a>
                                <a class="dropdown-item" routerLink="/services/hcm/hcm-coe">HCM COE</a>
                                <a class="dropdown-item" routerLink="/services/hcm/recruiting">Recruiting</a>
                                <a class="dropdown-item" routerLink="/services/hcm/learning"> Learning</a>
                                <a class="dropdown-item" routerLink="/services/hcm/talentmanagement">Talent
                                    Management</a>
                                <a class="dropdown-item" routerLink="/services/hcm/payroll">Payroll</a>
                                <a class="dropdown-item" routerLink="/services/hcm/compensation">Compensation</a>
                                <a class="dropdown-item" routerLink="/services/hcm/enterprise-integrations">Enterprise
                                    Integrations</a>
                                <a class="dropdown-item" routerLink="/services/hcm/peopleanalytics">People Analytics</a>
                                <a class="dropdown-item" routerLink="/services/hcm/employee-portals">Employee
                                    Portals</a>
                                <a class="dropdown-item" routerLink="/services/hcm/hcm-value-calculator">Value
                                    Calculator</a>
                                <a class="dropdown-item" routerLink="/services/hcm/hcm-resource-center">HCM Resource
                                    Center - AMS</a>
                            </div>
                            <div>
                                <a class="dropdown-item primary-color"> Learning</a>
                                <a class="dropdown-item" routerLink="/services/learning/learning-coe">Learning COE</a>
                                <a class="dropdown-item"
                                    routerLink="/services/learning/learningmanagementsystems">Learning Management
                                    Systems</a>
                                <a class="dropdown-item" routerLink="/services/learning/learning-portals">Learning
                                    Portals</a>
                                <a class="dropdown-item" routerLink="/services/learning/customlearning">Custom
                                    Learning</a>
                                <a class="dropdown-item" routerLink="/services/learning/mobilelearning">Mobile
                                    Learning</a>
                                <a class="dropdown-item"
                                    routerLink="/services/learning/learningstylesandgamification">Learning Styles
                                    and Gamification</a>
                                <a class="dropdown-item" routerLink="/services/learning/demosmain">Learning Demos</a>
                                <a class="dropdown-item" routerLink="/services/learning/learning-resource-center">Learn
                                    Resource Center
                                    - AMS</a>
                            </div>
                            <div>
                                <a class="dropdown-item primary-color"> Cloud Transformation</a>
                                <a class="dropdown-item" routerLink="/services/cloud-transformation/workday">Workday</a>
                                <a class="dropdown-item"
                                    routerLink="/services/cloud-transformation/sumtotal">Sumtotal</a>
                                <a class="dropdown-item"
                                    routerLink="/services/cloud-transformation/cornerstone">Cornerstone</a>
                                <a class="dropdown-item"
                                    routerLink="/services/cloud-transformation/ceridian">Ceridian</a>
                                <a class="dropdown-item"
                                    routerLink="/services/cloud-transformation/servicenow">Servicenow</a>
                                <a class="dropdown-item"
                                    routerLink="/services/cloud-transformation/salesforce">Salesforce</a>
                                <div class="mt-4">
                                    <a class="dropdown-item primary-color" routerLink="/services/application-management-services">Application Management Services</a>

                                    <a class="dropdown-item primary-color" routerLink="/services/managed-talent-development-services">Managed Talent Development Services </a>
                                    
                                    <a class="dropdown-item primary-color"
                                        routerLink="/services/digital-transformation"> Digital
                                        Transformation</a>
                                    <a class="dropdown-item primary-color" routerLink="/services/mobile"> Mobile
                                        Applications</a>
                                    <a class="dropdown-item primary-color" routerLink="/services/analytics"> Advanced
                                        Analytics</a>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle cursor-pointer" id="dropdown01" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Company</a>
                    <div class="dropdown-menu" aria-labelledby="dropdown01">
                        <div class="d-md-flex">
                            <div>
                                <a class="dropdown-item primary-color" routerLink="/about"> About Us</a>
                                <a class="dropdown-item primary-color" href="https://careers.pixentia.com/"> Careers</a>
                            </div>
                            <div>
                                <a class="dropdown-item primary-color" routerLink="/customers"> Customers</a>
                                <a class="dropdown-item primary-color" routerLink="/partners"> Partners</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle cursor-pointer" id="dropdown03" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Resources</a>
                    <div class="dropdown-menu" aria-labelledby="dropdown03">
                        <div class="d-md-flex">
                            <div>
                                <a class="dropdown-item primary-color" href="https://blog.pixentia.com/">Blog</a>
                                <a class="dropdown-item primary-color"
                                    href="https://resources.pixentia.com/">Resources</a>
                                <a class="dropdown-item primary-color"
                                    href="https://resources.pixentia.com/liveevents">Events</a>
                                <a class="dropdown-item primary-color"
                                    href="https://resources.pixentia.com/data-sheets">Data Sheets</a>
                            </div>
                            <div>
                                <a class="dropdown-item primary-color"
                                    href="https://resources.pixentia.com/guides">Guides &
                                    Whitepapers</a>
                                <a class="dropdown-item primary-color"
                                    href="https://resources.pixentia.com/case-studies">Case
                                    Studies</a>
                                <a class="dropdown-item primary-color"
                                    href="https://resources.pixentia.com/our-collections">Collections</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/contact-us">Contact Us</a>
                </li>
                <li class="nav-item" style="margin: auto;">
                    <i *ngIf="!hasValue" (click)="hasValue=!hasValue" class="fa fa-search"></i>
                    <input *ngIf="hasValue" [(ngModel)]="searchTerm" placeholder="Search" type="text" name="searchTerm"
                        id="searchTerms" (ngModelChange)="this.userSearchUpdate.next($event)">
                </li>
            </ul>
        </div>
    </nav>
    <div class="marTop124">
    </div>
</div>
<div *ngIf="searchArrayLength > 0 || (searchArrayLength == 0 && searchTerm != '')">
    <app-search [searchResults]="searchResults" [searchTerm]="searchTerm"></app-search>
</div>