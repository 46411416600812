<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/change-management">Change Management</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Boost Adoption and Utilization
                </h3>
                <p>Change communications should involve your key stakeholders from the beginning of your project. The best way to gain their enthusiasm is to involve your people in the change and respond quickly to their questions and concerns.</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/change-management/change-management-image.png" alt="Change management image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Our Change Management Services</h1>
                </div>
                <div>
                    <p class="mb-3 font-weight-bold">
                        Change Management Planning
                    </p>
                    <p class="mb-3">
                        If we had only one principle to guide us in change management, it would be to communicate early and often and never stop listening. Our planning services are based on that principle. We support you by:
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/change-management/Analyzing the internal.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p>
                        Analyzing the internal and external forces driving the change so you can communicate them effectively
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/change-management/Assessing organizational.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p>
                        Assessing organizational readiness to assist you in shaping your communications
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/change-management/Identifying communication.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p>
                        Identifying communication channels and messaging to create a two-way conversation with stakeholders
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">Stakeholder Surveys</h4>
                <div class="border d-inline-block px-5 border-radius">
                    <p class="mt-3 mb-0">
                        People don't resist change. They resist being changed
                    </p>
                    <p>-Peter Senge</p>
                </div>
                <div class="mt-4">
                    <p>
                        Getting your people to support your initiative is often as easy as asking their opinions. The Pixentia team helps you create and launch a survey to assess your organization’s readiness for the change. Our support includes:
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/change-management/Best practices in.png" alt="Out-of-the-box Apps" class="mb-3">
                    <p class="px-4">
                        Best practices in change surveys to promote organizational readiness
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/change-management/Developing the survey.png" alt="Modern User Interface" class="mb-3">
                    <p class="px-4">
                        Developing the survey questions, structure, and messaging, so the survey itself educates and informs your stakeholders
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/change-management/Identifying how you.png" alt="Cloud Advantage" class="mb-3">
                    <p class="px-4">
                        Identifying how you will respond to the survey, preferably using a channel that enables two-way communication throughout the project
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-4">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Program Marketing</h4>
                    <p class="">
                        Our program marketing techniques go beyond mere communication. We employ gamification techniques such as “treasure hunts” with prizes to encourage exploration of your platform features. We also work with you to encourage communities of interest using
                        social media or collaboration tools where your people can share their discoveries and ask questions.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <img src="../../../../../assets/images/hcm/learning/change-management/Program Marketing.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section resource_guides_section">
    <div class="template_width">
        <div class="row text-white align-items-center">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold py-4 mb-0">Resource Guides</h4>
            </div>
            <div class="col-md-6">
                <div>
                    <img src="../../../../../assets/images/hcm/learning/change-management/Resource Guides.png" class="img-fluid" width="100%" alt="">

                </div>
            </div>
            <div class="col-md-6">
                <div class="video_link">
                    <p class="">
                        Quick reference guides can be handy when your people are new to the system and need a little help. You will want to keep them as part of your onboarding support for new learners.
                    </p>
                    <p class="mb-4">
                        Your administrators will be especially grateful for these guides when they are working with procedures they rarely use but are essential to the smooth operation of your platform.
                    </p>
                    <div class="d-flex align-items-center mt-3">
                        <p>
                            <img src="../../../../../assets/images/hcm/learning/change-management/User guides for end users.png" class="mr-2" alt="Planning your testing">
                        </p>
                        <p class="ml-3">
                            User guides for end users
                        </p>
                    </div>
                    <div class="d-flex align-items-center">
                        <p>
                            <img src="../../../../../assets/images/hcm/learning/change-management/Reference guides for administrators.png" class="mr-2" alt="Planning your testing">
                        </p>
                        <p class="ml-3">
                            Reference guides for administrators
                        </p>
                    </div>
                    <div class="d-flex align-items-center">
                        <p>
                            <img src="../../../../../assets/images/hcm/learning/change-management/Reference guides for managing resources and delivery.png" class="mr-2" alt="Planning your testing">
                        </p>
                        <p class="ml-3">
                            Reference guides for managing resources and delivery
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">
                            Learn more about how our change management services can boost your platform utilization
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="https://resources.pixentia.com/data-sheets/change-management-for-sumtotal-technology" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning">Back to Strategy and Planning <i
                                        class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>