<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps">Chasma Apps</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps/chasma-event">Chasma Event</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Event</h3>
                <p>Agile administration for gatherings of any size</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/event/Eventimage.webp" alt="Chasma event image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">AUTOMATED EVENT MANAGEMENT</h1>
                <div>
                    <p class="mb-3">
                        Chasma Event is mobile event management software for employee events or any event across your organization. It automates invitations, RSVPs, details and directions, and resources for any size gathering.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/event/Manage Registration.png" alt="Manage Registration">
                </div>
                <div class="px-5">
                    <p>
                        Manage registration and <span class="d-inline-block">eligibility for credit.</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/event/Automate Walk-in.png" alt="Automate walk-in registration">
                </div>
                <div class="px-5">
                    <p>
                        Automate walk-in registration <span class="d-inline-block">or enrollment.</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/event/Set up reminders.png" alt="Set up reminders">
                </div>
                <div class="px-5">
                    <p>
                        Set up reminders by email and <span class="d-inline-block">text message.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center mb-4">
                <h4 class="font-weight-bold py-4 mb-0">Chasma Event Advantage</h4>
                <p>Chasma event makes your events easier to manage and helps you manage all the resources and support that make your happenings memorable.</p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/event/Free App for Individuals.png" alt="Free App for Individuals" class="mb-3">
                    <p class="font-weight-bold mb-0">Free App for Individuals</p>
                    <p class="px-4">
                        Help managers and team leaders manage team events.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/event/Attendance Dashboard.png" alt="Attendance Dashboard" class="mb-3">
                    <p class="font-weight-bold mb-0">Attendance Dashboard</p>
                    <p class="px-4">
                        Manage invitations and attendance with an easy-to-use dashboard.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/event/Use Free Mobile Apps.png" alt="Use Free Mobile Apps" class="mb-3">
                    <p class="font-weight-bold mb-0">Use Free Mobile Apps</p>
                    <p class="px-4">
                        Generate passes for Mobile Wallet, MobilePASS, or other <span class="d-inline-block">digital
                            authentication.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/event/Faster Check-In.png" alt="Faster Check-In" class="mb-3">
                    <p class="font-weight-bold mb-0">Faster Check-In</p>
                    <p class="px-4">
                        Don’t make attendees stand in line to sign in. A quick scan of a mobile device, printed ticket, or badge is all it takes.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/event/Better Data Quality.png" alt="Better Data Quality" class="mb-3">
                    <p class="font-weight-bold mb-0">Better Data Quality</p>
                    <p class="px-4">
                        Eliminate messy data caused by manual sign-in sheets, manual data entry, <span class="d-inline-block">and spreadsheet errors.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/event/Manage Details.png" alt="Manage Details" class="mb-3">
                    <p class="font-weight-bold mb-0">Manage Details</p>
                    <p class="px-4">
                        Provide a map and direction on the mobile app. Create to-do lists <span class="d-inline-block">and task assignments.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Event?</h4>
                    <p class="">
                        Administration and management of your enterprise events, instructor-led training, and other meetings could be so frustrating. Watch the video to see how Chasma Event eliminates all the hassles involved in employee event management and help you organize
                        all your <span class="d-inline-block">events like a pro.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/284219640?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div>
                            <img src="../../assets/images/products/event/Need more.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-white mt-5 mt-md-0">
                        <h2 class="font-weight-bold">Let Chasma handle your ILT administration
                        </h2>
                        <p class="mb-4">Automate your ILT management with digital invitations, registration, and attendance.
                        </p>
                        <div class="more_info_btn">
                            <a href="http://resources.chasma.io/automate-ilt-administration-and-management" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2"
                                    aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>