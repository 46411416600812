<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/digital-transformation">Digital Transformation</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Your Path to Digital HR Transformation</h3>
                <p>Start with strategy
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../assets/images/digital-transformation/Digital Transformation Image.png" alt="Digital Transformation Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center info_section">
            <div class="col-md-12 sub_head">
                <h1 class="text-center mb-3 font-weight-bold">
                    Start With Strategy in Your HR Digital Transformation
                </h1>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <p class="text-center">
                            Today’s fast-paced business environment requires us to operate efficiently, scale fast, and innovate quickly to create value for our customers.
                        </p>
                    </div>
                </div>

                <div class="row workday_section my-5">
                    <div class="col-md-10 offset-md-1">
                        <div class="row align-items-center">
                            <div class="col-md-7">
                                <div class="py-2 px-5">
                                    <div>
                                        <p>
                                            Unfortunately, much of the HR teams’ use of technology is tying their hands
                                        </p>
                                        <p>
                                            Usually, they have cobbled together perhaps a dozen or more technologies to meet the “needs of the moment,” without a cohesive strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="py-4 px-3 right_box">
                                    <div class="">
                                        <div class="d-flex mb-3">
                                            <div>
                                                <img src="../../../assets/images/digital-transformation/Are you looking for specific apps.png" alt="">
                                            </div>
                                            <div>
                                                <p class="font-weight-bold mb-0">
                                                    Are you looking for specific apps or technologies?
                                                </p>
                                            </div>
                                        </div>

                                        <p class="hcm_link">
                                            <a href="services/hcm/hcm-coe">Visit our HCM pages to learn about Pixentia Implementation and Support Services</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center">
                            <p class="font-weight-bold mb-2">
                                It’s time for a change.
                            </p>
                            <p class="mb-0">
                                Stop running on the hamster wheel. Instead, let’s move beyond technology to embrace a digital strategy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="digital_section">
        <div class="template_width">
            <div class="row text-white text-center py-5">
                <div class="col-md-12 text-white">
                    <h3 class="font-weight-bold">A Strategic Approach to Digitization</h3>
                    <p class="mb-2">
                        Soon, every company will be digital—even your local dog-walker—but technology alone won’t get you there.
                    </p>
                    <p class="mb-2">
                        It’s only your entry into the rodeo. It won’t make you the top bull-rider.
                    </p>
                    <p>
                        If you want to succeed, you’re going to need a strategy.
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="mb-0">
                        Pixentia helps you get started in the right direction with strategy workshops that prepare you for a new leadership mindset.
                    </p>
                </div>
            </div>
            <div class="row text-white pb-5 align-items-center">
                <div class="col-md-6">
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/A review of your business.png" class="mr-2" alt="A review of your business and workforce">
                        </p>
                        <p>
                            A review of your business and workforce strategies to align your purpose to the business.
                        </p>
                    </div>
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/Technology assessment.png" class="mr-2" alt="Technology assessment">
                        </p>
                        <p>
                            Technology assessment to map your current situation to create a roadmap for digitization.
                        </p>
                    </div>
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/A governance framework.png" class="mr-2" alt="A governance framework">
                        </p>
                        <p>
                            A governance framework for decisions and accountability critical to the success of your strategy.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../../assets/images/digital-transformation/Change management tools.png" class="mr-2" alt="Change management tools">
                        </p>
                        <p>
                            Change management tools help you generate enthusiasm for change across your organization.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-right">
                    <div>
                        <img src="../../../assets/images/digital-transformation/A Strategic Approach to Digitization.png" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="text-center mt-4">
                        <p class="mb-0">
                            Get ready for the ride of your life. It will never end.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="strategy_section">
        <div class="template_width">
            <div class="row text-center py-5">
                <div class="col-md-12">
                    <h3 class="font-weight-bold">Strategy Review</h3>
                    <p class="mb-2">
                        Your competitive advantage won’t be in the new technology you buy. On the contrary, it’ll be in how you use it to adapt to change.
                    </p>
                    <p class="mb-2">
                        You may find you don’t need new technology but only need to align what you have to activate your strategy.
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="mb-0 font-italic">
                        So, we will work with you to discover:
                    </p>
                </div>
            </div>
            <div class="row pb-5 align-items-center">
                <div class="col-md-6 text-center text-md-left">
                    <div>
                        <img src="../../../assets/images/digital-transformation/Strategy Review.png" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/Where you can automate.png" class="mr-2" alt="Where you can automate decisions">
                        </p>
                        <p>
                            Where you can automate decisions when it makes sense to do.
                        </p>
                    </div>
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/How you can develop.png" class="mr-2" alt="How you can develop a complete picture">
                        </p>
                        <p>
                            How you can develop a complete picture to make better decisions.
                        </p>
                    </div>
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/Where you need to.png" class="mr-2" alt="Where you need to improve information">
                        </p>
                        <p>
                            Where you need to improve information flow to seize opportunities, so you can drive change instead of reacting to it.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../../assets/images/digital-transformation/A method for environmental.png" class="mr-2" alt="A method for environmental assessment">
                        </p>
                        <p>
                            A method for environmental assessment to know when you need to examine your strategy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-8 offset-md-2 mb-5">
                <div class="blue_section">
                    <div class="quote1">
                        <img src="../../../assets/images/digital-transformation/quote2.png" alt="">
                    </div>
                    <h3 class="mb-0">
                        Digital possibilities must shape strategy. And strategy must shape digital priorities.
                    </h3>
                    <p class="mb-0 mt-4">Ragu Gurumurthy, Rich Nanda, and David Schatsky</p>
                    <p class="mb-0">“Putting Digital at the Heart of Strategy” Deloitte</p>
                    <div class="quote2">
                        <img src="../../../assets/images/digital-transformation/quote1.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="digital_section">
        <div class="template_width">
            <div class="row text-white text-center py-5">
                <div class="col-md-12 text-white">
                    <h3 class="font-weight-bold">Technology Assessment</h3>
                    <p class="mb-2">
                        We often do technology assessments looking for bottlenecks, broken processes, and workaround that hamper efficiency.
                    </p>
                    <p class="mb-2">
                        But technology strategy is different.
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="mb-0 font-italic">
                        We help you discover:
                    </p>
                </div>
            </div>
            <div class="row text-white pb-5 align-items-center">
                <div class="col-md-6">
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/whether you are using.png" class="mr-2" alt="Technology assessment">
                        </p>
                        <p>
                            Whether you are using your current technology effectively to enable your strategy,
                        </p>
                    </div>
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/how it enables you.png" class="mr-2" alt="A governance framework">
                        </p>
                        <p>
                            How it enables you to adapt and pivot, and
                        </p>
                    </div>
                    <div class="d-flex mb-4">
                        <p>
                            <img src="../../../assets/images/digital-transformation/how you can enable.png" class="mr-2" alt="Change management tools">
                        </p>
                        <p>
                            How you can enable organizational agility.
                        </p>
                    </div>
                    <div>
                        <p class="mb-0">
                            For example, do you have analytics to know when you need to review salary offers for critical positions?
                        </p>
                        <p>
                            How long does it take until you know that there may be a problem?
                        </p>
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-right">
                    <div>
                        <img src="../../../assets/images/digital-transformation/Technology Assessment-1.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="strategy_section">
        <div class="template_width">
            <div class="row text-center py-5">
                <div class="col-md-12">
                    <h3 class="font-weight-bold digital-color">Governance</h3>
                    <p class="mb-2">
                        When people mention governance, some of us think immediately of rules and central control.
                    </p>
                    <p class="mb-2">
                        In contrast, modern governance is a framework for collaboration.
                    </p>
                    <p class="mb-2">
                        Governance enables a culture of continuous adaptation in a transformation that will never end.
                    </p>
                    <p class="mb-0">
                        It opens up a new world of possibilities where stakeholders have a voice in policies and decisions.
                    </p>
                </div>
                <div class="col-md-8 offset-md-2 my-5">
                    <div class="blue_section">
                        <div class="quote1">
                            <img src="../../../assets/images/digital-transformation/quote2.png" alt="">
                        </div>
                        <h3 class="mb-0">
                            Governance enables a culture of continuous adaptation in a transformation that will never end.
                        </h3>
                        <div class="quote2">
                            <img src="../../../assets/images/digital-transformation/quote1.jpg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-8 offset-md-2">
                    <p class="mb-0">
                        Governance opens up new avenues for HR to become the organization’s coach and enabler—instead of being viewed as the policy police.
                    </p>
                </div>
                <div class="col-md-12">
                    <div>
                        <img src="../../../assets/images/digital-transformation/governence.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="digital_section">
    <div class="template_width">
        <div class="row text-white text-center py-5">
            <div class="col-md-12 text-white">
                <h3 class="font-weight-bold">Change Management</h3>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <p class="mb-2">
                            We recommend you start your change management communication when you decide you need a change—even before you have decided what change to make.
                        </p>
                        <p class="mb-2">
                            Early communication will enable your people to become agents of change.
                        </p>
                        <p class="mb-2">
                            You can make that happen by asking them into the conversation.
                        </p>
                        <p class="mb-2 font-italic">
                            We support you by:
                        </p>
                    </div>
                </div>

            </div>
        </div>
        <div class="row text-white pb-5 align-items-center">
            <div class="col-md-6">
                <div class="d-flex mb-4">
                    <p>
                        <img src="../../../assets/images/digital-transformation/Analyzing the.png" class="mr-2" alt="Analyzing the internal and external forces">
                    </p>
                    <p>
                        Analyzing the internal and external forces driving the change so you can communicate them effectively.
                    </p>
                </div>
                <div class="d-flex mb-4">
                    <p>
                        <img src="../../../assets/images/digital-transformation/Assessing organizational.png" class="mr-2" alt="Assessing organizational readiness">
                    </p>
                    <p>
                        Assessing organizational readiness to assist you in shaping your communications.
                    </p>
                </div>
                <div class="d-flex mb-4">
                    <p>
                        <img src="../../../assets/images/digital-transformation/Identifying communication.png" class="mr-2" alt="Identifying communication">
                    </p>
                    <p>
                        Identifying communication channels and messaging to create a two-way conversation with stakeholders, beginning with your executive team
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-center text-md-right">
                <div>
                    <img src="../../../assets/images/digital-transformation/Change Management.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width my-4">
    <app-get-started></app-get-started>
</div>