<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps">Chasma Apps</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="products/chasma-apps/chasma-quale">Chasma Quale</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Chasma Quale</h3>
                <p>Automated Cloud Validation Platform</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/products/quale/Quale-image.webp" alt="Chasma Quale image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <h1 class="font-weight-bold mb-4">An automated testing solution that eliminates repetitive manual testing
                </h1>
                <div>
                    <p class="mb-3">
                        With a library of 10,000+ testing scenarios (and growing), Quale handles routine tasks so you can focus on critical, high-value testing.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row my-5">
        <div class="col-md-3">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/quale/Reduce labor Costs.png" alt="Reduce labor costs.">
                </div>
                <div class="px-5">
                    <p>
                        Reduce labor costs.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/quale/Eliminate human.png" alt="Eliminate human error">
                </div>
                <div class="px-5">
                    <p>
                        Eliminate <span class="d-inline-block">human error.</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/quale/Create the consis.png" alt="consistency">
                </div>
                <div class="px-2">
                    <p>
                        Create the consistency, thoroughness and transparency that auditors love.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../assets/images/products/quale/Reduce Total.png" alt="Reduce total cost of ownership">
                </div>
                <div class="px-2">
                    <p>
                        Reduce total cost of ownership.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-apps">
                            <img src="../../assets/images/products/apps//apps-icon.png" alt=""> Chasma Apps
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-analytics">
                            <img src="../../assets/images/products/apps//analytics-icon.png" alt=""> Chasma Analytics
                        </a>
                    </h3>
                </div>
            </div>
            <div class="col-md-4">
                <div class="app_name">
                    <h3>
                        <a class="cursor-pointer black_Clr" routerLink="/products/chasma-connect">
                            <img src="../../assets/images/products/apps//connect-icon.png" alt=""> Chasma Connectors
                        </a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center mb-4">
                <h4 class="font-weight-bold py-4 mb-0">How Quale’s automated testing can save you time and money
                </h4>
                <p>
                    Quale runs the predefined test cases for each of your releases, and thoroughly tests your application, then presents the results in tabular format so you can focus on the areas that need attention.
                </p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/quale/Eliminate Labor-Intensive Manual Testing.png" alt="Eliminate Labor-Intensive Manual Testing" class="mb-3">
                    <p class="font-weight-bold mb-0">Eliminate Labor-Intensive Manual Testing</p>
                    <p class="px-4">
                        No more pulling off your people from productive work to validate your <span class="d-inline-block">processes and
                        integrations.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/quale/Automated Integrations Testing.png" alt="Automated Integrations Testing" class="mb-3">
                    <p class="font-weight-bold mb-0">Automated Integrations Testing</p>
                    <p class="px-4">
                        Build, deploy and validate test cases for your custom integrations.
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/quale/Enjoy a Vast Library of Test Scenarios.png" alt="Enjoy a Vast Library of Test Scenarios" class="mb-3">
                    <p class="font-weight-bold mb-0">Enjoy a Vast Library of Test Scenarios</p>
                    <p class="px-4">
                        10,000+ common use cases and scenarios to thoroughly validate your system <span class="d-inline-block">before we sign off.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/quale/Configuration.png" alt="Configuration" class="mb-3">
                    <p class="font-weight-bold mb-0">Configuration</p>
                    <p class="px-4">
                        Configure standard scripts to your <span class="d-inline-block">application in minutes.</span>
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/quale/Real time Status.png" alt="Real time Status" class="mb-3">
                    <p class="font-weight-bold mb-0">Real time Status</p>
                    <p class="px-4">
                        You can view real time progress of the results and your testing status.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../assets/images/products/quale/Automated Reporting.png" alt="Automated Reporting" class="mb-3">
                    <p class="font-weight-bold mb-0">Automated Reporting</p>
                    <p class="px-4">
                        Detailed reports for each area of the application and the number of <span class="d-inline-block">cases being executed.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-3">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div>
                    <h4 class="mb-3 font-weight-bold">Why Chasma Quale?</h4>
                    <p class="">
                        Without an automated testing solution, you often must choose between costly, labor-intensive testing and the risk of broken processes, faulty reports, failed audits, and data exposure. Watch the video to see how Quale can eliminate thousands of hours
                        of manual testing.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-md-right">
                <div class="video_link">
                    <iframe src="https://player.vimeo.com/video/243703423?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="more_info_section mb-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div>
                            <img src="../../assets/images/products/quale/Automated Testing CTA.png" class="img-fluid" alt="Ease Communication">
                        </div>
                    </div>
                    <div class="col-md-7 text-white mt-5 mt-md-0">
                        <h4 class="font-weight-bold">Automated testing for HR applications
                        </h4>
                        <p class="mb-4">
                            Run automated tests while you sleep. Let Quale manage your routine testing so you can focus on critical decisions.
                        </p>
                        <div class="more_info_btn">
                            <a href="http://resources.chasma.io/chasma-quale-automated-testing" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>