<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/learning/learningmanagementsystems">Learning Management Systems</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Build Your Ideal Workforce With Right LMS</h1>
                <p>Make informed LMS decisions, whether if you are choosing a new LMS, or looking for ways to get better results from an existing system.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/learning/lms/LMS Image.png" alt="LMS Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-4 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h3 class="text-center mb-5 font-weight-bold">
                    Superior E-Learning Solutions that Will Energize Your Workforce
                </h3>
            </div>
            <div class="col-md-10 offset-md-1 text-center">
                <p class="mb-0">
                    Pixentia’s deep understanding of your organization and its learning goals enables us to create superior, cost-effective solutions that measurably increase workforce knowledge and productivity.
                </p>
            </div>
        </div>
    </div>
    <div class="service_section mb-5">
        <div class="template_width">
            <div class="row">
                <div class="col-md-12 text-center sub_head">
                    <h5 class="font-weight-bold py-4 mb-3">OUR SERVICES INCLUDE</h5>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Product training.png" alt="Product Training" class="mb-3">
                        <p class="px-4">
                            Product Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Compliance traning.png" alt="Compliance Training" class="mb-3">
                        <p class="px-4">
                            Compliance Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Onboarding.png" alt="Onboarding" class="mb-3">
                        <p class="px-4">
                            Onboarding
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Leadership.png" alt="Leadership Development Process" class="mb-3">
                        <p class="px-4">
                            Leadership Development Process & Skills Training
                        </p>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Sales Training.png" alt="Sales Training" class="mb-3">
                        <p class="px-4">
                            Sales Training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/College Level Instruction.png" alt="College Level Instruction" class="mb-3">
                        <p class="px-4">
                            College Level Instruction
                        </p>
                    </div>
                </div>
                <div class="col-md-3 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/learning-coe/Software Training.png" alt="Software Training" class="mb-3">
                        <p class="px-4">
                            Software Training
                        </p>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Learning Management System Planning</h4>
                    <p>
                        Our consultants – with extensive experience on such LMS platforms as SumTotal, Moodle, <a href="services/cloud-transformation/cornerstone" class="text-white">Cornerstone</a>, and Successfactors – bring your organization objective
                        advice and guidance that leads to improved learning management system decisions. Our discovery and planning processes include:
                    </p>
                </div>
                <div class="col-md-6 border-right border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Course Development Workshops.png" alt="LMS Platform Workshops" class="mb-3">
                        <p class="font-weight-bold mb-1">LMS Platform Workshops</p>
                        <p class="px-4">
                            Looking at your organization’s overall goals, <a href="services/hcm/hcm-coe" class="text-white">HCM</a> strategy, workforce needs and budget in order to specify your ideal LMS or determine how to get more from an existing installation.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/E-learning Course Planning.png" alt="LMS Platform Selection" class="mb-3">
                        <p class="font-weight-bold mb-1">LMS Platform Selection</p>
                        <p class="px-4">
                            Supporting your selection process with RFPs, decision tools, and advice that enables your organization to choose the platform that will generate the highest return on investment.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 border-right">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Course Project Planning.png" alt="Project Planning" class="mb-3">
                        <p class="font-weight-bold mb-1">Project Planning</p>
                        <p class="px-4">
                            Creating end-to-end work plans and credible, cost-effective budgets for learning initiatives of all sizes, including full platform implementations and legacy content moves.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Business Case Support.png" alt="Business Case Support" class="mb-3">
                        <p class="font-weight-bold mb-1">Business Case Support</p>
                        <p class="px-4">
                            Building executive level support for your organization’s LMS initiatives with comprehensive financial return calculations and post-implementation metrics for tracking actual results.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="community_section my-3">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-12 text-center sub_head mb-4">
                    <h5 class="font-weight-bold py-4 mb-0">Learning Management System Implementation</h5>
                    <p>
                        Pixentia’s hands-on LMS specialists are more than IT experts – they can deliver superior results to every part of your project or initiative. Our services include:
                    </p>
                </div>
                <div class="col-md-7">
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Multi-platform Course Development.png" class="mr-2" alt="LMS Configuration Workshops">
                        </p>
                        <p>
                            <strong class="d-block">LMS Configuration Workshops</strong> Fully mapping your LMS platform goals, learning content deployment plans, and associated support applications to develop platform configurations that reduce the cost
                            implementation, use, and maintenance.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Content Creation.png" class="mr-2" alt="Implementation and Integration Resources">
                        </p>
                        <p>
                            <strong class="d-block">Implementation and Integration Resources</strong> Who can speed and simplify the implementation process by integrating your LMS with existing enterprise and HR systems, migrate data from legacy systems,
                            undertake detailed QA and acceptance testing, and provide overall project management.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Legacy Content Conversion.png" class="mr-2" alt="Legacy Content Conversion">
                        </p>
                        <p>
                            <strong class="d-block">Transformation Workshops and Marketing Programs
                            </strong> Which can maximize employee adoption of a new or updated LMS with innovative outreach programs and proven gamification strategies.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Learning Deployment.png" class="mr-2" alt="Learning Deployment">
                        </p>
                        <p>
                            <strong class="d-block">LMS Training Workshops</strong> Providing your LMS platform owners with the knowledge and skills to fully exploit their LMS and provide stakeholders and employees with a superior user experience.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Course and Module Marketing.png" class="mr-2" alt="Course and Module Marketing">
                        </p>
                        <p>
                            <strong class="d-block">LMS Reporting
                            </strong> Supplying your employees, supervisors and executives with real-time reports that will encourage personal achievement and improved organizational results.
                        </p>
                    </div>
                    <div class="d-flex">
                        <p>
                            <img src="../../assets/images/learning/custom-learning/Evaluation and Continuous Improvement.png" class="mr-2" alt="Evaluation and Continuous Improvement">
                        </p>
                        <p>
                            <strong class="d-block">Learning Analytics</strong> Integrating the data generated by your LMS into your <a href="services/hcm/peopleanalytics">people analytics</a> program and enabling new, actionable insights about learning
                            and performance.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 text-md-right">
                    <div class="video_link">
                        <img src="../../assets/images/learning/custom-learning/Learning Project Implementation.png" alt="Learning Project Implementation" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="promise_section">
        <div class="template_width">
            <div class="row text-white">
                <div class="col-md-12 text-center">
                    <h4 class="font-weight-bold py-4 mb-0">Operations Support</h4>
                    <p>
                        Your organization’s LMS platform is one part of an integrated system that needs ongoing maintenance and support. Pixentia’s IT specialists are an experienced, cost effective alternative to your internal IT staff. Our operations services cover:
                    </p>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/Content Deployment.png" alt="Daily Operations Support" class="mb-3">
                        <p class="font-weight-bold mb-1">Daily Operations Support</p>
                        <p class="px-4">
                            Delivering high uptime and uninterrupted data exchange with associated applications via system monitoring, uptime support, platform maintenance, and test/validation of new upgrades and releases.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning/custom-learning/LMS Maintenance.png" alt="Content Launch and Management" class="mb-3">
                        <p class="font-weight-bold mb-1">Content Launch and Management</p>
                        <p class="px-4">
                            Providing flawless launch and deployment of new content over multiple employee access methods and media platforms.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box text-center my-4">
                        <img src="../../assets/images/learning//custom-learning/Content Updating.png" alt="LMS Analytics" class="mb-3">
                        <p class="font-weight-bold mb-1">LMS Analytics</p>
                        <p class="px-4">
                            Providing reliable, real-time LMS data feeds into people analytics programs through integrated data curation <span class="d-inline-block">and warehousing services.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-5">
        <div class="template_width">
            <div class="row align-items-center">
                <div class="col-md-10 offset-md-1">
                    <div class="row align-items-center">
                        <div class="col-md-7 pl-md-5 mt-5 mt-md-0">
                            <p class="sub_heading_cta mb-4">Learn how you can adapt your learning technology and ecosystem to the evolving business needs.
                            </p>
                            <div class="more_info_btn datasheet_btn">
                                <a href="https://resources.pixentia.com/data-sheets/your-lms-decision-update-or-replace-0" rel="noopener noreferrer" target="_blank">Get the details here <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="col-md-5 mt-5 mt-md-0">
                            <div>
                                <img src="../../../../assets/images/cta_images/learningmanagementsystems.png" class="img-fluid" alt="Our consultants – with extensive experience on such LMS platforms as SumTotal, Moodle, Cornerstone, and Successfactors – bring your organization objective advice and guidance that leads to improved learning management system decisions.">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>