<div class="footer_section py-4" style="padding: 0% 6%;">
    <div class="">
        <div class="row mb-5 text-white text-center text-md-left">
            <div class="col-md-3">
                <div>
                    <h5>Products</h5>
                    <div class="footer_links">
                        <p>
                            <a routerLink="/products/chasma-apps">Chasma Apps</a>
                        </p>
                        <p>
                            <a routerLink="/products/chasma-analytics">Chasma Analytics</a>
                        </p>
                        <p>
                            <a routerLink="/products/chasma-connect">Chasma Connect</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div>
                    <h5 class="mt-3 mt-md-0">Services</h5>
                    <div class="footer_links">
                        <p>
                            <a routerLink="/services/hcm/hcm-coe">Human Capital Management</a>
                        </p>
                        <p>
                            <a routerLink="/services/learning/learning-coe">Learning</a>
                        </p>
                        <p>
                            <a routerLink="/services/cloud-transformation/sumtotal">Cloud Transformation</a>
                        </p>
                        <p>
                            <a routerLink="/services/application-management-services">Application Management Services</a>
                        </p>

                        <p>
                            <a routerLink="/services/managed-talent-development-services">Managed Talent Development Services</a>
                        </p>
                        <p>
                            <a routerLink="/services/digital-transformation">Digital Transformation</a>
                        </p>
                        <p>
                            <a routerLink="/services/mobile">Mobile Applications</a>
                        </p>
                        <p>
                            <a routerLink="/services/analytics">Advanced Analytics</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div>
                    <h5 class="mt-3 mt-md-0">Who We Are</h5>
                    <div class="footer_links">
                        <p>
                            <a routerLink="/about">About Us</a>
                        </p>
                        <p>
                            <a href="https://careers.pixentia.com/">Careers</a>
                        </p>
                        <p>
                            <a href="https://blog.pixentia.com/" rel="noopener noreferrer" target="_blank">Blog</a>
                        </p>
                        <p>
                            <a href="https://resources.pixentia.com/" rel="noopener noreferrer"
                                target="_blank">Resources</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div>
                    <h5 class="mt-3 mt-md-0">Corporate Headquarters</h5>
                </div>
                <div class="footer_links">
                    <p>
                        1333 Corporate Dr, # 212 <br> Irving, TX - 75038, USA <br> +1 903-306-2430
                    </p>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-4 pr-0 d-none d-md-block">
                <div class="border_style"></div>
            </div>
            <div class="col-md-4 p-0 social">
                <div class="text-center">
                    <a href="https://www.facebook.com/pixentia" rel="noopener noreferrer" target="_blank"><img
                            src="../assets/images/fb.png" class="mr-4" alt=""></a>
                    <a href="https://twitter.com/pixentia" rel="noopener noreferrer" target="_blank"><img
                            src="../assets/images/Twitter.png" class="mr-4" alt=""></a>
                    <a href="https://www.linkedin.com/company/pixentia" rel="noopener noreferrer" target="_blank"><img
                            src="../assets/images/Linkedin.png" class="mr-4" alt=""></a>
                    <a href="https://www.youtube.com/c/Pixentia" rel="noopener noreferrer" target="_blank"><img
                            src="../assets/images/Yoiutube.png" class="mr-4" alt=""></a>
                    <a href="https://www.instagram.com/pixentiacorporation/" rel="noopener noreferrer"
                        target="_blank"><img src="../assets/images/instagram.png" class="mr-4" alt=""></a>
                    <a href="mailto:info@pixentia.com" rel="noopener noreferrer" target="_blank"><img
                            src="../assets/images/Mail.png" alt=""></a>
                </div>
            </div>
            <div class="col-md-4 pl-0 d-none d-md-block">
                <div class="border_style"></div>
            </div>
        </div>
        <div class="row mt-4 Policy-section">
            <div class="col-md-12 text-center text-white">
                <a class="px-3 text-white d-block d-md-inline">{{currentYear}} © Pixentia. All Rights Reserved</a>
                <a class="text-white px-3 d-block d-md-inline" routerLink="/privacy-policy">Privacy
                    Policy</a>
                <a class="text-white px-3 d-block d-md-inline" routerLink="/site-map">Site Map</a>
                <a class="text-white px-3 d-block d-md-inline" routerLink="/disclaimer">Disclaimer</a>
                <a class="text-white px-3 d-block d-md-inline border-0" routerLink="/cookie-policy">Cookie Policy</a>
            </div>
        </div>
    </div>
</div>