<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/recruiting">Recruiting</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h1 class="font-weight-bold">Talent Acquisition Technology
                </h1>
                <p>Implementation, Support, and Optimization </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/hcm/recruting/recruiting-image.png" alt="Recruiting image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center text-center">
            <div class="col-md-10 offset-md-1">
                <h1 class="text-center mb-3 font-weight-bold">
                    Talent Acquisition Technology, Support, and Optimization Services
                </h1>
            </div>
            <div class="col-md-12 text-center">
                <p>
                    The right recruiting solution for your organization may be a simple applicant tracking system…
                </p>
                <p>
                    …a sophisticated platform with everything from recruitment marketing to onboarding...
                </p>
                <p>
                    …or anything in between.
                </p>
            </div>
            <div class="col-md-12 text-center mt-4">
                <p class="font-weight-bold orange-color">
                    Whatever your recruiting needs, Pixentia provides comprehensive services to
                </p>
                <p class="mb-2">
                    Assist you in selecting the solution that best fits your needs.
                </p>
                <p class="mb-2">
                    Implement your chosen solution and prepare you to manage it.
                </p>
                <p class="mb-2">
                    Support and optimize your software, so your recruiting ecosystem can grow with you.
                </p>
            </div>
        </div>
    </div>
    <div class="services_section">
        <div class="template_width">
            <div class="row mb-5">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified">
                        <li class="nav-item text-center">
                            <a class="nav-link active text-center font-weight-bold" data-toggle="pill" href="#strategy">
                                <img src="../../assets/images/hcm/talent/Strategy and planning.png" class="d-block mx-auto" alt=""> Strategy and Planning
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#deployment">
                                <img src="../../assets/images/hcm/talent/Preparation.png" class="d-block mx-auto" alt=""> Preparation and deployment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link font-weight-bold" data-toggle="pill" href="#optimization">
                                <img src="../../assets/images/hcm/talent/Optimization.png" class="d-block mx-auto" alt=""> Optimization
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="template_width">
        <div class="row">
            <div class="col-md-12">
                <!-- Tab panes -->
                <div class="tab-content my-5">
                    <div class="tab-pane active" id="strategy">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-3">A Strategic Approach to Planning
                                    </h4>
                                    <p>
                                        Prepare a strong foundation for your technology implementation with a focus on your business objectives. We want to understand your business needs so that we can make informed recommendations for your workforce needs.
                                    </p>
                                </div>
                                <div class="sub_head my-4">
                                    <h5 class="font-weight-bold">Our services include:</h5>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-5 services_list">
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Strategy/Technology Assessment.png" alt="Technology Assessment">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Technology Assessment</p>
                                        <p>
                                            A facilitated workshop to map your current technology, your plans, and where your project fits in your strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Strategy/Business Case Development.png" alt="Business Case Development">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">
                                            Business Case Development
                                        </p>
                                        <p>
                                            We provide tools and support for identifying the costs and benefits of your proposed solution and building your business case.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Strategy/Technology Roadmap.png" alt="Technology Roadmap">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Technology Roadmap</p>
                                        <p>
                                            Develop a long-term technology and support plan that will grow with you as your <span class="d-inline-block">needs change.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Strategy/Vendor Selection.png" alt="Vendor Selection">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Vendor Selection</p>
                                        <p>
                                            We help you see through the noise to select the right solution for your organization. Our services include support during the RFP process to formalize your requirements and evaluate responses.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Strategy/Project Governance.png" alt="Project Governance">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Project Governance</p>
                                        <p>
                                            A framework for communication and decisions that enables you to establish the responsibilities and accountabilities required for project success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Strategy/Change Management Support.png" alt="Change Management Support">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Change Management Support</p>
                                        <p>
                                            We assist you in assessing your organizational readiness, so you can shape and plan your communications to gain the support of <span class="d-inline-block">all your stakeholders.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Strategy/Project Analytics Strategy.png" alt="Project Analytics Strategy">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Project Analytics Strategy
                                        </p>
                                        <p>
                                            Develop metrics and measures to assess and report on project progress and assess the impact of your efforts on your organization.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="mt-4">
                                    <div class="text-center cta_bg mx-md-2 py-3">
                                        <h5 class="font-weight-bold mb-3">Are your people initiatives being turned down?
                                        </h5>
                                        <p>
                                            Start a conversation about people and performance in the language your CFO understands.
                                        </p>
                                        <p>
                                            Download our free e-book: Building the Business Case for talent management
                                        </p>
                                        <p>
                                            Learn how to build alliances to change how people think about people programs.
                                        </p>
                                        <p class="datasheet_btn mb-0">
                                            <a href="">Get E book</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="deployment">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head">
                                    <h4 class="font-weight-bold mb-3">A Structured, Scalable Approach to Implementation
                                    </h4>
                                    <p>
                                        Preparation, accountability, communication, and control are the foundations of a successful deployment. We use a disciplined approach that prepares you to realize a return on your investment.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Preparation/Project Planning.png" alt="Project Planning">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Project Planning</p>
                                        <p>
                                            Develop a phased project plan you will use to establish timelines and assign accountability, manage your resources, and control your risks.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Preparation/Data Cleansing.png" alt="Data Cleansing">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">
                                            Data Cleansing
                                        </p>
                                        <p>
                                            Tools and support for testing the condition of your data, so you can make an informed decision on whether and how to migrate legacy data to <span class="d-inline-block">your new platform.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Preparation/Data Migration.png" alt="Data Migration">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Data Migration</p>
                                        <p>
                                            Mapping and importing data to your new platform, eliminating outdated field names and data structures, and testing the quality and usability of the migrated data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Preparation/Workshops.png" alt="Workshops">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Workshops</p>
                                        <p>
                                            Workshops on business process alignment, configuration, and product training to get you started on your journey to becoming expert managers of your system.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-right border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Preparation/Configuration and Review.png" alt="Configuration and Review">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Configuration and Review</p>
                                        <p>
                                            Work side by side with our configuration experts to learn the tools in your platform to adapt it <span class="d-inline-block">to your business.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center border-bottom">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Preparation/Integration.png" alt="Integration">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Integration</p>
                                        <p>
                                            Data flow is the lifeblood of your organization. We use standardized, proven tools and technology to develop scalable integrations that can support you through the entire integration life cycle.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Preparation/Analytics.png" alt="Integration">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Analytics</p>
                                        <p>
                                            Consulting services and training to get you started in using your reporting tools. We also offer advanced analytics services, including predictive data models.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Preparation/Testing.png" alt="Integration">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Testing</p>
                                        <p>
                                            Support and resources for testing your applications to validate your business processes. We also offer automated testing that can test your processes to a fraction of the time needed for manual testing, without the inherent human error.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="optimization">
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head mt-5">
                                    <h5 class="font-weight-bold">Enabling Your Ongoing Success
                                    </h5>
                                    <p>
                                        Our Resource Centers provide direct support to your administrators for maintenance and operations, provide resources to fill a temporary gap, or serve as a ready backup for your administrator.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/Admin Resource Center.png" alt="Admin Resource Center">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Admin Resource Center</p>
                                        <p>
                                            Let our expert team support your administrators in day-to-day operations <span class="d-inline-block">and maintenance.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/Technology Resource Center.png" alt="Technology Resource Center">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">
                                            Technology Resource Center
                                        </p>
                                        <p>
                                            Support for technical tasks beyond daily administration, including updates and upgrades, third-party integrations, <span class="d-inline-block">and data feeds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/Analytics Resource Center.png" alt="Analytics Resource Center">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Analytics Resource Center
                                        </p>
                                        <p>
                                            Support for reporting, dashboards and visualizations, advanced analytics, and <span class="d-inline-block">data aggregation and storage.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/managed services.png" alt="Managed Services Center">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Managed Services Center</p>
                                        <p>
                                            We can handle all or any part of your operations and administration. You choose what services you need and <span class="d-inline-block">your level of support.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                        </div>
                        <div class="col-md-10 offset-md-1">
                            <div class="text-center">
                                <div class="sub_head mt-5">
                                    <h5 class="font-weight-bold mb-3">Optimization
                                    </h5>
                                    <h6 class="orange-color font-weight-bold mb-2">
                                        Don’t Work Around Your Technology—Make it Work for You</h6>
                                    <p>
                                        Comprehensive optimization services to help you achieve more operational value from your technology platform and to adapt your technology to the growing needs of your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row my-5 services_list">
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/Administration Optimization.png" alt="Administration Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Administration Optimization</p>
                                        <p>
                                            Analyze and optimize your administrative workflow to remove bottlenecks <span class="d-inline-block">and pain points.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/Security Optimization.png" alt="Security Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">
                                            Security Optimization
                                        </p>
                                        <p>
                                            Over time, organization and security configurations can become misaligned. Realign your security configuration to your organization and <span class="d-inline-block">the way you work.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/Business Process Optimization.png" alt="Business Process Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Business Process Optimization
                                        </p>
                                        <p>
                                            Tune up your business processes to eliminate workarounds. Automate offline processes with our fully compatible custom micro-apps.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/User Experience Optimization.png" alt="User Experience Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">User Experience Optimization</p>
                                        <p>
                                            Use your analytics and reporting tools to analyze user behavior and feedback so you can create an engaging, useful experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="py-2">
                                    <div class="mb-3 mt-4">
                                        <img src="../../assets/images/hcm/recruting/Support Service/Analytics.png" alt="Analytics Optimization">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold mb-1">Analytics Optimization</p>
                                        <p>
                                            Complete optimization services for all your reporting needs—from basic reporting to <span class="d-inline-block">advanced predictive analytics.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>