import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-digital-transformation',
  templateUrl: './hr-digital-transformation.component.html',
  styleUrls: ['./hr-digital-transformation.component.scss']
})
export class HrDigitalTransformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
