import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hcm-resource-center',
  templateUrl: './hcm-resource-center.component.html',
  styleUrls: ['./hcm-resource-center.component.scss']
})
export class HcmResourceCenterComponent implements OnInit {

  title = 'HCM Resource Center | Pixentia';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Pixentia’s HCM Center of Excellence guides you through making the right decisions in talent management technology implementation, maintenance, and upgrades.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'HCM Resource Center | Pixentia' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Pixentia’s HCM Center of Excellence guides you through making the right decisions in talent management technology implementation, maintenance, and upgrades.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://www.pixentia.com/services/hcm/hcm-resource-center' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'HCM Resource Center | Pixentia' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Pixentia’s HCM Center of Excellence guides you through making the right decisions in talent management technology implementation, maintenance, and upgrades.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://www.pixentia.com/services/hcm/hcm-resource-center' }      
    );
  }

}
