<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/mobile">Mobile Applications</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Custom Mobile Apps for all your business needs</h3>
                <p>Extend your enterprise with mobile apps for your partners, supply chain, and distribution channels.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../assets/images/mobile/Mobile-Applications-Image.png" alt="Mobile Application Image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.kubota.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/kuboto.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="http://www.intelsecurity.com" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/mcafee_logo.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.pheaa.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/pheaa.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.linde.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/linde.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.tmhcc.com/en/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/tokia-marine.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content_area_section">
    <div class="template_width">
        <div class="row mb-5 align-items-center">
            <div class="col-md-8 offset-md-2">
                <h1 class="text-center mb-5 font-weight-bold">
                    CROSS PLATFORM MOBILE APPLICATIONS
                </h1>
            </div>
            <div class="col-md-7">
                <p>
                    In this fast paced world, when the means of communication have completely been revamped due to the advancement of mobile phones, it’s time for each one of us to use technology as a medium to search for a broader spectrum of customers and keep welcoming
                    your business customers 24X7.
                </p>
                <p>
                    Pixentia is a pioneer in developing customized mobile applications that runs on almost all types of smart phone (Android, Blackberry, Windows and iOS).
                </p>
                <p>
                    When we manufacture for iOS, we streamline our applications for the iOS standard. When we construct for Android, we make full utilization of Android's usefulness. Fabricated with the DNA of the gadget they live on, local applications drive immersive versatile
                    encounters.
                </p>
                <p>
                    The idea is to build applications that saves customers time and money and in-turn increase your sales when you tap the eccentric customer-base.
                </p>
            </div>
            <div class="col-md-5">
                <img src="../../assets/images/mobile/Cross-Platform-Mobile-Application.png" class="img-fluid" style="width: 100%;" alt="Cross Platform Mobile Application">
            </div>
        </div>
    </div>
    <app-vendors></app-vendors>
</div>
<div class="template_width">
    <app-get-started></app-get-started>
</div>