<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/admin-resource">Admin Resource Center</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Dedicated services for LMS administration
                </h3>
                <p>Managing your learning operations is complex and often time-sensitive. Sometimes you need a little help…
                </p>
                <p>…or a lot.</p>
                <p>Pixentia’s LMS Learning Resource Center provides technical services for all your admin tasks, including updates and integrations.</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/admin-resource/Admin Resource Center.png" alt="Admin Resource Center" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Pixentia Admin Services</h1>
                </div>
                <div>
                    <p class="mb-3">
                        We provide experts and resources to assist you in managing any of your LMS administration needs.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-6">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/change-management/Analyzing the internal.png" alt="Automate">
                </div>
                <div class="px-5">
                    <p class="font-weight-bold">Help Desk Support</p>
                    <p>
                        On-call services to answer your questions, guide you through admin procedures, and resolve administrative issues. We handle things like login issues, registrations, and schedule management.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/change-management/Assessing organizational.png" alt="Offer">
                </div>
                <div class="px-5">
                    <p class="font-weight-bold">Technical Services</p>
                    <p>
                        Helping you manage technical tasks like update validations, LMS integrations, and launching new programs.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="admin_services_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Dedicated LMS Administration in 3 Easy Steps
                </h4>
                <div class="mt-4">
                    <p>
                        Whether you need an expert available on-call, a complete managed administration solution, or anything in between, Pixentia provides a plan designed for your specific needs and budget. We work with you to develop the service level that is right for you
                        in three steps.
                    </p>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-5 justify-content-center">
            <div class="flex_1">
                <div class="box text-center mb-2">
                    <div class="text-center">
                        <img src="../../../../../assets/images/hcm/learning/admin-resource/Tailored SOP and SLA.png" class="img-fluid" alt="Tailored SOP and SLA">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">
                            A Dedicated Admin Resource Manager
                        </p>
                        <p>
                            Pixentia started as a conversation among a group of like-minded technology consultants. Something was fundamentally wrong with the way many IT service providers approached their customers.
                        </p>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="d-none d-md-block arrow_sign">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/Line.png" alt="">
                </div>
                <div class="d-block d-md-none arrow_sign1">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/vertical-line.png" alt="">
                </div>
            </div>
            <div class="flex_1">
                <div class="box text-center">
                    <div class="text-center mb-2">
                        <img src="../../../../../assets/images/hcm/learning/admin-resource/We assign a team to you.png" class="img-fluid" alt="Tailored SOP and SLA">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">Tailored SOP and SLA</p>
                        <p>
                            Instead of understanding their client’s needs, these firms set out to sell a one-size-fits-all package that offered the least customer resistance.
                        </p>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="d-none d-md-block arrow_sign">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/Line.png" alt="">
                </div>
                <div class="d-block d-md-none arrow_sign1">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/vertical-line.png" alt="">
                </div>
            </div>
            <div class="flex_1">
                <div class="box text-center">
                    <div class="text-center mb-2">
                        <img src="../../../../../assets/images/hcm/learning/admin-resource/We assign a team to you.png" class="img-fluid" alt="We assign a team to you">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">We assign a team to you</p>
                        <p>
                            Instead of understanding their client’s needs, these firms set out to sell a one-size-fits-all package that offered the least customer resistance.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn more about how Pixentia Learning Technology Admin resource center can offer comprehensive services for managing your learning technology.
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to
                                    Learning Optimization <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>