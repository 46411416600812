<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/virtual-reality">Virtual Reality</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Remove the Barriers between Learning and Doing
                </h3>
                <p>Virtual Reality enables your trainees to make mistakes and learn from them in a simulated environment. Pixentia’s designers create VR to fit the job context so your people can learn real job skills without the risks.
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/vr/vr-image.png" alt="Augmented Reality image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">VR in Learning
                    </h1>
                </div>
                <div>
                    <p class="mb-3">
                        Lifelike simulation can enhance learning for almost any situation, but until recently, it has been out of reach for most occupations. Today, thanks to virtual reality (VR), they are within reach of almost any organization.
                    </p>
                    <p>
                        We can now create a near-perfect computer-generated work context for any job, where people can learn complex skills in a risk-free environment.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/vr/Where there is a need.png" alt="simplify complex problems">
                </div>
                <div class="px-5 mb-md-5">
                    <p>
                        Where there is a need to simplify complex problems or multiple sensory inputs to allow learners to build on experience
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/vr/Where we can.png" alt="real-time feedback">
                </div>
                <div class="px-5 mb-md-5">
                    <p>
                        Where we can use real-time feedback to allow people to learn as they explore a realistic simulation of the work context
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/vr/When we want to reduce.png" alt="reduce time to productivity">
                </div>
                <div class="px-5 mb-md-5">
                    <p>
                        When we want to reduce time to productivity and overall training cost with virtual work experience
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/vr/High-risk situations.png" alt="High-risk situations">
                </div>
                <div class="px-5">
                    <p>
                        High-risk situations that require fine manual skills, like surgery
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/vr/To develop the capability.png" alt="To develop the capability">
                </div>
                <div class="px-5">
                    <p>
                        To develop the capability to make rapid real-time decisions
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-12 text-center mt-4">
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">
                            Learn how you can boost learning retention with virtual reality learning solutions.
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to
                                    Learning Content <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>