import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chasma-lexy',
  templateUrl: './chasma-lexy.component.html',
  styleUrls: ['./chasma-lexy.component.scss']
})
export class ChasmaLexyComponent implements OnInit {

  title = 'Chasma Lexy | Virtual Assistant for HCM Applications';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: "Meet Lexy, the HR chatbot that can both lower your costs and improve HR response times. It works 24/7--and doesn't take vacations." }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Chasma Lexy | Virtual Assistant for HCM Applications' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: "Meet Lexy, the HR chatbot that can both lower your costs and improve HR response times. It works 24/7--and doesn't take vacations." }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/products/chasma-apps/chasma-lexy' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Chasma Lexy | Virtual Assistant for HCM Applications' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: "Meet Lexy, the HR chatbot that can both lower your costs and improve HR response times. It works 24/7--and doesn't take vacations." }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/products/chasma-apps/chasma-lexy' }      
    );
  }

}
