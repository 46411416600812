import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {

  title = 'Pixentia Learning Technology Integrations';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Upskilling the workforce to meet the demands of the digital economy is a top business priority. It requires learning at every phase of the employee lifecycle. It cannot be accomplished without tight integration of learning with your core HR and talent management operations' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Learning Technology Integrations' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'Upskilling the workforce to meet the demands of the digital economy is a top business priority. It requires learning at every phase of the employee lifecycle. It cannot be accomplished without tight integration of learning with your core HR and talent management operations' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/hcm/learning/integration' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Learning Technology Integrations' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'Upskilling the workforce to meet the demands of the digital economy is a top business priority. It requires learning at every phase of the employee lifecycle. It cannot be accomplished without tight integration of learning with your core HR and talent management operations' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/hcm/learning/integration' }      
    );
  }

}
