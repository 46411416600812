import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hcm-managed-services',
  templateUrl: './hcm-managed-services.component.html',
  styleUrls: ['./hcm-managed-services.component.scss']
})
export class HcmManagedServicesComponent implements OnInit {

  title = 'Pixentia Managed Services Center';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private metaOgTitleTagService: Meta,
    private metaOgDescTagService: Meta,
    private metaOgUrlTagService: Meta,
    private metaOgTwitterTitleTagService: Meta,
    private metaOgTwitterDescTagService: Meta,
    private metaOgTwitterUrlTagService: Meta,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'If your Core HR, talent, or learning administration is an unwelcome burden, let Pixentia carry it for you. so you can focus on your people.' }
    );
    this.metaOgTitleTagService.updateTag(
      { property: 'og:title', content: 'Pixentia Managed Services Center' }      
    );
    this.metaOgDescTagService.updateTag(
      { property: 'og:description', content: 'If your Core HR, talent, or learning administration is an unwelcome burden, let Pixentia carry it for you. so you can focus on your people.' }      
    );
    this.metaOgUrlTagService.updateTag(
      { property: 'og:url', content: 'https://pixentia.com/services/hcm/hcm-coe/managed-services' }      
    );
    this.metaOgTwitterTitleTagService.updateTag(
      { property: 'twitter:title', content: 'Pixentia Managed Services Center' }      
    );
    this.metaOgTwitterDescTagService.updateTag(
      { property: 'twitter:description', content: 'If your Core HR, talent, or learning administration is an unwelcome burden, let Pixentia carry it for you. so you can focus on your people.' }      
    );
    this.metaOgTwitterUrlTagService.updateTag(
      { property: 'twitter:url', content: 'https://pixentia.com/services/hcm/hcm-coe/managed-services' }      
    );
  }

}