<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/integration">Integrations</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">SumTotal<sup>&reg;</sup> Integrations
                </h3>
                <p>Drive Workforce Development With Connected Learning
                </p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/integrations/Integration.png" alt="Change management image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Integrate Your Learning And Development Data Flow
                    </h1>
                </div>
                <div>
                    <p class="mb-3">
                        A skilled workforce requires learning at every phase of the employee lifecycle, but it cannot be accomplished without tight integration of learning with your core HR and talent management systems.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h3 class="font-weight-bold">Why Integrate?
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/integrations/Efficiency.png" alt="Efficiency">
                </div>
                <div class="px-5">
                    <p class="font-weight-bold mb-1">Efficiency</p>
                    <p>
                        Every time your people key data into more than one application, you lose productivity. It would take days for someone to type 5,000 records into a computer. A data integration, once composed by a human, can do it <span class="d-inline-block">in a minute or less</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/integrations/Data Control.png" alt="Data Control">
                </div>
                <div class="px-5">
                    <p class="font-weight-bold mb-1">Data Control</p>
                    <p>
                        People make data entry errors. To reduce those errors, strive to integrate your systems so well that information need only be entered once to use where ever you need it
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/integrations/A Single Source of Truth.png" alt="A Single Source of Truth">
                </div>
                <div class="px-5">
                    <p class="font-weight-bold mb-1">A Single Source of Truth</p>
                    <p>
                        When separate parts of your organization report different data on the same information, reporting and compliance can be risky. Each dataset should have only one master record
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Talent Development</h4>
                <p class="mb-4">Learning drives success in every phase of the employee lifecycle, from onboarding to offboarding.
                </p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/integrations/Recruiting.png" alt="Out-of-the-box Apps" class="mb-3">
                    <p class="font-weight-bold mb-1">Recruiting</p>
                    <p class="px-4">
                        Deliver what prospects need to know through your LMS
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/integrations/Onboarding.png" alt="Onboarding" class="mb-3">
                    <p class="font-weight-bold mb-1">Onboarding</p>
                    <p class="px-4">
                        Deliver information about policies and work rules, pay and benefits, and your culture
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/integrations/Internal Hiring.png" alt="Internal Hiring" class="mb-3">
                    <p class="font-weight-bold mb-1">Internal Hiring</p>
                    <p class="px-4">
                        An employee’s training record can tell you a lot about an individual’s interest in and readiness for a new role
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/integrations/Development.png" alt="Development" class="mb-3">
                    <p class="font-weight-bold mb-1">Development</p>
                    <p class="px-4">
                        Any worthy development planning tool will have a connection to your learning resources with complete descriptions of the content
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/integrations/Succession Planning and Internal Hiring.png" alt="Succession Planning and Internal Hiring" class="mb-3">
                    <p class="font-weight-bold mb-1">Succession Planning and Internal Hiring</p>
                    <p class="px-4">
                        Each individual’s development record is a critical component for assessing potential and readiness
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/integrations/Collaboration.png" alt="Collaboration" class="mb-3">
                    <p class="font-weight-bold mb-1">Collaboration</p>
                    <p class="px-4">
                        Making social learning available through your collaboration tools and social media creates boundless opportunity to reinforce and share knowledge
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-4">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-12 text-center sub_head">
                <h4 class="font-weight-bold pt-4 mb-3">Work with Seasoned Integration Experts
                </h4>
                <p>
                    Pixentia’s experts have over two decades of experience in integrating learning platforms with HR systems. We use standardized, proven tools and technology. Our open architecture approach and careful documentation will help you through the entire integration
                    lifecycle.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn more about how you can integrate learning with your core HR and talent management systems.

                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to
                                    Preparation and Planning <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>