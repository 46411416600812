<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe">HCM COE</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/hcm-coe/technology">Technology Resource Center</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">On-Call Technical Assistance
                </h3>
                <p>Our Technology Resource Center helps you manage admin technical tasks, from user support to data integrations, from on-call support to complete managed services.</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/hcm-coe/technology-resource/Technology Image.png" alt="Technology Resorce image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Technical Issues</h1>
                </div>
                <div>
                    <p class="mb-3">
                        Our technology resource center is available to assist your administrators with administrative tasks and technical user issues. Services will typically include:
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Technical administrative tasks.png" alt="Technical administrative tasks">
                </div>
                <div class="px-5">
                    <p>
                        Technical administrative tasks
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Integrations.png" alt="Integrations">
                </div>
                <div class="px-5">
                    <p>
                        Integrations
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Instructor and resource management.png" alt="Instructor and resource management">
                </div>
                <div class="px-5">
                    <p>
                        Instructor and resource management
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Course set up and.png" alt="Course set up and new program launch guidance">
                </div>
                <div class="px-5">
                    <p>
                        Course set up and new program launch guidance
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Database backups.png" alt="Database backups">
                </div>
                <div class="px-5">
                    <p>
                        Database backups
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Job scheduling and maintenance.png" alt="Job scheduling and maintenance">
                </div>
                <div class="px-5">
                    <p>
                        Job scheduling and maintenance
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Integrations</h4>
                <div class="mt-4">
                    <p>
                        Integrations are the lifeblood of the modern <a href="services/hcm/enterprise-integrations">enterprise</a>. The efficient flow of data to where your people need it can:
                    </p>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Fuel process efficiency.png" alt="Fuel process efficiency">
                    </div>
                    <div class="px-5">
                        <p>
                            Fuel process efficiency.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Drive better job performance.png" alt="Drive better job performance">
                    </div>
                    <div class="px-5">
                        <p>
                            Drive better job performance.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Enable better and faster.png" alt="Enable better and faster decisions at every level of your organization">
                    </div>
                    <div class="px-5">
                        <p>
                            Enable better and faster decisions at every level of your organization.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center">
                <p>
                    Pixentia’s experts have over two decades of experience in integrating learning platforms with enterprise systems. Our open architecture approach and careful documentation will help you through the entire integration life cycle. Our services include:
                </p>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/HRIS nightly data feeds.png" alt="HRIS nightly data feeds">
                    </div>
                    <div class="px-5">
                        <p>
                            HRIS nightly data feeds
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/3rd party learning.png" alt="3rd party learning content and curation tools">
                    </div>
                    <div class="px-5">
                        <p>
                            3rd party learning content and curation tools
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Data feeds for people analytics.png" alt="Data feeds for people analytics">
                    </div>
                    <div class="px-5">
                        <p>
                            Data feeds for <a href="services/hcm/peopleanalytics">people analytics</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/E-commerce and.png" alt="E-commerce and customer learning portals">
                    </div>
                    <div class="px-5">
                        <p>
                            E-commerce and customer learning portals
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Partner learning and.png" alt="Partner learning and certification portals; and any other enterprise software">
                    </div>
                    <div class="px-5">
                        <p>
                            Partner learning and certification portals
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-5">
                <div class="text-center">
                    <div class="mb-3">
                        <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Talent management integration.png" alt="Talent management integrations, such as onboarding, development, and succession planning">
                    </div>
                    <div class="px-5">
                        <p>
                            Talent management integrations, such as onboarding, development, and succession planning
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-4">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="col-md-12 sub_head">
                    <h4 class="font-weight-bold pt-4 mb-3">Software Updates and Upgrades</h4>
                    <p>
                        Most cloud technology vendors provide system updates twice a year, but many do so more or less frequently. The updates often contain changes in functionality that can have an impact on your operations. Some features may be deployed automatically, while
                        you can decide for yourself whether to use others.
                    </p>
                    <p>
                        We recommend that you treat a significant upgrade with the same care as a new implementation, forming a project team, and testing your work processes from end to end in a safe staging environment.
                    </p>
                    <p>
                        Our Technology Resource Center will assist your preparation, testing, and training, and helping you with communicating and managing changes in the way you operate.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-center">
                <div>
                    <img src="../../../../../assets/images/hcm/hcm-coe/technology-resource/Updates and Upgrades.png" alt="Updates and Upgrades">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="promise_section resource_guides_section">
    <div class="template_width">
        <div class="row text-white align-items-center">
            <div class="col-md-6">
                <div>
                    <img src="../../../../../assets/images/hcm/hcm-coe/technology-resource/Testing.png" alt="Testing">
                </div>
            </div>
            <div class="col-md-6">
                <div class="col-md-12">
                    <h4 class="font-weight-bold pt-4 mb-3">Testing</h4>
                    <p>
                        Manual testing of regular updates can be easy to overlook since your vendor tests updates before they release them. However, they don’t test your configuration, your integrations, or your content delivery.
                    </p>
                    <p>
                        The good news for you is that we can automate your update testing. We often complete it in just a few hours, minimizing the amount of labor-intensive, manual testing you need to do.
                    </p>
                    <p>
                        We also assist in testing new programs and learning content, organizational changes, new learning delivery modes, and reports.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-12 text-center">
            <div class="sub_head pb-4">
                <h4 class="font-weight-bold">Analytics</h4>
                <p>
                    Call on our technology resource center when you need assistance in using your reporting tools. Our reporting experts can help you with any of the tasks or functions in reporting.
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Scheduling and distributing.png" alt="Scheduling and distributing reports to your users">
                </div>
                <div class="px-5">
                    <p>
                        Scheduling and distributing reports to your users.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Minimizing server loads.png" alt="Minimizing server loads during peak time">
                </div>
                <div class="px-5">
                    <p>
                        Minimizing server loads during peak time.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Data governance.png" alt="Data governance and best practices for managing report names">
                </div>
                <div class="px-5">
                    <p>
                        Data governance and best practices for managing report names.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Creating and managing.png" alt="Creating and managing ad hoc views, including custom fields, calculated fields, filters, and replicated tables">
                </div>
                <div class="px-5">
                    <p>
                        Creating and managing ad hoc views, including custom fields, calculated fields, filters, and replicated tables.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Using custom domains.png" alt="Using custom domains, domain topics, and pre-filters to refine data">
                </div>
                <div class="px-5">
                    <p>
                        Using custom domains, domain topics, and pre-filters to refine data.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-5">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/hcm-coe/technology-resource/Choosing the right report.png" alt="Choosing the right report type or visualization for your business use case">
                </div>
                <div class="px-5">
                    <p>
                        Choosing the right report type or visualization for your business use case.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="admin_services_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Dedicated Technical Services in 3 Easy Steps
                </h4>
                <div class="mt-4">
                    <p>
                        Whether you need an expert available on-call, a complete managed services solution, or anything in between, Pixentia provides a plan designed for your specific needs and budget. We work with you to develop the service level right for you in easy three
                        steps.
                    </p>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-5 justify-content-center">
            <div class="flex_1">
                <div class="box text-center mb-2">
                    <div class="text-center">
                        <img src="../../../../../assets/images/hcm/hcm-coe/admin-resource/Tailored SOP and SLA.png" class="img-fluid" alt="Tailored SOP and SLA">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">
                            A Dedicated Technology Resource Manager
                        </p>
                        <p>
                            We introduce you to your resource manager who will work with you to design your service package.
                        </p>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="d-none d-md-block arrow_sign">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/Line.png" alt="">
                </div>
                <div class="d-block d-md-none arrow_sign1">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/vertical-line.png" alt="">
                </div>
            </div>
            <div class="flex_1">
                <div class="box text-center">
                    <div class="text-center mb-2">
                        <img src="../../../../../assets/images/hcm/hcm-coe/admin-resource/We assign a team to you.png" class="img-fluid" alt="Tailored SOP and SLA">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">Tailored SOP and SLA</p>
                        <p>
                            Your manager will work with you to understand your requirements, then negotiate Standard Operating Procedures (SOPs) and a Service Level Agreement (SLA) designed for your service requirements.
                        </p>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="d-none d-md-block arrow_sign">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/Line.png" alt="">
                </div>
                <div class="d-block d-md-none arrow_sign1">
                    <img src="../../../../../assets/images/hcm/learning/admin-resource/vertical-line.png" alt="">
                </div>
            </div>
            <div class="flex_1">
                <div class="box text-center">
                    <div class="text-center mb-2">
                        <img src="../../../../../assets/images/hcm/hcm-coe/admin-resource/We assign a team to you.png" class="img-fluid" alt="We assign a team to you">
                    </div>
                    <div>
                        <p class="font-weight-bold mb-1">We assign a team to you</p>
                        <p>
                            You can contact your team by email, phone, or online chat, whenever the need arises.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Need help with any technical task, like update validations, third-party integrations, analytics and more?
                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/hcm-coe"> Back to
                                    Resource Center <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-vendors></app-vendors>
<div class="template_width">
    <app-get-started></app-get-started>
</div>