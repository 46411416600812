<div class="bannerSlide1 text-white banner_section">
    <div class="breadcrumbs">
        <ul>
            <li><a href="/">Home</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Services</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a>Human Capital</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning">Learning</a></li>
            <li><i class="fa fa-angle-right ml-0" aria-hidden="true"></i></li>
            <li><a href="services/hcm/learning/business-workshop">Business Workshop</a></li>
        </ul>
    </div>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div>
                <h3 class="font-weight-bold">Business Workshop
                </h3>
                <p>Achieve Purpose and Alignment</p>
            </div>
        </div>
        <div class="col-md-6 text-md-right text-center">
            <div>
                <img src="../../../../../assets/images/hcm/learning/business-workshops/business-workshop-image.png" alt="Change management image" class="img-fluid px-5">
            </div>
        </div>
    </div>
</div>
<div class="template_width">
    <div class="row">
        <div class="col-md-12 py-5">
            <div class="text-center">
                <p>TRUSTED BY ORGANIZATIONS ACROSS THE WORLD</p>
            </div>
            <div class="d-md-flex clients_section justify-content-center text-center">
                <div>
                    <a href="https://www.abbott.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/abbott.png" alt="Abbott"></a>
                </div>
                <div>
                    <a href="https://www.cabionline.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cabi.png" alt="Cabi"></a>
                </div>
                <div>
                    <a href="https://www.cuna.org/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/cuna.png" alt="Cuna"></a>
                </div>
                <div>
                    <a href="https://www.hcsc.com/" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/hcsc.png" alt="HCSC"></a>
                </div>
                <div>
                    <a href="https://www.volkswagenag.com//" rel="noopener noreferrer" target="_blank"><img src="../../assets/images/clients/vw.png" alt="Volks Wagen"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 who_we_are_section">
            <div class="text-center">
                <div class="sub_head">
                    <h1 class="font-weight-bold">Align Your LMS to Your Business</h1>
                </div>
                <div>
                    <p class="mb-3">
                        Our business workshops support your technology deployment with a detailed analysis of your business practices and learning processes. Our business workshops have three primary objectives:
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Establish the learning.png" alt="Establish the learning priorities critical to your business">
                </div>
                <div class="px-5">
                    <p>
                        Establish the learning priorities critical to your business
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Define your current.png" alt="Define your current learning practices and processes">
                </div>
                <div class="px-5">
                    <p>
                        Define your current learning practices and processes
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Optimize your practices.png" alt="Optimize your practices and processes">
                </div>
                <div class="px-5">
                    <p>
                        Optimize your practices and processes
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-center mt-4">
            <p class="mb-0">
                We will understand your business before we propose a solution. We target our recommendations to your specific objectives and challenges.
            </p>
        </div>
    </div>
</div>
<div class="promise_section">
    <div class="template_width">
        <div class="row text-white">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 pb-2 mb-0">Learning Technology Business Workshop</h4>
                <div class="mt-4">
                    <p>
                        A thorough understanding of your business practices and processes is essential to adapting Learning Technology to the way your people work. We work with you to understand the internal and external forces that drive your learning strategy, so we can see
                        how to best leverage your technology to improve them.
                    </p>
                    <p>
                        We strive to understand your business before we propose a solution, and we target our recommendations to your specific objectives and challenges.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="community_section my-4">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-12 text-center sub_head">
                <h4 class="font-weight-bold pt-4 mb-3">Process Workshop</h4>
                <p>
                    Process work begins with mapping your current workflows to analyze and improve them.
                </p>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/business-workshops/Assist you in mapping.png" alt="Assist you in mapping the processes" class="mb-3">
                    <p class="px-4">
                        Assist you in mapping the processes as they exist, including offline processes
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/business-workshops/Learn how users in.png" alt="Learn how users in each role access the platform and do their work" class="mb-3">
                    <p class="px-4">
                        Learn how users in each role access the platform and do their work
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-bottom">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/business-workshops/Use your process map.png" alt="Use your process map to analyze current workflows" class="mb-3">
                    <p class="px-4">
                        Use your process map to analyze current workflows
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/business-workshops/Identify functionality gaps.png" alt="functionality gaps" class="mb-3">
                    <p class="px-4">
                        Identify functionality gaps, bottlenecks, pain points, and workarounds in your existing workflows
                    </p>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/business-workshops/Recommend how you.png" alt="workflow tools" class="mb-3">
                    <p class="px-4">
                        Recommend how you can improve processes using workflow tools
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box text-center my-4">
                    <img src="../../../../../assets/images/hcm/learning/business-workshops/Map the desired future state.png" alt="Map the desired future state" class="mb-3">
                    <p class="px-4">
                        Map the desired future state
                    </p>
                </div>
            </div>
            <div class="col-md-12 text-center mt-4">
                <p class="mb-0">
                    This work serves as the foundation for your configuration workshop where you will apply your business rules to your technology platform.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="promise_section resource_guides_section">
    <div class="template_width">
        <div class="row text-white align-items-center">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 mb-3">LMS Training Workshop</h4>
                <h4 class="font-weight-bold pb-4 mb-0">Learn How to Manage Your LMS for the Way You Do Business </h4>
                <p>
                    Your configuration workshop and administrator training will show you what you can do to manage your learning platform. But what you can do differs from what you should do to improve business impact. Pixentia’s training workshop shows you how to use those
                    tools to your best advantage.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-12 text-center">
            <div class="sub_head pb-4">
                <h4 class="font-weight-bold">Pixentia Learning Technology Implementation Training Workshop</h4>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Simplify Administration.png" alt="Simplify Administration">
                </div>
                <div class="px-5">
                    <p>
                        Simplify Administration
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Deliver a User-Centric Experience.png" alt="Deliver a User-Centric Experience">
                </div>
                <div class="px-5">
                    <p>
                        Deliver a User-Centric Experience
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Increase User Engagement.png" alt="Increase User Engagement">
                </div>
                <div class="px-5">
                    <p>
                        Increase User Engagement
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-12 text-center">
            <div class="sub_head pb-4">
                <h4 class="font-weight-bold">Make Organizational Structures Simple
                </h4>
                <p>
                    <a href="services/learning/learningmanagementsystems">Enterprise learning management systems</a> have tools to define your organizational structure, learner groups, and content. Those tools require knowledge of what happens behind
                    the user interface.
                </p>
                <p>
                    Pixentia’s training team shows you how to plan your structures to make them both useful and flexible, so you can handle organizational changes as they happen. That includes:
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/How to gather requirements.png" alt="How to gather requirements from your user groups">
                </div>
                <div class="px-5">
                    <p>
                        How to gather requirements from your user groups
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Setting up your structures.png" alt="Setting up your structures">
                </div>
                <div class="px-5">
                    <p>
                        Setting up your structures
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Managing structural changes.png" alt="Managing structural changes">
                </div>
                <div class="px-5">
                    <p>
                        Managing structural changes
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-center mt-4">
            <p class="mb-0">
                We will understand your business before we propose a solution. We target our recommendations to your specific objectives and challenges.
            </p>
        </div>
    </div>
</div>
<div class="promise_section resource_guides_section">
    <div class="template_width">
        <div class="row text-white align-items-center">
            <div class="col-md-12 text-center">
                <h4 class="font-weight-bold pt-4 mb-3">Delivering a Useful, Engaging Experience</h4>
                <p class="">What is a useful, engaging experience?
                </p>
            </div>
            <div class="col-md-6">
                <div>
                    <img src="../../../../../assets/images/hcm/learning/business-workshops/Delivering a Useful.png" class="img-fluid" width="100%" alt="">

                </div>
            </div>
            <div class="col-md-6">
                <div class="video_link deliver_section">
                    <div class="d-flex align-items-center mt-3">
                        <p>
                            <img src="../../../../../assets/images/hcm/learning/business-workshops/As learners.png" class="mr-2" alt="Planning your testing">
                        </p>
                        <p class="ml-3">
                            As learners, we want quick access to the training and resources we need when we need them. It would be learning content and delivery that meets our needs and helps us to do a better job and improve our prospects
                        </p>
                    </div>
                    <div class="d-flex align-items-center">
                        <p>
                            <img src="../../../../../assets/images/hcm/learning/business-workshops/As managers.png" class="mr-2" alt="Planning your testing">
                        </p>
                        <p class="ml-3">
                            As managers, we need a holistic picture of each person’s learning path and progress, and having development resources at the moment we need them
                        </p>
                    </div>
                    <div class="d-flex align-items-center">
                        <p>
                            <img src="../../../../../assets/images/hcm/learning/business-workshops/As administrators.png" class="mr-2" alt="Planning your testing">
                        </p>
                        <p class="ml-3">
                            As administrators, we want to manage the structures and resources with an intuitive interface that doesn’t require an engineering degree to operate
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-10 offset-md-1 text-center">
                <p class="mt-4 mb-0">
                    Pixentia’s core purpose is to deliver on that promise. We do that by keeping the user’s needs first and enabling you to give them that useful, engaging experience.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="template_width services_lists">
    <div class="row my-5">
        <div class="col-md-12 text-center">
            <div class="sub_head pb-4">
                <h4 class="font-weight-bold mb-4">Increasing User Engagement
                </h4>
                <p>
                    Every user notification, registration form, homepage, news page, certificate, and diploma is an opportunity to communicate with your people about the value of their contribution to your organization. They can also be opportunities for them to interact
                    with you.
                </p>
                <p>
                    To facilitate that conversation, Pixentia’s experts will show you how to:
                </p>
            </div>
        </div>
        <div class="col-md-4 border-right border-bottom">
            <div class="text-center my-4">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Structure notifications.png" alt="Structure notifications">
                </div>
                <div class="px-5">
                    <p>
                        Structure notifications to benefit from each communication opportunity to reinforce your company culture
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 border-right border-bottom">
            <div class="text-center my-4">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Deliver personalized.png" alt="Deliver personalized and targeted interactive news pages">
                </div>
                <div class="px-5">
                    <p>
                        Deliver personalized and targeted interactive news pages
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4 border-bottom">
            <div class="text-center my-4">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Display progress and achievements.png" alt="Display progress and achievements">
                </div>
                <div class="px-5">
                    <p>
                        Display progress and achievements, so each learner knows how they are doing
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4 border-right">
            <div class="text-center my-4">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Use calendar.png" alt="Use calendars and announcements">
                </div>
                <div class="px-5">
                    <p>
                        Use calendars and announcements to encourage engagement in learning
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="text-center my-4">
                <div class="mb-3">
                    <img src="../../../../..//assets/images/hcm/learning/business-workshops/Create certificates.png" alt="Deploy">
                </div>
                <div class="px-5">
                    <p>
                        Create certificates and diplomas that communicate the value of skills earned
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>

</div>
<app-vendors></app-vendors>
<div class="my-5">
    <div class="template_width">
        <div class="row align-items-center">
            <div class="col-md-10 offset-md-1">
                <div class="row align-items-center">
                    <div class="col-md-7 mt-5 mt-md-0">
                        <p class="sub_heading_cta mb-4 font-weight-bold">Learn how our workshops can help you align your learning technology to your evolving business needs.

                        </p>
                        <div class="more_info_btn datasheet_btn d-md-flex">
                            <div>
                                <a class="mr-3" href="contact-us">Contact Us <i class="fa fa-angle-right ml-2"
                                        aria-hidden="true"></i></a>
                            </div>
                            <div class="mt-4 mt-md-0">
                                <a class="back-btn d-block d-md-inline" href="services/hcm/learning"> Back to Preparation and Planning  <i class="fa fa-angle-right ml-2" aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-4 mt-md-0">
                            <img src="../../../../../assets/images/hcm/learning/change-management/cta-img.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>